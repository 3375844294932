import React from 'react';

import { Select } from '@lucidhq/lucidium';

import { useFetchTargetAudienceOptions } from '~/components/Shared/hooks/useFetchTargetAudienceOptions';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type SelectInputProps = {
  questionId: number;
  value?: (number | string)[];
  onChange: (value: (number | string)[]) => void;
};

export const SelectInput = ({
  questionId,
  value,
  onChange
}: SelectInputProps) => {
  const { managedCampaign } = useCampaignManager();

  const { data } = useFetchTargetAudienceOptions(
    questionId,
    managedCampaign?.details.country_language_id
  );

  return (
    <Select
      value={value as number[]}
      isMulti
      labelKey="option_text"
      valueKey="precode"
      name="option"
      options={data?.question_options}
      placeholder={t('forms.placeholders.options')}
      onChange={(e: number[] | null) => e && onChange(e)}
    />
  );
};
