import React from 'react';

import {
  Button,
  DialogContent,
  Flex,
  H4,
  InlineNotification,
  LssProp
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { IPMatchedID } from './IPMatchingProvider';

type DuplicateIDsDialogContentProps = {
  handleToggle: () => void;
  duplicateIds: IPMatchedID[];
};

//NOTE: this method is used to format the list of duplicate ids to match the design for the inline notification in the dialog below.
const formatDuplicateIdsStr = (duplicateIds: IPMatchedID[]): string => {
  if (!duplicateIds?.length) return '';

  if (duplicateIds?.length === 1) return `${duplicateIds[0]?.ip_matching_id}`;

  if (duplicateIds?.length === 2) {
    return `${duplicateIds[0]?.ip_matching_id} and ${duplicateIds[1]?.ip_matching_id}`;
  }

  const copy = [...duplicateIds];
  const ids = copy.map(item => item?.ip_matching_id);
  const lastIndex = ids.length - 1;

  ids[ids.length - 1] = `and ${ids[lastIndex]}`;

  return ids.join(', ');
};

const dialogContentLss: LssProp = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '1.5rem',
  padding: '2rem 1rem'
};

const buttonLss: LssProp = {
  display: 'flex',
  maxWidth: '5.5rem',
  alignSelf: 'center',
  justifyContent: 'center'
};

export const DuplicateIDsDialogContent = ({
  handleToggle,
  duplicateIds
}: DuplicateIDsDialogContentProps) => {
  const duplicateIdsStr = formatDuplicateIdsStr(duplicateIds);

  return (
    <DialogContent lss={dialogContentLss}>
      <Flex lss={{ gap: '3rem', flexDirection: 'column' }}>
        <H4 lss={{ textWrap: 'balance', textAlign: 'center' } as LssProp}>
          {t('ipMatching.duplicateIDsDialog.title')}
        </H4>
        <InlineNotification
          iconLss={{ color: 'primary', alignSelf: 'flex-start' }}
          palette="warning"
          title={t('ipMatching.duplicateIDsDialog.notificationTitle')}
          lss={{ wordWrap: 'normal' }}
        >
          {t('ipMatching.duplicateIDsDialog.notificationMsg', {
            duplicateIds: duplicateIdsStr
          })}
        </InlineNotification>
      </Flex>
      <Button
        type="button"
        scale="md"
        palette="secondary"
        emphasis="high"
        onClick={handleToggle}
        lss={buttonLss}
      >
        {t('ipMatching.actions.close')}
      </Button>
    </DialogContent>
  );
};
