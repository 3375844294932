import React, { useMemo } from 'react';

import { BusyButton, Button, Flex, LssProp, Text } from '@lucidhq/lucidium';
import { Form } from '@lucidhq/lucidium/components/FormV2';

import {
  CampaignPixelVariables,
  PixelQueryParams
} from '~/components/Shared/hooks/useMutateCampaignPixelGroup';
import { t } from '~/utils/i18n';

import { PixelQueryParamForm } from './PixelQueryParamsForm';

type SiteServedTagFormProps = {
  onSubmit: (params: CampaignPixelVariables) => void;
  isLoading: boolean;
  onCancel: () => void;
  initialValues?: PixelQueryParams;
};

const INITIAL_VALUES = {
  sid: '',
  pid: '',
  crid: '',
  cachebuster: '',
  device_id: '',
  c1: '',
  c2: '',
  c3: '',
  c4: '',
  c5: ''
};

const actionsLss: LssProp = {
  justifyContent: 'center',
  mt: 'xxl',
  mb: 'xxl'
};

export const SiteServedTagForm = ({
  onSubmit,
  onCancel,
  isLoading,
  initialValues = INITIAL_VALUES
}: SiteServedTagFormProps) => {
  const finalInitialValues = useMemo(() => {
    return {
      ...INITIAL_VALUES,
      ...initialValues
    };
  }, [initialValues]);

  const formattedQueryParams = useMemo(() => {
    return Object.entries(finalInitialValues).map(([k, v]) => ({
      name: k,
      label: t(`forms.labels.${k}`),
      initialValue: v
    }));
  }, [finalInitialValues]);

  return (
    <>
      <Text lss={{ mb: 'xl' }}>
        {t('campaignManager.mediaTracking.siteServed.form.description')}
      </Text>
      <Form
        initialValues={finalInitialValues}
        enableReinitialize
        onSubmit={async queryParams => {
          await onSubmit({
            query_params: queryParams
          });
        }}
      >
        {<PixelQueryParamForm params={formattedQueryParams} />}

        <Flex lss={actionsLss}>
          <BusyButton isBusy={isLoading} type="submit" lss={{ mr: 'md' }}>
            {t('campaignManager.actions.saveAndClose')}
          </BusyButton>
          <Button
            type="button"
            emphasis="medium"
            palette="secondary"
            onClick={onCancel}
          >
            {t('campaignManager.actions.cancel')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};
