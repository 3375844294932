import React from 'react';

import { Checkbox, Flex, Label } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

type CheckboxFieldProps = {
  name: string;
  label: string;
  description?: string;
};

export const CheckboxField = ({ name, label }: CheckboxFieldProps) => {
  const [field, , helpers] = useField(name);
  const handleToggle = () => helpers.setValue(!field.value);

  return (
    <Flex lss={{ mb: 'xl', alignItems: 'center' }}>
      <Checkbox
        name={field.name}
        checked={!!field.value}
        onChange={handleToggle}
      />

      <Flex lss={{ ml: 'md' }}>
        <Label lss={{ mb: 0 }}>{label}</Label>
      </Flex>
    </Flex>
  );
};
