import React from 'react';

import {
  FormikContextType,
  useFormikContext
} from '@lucidhq/lucidium/components/FormV2';

type FormPropsProps<T> = {
  children: (props: FormikContextType<T>) => React.ReactNode;
};

export const FormProps = <T extends unknown>({
  children
}: FormPropsProps<T>) => {
  const props = useFormikContext<T>();
  return <>{children(props)}</>;
};
