import React, { useCallback, useMemo, useState } from 'react';
import { MdStar } from 'react-icons/md';

import {
  Box,
  Flex,
  Icon,
  List,
  ListItem,
  LssProp,
  Tag,
  Text
} from '@lucidhq/lucidium';

import {
  TargetAudienceQuestion,
  TargetAudienceQuestionType,
  useFetchTargetAudienceQuestions
} from '~/components/Shared/hooks/useFetchTargetAudienceQuestions';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { SegmentItem } from '../../TargetAudienceStep/TargetedRecruitment/SegmentItem';
import { Colors, ReviewLayout } from '../Misc/ReviewLayout';
import { AudienceBlockAnswers } from './AudienceBlock/AudienceBlockAnswers';
import { AudienceNotRequired } from './AudienceBlock/AudienceNotRequired';
import { AudienceWarning } from './AudienceBlock/AudienceWarning';

const headingLss: LssProp = {
  fontSize: '1.1rem',
  fontWeight: 'bold',
  mb: 'sm'
};

export const AudienceBlock = () => {
  const {
    managedCampaign,
    goToStep,
    steps,
    can,
    refetchCampaign
  } = useCampaignManager();
  const [audienceAck, setAudienceAck] = useState<Boolean>(
    !!managedCampaign?.details.meta.targetAudAck
  );
  const { mutate } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );
  const { data } = useFetchTargetAudienceQuestions(
    managedCampaign?.details.country_language_id
  );
  const { isOn } = useSplit(IM_FLAGS.TARGETED_RECRUITMENT);

  const questions = data?.questions;
  const targets = managedCampaign?.details.draft_settings.target_audiences;

  const currentQuestions = useMemo(() => {
    if (!questions || !targets) return null;

    const ids = targets?.reduce((acc, target) => {
      return [
        ...acc,
        ...target.qualifications.map(qual => qual.marketplace_question_id)
      ];
    }, [] as number[]);

    const filteredQuestions = questions.filter(q =>
      ids.includes(q.question_id)
    );

    return filteredQuestions.reduce((acc, q) => {
      return {
        ...acc,
        [q.question_id]: q
      };
    }, {} as Record<number, TargetAudienceQuestion>);
  }, [targets, questions]);

  const handleDecline = useCallback(() => {
    if (!managedCampaign) return;

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details.draft_settings,
          ui: {
            ...managedCampaign.details.draft_settings.ui,
            targetAudAck: true
          }
        }
      },
      {
        onSuccess: () => {
          refetchCampaign();
        }
      }
    );

    setAudienceAck(true);
  }, [mutate, setAudienceAck, managedCampaign, refetchCampaign]);

  const hasTargetAud = !!targets?.length && currentQuestions;
  const hasTR = !!managedCampaign?.details?.meta?.hasTargetedRecruitment;

  return (
    <ReviewLayout.Content color={Colors.PURPLE}>
      {!hasTargetAud && !hasTR && (
        <ReviewLayout.Item>
          {!audienceAck && (
            <AudienceWarning
              showCTAs={can.edit}
              onConfirm={() => goToStep(steps.AUDIENCE.id)}
              onDecline={() => handleDecline()}
            />
          )}

          {audienceAck && (
            <AudienceNotRequired
              disabled={!can.edit}
              onClick={() => goToStep(steps.AUDIENCE.id)}
            />
          )}
        </ReviewLayout.Item>
      )}

      <>
        {can.edit && (
          <ReviewLayout.Edit onClick={() => goToStep(steps.AUDIENCE.id)} />
        )}

        {isOn && hasTR && (
          <>
            <ReviewLayout.Item lss={{ gap: '1.5rem' }}>
              <Box>
                <Tag textScale={'content'} isRound lss={{ bg: 'amethyst600' }}>
                  {t('campaignManager.review.audience.targetedRecruitment')}
                </Tag>
              </Box>

              <SegmentItem containerLss={{ pl: 'none' }} hasActions={false} />
            </ReviewLayout.Item>
          </>
        )}

        {hasTargetAud && (
          <>
            <ReviewLayout.Item>
              <Box>
                <Tag textScale={'content'} isRound lss={{ bg: 'amethyst600' }}>
                  {t('campaignManager.review.audience.targetAudience')}
                </Tag>
              </Box>
            </ReviewLayout.Item>
            {targets.map((target, it) => (
              <ReviewLayout.Item key={it}>
                <Flex>
                  {target.is_default && (
                    <Icon
                      size={24}
                      as={MdStar}
                      lss={{ color: 'secondary500', mr: 'sm' }}
                    />
                  )}
                  <Text lss={headingLss}>{target.target_name}</Text>
                </Flex>

                {target.qualifications.map((qual, iq) => {
                  const q = currentQuestions[qual.marketplace_question_id];
                  //NOTE: custom quals can have misaligned field values - this condition is for when the question is undefined or not found.
                  if (!q) return null;

                  const isNumeric =
                    q.question_type === TargetAudienceQuestionType.Numeric;

                  const isRange = qual.qual_name === 'AGE';

                  return (
                    <Box key={iq}>
                      <Text as={'div'} lss={{ my: 'sm' }}>
                        {q.question_text}
                      </Text>

                      {isRange && (
                        <List>
                          <ListItem>{qual.precodes.join(' - ')}</ListItem>
                        </List>
                      )}

                      {!isRange && !isNumeric && (
                        <AudienceBlockAnswers
                          questionId={q.question_id}
                          answerIds={qual.precodes}
                        />
                      )}
                    </Box>
                  );
                })}
              </ReviewLayout.Item>
            ))}
          </>
        )}
      </>
    </ReviewLayout.Content>
  );
};
