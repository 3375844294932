import { LssProp } from '@lucidhq/lucidium';

import { MAPPING_SELECT_WIDTH } from '../../../constants';

export const dialogSectionLss: LssProp = {
  my: 'sm',
  mx: 'xl'
};

export const dialogTooltipLss: LssProp = {
  textScale: 'caption',
  width: '8rem',
  px: 'sm'
};

export const mappingSectionWrapperLss: LssProp = {
  ...dialogSectionLss,
  flexDirection: 'column',
  mb: 'xl'
};

export const mappingRowLss: LssProp = {
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  my: 'sm'
};

export const addCustomButtonLss: LssProp = {
  width: MAPPING_SELECT_WIDTH,
  ml: 'auto',
  mr: '32px' as any,
  mb: 'md'
};

export const dialogContentLss: LssProp = {
  p: 0,
  py: 'md',
  overflowY: 'scroll'
};

export const defaultBulkFieldLss: LssProp = {
  width: MAPPING_SELECT_WIDTH,
  mr: 'xxl',
  pb: 0
};
