import * as React from 'react';

import {
  IconDropdown,
  DropdownItemButton,
  Flex,
  Text,
  useToggle
} from '@lucidhq/lucidium';
import {
  ImpactMeasurementIcon,
  MenuIcon,
  IMAdminIcon
} from '@lucidhq/lucidium/icons';

import { useAppAccess } from '~/impact-measurement/utils/auth/useAppAccess';

type ProductButtonProps = {
  path: string;
  icon: any;
  text?: string;
  id: string;
  disabled: boolean;
};

const ProductButton: React.FunctionComponent<ProductButtonProps> = ({
  path,
  icon,
  text,
  id,
  disabled
}) => (
  <DropdownItemButton
    id={id}
    disabled={disabled}
    lss={{
      p: 'xs',
      width: '128px',
      ':focus': {
        outlineWidth: '2px'
      }
    }}
    onClick={() => {
      window.location.replace((window.location.pathname = path)); //need to replace or params will carry over between products
    }}
  >
    <Flex lss={{ flexDirection: 'column', alignItems: 'center' }}>
      {icon}
      <Text
        lss={{
          textScale: 'caption',
          pt: 'xs'
        }}
      >
        {text}
      </Text>
    </Flex>
  </DropdownItemButton>
);

type NavProductDropdownProps = {
  isSuperAdmin: boolean;
};

export const NavProductDropdown: React.FC<NavProductDropdownProps> = ({
  isSuperAdmin
}) => {
  const [isOpen, onToggle] = useToggle();

  const { hasImAuth } = useAppAccess();

  return (
    <IconDropdown
      id="product-dropdown-menu"
      icon={
        <MenuIcon
          lss={{ color: 'white' }}
          size={20}
          wrapperLss={{ justifyContent: 'center' }}
        />
      }
      isOpen={isOpen}
      onToggle={onToggle}
      ariaLabel="Product Menu"
      showArrow
      arrowColor="white"
      lss={{ mx: 'sm' }}
      buttonProps={{
        lss: {
          ':focus': {
            outline: 'none',
            bg: 'opacityLight'
          }
        }
      }}
      contentProps={{
        position: 'left',
        lss: {
          display: 'flex',
          px: 'sm',
          py: 'lg'
        }
      }}
    >
      <ProductButton
        id="im-admin"
        text="Admin"
        path={'/impact-measurement/admin'}
        icon={
          <IMAdminIcon
            wrapperLss={{ paddingLeft: '0.375rem', pb: 'xs' }}
            size={45}
            isDisabled={!hasImAuth}
          />
        }
        disabled={!isSuperAdmin}
      />

      <ProductButton
        id="impact-measurement"
        path={'/impact-measurement'}
        icon={<ImpactMeasurementIcon size={45} isDisabled={!hasImAuth} />}
        text="Impact Measurement"
        disabled={!hasImAuth}
      />
    </IconDropdown>
  );
};
