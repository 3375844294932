import React from 'react';

import { DatePicker, Flex, Label, LssProp } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

import { InfoTooltip } from '~/impact-measurement/components';
import { dateToISO } from '~/utils/date';
import { t } from '~/utils/i18n';

const dateFieldLss: LssProp = {
  flexDirection: 'column',
  width: '49%'
};

type StartDateProps = {
  name: string;
  readOnly?: boolean;
};

export const StartDateField = ({ name, readOnly = false }: StartDateProps) => {
  const [field, meta, helpers] = useField(name);
  return (
    <Flex lss={dateFieldLss}>
      <Flex lss={{ flexDirection: 'row', gap: '2px' }}>
        <Label htmlFor={field.name} required>
          {t(`forms.labels.startDate`)}
        </Label>
        <InfoTooltip
          title={t('campaignManager.campaignDetails.tooltips.startDate')}
          placement="right-start"
          iconLss={{ height: '1.125rem', width: '1.125rem' }}
        />
      </Flex>
      <DatePicker
        {...field}
        name={field.name}
        error={meta.error}
        placeholder="YYYY/MM/DD"
        readOnly
        disabled={readOnly}
        value={dateToISO(field.value as string, 'startDate', true)}
        onChange={selectedDate =>
          helpers.setValue(
            dateToISO(selectedDate as string, 'startDate', false) || null
          )
        }
      />
    </Flex>
  );
};
