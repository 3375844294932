import React, { useEffect } from 'react';

import { Flex, Field, Text } from '@lucidhq/lucidium';

import { InfoTooltip } from '~/impact-measurement/components';

import { useWarningTracking } from '../../lib';
import {
  containerLss,
  labelLss,
  getFieldWrapperLss,
  getWarningLss
} from '../../styles';
import { BlankMacroWarning } from './BlankMacroWarning';
import { MacroFieldAppend } from './MacroFieldAppend';

type SingleUrlMacroFieldProps = {
  tagKey: TagKeys;
  label: string;
  index: number;
  onRemove: () => void;
  isModified?: boolean;
  tagIndex: number;
  hasModifiedMacros?: boolean[];
  setHasModifiedMacros?: ([]: boolean[]) => void;
  isBulk?: boolean;
  macroSize?: number;
  valuesCount?: number;
};

export const SingleUrlMacroField: React.FC<SingleUrlMacroFieldProps> = ({
  tagKey,
  label,
  index,
  onRemove,
  isModified = false,
  tagIndex,
  hasModifiedMacros,
  setHasModifiedMacros,
  isBulk = false,
  macroSize = 0,
  valuesCount = 0
}) => {
  const isCustom = label.includes('Custom');
  const showBulkWarning = isBulk && !valuesCount;

  const { hasWarning, handleBlurOrChange } = useWarningTracking({
    tagKey,
    tagIndex,
    index,
    valuesCount,
    showBulkWarning,
    isModified
  });

  useEffect(() => {
    !isCustom &&
      setHasModifiedMacros &&
      !!hasModifiedMacros &&
      setHasModifiedMacros([...hasModifiedMacros, isModified]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModified, isCustom]);

  return (
    <Flex lss={containerLss}>
      <Text lss={labelLss}>{label}:</Text>
      <Flex lss={getFieldWrapperLss(isBulk)}>
        <Flex>
          <Field
            name={`${tagKey}.${tagIndex}.macros.${index}.values.0`}
            groupLss={{ p: 0, width: '100%' }}
            placeholder={isBulk ? 'N/A' : 'Enter Macro or Value'}
            readOnly={isBulk}
            lss={getWarningLss(hasWarning || showBulkWarning)}
            onBlur={handleBlurOrChange}
          />

          <MacroFieldAppend
            isBulk={isBulk}
            isCustom={isCustom}
            macroSize={macroSize}
            onRemove={onRemove}
          />

          {isModified && (
            <InfoTooltip
              title="Modified ID"
              colorScheme="dark"
              iconLss={{ ml: 'xs', mt: 'md' }}
            />
          )}
        </Flex>
        {(hasWarning || showBulkWarning) && <BlankMacroWarning label={label} />}
      </Flex>
    </Flex>
  );
};
