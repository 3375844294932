import * as React from 'react';

import { FormikContext, FormikErrors } from 'formik-legacy';

import { initialCampaignFormValues } from '~/impact-measurement/constants';
import { CampaignFormValues } from '~/impact-measurement/pages/types';

const noop = () => {}; // eslint-disable-line

type ProjectSetupFormikContextType = {
  setFieldValue: FormikContext<CampaignFormValues>['setFieldValue'];
  setFieldTouched: FormikContext<CampaignFormValues>['setFieldTouched'];
  setFieldError: FormikContext<CampaignFormValues>['setFieldError'];
  draftSettings: CampaignFormValues['draft_settings'];
  formValues: CampaignFormValues;
  formErrors: FormikErrors<CampaignFormValues>;
  isValid: boolean;
  submitForm: FormikContext<CampaignFormValues>['submitForm'];
};

export const ProjectSetupFormikContext = React.createContext<
  ProjectSetupFormikContextType
>({
  setFieldValue: noop,
  setFieldTouched: noop,
  setFieldError: noop,
  draftSettings: initialCampaignFormValues.draft_settings,
  formValues: initialCampaignFormValues,
  formErrors: {},
  isValid: true,
  submitForm: noop
});
