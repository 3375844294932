import React from 'react';

import { Flex, Text, Field } from '@lucidhq/lucidium';

import { defaultBulkFieldLss, mappingRowLss } from '../styles';

type ColumnMappingDefaultRowProps = {
  label: string;
  fieldName: string;
};

export const ColumnMappingDefaultRow: React.FC<ColumnMappingDefaultRowProps> = ({
  label,
  fieldName
}) => (
  <Flex lss={mappingRowLss}>
    <Text lss={{ width: '150px', mt: 'sm' }}>{label}:</Text>
    <Field
      aria-label={fieldName}
      name={fieldName}
      groupLss={defaultBulkFieldLss}
    />
  </Flex>
);
