import { initReactI18next, Trans } from 'react-i18next';

import { use, t, exists } from 'i18next';

import en from '~/translations/en';

use(initReactI18next).init({
  resources: { en },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

export { t, exists, Trans };
