import { useQuery } from '~/utils/rq';

type AdServerQueryParameters = {
  default_macro: string;
  query_key: string;
  query_label: string;
  sort_order: number;
  tag_type: string;
};

export type AdServer = {
  id: number;
  ad_server_name: string;
  query_parameters: AdServerQueryParameters[];
};

type AdServers = AdServer[];

type AdServersResponse = {
  items: AdServers;
};

const URL = '/admin/ad-servers?include_parameters=true';

export const useFetchAdServers = () =>
  useQuery<AdServersResponse>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60
    },
    {
      method: 'get',
      url: URL
    }
  );
