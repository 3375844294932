import { Campaign } from '~/types';
import { useMutation } from '~/utils/rq';

export const useMutateServiceCampaign = (campaignId?: string) => {
  const url = `/internal/admin/services/campaigns/${campaignId ?? ''}`;
  const method = !!campaignId ? 'put' : 'post';

  return useMutation<Campaign, Campaign>(
    {},
    {
      url,
      method
    }
  );
};
