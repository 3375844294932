import React from 'react';

import { Box, Flex, Text, List, LssProp } from '@lucidhq/lucidium';

import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { StandardQuestionsListItem } from './StandardQuestionsListItem';
import { useStandardQuestionsProvider } from './StandardQuestionsProvider';

const questionListLss: LssProp = {
  backgroundColor: 'white',
  flexDirection: 'column',
  border: 'regular',
  borderRadius: 'md',
  flexBasis: '20rem',
  maxWidth: '330px',
  px: 'md',
  py: 'sm'
};

const containerLss: LssProp = {
  display: 'grid',
  alignItems: 'start',
  mt: 'xxl',
  mr: 'md',
  justifySelf: 'end'
};

const UNAIDED_AWARENESS = 'unaided_awareness';

export const StandardQuestionsList = () => {
  const {
    standardTemplateQuestions,
    standardQuestions,
    addStandardQuestion
  } = useStandardQuestionsProvider();
  const { isOff } = useSplit(IM_FLAGS.UNAIDED_AWARENESS);

  const { can } = useCampaignManager();

  return (
    <Box lss={containerLss}>
      <Flex lss={questionListLss}>
        <Text as="strong" lss={{ fontWeight: 'bold', px: 'sm', ml: 'xxs' }}>
          {t(
            `campaignManager.survey.standardQuestions.questionsListDescription`
          )}
        </Text>
        <List as="ul" lss={{ listStyle: 'none', px: 'sm' }}>
          {!!standardTemplateQuestions?.length &&
            standardTemplateQuestions?.map(item => {
              if (item.kpi_type === UNAIDED_AWARENESS && isOff) return null;

              return (
                <StandardQuestionsListItem
                  key={item.kpi_type}
                  name={
                    item.meta.isCustomKPI
                      ? t('campaignManager.survey.customKPI')
                      : item.question_name
                  }
                  disabled={
                    (standardQuestions.length >= 5 && !can.addExtraKPI) ||
                    !can.edit
                  }
                  onClick={() => addStandardQuestion(item.kpi_type)}
                />
              );
            })}
        </List>
      </Flex>
    </Box>
  );
};
