import React from 'react';

import { TextInput, Flex, Label } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

const TEXT_ANSWER = 'Text Answer';

type TextAnswerOptionProps = {
  readOnly: boolean;
};

export const TextAnswerOption = ({ readOnly }: TextAnswerOptionProps) => (
  <Flex
    lss={{
      flexDirection: 'column',
      width: '100%',
      maxWidth: readOnly ? '32rem' : 'none',
      marginTop: !readOnly ? '2.625rem' : '0.5rem'
    }}
  >
    {!readOnly && (
      <Label required>
        {t('campaignManager.survey.standardQuestions.questionCard.answer')}
      </Label>
    )}
    <TextInput placeholder={TEXT_ANSWER} disabled />
  </Flex>
);
