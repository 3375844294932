import React from 'react';

import { BusyButton, Button, Flex, LssProp, Text } from '@lucidhq/lucidium';
import { Field, Form } from '@lucidhq/lucidium/components/FormV2';

import { DraftTargetQualification } from '~/types';
import { t } from '~/utils/i18n';

import { CheckboxField } from './CheckboxField';
import { Qualifications } from './Qualifications';

type AudienceFilterFormProps = {
  onSubmit: (params: {
    target_name: string;
    qualifications: DraftTargetQualification[];
  }) => void;
  isLoading: boolean;
  onCancel: () => void;
  initialValues?: {
    target_name: string;
    qualifications: DraftTargetQualification[];
    is_default?: boolean;
  };
  hasDefault: boolean;
};

const INITIAL_VALUES = {
  target_name: '',
  qualifications: [],
  is_default: false
};

const actionsLss: LssProp = {
  justifyContent: 'center',
  mt: 'xxl',
  mb: 'xxl'
};

export const AudienceFilterForm = ({
  onSubmit,
  onCancel,
  isLoading,
  initialValues = INITIAL_VALUES,
  hasDefault
}: AudienceFilterFormProps) => {
  return (
    <Form initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
      <Text lss={{ mb: 'xl', display: 'block' }}>
        {t('forms.descriptions.targetName')}
      </Text>

      <Field label={t('forms.labels.targetName')} name="target_name" required />

      <CheckboxField
        name="is_default"
        label={t(
          `forms.labels.${
            hasDefault ? 'targetDefaultOverride' : 'targetDefault'
          }`
        )}
      />

      <Text lss={{ mb: 'lg', display: 'block' }}>
        {t('forms.descriptions.qualifications')}
      </Text>

      <Qualifications />

      <Flex lss={actionsLss}>
        <BusyButton isBusy={isLoading} type="submit" lss={{ mr: 'md' }}>
          {t('campaignManager.actions.saveAndClose')}
        </BusyButton>
        <Button
          type="button"
          emphasis="medium"
          palette="secondary"
          onClick={onCancel}
        >
          {t('campaignManager.actions.cancel')}
        </Button>
      </Flex>
    </Form>
  );
};
