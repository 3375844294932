import { LssProp } from '@lucidhq/lucidium';

import { PIXEL_DOWNLOAD, SIZES } from '../../constants';
import { MediaTrackingToolsContentProps } from '../MediaTrackingToolsContent';

export const connector = (url: string, baseURL: string) =>
  url === baseURL ? '?' : '&';

export const buildPreviewURL = (
  macros: MacroType[],
  URL: string,
  gdpr: string
) => {
  let previewUrl = URL;

  if (!macros || !macros.length) return '';

  macros?.forEach((macro: MacroType) => {
    // We're assuming we should build just for the first macro value because this is our use case today
    if (!macro.values.length || macro.values[0] === '') return;
    previewUrl = `${previewUrl}${connector(previewUrl, URL)}${macro.param}=${
      macro.values[0]
    }`;
  });

  return `${previewUrl}${connector(previewUrl, URL)}${gdpr}`;
};

export const formatRows = (data: PixelGroupsResponse['campaign_pixels']) =>
  data.map(
    pixel =>
      `${pixel.pixel_type},${pixel.ad_server_name || 'N/A'},${
        pixel.pixel_url
      },<img src="${pixel.pixel_url}" />`
  );

export const parsePixelToDownload = (
  data: PixelGroupsResponse['campaign_pixels'],
  campaignName: string
) => {
  const csvHeader = 'data:text/csv;charset=utf-8,';
  const rows = formatRows(data).join('\n');
  const tableHeader = 'Type, Ad Server, URL, IMG Tag';

  const instructions = `${PIXEL_DOWNLOAD.HEADER}
${campaignName}
,
${PIXEL_DOWNLOAD.INSTRUCTIONS}`;

  return `${csvHeader}${instructions}\n${tableHeader}\n${rows}`;
};

type QueryParam = {
  [key: string]: string;
};

export const parseData = (
  data: UniversalPixel[] | SiteServedPixel[]
): PixelGroupParams[] => {
  const pixelGroups: PixelGroupParams[] = [];

  data.forEach((pixel: any) => {
    const query_params: QueryParam[] = [];

    pixel.macros.forEach((macro: MacroType) => {
      macro.values.forEach((value: string, index: number) => {
        if (!query_params[index]) query_params[index] = {};
        query_params[index][macro.param] = value;
      });
    });

    Object.keys(query_params).forEach((query: string) => {
      pixelGroups.push({
        ...(pixel.ad_server_id && { ad_server_id: pixel.ad_server_id }),
        query_params: query_params[query]
      });
    });
  });

  return pixelGroups;
};

export const savePixels = (data: MediaTrackingToolsData) => {
  const universal_tags = parseData(data.universal_tags);
  const bulk_tags = parseData(data.bulk_tags);
  const site_served_tags = parseData(data.site_served_tags);

  const campaign_pixels = [
    ...universal_tags,
    ...bulk_tags,
    ...site_served_tags
  ];

  return { campaign_pixels };
};

export const getMediaTrackingMainSectionLss = (
  isCampaignTask: MediaTrackingToolsContentProps['isCampaignTask']
): LssProp => {
  const heightCalc = isCampaignTask
    ? SIZES.MAPPING_TASK_HEADER_HEIGHT
    : SIZES.CAMPAIGN_HEADER_HEIGHT;

  return {
    minHeight: `calc(100vh - ${heightCalc})`,
    p: 'none',
    tablet: { mt: 'none', my: 'none' }
  };
};

export const getTabsAndCounts = (
  bulk_tags: SiteServedPixel[],
  site_served_tags: SiteServedPixel[],
  universal_tags: UniversalPixel[],
  linearTv: LinearTv[],
  treatment: string
) => {
  const universalTagsLength = universal_tags.filter(tag => !!tag.ad_server_id)
    .length;

  const siteServedTagsLength = site_served_tags.length;
  const bulkTagsLength = bulk_tags.length;

  const linearTvLength = linearTv
    .map(item => item.partner_campaigns_ids)
    .flat()
    .filter(id => !!id).length;

  const pixelCount =
    bulkTagsLength + universalTagsLength + siteServedTagsLength;

  const universalTagTab = {
    id: 'universal',
    title: 'Universal Tag',
    count: universalTagsLength
  };

  const siteServedTab = {
    id: 'site',
    title: 'Site-Served',
    count: bulkTagsLength + siteServedTagsLength
  };

  const tvMappingTab = {
    id: 'tv',
    title: 'Linear TV',
    count: linearTvLength
  };

  const mediaTrackingTabs =
    treatment === 'on'
      ? [universalTagTab, siteServedTab, tvMappingTab]
      : [universalTagTab, siteServedTab];

  return {
    bulkTagsLength,
    mediaTrackingTabs,
    pixelCount,
    linearTvLength
  };
};

export const getMatchedPartner = (
  associatedMappings: LinearTv[],
  partnerId: number
) => associatedMappings.find(mapping => mapping.partner_id === partnerId);

export const getCreativeIds = (
  campaignIds: string[],
  matchedPartner?: LinearTv
) =>
  (!!matchedPartner
    ? [...campaignIds, ...matchedPartner.partner_campaigns_ids]
    : campaignIds
  ).filter(id => !!id);

export const formatMergedTVData = (data: LinearTvSaveItem[]) => {
  return data
    .filter(mapping => !!mapping.partner_campaigns_ids.length)
    .map(({ partner_id, partner_campaigns_ids }) => ({
      partner_id,
      partner_campaigns_ids: [
        ...new Set(partner_campaigns_ids.filter(id => !!id))
      ]
    }));
};

export const getMergedTvDataForSave = (
  linearTvFormData: LinearTv[],
  associatedMappings: LinearTv[]
): LinearTvSaveItem[] => {
  const mergedData = linearTvFormData.map(
    ({ partner_id, partner_campaigns_ids }) => {
      const matchedPartner = getMatchedPartner(associatedMappings, partner_id);

      return {
        partner_id,
        partner_campaigns_ids: getCreativeIds(
          partner_campaigns_ids,
          matchedPartner
        )
      };
    }
  );

  return formatMergedTVData(mergedData);
};
