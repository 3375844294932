import React from 'react';

import {
  AuthorizedRoute as AuthorizedRouteUI,
  AuthorizedRouteProps as AuthorizedRoutePropsUI
} from '@lucidhq/ui-auth';

import { UnauthorizedPage } from '~/components/Pages/UnauthorizedPage';
import { ROLES } from '~/utils/permissions';

interface AuthorizedRouteProps extends AuthorizedRoutePropsUI {
  isAdminOnly?: boolean;
  isSuperAdminOnly?: boolean;
}

export const AuthorizedRoute: React.FunctionComponent<AuthorizedRouteProps> = ({
  component: Component,
  isAdminOnly,
  isSuperAdminOnly,
  ...rest
}) => {
  const superAdminRole = isSuperAdminOnly && [ROLES.superAdmin];
  const adminRole = isAdminOnly && [ROLES.admin, ROLES.basicPlusUser];
  const roles = superAdminRole || adminRole || [];

  return (
    <AuthorizedRouteUI
      {...rest}
      authorizationErrorComponent={UnauthorizedPage}
      component={Component}
      authorizedRoles={roles}
      authorizationRule="or"
    />
  );
};
