import { useRoles } from '@lucidhq/ui-auth';

import { ADMIN_TYPES, ROLES } from './constants';

type UseIsAdminType = (role?: ADMIN_TYPES) => boolean;

export const useIsAdmin: UseIsAdminType = (role = ADMIN_TYPES.ADMIN) => {
  const { roles } = useRoles();
  const appRoles = ROLES.imt;
  const hasAdminRole =
    appRoles &&
    appRoles[role] &&
    appRoles[role].some((r: string) => roles.some(role => role.includes(r)));
  return hasAdminRole;
};
