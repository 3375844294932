import React from 'react';
import { MdFullscreenExit } from 'react-icons/md';

import { Trans, t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { AddNewButton } from '../../Steps/AddNewButton';
import { StepLayout } from '../../Steps/StepLayout';
import { ManageTargetedRecruitment } from './ManageTargetedRecruitment';

export const TargetedRecruitmentSection = () => {
  const { can, managedCampaign } = useCampaignManager();

  const hasTRSegment = !!managedCampaign?.details?.meta?.hasTargetedRecruitment;

  const hasValidCountryLang =
    managedCampaign?.details?.meta?.hasValidCountryLang;

  const isDisabled = !can.edit || hasTRSegment || !hasValidCountryLang;

  return (
    <StepLayout.Card lss={{ pb: 'lg' }}>
      <StepLayout.CardHeader
        title={t('campaignManager.targetAudience.targetedRecruitment.title')}
        description={
          <Trans
            defaults={t(
              'campaignManager.targetAudience.targetedRecruitment.description'
            )}
            components={{ bold: <strong /> }}
          />
        }
        titleIcon={MdFullscreenExit}
        cardActions={
          <ManageTargetedRecruitment>
            {({ onToggle }) => (
              <AddNewButton
                id="add-Segment"
                onClick={onToggle}
                disabled={isDisabled}
                lss={{
                  maxHeight: '2.5rem'
                }}
              />
            )}
          </ManageTargetedRecruitment>
        }
        descriptionLss={{ paddingLeft: '3.375rem' }}
      />
    </StepLayout.Card>
  );
};
