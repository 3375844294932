import React from 'react';

import { Flex, H3, LssProp, Text } from '@lucidhq/lucidium';

export type PageIntroProps = {
  title: string;
  description?: string;
  lss?: LssProp;
};

export const PageIntro: React.FC<PageIntroProps> = ({
  title,
  description = '',
  lss = {}
}) => (
  <Flex lss={{ flexDirection: 'column', my: 'sm', ...lss }}>
    <H3>{title}</H3>
    {description && (
      <Text as="p" lss={{ width: 'auto', maxWidth: '30rem', my: 'sm' }}>
        {description}
      </Text>
    )}
  </Flex>
);
