import React, { useContext } from 'react';

import { Message, Text } from '@lucidhq/lucidium';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';

import { SingleTagSetupSection, TagCard } from '../components';
import { TAG_KEYS } from '../constants';
import { AdServerSelect } from './components/AdServerSelect';
import { UniversalUrlSetup } from './components/UniversalUrlSetup';
import {
  AD_SERVER_ERROR_MESSAGE,
  SELECT_AD_SERVER,
  UNIVERSAL_INTRO
} from './constants';
import { getCardTitle } from './lib';

type UniversalSetupProps = {
  adServerErrors: ErrorResponse;
  adServers: AdServerType[];
};

export const UniversalSetup: React.FC<UniversalSetupProps> = ({
  adServerErrors,
  adServers
}) => {
  const {
    mediaToolData: { universal_tags }
  } = useContext(MediaTrackingToolsFormContext);

  const hasError = adServerErrors || !adServers.length;

  if (hasError) return <Message description={AD_SERVER_ERROR_MESSAGE} />;

  return (
    <SingleTagSetupSection
      tagKey={TAG_KEYS.universal}
      sectionIntro={UNIVERSAL_INTRO}
    >
      <>
        {universal_tags.map((tag, index) => (
          <TagCard
            index={index}
            key={`${TAG_KEYS.universal}-${index}`}
            tagKey={TAG_KEYS.universal}
            title={getCardTitle({
              adServers,
              ad_server_id: tag.ad_server_id
            })}
          >
            <Text as="p" lss={{ mb: 'md', mt: 'none' }}>
              {SELECT_AD_SERVER}:
            </Text>
            <AdServerSelect tagIndex={index} adServers={adServers} />
            <UniversalUrlSetup tagIndex={index} adServers={adServers} />
          </TagCard>
        ))}
      </>
    </SingleTagSetupSection>
  );
};
