import React from 'react';

import { Box, Flex, LssProp } from '@lucidhq/lucidium';

import { AudienceTheme } from '~/utils/styles';

import { useCampaignManager } from '../CampaignManagerProvider';
import { CampaignManagerStepIds } from '../useCampaignManagerSteps';

const getCarouselCircleLss = (isActive: boolean): LssProp => ({
  width: '12px',
  height: '12px',
  borderRadius: 'lg',
  mx: 'xs',
  backgroundColor: isActive
    ? (AudienceTheme.colors.secondary500 as any)
    : (AudienceTheme.colors.gray500 as any)
});

export const SurveyStepCarousel = () => {
  const { steps, activeStep, options, goToStep } = useCampaignManager();

  return (
    <Flex lss={{ justifyContent: 'center', cursor: 'pointer', mt: 'sm' }}>
      {steps[activeStep]?.childSteps?.map(({ id }) => (
        <Box
          key={id}
          lss={getCarouselCircleLss(id === options?.activeChildStep)}
          onClick={() =>
            goToStep(CampaignManagerStepIds.SURVEY, {
              activeChildStep: id
            })
          }
        />
      ))}
    </Flex>
  );
};
