import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  useToggle
} from '@lucidhq/lucidium';

import {
  CampaignPixel,
  CampaignPixelVariables,
  useEditCampaignPixelGroup
} from '~/components/Shared/hooks/useMutateCampaignPixelGroup';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { SiteServedTagForm } from './SiteServedTagForm';

type UpdateSiteServedTagProps = {
  campaignId: string;
  title: string;
  id: string;
  pixel: CampaignPixel;
  onUpdate: () => void;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const UpdateSiteServedTag = ({
  children,
  title,
  pixel,
  id,
  onUpdate,
  campaignId
}: UpdateSiteServedTagProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const { mutate, isLoading } = useEditCampaignPixelGroup(campaignId, id);
  const { onMutateError } = useCampaignManager();

  const handleSubmit = (params: CampaignPixelVariables) => {
    mutate(
      {
        campaign_pixels: [{ id: pixel.pixel_id, ...params }]
      },
      {
        onSuccess: () => {
          onUpdate();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  const initialValues = {
    ...pixel.query_params
  };

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog isOpen={isOpen} onClose={handleToggle} lss={{ width: '43rem' }}>
        <DialogHeader palette="disabled" onClose={handleToggle}>
          {title}
        </DialogHeader>
        <DialogContent>
          <SiteServedTagForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onCancel={handleToggle}
            initialValues={initialValues}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
