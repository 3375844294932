import React from 'react';
import { useHistory } from 'react-router-dom';

import { isBefore, isAfter, isEqual, format, add } from 'date-fns';

import { Flex } from '@lucidhq/lucidium';
import { Field, Form } from '@lucidhq/lucidium/components/FormV2';

import { FormProps } from '~/components/Shared/FormProps';
import { useMutateServiceCampaign } from '~/components/Shared/hooks/useMutateServiceCampaign';
import { ROUTES } from '~/constants';
import { Campaign, CampaignTypes, Channels } from '~/types';
import { getCampaignManagerRoute } from '~/utils/campaignManager';
import { t } from '~/utils/i18n';

import { CountryLanguageField } from '../CampaignDetailsStep/CampaignDetailsForm/CountryLanguageField';
import { EndDateField } from '../CampaignDetailsStep/CampaignDetailsForm/EndDateField';
import { SalesforceField } from '../CampaignDetailsStep/CampaignDetailsForm/SalesforceField';
import { StartDateField } from '../CampaignDetailsStep/CampaignDetailsForm/StartDateField';
import { TrackedMediaSection } from '../CampaignDetailsStep/CampaignDetailsForm/TrackedMediaSection';
import { CampaignManagerFooter } from '../CampaignManagerFooter';
import { useCampaignManager } from '../CampaignManagerProvider';
import { ProjectManagerField } from './Form/ProjectManagerField';
import { QUOTA_TEMPLATES, QuotaTemplateField } from './Form/QuotaTemplateField';
import { ToggleField } from './Form/ToggleField';

const initialValues = {
  campaign_name: '',
  country_language_id: 1,
  draft_settings: {
    questions: [],
    baseline_questions: [],
    data_partners: [],
    control_gemini_enabled: true,
    control_survey_enabled: true,
    quota_scheme: QUOTA_TEMPLATES.open.id,
    quota_size: 0
  },
  end_date: '',
  start_date: '',
  salesforce_id: '',
  channels: [],
  project_manager_user_id: 0
};

const handleValidate = (
  values: Campaign,
  isAdmin: boolean,
  initialValues?: Campaign
) => {
  const errors: Record<string, any> = {};
  const draft_settings: Record<string, any> = {};

  if (!values.campaign_name) {
    errors.campaign_name = t('forms.errors.requiredField');
  }

  if (!values.start_date) {
    errors.start_date = t('forms.errors.requiredField');
  }

  if (!values.end_date) {
    errors.end_date = t('forms.errors.requiredField');
  }

  if (!!values?.start_date && !!values?.end_date) {
    // This adjustment is mainly to check only the day value and validate the difference between dates.
    const adjustedStartDate = add(new Date(values?.start_date), { days: 1 });
    const startDate = new Date(format(adjustedStartDate, 'MM/dd/yyyy'));
    const endDate = new Date(format(new Date(values.end_date), 'MM/dd/yyyy'));

    if (isAfter(startDate, endDate)) {
      errors.start_date = t('forms.errors.startDate');
    }

    if (isBefore(endDate, startDate)) {
      errors.end_date = t('forms.errors.endDate');
    }

    if (isEqual(startDate, endDate)) {
      errors.start_date = t('forms.errors.startDate');
      errors.end_date = t('forms.errors.endDate');
    }

    if (isBefore(endDate, new Date())) {
      errors.end_date = t('forms.errors.endDatePast');
    }

    if (initialValues?.end_date) {
      const initialEndDate = new Date(
        format(new Date(initialValues.end_date), 'MM/dd/yyyy')
      );

      if (isBefore(endDate, initialEndDate)) {
        errors.end_date = t('forms.errors.endDateLive');
      }
    }
  }

  if (
    !values.draft_settings.quota_size ||
    values.draft_settings.quota_size < 1
  ) {
    draft_settings.quota_size = t('forms.errors.quotaSize');
  }

  if (
    values.channels.includes(Channels.LINEAR_TV) &&
    values.country_language_id !== 1
  ) {
    errors.country_language_id = t('forms.errors.countryLanguage');
  }

  if (!values.channels.length) {
    errors.channels = t('forms.errors.channels');
  }

  if (
    values.channels.includes(Channels.LINEAR_TV) &&
    !values?.draft_settings?.data_partners?.length
  ) {
    draft_settings.data_partners = t('forms.errors.requiredField');
  }

  if (
    initialValues &&
    values?.draft_settings?.quota_size &&
    initialValues?.draft_settings?.quota_size &&
    values?.draft_settings?.quota_size <
      initialValues?.draft_settings?.quota_size
  ) {
    draft_settings.quota_size = t('forms.errors.smallerThanInitial', {
      min: initialValues?.draft_settings?.quota_size.toLocaleString()
    });
  }

  return {
    ...errors,
    ...(Object.keys(draft_settings).length && { draft_settings })
  };
};

export const ServiceCampaignDetailsForm = () => {
  const history = useHistory();
  const {
    goToStep,
    steps,
    activeStep,
    managedCampaign,
    refetchCampaign,
    onMutateError,
    can
  } = useCampaignManager();

  const defaultValues = !!managedCampaign
    ? { ...managedCampaign.details }
    : initialValues;
  const initValues = defaultValues as Campaign;

  const { mutate } = useMutateServiceCampaign(
    managedCampaign?.details?.campaign_id
  );

  const hasCampaign = managedCampaign?.details?.campaign_id;

  const handleBack = () => history.push(ROUTES.adminCampaigns);

  const handleSubmit = (action: 'close' | 'continue') => (values: Campaign) => {
    // @TODO @ccv2 Remove this logic after campaign v1 is deprecated
    const formattedValues = !!hasCampaign
      ? values
      : {
          ...values,
          draft_settings: { ...values.draft_settings, campaign_version: 2 }
        };

    mutate(formattedValues, {
      onSuccess: data => {
        if (!hasCampaign) {
          if (action === 'close') return handleBack();
          if (action === 'continue') {
            history.push(
              getCampaignManagerRoute(
                CampaignTypes.SERVICE,
                data.campaign_id,
                steps.MEDIA_TRACKING.id
              )
            );
          }
        } else {
          if (action === 'close') return handleBack();
          if (action === 'continue') {
            refetchCampaign();
            const nextStep = can.limitedEdit
              ? steps.REVIEW.id
              : steps[activeStep].nextId;
            if (nextStep) goToStep(nextStep);
          }
        }
      },
      ...onMutateError
    });
  };

  return (
    <Form
      initialValues={initValues}
      validate={values =>
        handleValidate(values, true, can.limitedEdit ? initValues : undefined)
      }
      // @ts-ignore
      onSubmit={() => null}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      <Field
        required
        name={'campaign_name'}
        label={t(`forms.labels.project`)}
        readOnly={!!hasCampaign && !(can.edit || can.limitedEdit)}
      />

      <Flex lss={{ justifyContent: 'space-between', mt: 'md' }}>
        <StartDateField
          name={'start_date'}
          readOnly={!!hasCampaign && !can.edit}
        />
        <EndDateField name={'end_date'} readOnly={!!hasCampaign && !can.edit} />
      </Flex>

      <Flex lss={{ flexDirection: 'column', mt: 'xl' }}>
        <ProjectManagerField readOnly={!!hasCampaign && !can.edit} />
      </Flex>

      <SalesforceField
        readOnly={!!hasCampaign && !(can.edit || can.limitedEdit)}
      />

      <CountryLanguageField
        name={'country_language_id'}
        readOnly={!!hasCampaign && !can.edit}
      />

      <QuotaTemplateField readOnly={!!hasCampaign && !can.edit} />

      <Field
        required
        type="number"
        name="draft_settings.quota_size"
        label={t(`forms.labels.completes`)}
        readOnly={!!hasCampaign && !can.edit}
        disabled={!!hasCampaign && !can.edit}
      />

      <TrackedMediaSection
        showImpressionsFields={false}
        limitedEdit={!!hasCampaign && can.limitedEdit}
      />

      <ToggleField
        name={'draft_settings.control_survey_enabled'}
        label={t(`forms.labels.controlSurvey`)}
        description={t(`forms.placeholders.controlSurvey`)}
        readOnly={!!hasCampaign && !can.edit}
      />

      <ToggleField
        name={'draft_settings.control_gemini_enabled'}
        label={t(`forms.labels.gemini`)}
        readOnly={!!hasCampaign && !can.edit}
      />

      <FormProps>
        {({ validateForm, values }) => (
          <CampaignManagerFooter
            onBack={handleBack}
            saveCloseProps={{
              onClick: async () => {
                const errors = await validateForm();
                if (Object.values(errors).length) return;
                const formHandler = handleSubmit('close');
                formHandler(values as Campaign);
              },
              type: 'button'
            }}
            saveContinueProps={{
              onClick: async () => {
                const errors = await validateForm();
                if (Object.values(errors).length) return;
                const formHandler = handleSubmit('continue');
                formHandler(values as Campaign);
              },
              type: 'submit'
            }}
          />
        )}
      </FormProps>
    </Form>
  );
};
