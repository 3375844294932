export const SCOPES = ['app:seg', 'app:imt', 'app:aud'];
export const ROLES = {
  imt: {
    superAdmin: ['imt:admin#pg_3'],
    admin: ['imt:admin']
  }
};

export enum ADMIN_TYPES {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin'
}
