import React, { useState, useEffect } from 'react';

import { Flex, Text, LssProp } from '@lucidhq/lucidium';

import { IndicatorDot } from '~/impact-measurement/components';

type StatusDotProps = {
  status: CampaignStatus;
  lss?: LssProp;
};

export const StatusDot: React.FunctionComponent<StatusDotProps> = ({
  status,
  lss
}) => {
  const [dotOpacity, setDotOpacity] = useState(1);
  const isLive = status === 'live';

  useEffect(() => {
    const interval = setInterval(() => {
      setDotOpacity(dotOpacity === 1 ? 0.5 : 1);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, [dotOpacity]);

  return (
    <Flex lss={{ alignItems: 'center', ...lss }}>
      <IndicatorDot
        lss={{
          opacity: isLive ? dotOpacity : 1,
          bg: isLive ? 'secondary500' : 'gray600',
          transition: 'fast'
        }}
      />
      <Text lss={{ textScale: 'control' }}>{status.toUpperCase()}</Text>
    </Flex>
  );
};
