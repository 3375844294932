import React, { useState } from 'react';
import { MdDelete, MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

import { Flex, Icon, Label, LssProp, TextInput } from '@lucidhq/lucidium';
import {
  useField,
  useFormikContext
} from '@lucidhq/lucidium/components/FormV2';

import { FieldErrorMessage } from '~/components/Shared/FieldErrorMessage';
import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { getUpdatedAnswers } from './AnswersRadioGroup';
import { CustomKPIData } from './CustomAnswersContainer';
import { updateInTargetAnswers } from './NonGridAnswerOptions';

const fieldWrapperLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mb: 'xxs',
  paddingLeft: '2.5rem'
};

const targetAnswerWrapperLss: LssProp = {
  display: 'flex',
  alignItems: 'center',
  p: 'xs',
  borderRadius: 'xl'
};

const deleteAnswerLss: LssProp = {
  flexBasis: '2rem',
  height: '2rem',
  justifyContent: 'center',
  textDecoration: 'none',
  color: 'secondary500',
  p: 'xxs'
};

type CustomAnswerFieldProps = {
  index: number;
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  handleRemoveItem: (index: number) => void | undefined;
  useAnswerMeta: boolean;
  readOnly?: boolean;
  hasCustomKPIErrors?: boolean;
  customKPIErrors?: Record<string, any>;
  questionIndex?: number;
};

export const CustomAnswerField = ({
  index,
  onSave,
  question,
  handleRemoveItem,
  useAnswerMeta,
  readOnly = false,
  hasCustomKPIErrors,
  customKPIErrors,
  questionIndex
}: CustomAnswerFieldProps) => {
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [field, ,] = useField(`[customAnswers.${index}]`);
  const { setFieldValue, values } = useFormikContext<CustomKPIData>();
  const isRequired = index <= 2;
  const isSelected = !!question?.multi_target_option_index?.includes(index);

  //NOTE: the reason for questionIndex and index is due to each custom KPI question and their individual custom answers
  const hasError =
    hasCustomKPIErrors &&
    !!customKPIErrors?.[`customKPI${questionIndex}`]?.[`customAnswer${index}`];

  return (
    <Flex lss={{ flexDirection: 'column', my: 'md', gap: '4px' }}>
      <Flex
        lss={{
          ...fieldWrapperLss,
          paddingLeft: !!question.isBehavioralCustomKPI ? 'none' : '2.5rem'
        }}
      >
        <Label lss={{ my: 'sm' }} required={isRequired}>
          {t(`forms.labels.customAnswerX`, { index: index + 1 })}
        </Label>

        {!isRequired && (
          <Flex
            as="a"
            href="#"
            role="button"
            aria-label={t(
              `campaignManager.survey.brands.ariaLabels.deleteField`,
              { field: 'Answer', index: index + 1 }
            )}
            lss={deleteAnswerLss}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const incomingAnswers = [...values.customAnswers];
              incomingAnswers.splice(index, 1);

              handleRemoveItem(index);
              const updatedAnswers = getUpdatedAnswers(
                question,
                incomingAnswers
              );

              setFieldValue('customAnswers', updatedAnswers);
              onSave({
                answers: updatedAnswers,
                customAnswers: updatedAnswers
              });
            }}
          >
            <Icon as={MdDelete} size={28} />
          </Flex>
        )}
      </Flex>

      <Flex
        lss={{
          flexDirection: 'row',
          gap: '0.5rem',
          flexBasis: '100%',
          alignItems: 'center',
          width: '100%',
          maxWidth: '744px'
        }}
      >
        {!question?.isBehavioralCustomKPI && (
          <Flex
            as="a"
            href="#"
            role="button"
            lss={{
              ...targetAnswerWrapperLss,
              ...(!isSelected && readOnly && { visibility: 'hidden' }),
              ...(isSelected && readOnly && { cursor: 'default' })
            }}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              onSave({
                multi_target_option_index: updateInTargetAnswers(
                  question.multi_target_option_index,
                  index
                )
              });
            }}
          >
            <Icon
              as={isSelected ? MdOutlineStar : MdOutlineStarBorder}
              size={22}
              lss={{ color: 'secondary500' }}
            />
          </Flex>
        )}
        <TextInput
          required={isRequired}
          value={useAnswerMeta ? question.answers[index] : field.value}
          onChange={e => {
            const incomingAnswers = !!values?.customAnswers?.length
              ? [...values.customAnswers]
              : [];

            incomingAnswers[index] = e.target.value;

            const updatedAnswers = getUpdatedAnswers(question, incomingAnswers);

            setFieldValue('customAnswers', updatedAnswers);
            onSave({
              answers: updatedAnswers,
              customAnswers: updatedAnswers
            });
          }}
          onBlur={e => {
            setDirty(true);
            setValid(e.target.value !== '');
          }}
          lss={{
            p: 'none',
            width: '100%',
            maxWidth: '46rem'
          }}
        />
      </Flex>
      {hasError && isDirty && !isValid && (
        <FieldErrorMessage
          message={t('forms.errors.answerX', { index: index + 1 })}
          lss={{
            paddingLeft: question?.isBehavioralCustomKPI ? 'none' : '2.5rem'
          }}
        />
      )}
    </Flex>
  );
};
