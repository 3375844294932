import React from 'react';
import { MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

import { Flex, Icon, LssProp, Text } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import {
  EnhancedTemplateQuestion,
  findInTargetGridAnswer,
  updateMultiLevelInTargetAnswers
} from '~/utils/templateQuestions';

type DefaultRowOptionProps = {
  rowItem: Record<string, any>;
  index: number;
  questionIndex?: number;
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
  fieldName: 'rows' | 'columns';
};

const targetAnswerWrapperLss: LssProp = {
  display: 'flex',
  alignItems: 'center',
  p: 'xs',
  borderRadius: 'xl'
};

const optionWrapperLss: LssProp = {
  flexDirection: 'row',
  gap: '0.5rem',
  flexBasis: '100%',
  alignItems: 'center',
  width: '100%',
  maxWidth: '744px',
  pb: 'sm'
};

export const DefaultRowOption = ({
  rowItem,
  index,
  question,
  onSave,
  fieldName = 'rows',
  readOnly = false
}: DefaultRowOptionProps) => {
  const selectedGridAnswerOptions = !!question?.multi_level_target_option_index
    ?.length
    ? question.multi_level_target_option_index
    : [];

  const isSelected = findInTargetGridAnswer(
    fieldName,
    selectedGridAnswerOptions,
    index
  );

  return (
    <>
      <Flex lss={optionWrapperLss}>
        {!question?.isBehavioralCustomKPI && (
          <Flex
            as="a"
            href="#"
            role="button"
            lss={{
              ...targetAnswerWrapperLss,
              ...(!isSelected && readOnly && { visibility: 'hidden' }),
              ...(isSelected && readOnly && { cursor: 'default' })
            }}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const gridIndices = !!question?.multi_level_target_option_index
                ?.length
                ? question.multi_level_target_option_index
                : [];
              updateMultiLevelInTargetAnswers(index, fieldName, gridIndices);

              onSave({
                multi_level_target_option_index: updateMultiLevelInTargetAnswers(
                  index,
                  fieldName,
                  gridIndices
                )
              });
            }}
          >
            <Icon
              as={isSelected ? MdOutlineStar : MdOutlineStarBorder}
              size={22}
              lss={{ color: 'secondary500' }}
            />
          </Flex>
        )}

        <Text>{rowItem?.text}</Text>
      </Flex>
    </>
  );
};
