import { BaseTheme, LssProp, TextScaleProps } from '@lucidhq/lucidium';

import { BOX_SHADOW_BOTTOM } from '~/impact-measurement/components/ShadowBox';
import {
  COLORS,
  NAV_HEIGHT,
  REPORT_SUBNAV_HEIGHT,
  REPORT_SUBNAV_HEIGHT_MOBILE
} from '~/impact-measurement/constants';

import { BASE_CHART_HEIGHT } from '../KPIDetail/components/KPIReportCharts/BarChart/constants';
import { CONTENT_WIDTH_REM } from './numbers';

export const centerWithMaxWidth: LssProp = {
  tablet: {
    mx: 'auto',
    maxWidth: `${CONTENT_WIDTH_REM}rem`
  }
};

export const getReportDashboardLss = (view: string): LssProp => ({
  p: 0,
  mx: 'auto',
  pb: '12rem' as any,
  bg: view === 'kpi' ? 'gray100' : 'white',
  tablet: {
    mt: REPORT_SUBNAV_HEIGHT.string as any,
    p: 0,
    pb: '12rem' as any
  }
});

export const metaDataAndTabsLss: LssProp = {
  position: 'sticky',
  top: `${NAV_HEIGHT.rem + REPORT_SUBNAV_HEIGHT_MOBILE.rem}rem`,
  bg: 'white',
  pt: 'sm',
  zIndex: 'footer',
  width: '100%',
  tablet: {
    top: `${NAV_HEIGHT.rem + REPORT_SUBNAV_HEIGHT.rem}rem`
  }
};

export const campaignInfoWrapperLss: LssProp = {
  alignItems: 'center',
  mx: 'lg',
  my: 'xs',
  flexFlow: 'wrap'
};

export const nameLss: LssProp = {
  textScale: 'heading4',
  tablet: { textScale: 'heading3' }
};

export const TabbedNavStickyWrapperLss: LssProp = {
  position: 'sticky',
  top: `${REPORT_SUBNAV_HEIGHT_MOBILE.rem + NAV_HEIGHT.rem}rem`,
  bg: 'white',
  zIndex: 'footer',
  boxShadow: BOX_SHADOW_BOTTOM,
  borderTop: 'regular',
  mt: 'sm',
  tablet: {
    top: `${REPORT_SUBNAV_HEIGHT.rem + NAV_HEIGHT.rem}rem`
  }
};

export const topSectionWrapperLss: LssProp = {
  bg: 'gray100',
  boxShadow: ` inset 0px 11px 10px -10px ${BaseTheme.colors.gray400}` as any,
  flexDirection: 'column',
  py: 'xl',
  width: '100%'
};

export const infoBoxLss: LssProp = {
  bg: 'white',
  border: 'regular',
  borderRadius: 'md',
  flexDirection: 'column',
  flexGrow: 10,
  px: 'xl',
  py: 'lg',
  mr: 'xs',
  mt: 'xs'
};

export const infoImpressionsLongLss: LssProp = {
  color: 'gray700',
  textScale: 'control',
  ml: 'sm'
};

export const lastUpdateContainerLss: LssProp = {
  ...infoBoxLss,
  flexGrow: 1,
  justifyContent: 'space-between',
  mr: 'xs',
  px: 'lg',
  py: 'xl'
};

export const lastUpdateTitleLss: LssProp = {
  fontWeight: 'bold',
  textAlign: 'center',
  textScale: 'control'
};

export const lastUpdateDateTextLss: LssProp = {
  fontWeight: 'bold',
  textAlign: 'center',
  textScale: 'heading4'
};

export const lastUpdateTimeWrapperLss: LssProp = {
  alignItems: 'center',
  justifyContent: 'center'
};

export const lastUpdatedTimeLss: LssProp = {
  color: 'gray800',
  ml: 'xs',
  textAlign: 'center',
  textScale: 'control'
};

export const totalCompletesContainerLss: LssProp = {
  ...lastUpdateContainerLss
};
export const totalCompletesTitleLss: LssProp = { ...lastUpdateTitleLss };
export const totalCompletesTextLss: LssProp = { ...lastUpdateDateTextLss };
export const totalCompletesLongLss: LssProp = {
  ...infoImpressionsLongLss,
  textAlign: 'center',
  ml: 0
};

export const progressContainerLss: LssProp = {
  ...infoBoxLss,
  justifyContent: 'space-between',
  py: 'xl',
  minWidth: '15rem'
};

export const progressTitleLss: LssProp = {
  ...lastUpdateTitleLss,
  textAlign: 'left'
};

export const progressDaysLss: LssProp = { textScale: 'control' };

export const progressLaunchEndLss: LssProp = {
  textScale: 'caption',
  color: 'gray800'
};

export const confidenceLevelOptionButtonLss = (isSelected: boolean) => ({
  ':focus': {
    outline: 'none',
    span: {
      color: isSelected ? 'white' : 'secondary500'
    }
  } as LssProp
});

export const confidenceOptionPercentLss: LssProp = {
  textScale: 'caption',
  textStyle: 'caps',
  fontWeight: 'bold',
  mr: 'xs'
};

export const confidenceOptionTextLss = (isSelected: boolean): LssProp => ({
  textScale: 'caption',
  textStyle: 'caps',
  color: isSelected ? 'white' : 'primary500',
  ':hover': { color: isSelected ? 'white' : 'secondary500' }
});

export const confidenceAndMarginWrapperLss: LssProp = {
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: 'auto',
  alignItems: 'center'
};

export const answerItemLss: LssProp = {
  alignItems: 'center',
  border: 'regular',
  borderColor: 'primary500',
  borderRadius: 'xl',
  height: '2.5rem',
  justifyContent: 'center',
  px: 'md',
  mr: 'sm'
};

export const addAnswersButtonOuterLss: LssProp = {
  p: 0,
  mr: 'md',
  border: 'thick',
  borderColor: 'transparent',
  borderRadius: 'rounded',
  ':focus': {
    outline: 'none',
    borderColor: 'secondary500'
  }
};

export const addAnswersButtonIconLss: LssProp = {
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'rounded',
  border: 'regular',
  borderColor: 'primary500',
  width: '2.5rem',
  height: '2.5rem'
};

export const removeAnswerButtonOuterLss: LssProp = {
  ...addAnswersButtonOuterLss,
  mx: 'none',
  ml: 'xxs'
};

export const removeAnswerButtonIconLss: LssProp = {
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'rounded',
  width: '1.5rem',
  height: '1.5rem',
  ':hover': { bg: 'gray200' }
};

export const dataAndLegendWrapperLss: LssProp = {
  flexDirection: 'column',
  width: '100%',
  tablet: { flexDirection: 'row' }
};

export const chartOrTableWrapperLss: LssProp = {
  ...infoBoxLss,
  mt: 0,
  py: 'md',
  flexGrow: 1,
  minWidth: '100%',
  minHeight: `${BASE_CHART_HEIGHT}rem`,
  tablet: { minWidth: '70%' }
};

export const chartLegendWrapperLss: LssProp = {
  ...infoBoxLss,
  flexGrow: 0,
  mt: 'xs',
  mr: 0,
  tablet: { mt: 0, width: '18rem' }
};

export const chartTooltipLss: LssProp = {
  flexDirection: 'column',
  bg: 'white',
  color: 'primary500',
  boxShadow: 'sm',
  border: 'none',
  borderRadius: 'md',
  p: 0,
  m: 0,
  width: '12rem'
};

export const overviewKPITableSignificanceLss: LssProp = {
  p: 'lg',
  justifyContent: 'center',
  tablet: { p: 'none', justifyContent: 'flex-start' }
};

export const overviewKPITableMarginLss: LssProp = {
  textScale: 'caption',
  display: 'block',
  color: 'gray800',
  mt: 'xxs'
};

export const significanceIndicatorWrapperLss = (
  wrapperLss: LssProp
): LssProp => ({
  alignItems: 'center',
  ...wrapperLss
});

export const significanceIndicatorLabelLss = (
  textScale: TextScaleProps['textScale'],
  textLss: LssProp,
  isSignificant: boolean
): LssProp => ({
  textScale,
  color: isSignificant ? 'primary500' : 'gray800',
  ...textLss
});

export const channelTagLss: LssProp = {
  alignItems: 'center',
  borderRadius: 'sm',
  border: 'regular',
  borderColor: 'gray300',
  py: 'xxs',
  px: 'xs'
};

export const liftBarPercentageLabelLss = (isXL?: boolean): LssProp => ({
  ml: isXL ? 'xs' : 'lg',
  textScale: 'control',
  fontWeight: 'bold',
  minWidth: '2.5rem'
});

// BenchmarkIndicator

export const getArrowStyle = (
  rating: BenchmarkRating | string,
  isLoading?: boolean
): LssProp => {
  const style: { [key: string]: [number, string] } = {
    Above: [180, COLORS.positive],
    Average: [-90, COLORS.positive],
    Below: [0, COLORS.negative],
    loading: [-90, 'gray400']
  };

  const [degrees, color] = style[isLoading ? 'loading' : rating];

  return {
    color: color as LssProp['color'],
    ml: (degrees === -90 ? '-1.2rem' : '-1rem') as any,
    transform: `rotate(${degrees}deg)`
  };
};

export const getTextStyles = (
  isLarge: boolean
): { [key: string]: LssProp } => ({
  main: {
    textScale: isLarge ? 'content' : 'control',
    fontWeight: isLarge ? 'bold' : 'regular'
  },
  sub: {
    textScale: isLarge ? 'control' : 'caption',
    color: 'gray800'
  }
});
