import React from 'react';
import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineRadioButtonUnchecked,
  MdOutlineStar
} from 'react-icons/md';

import {
  Dialog,
  DialogActions,
  DialogContent,
  Text,
  Button,
  Box,
  Icon,
  Flex,
  LssProp
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';
import {
  EnhancedTemplateQuestion,
  findTupleIndex
} from '~/utils/templateQuestions';

import { PrimaryKpiButton } from './PrimaryKPIButton';

type NumTuple = [number, number];

type CustomGridPreviewModalProps = {
  question: EnhancedTemplateQuestion;
  isOpen: boolean;
  onClick: () => void;
};

const dialogContentLss: LssProp = {
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '0.25rem'
};

const questionNameTextLss: LssProp = {
  textScale: 'heading4',
  fontWeight: 'bold',
  textAlign: 'start',
  my: 'sm',
  pl: 'md'
};

const customQuestionTextLss: LssProp = {
  textScale: 'content',
  textAlign: 'start',
  pl: 'md',
  fontSize: '1.125rem'
};

const colTextLss: LssProp = {
  justifySelf: 'center',
  px: 'xxs',
  pl: 'md',
  maxWidth: '10rem'
};

const rowTextLss: LssProp = {
  justifySelf: 'flex-start',
  px: 'xxs',
  alignSelf: 'center'
};

const answerOptionLss: LssProp = {
  justifySelf: 'center',
  p: 'xxs',
  pt: 'xs',
  alignSelf: 'center'
};

const getGridPreviewLss = (colLength: number, rowLength: number): LssProp => ({
  display: 'grid',
  alignContent: 'center',
  justifyContent: 'center',
  gridTemplateColumns: `minmax(3rem, max-content) repeat(${colLength}, minmax(3rem, max-content))`,
  gridTemplateRows: `repeat(${rowLength}, 1fr)`,
  p: 'sm',
  pb: 'xs',
  gap: '4px 8px'
});

export const CustomGridPreviewModal = ({
  isOpen,
  question,
  onClick
}: CustomGridPreviewModalProps) => {
  const answerOptionIcon = question.question_type.includes('radio')
    ? MdOutlineRadioButtonUnchecked
    : MdOutlineCheckBoxOutlineBlank;

  const rowLength = question?.rows?.length ?? 1;
  const colLength = question?.columns?.length ?? 1;

  return (
    <>
      <Dialog
        isOpen={isOpen}
        closeOnEscPress
        lss={{
          minWidth: 600
        }}
      >
        <DialogContent lss={dialogContentLss}>
          <Flex lss={{ flexDirection: 'row', pl: 'md' }}>
            <PrimaryKpiButton
              isSelected={question.default}
              onClick={() => null}
              readOnly
            />
            <Text lss={questionNameTextLss}>{question.question_name}</Text>
          </Flex>
          <Text lss={customQuestionTextLss}>
            {question.custom_question_text}
          </Text>

          <Box lss={getGridPreviewLss(colLength, rowLength)}>
            {/* COLUMNS*/}
            <Box />
            {!!question?.columns?.length &&
              question.columns?.map(col => {
                return (
                  <Box key={`${col.text}-${col.order}`} lss={colTextLss}>
                    {col.text}
                  </Box>
                );
              })}
            {/* ROWS */}
            {!!question?.rows?.length &&
              question.rows.map((row, rowIndex) => {
                return (
                  <React.Fragment key={`${row.text}-${row.order}`}>
                    <Box lss={rowTextLss}>{row.text}</Box>

                    {question.columns?.map((_, colIndex) => {
                      const targetOptionArr =
                        question?.multi_level_target_option_index;
                      const currTuple: NumTuple = [rowIndex, colIndex];
                      const isSelected = !!(
                        findTupleIndex(targetOptionArr, currTuple) > -1
                      );
                      const isInTargetVisible =
                        isSelected && !question.isBehavioralCustomKPI;

                      return (
                        <Box
                          key={`box-${colIndex}-answer`}
                          lss={answerOptionLss}
                        >
                          <Icon
                            as={MdOutlineStar}
                            size={22}
                            lss={{
                              color: 'secondary500',
                              visibility: isInTargetVisible
                                ? 'visible'
                                : 'hidden'
                            }}
                          />
                          <Icon as={answerOptionIcon} size={22} />
                        </Box>
                      );
                    })}
                  </React.Fragment>
                );
              })}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            emphasis="high"
            palette="secondary"
            lss={{ mr: 'xs' }}
            onClick={onClick}
          >
            {t('campaignManager.buttonTexts.edit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
