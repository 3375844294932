import { useState } from 'react';

type UseSingleUrlSetupParam = {
  mediaToolData: MediaTrackingToolsData;
  tagIndex: number;
  adServers: AdServerType[];
};

export type UseSingleUrlSetup = (
  config: UseSingleUrlSetupParam
) => {
  macros: MacroType[];
  adServer: AdServerType | null;
  hasModifiedMacros: boolean[];
  setHasModifiedMacros: ([]: boolean[]) => void;
};

export const useSingleUrlSetup: UseSingleUrlSetup = ({
  mediaToolData,
  tagIndex,
  adServers
}) => {
  const [hasModifiedMacros, setHasModifiedMacros] = useState<boolean[]>([
    false
  ]);

  const currentUniversalTag = mediaToolData?.universal_tags[tagIndex];
  const currentAdServerId = currentUniversalTag.ad_server_id;
  const macros = currentUniversalTag?.macros || [];

  const currentAdServer = adServers?.filter(
    server => server.id === currentAdServerId
  )[0];

  return {
    macros,
    adServer: currentAdServer,
    hasModifiedMacros,
    setHasModifiedMacros
  };
};
