import { SelectedTagType } from '~/impact-measurement/pages/Admin/Contexts';
import { EMPTY_UNIVERSAL_TAG } from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';
import { getArrayWithItemDeleted } from '~/impact-measurement/utils/getArrayWithItemDeleted';

import { TAG_KEYS } from '../constants';
import {
  HandleDeleteTagConfig,
  HandleToggleConfig,
  HandleAddTagConfig
} from './types';

export const isSelectedTag = (
  selectedTag: SelectedTagType | null,
  index: number,
  tagType: SelectedTagType['tagType']
) =>
  selectedTag !== null &&
  selectedTag?.index === index &&
  selectedTag.tagType === tagType;

export const handleDeleteTagCard = ({
  tagKey,
  tags,
  index,
  setFieldValue
}: HandleDeleteTagConfig) => {
  const newTags = getArrayWithItemDeleted(tags, index);

  setFieldValue(tagKey, newTags);
};

export const handleToggle = ({
  tagKey,
  index,
  selectedTag,
  setSelectedTag,
  isNew
}: HandleToggleConfig) => {
  const newSelectedTag = { index, tagType: tagKey };

  const tagValue =
    !isNew && isSelectedTag(selectedTag, index, tagKey) ? null : newSelectedTag;

  setSelectedTag(tagValue);
};

export const handleAddTagCard = ({
  tagKey,
  tags,
  setFieldValue,
  selectedTag,
  setSelectedTag,
  siteServedPixel
}: HandleAddTagConfig) => {
  const emptyTag =
    tagKey === TAG_KEYS.universal ? EMPTY_UNIVERSAL_TAG : siteServedPixel;

  const newTags = [...tags, emptyTag];

  setFieldValue(tagKey, newTags);

  const index = newTags.length - 1;

  handleToggle({ index, tagKey, selectedTag, setSelectedTag, isNew: true });
};

export const getHasWarningIcon = (
  warningTracker: WarningTracker,
  tagKey: TagKeys,
  index: number
) => {
  const warningKeys = Object.keys(warningTracker[tagKey][index] || {});
  return (
    warningKeys?.some(key => !!warningTracker[tagKey][index][key]) || false
  );
};
