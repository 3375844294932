import { useContext } from 'react';

import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';

export const useAssociatedMappings = (): { associatedMappings: LinearTv[] } => {
  const { savedMappings } = useContext(MediaTrackingToolsContext);

  const {
    mediaToolData: { linearTvListData, linearTv }
  } = useContext(MediaTrackingToolsFormContext);

  const initialMappingData: LinearTv[] = linearTvListData.length
    ? linearTvListData.map(
        ({ partner_id, partner_name, partner_campaigns_ids }) => ({
          partner_id,
          partner_name,
          partner_campaigns_ids
        })
      )
    : linearTv.map(p => ({
        ...p,
        partner_campaigns_ids: ['']
      }));

  // savedMappings is the API response after hitting save. Name is not included, so we need to map it back in.
  const savedMappingsWithName = savedMappings.map(mapping => ({
    ...mapping,
    partner_name:
      initialMappingData.find(m => m.partner_id === mapping.partner_id)
        ?.partner_name || ''
  }));

  const associatedMappings: LinearTv[] = savedMappingsWithName.length
    ? savedMappingsWithName
    : initialMappingData;

  const sorted = associatedMappings.sort((a, b) => a.partner_id - b.partner_id);

  return { associatedMappings: sorted };
};
