import React from 'react';

import { Box, LssProp } from '@lucidhq/lucidium';

export const DummyRadioButton = ({ lss }: { lss?: LssProp }) => (
  <Box
    lss={{
      borderRadius: 'rounded',
      border: 'regular',
      borderColor: 'gray500',
      bg: 'white',
      width: '16px',
      height: '16px',
      mr: 'md',
      ...lss
    }}
  />
);

export const DummyCheckbox = ({ lss }: { lss?: LssProp }) => (
  <Box
    lss={{
      border: 'regular',
      borderColor: 'primary500',
      bg: 'white',
      width: '14px',
      height: '14px',
      mr: 'md',
      ...lss
    }}
  />
);
