import { Dispatch, SetStateAction, useState } from 'react';

import { FetchMethods } from '@lucidhq/lucidium';

import { useApiFetch } from '~/utils/fetch';

import { URLS } from '../constants';

export type ExportType = 'excel' | 'powerpoint';

type UseExportCampaignReport = {
  exportCampaignReport: (
    campaignId: CampaignId,
    exportType: string,
    filters?: FiltersValues
  ) => Promise<void>;
  selectedFileType: string;
  setSelectedFileType: Dispatch<SetStateAction<string>>;
  isRequestingReport: boolean;
  errorMessage: string;
  hasRequestedReport: boolean;
  setHasRequestedReport: Dispatch<SetStateAction<boolean>>;
};

export const useExportCampaignReport = (): UseExportCampaignReport => {
  const apiFetch = useApiFetch();
  const [isRequestingReport, setRequestingReport] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedFileType, setSelectedFileType] = useState<string>('');
  const [hasRequestedReport, setHasRequestedReport] = useState<boolean>(false);

  const exportCampaignReport = async (
    campaignId: CampaignId,
    exportType: string,
    filters = {}
  ) => {
    setRequestingReport(true);
    setSelectedFileType(exportType);
    // if filters exist send them in the payload
    const json = !!Object.keys(filters).length
      ? {
          filters
        }
      : {};
    const config = {
      method: 'POST' as FetchMethods,
      url: URLS.campaignExport(campaignId, exportType),
      json
    };

    const { error } = await apiFetch<any, ApiErrorResponse>(config);

    setRequestingReport(false);

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    // cleanup error text if response does not include an error
    if (errorMessage.length) setErrorMessage('');

    return;
  };
  return {
    exportCampaignReport,
    selectedFileType,
    setSelectedFileType,
    isRequestingReport,
    errorMessage,
    hasRequestedReport,
    setHasRequestedReport
  };
};
