import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext
} from 'react';

import { useExportCampaignReport } from './hooks';

export type ExportReportContextType = {
  isRequestingReport: boolean;
  hasRequestedReport: boolean;
  setHasRequestedReport: Dispatch<SetStateAction<boolean>>;
  selectedFileType: string;
  setSelectedFileType: Dispatch<SetStateAction<string>>;
  errorMessage?: string;
  exportCampaignReport: (
    campaignId: CampaignId,
    exportType: string,
    filters?: FiltersValues
  ) => Promise<void>;
};

export const ExportReportContext = createContext<ExportReportContextType>(
  {} as ExportReportContextType
);

export const useExportReport = () =>
  useContext<ExportReportContextType>(ExportReportContext);

type ExportReportProviderProps = {
  children: ReactNode;
};

export const ExportReportProvider = ({
  children
}: ExportReportProviderProps) => {
  const {
    exportCampaignReport,
    selectedFileType,
    setSelectedFileType,
    isRequestingReport,
    errorMessage,
    hasRequestedReport,
    setHasRequestedReport
  } = useExportCampaignReport();

  return (
    <ExportReportContext.Provider
      value={{
        isRequestingReport,
        selectedFileType,
        setSelectedFileType,
        errorMessage,
        hasRequestedReport,
        setHasRequestedReport,
        exportCampaignReport
      }}
    >
      {children}
    </ExportReportContext.Provider>
  );
};
