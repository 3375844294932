import React, { useEffect } from 'react';
import { useContext } from 'react';
import { Prompt } from 'react-router';

import { Flex } from '@lucidhq/lucidium';

import { PageContentWrapper } from '~/impact-measurement/components';
import { useConfirmExit } from '~/impact-measurement/hooks';
import { MediaTrackingToolsContext } from '~/impact-measurement/pages/Admin/Contexts';

import { WARNING_DIALOG } from '../constants';
import { MediaTrackingToolsHeader } from './components';
import { getMediaTrackingMainSectionLss } from './lib';
import { useGetHasUnsavedItems } from './lib/useGetHasUnsavedItems';
import { MediaTrackingToolsProps } from './MediaTrackingTools';
import { mediaTrackingToolsWrapperLss } from './styles';
import { Tags } from './Tags';
import { Mapping } from './TvMapping';

export type MediaTrackingToolsContentProps = Omit<
  MediaTrackingToolsProps,
  'setProjectSetupView' | 'setMediaTrackingView' | 'hasUnsavedChanges'
>;

const promptMessage = {
  messageHeading: WARNING_DIALOG.HEADING,
  messageText: WARNING_DIALOG.SUBHEADING,
  confirmText: WARNING_DIALOG.BUTTON_DANGER,
  cancelText: WARNING_DIALOG.BUTTON_SECONDARY
};

export const MediaTrackingToolsContent: React.FC<MediaTrackingToolsContentProps> = ({
  isCampaignTask,
  mediaTrackingView,
  setHasUnsavedChanges,
  ...tagProps
}) => {
  const { toolView, setToolView } = useContext(MediaTrackingToolsContext);

  useEffect(() => {
    if (mediaTrackingView === 'tv') {
      setToolView('tv');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUnsavedItems = useGetHasUnsavedItems();

  useEffect(() => {
    setHasUnsavedChanges && setHasUnsavedChanges(hasUnsavedItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedItems]);

  useConfirmExit(hasUnsavedItems);

  return (
    <Flex lss={mediaTrackingToolsWrapperLss}>
      <MediaTrackingToolsHeader />
      <PageContentWrapper lss={getMediaTrackingMainSectionLss(isCampaignTask)}>
        {toolView === 'tv' ? (
          <Mapping isCampaignTask={isCampaignTask} />
        ) : (
          <Tags isCampaignTask={isCampaignTask} {...tagProps} />
        )}
      </PageContentWrapper>
      <Prompt when={hasUnsavedItems} message={JSON.stringify(promptMessage)} />
    </Flex>
  );
};
