import React, { useMemo } from 'react';
import { MdLock } from 'react-icons/md';

import {
  Box,
  Flex,
  Icon,
  LssProp,
  SystemColors,
  Tag,
  Text
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';
import {
  decorateTemplateQuestion,
  EnhancedTemplateQuestion
} from '~/utils/templateQuestions';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { AnswerSection } from '../../SurveyStep/TemplateQuestions/AnswerSection';
import { PrimaryKpiButton } from '../../SurveyStep/TemplateQuestions/PrimaryKPIButton';
import { QuestionTitle } from '../../SurveyStep/TemplateQuestions/QuestionTitle';
import { Colors, ReviewLayout } from '../Misc/ReviewLayout';
import { StandardQuestionWarning } from './SurveyBlock/StandardQuestionWarning';

const headingLss: LssProp = {
  fontSize: '1.1rem',
  mb: 'xl',
  fontWeight: 'bold'
};

export const SurveyBlock = () => {
  const { goToStep, steps, managedCampaign, can } = useCampaignManager();

  const isSocialCampaign = !!managedCampaign?.details.meta.isSocial;

  const behavioralQuestions = useMemo(() => {
    const q = managedCampaign?.details.draft_settings.baseline_questions;
    if (!q || !q.length) return [];

    return q.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[];
  }, [managedCampaign]);

  const standardQuestions = useMemo(() => {
    const q = managedCampaign?.details.draft_settings.questions;
    if (!q || !q.length) return [];

    return q.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[];
  }, [managedCampaign]);

  const socialQuestions = useMemo(() => {
    const q = managedCampaign?.details.draft_settings?.social_questions;
    if (!q || !q.length) return [];

    return q.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[];
  }, [managedCampaign]);

  const hasQuestions = !!standardQuestions.length;

  return (
    <>
      <ReviewLayout.Content color={Colors.GREEN}>
        {hasQuestions && (
          <>
            {can.edit && (
              <ReviewLayout.Edit onClick={() => goToStep(steps.SURVEY.id)} />
            )}
            <ReviewLayout.Item>
              <Text lss={headingLss}>
                {t('campaignManager.review.survey.standard')}
              </Text>
            </ReviewLayout.Item>

            {standardQuestions.map((q, i) => (
              <ReviewLayout.Item key={i}>
                <Flex lss={{ gap: '0.5rem', alignItems: 'center' }}>
                  {q.default && (
                    <PrimaryKpiButton
                      isSelected={q.default}
                      onClick={() => null}
                    />
                  )}

                  <Tag
                    textScale={'content'}
                    isRound
                    lss={{ bg: Colors.GREEN, color: 'gray900' }}
                  >
                    {q.question_name}
                  </Tag>

                  {q.default && (
                    <Text>
                      (
                      {t(
                        `campaignManager.survey.standardQuestions.tooltips.primaryKPI.selected`
                      )}
                      )
                    </Text>
                  )}
                </Flex>

                <Box lss={{ my: 'lg' }}>
                  <QuestionTitle question={q} readOnly index={i} />
                </Box>

                <Box lss={{ mb: 'xl' }}>
                  <AnswerSection question={q} onSave={() => null} readOnly />
                </Box>
              </ReviewLayout.Item>
            ))}
          </>
        )}

        {!hasQuestions && (
          <ReviewLayout.Item>
            <StandardQuestionWarning
              onClick={() => goToStep(steps.SURVEY.id)}
            />
          </ReviewLayout.Item>
        )}
      </ReviewLayout.Content>

      <ReviewLayout.Content color={Colors.YELLOW}>
        <ReviewLayout.Item>
          <Text lss={headingLss}>
            {t('campaignManager.review.survey.behavioral')}
          </Text>
        </ReviewLayout.Item>

        {behavioralQuestions.map((q, i) => (
          <ReviewLayout.Item key={i}>
            <Box>
              <Tag
                textScale={'content'}
                isRound
                lss={{ bg: Colors.YELLOW, color: 'gray900' }}
              >
                {q.question_name}{' '}
                <Icon as={MdLock} lss={{ ml: 'sm' }} size={24} />
              </Tag>
            </Box>

            <Box lss={{ my: 'lg' }}>
              <QuestionTitle question={q} readOnly index={i} />
            </Box>

            <Box lss={{ mb: 'xl' }}>
              <AnswerSection question={q} onSave={() => null} readOnly />
            </Box>
          </ReviewLayout.Item>
        ))}
      </ReviewLayout.Content>

      {isSocialCampaign && (
        <ReviewLayout.Content color={Colors.PINK}>
          <ReviewLayout.Item>
            <Text lss={headingLss}>
              {t('campaignManager.review.survey.social')}
            </Text>
          </ReviewLayout.Item>

          {socialQuestions.map((q, i) => (
            <ReviewLayout.Item key={i}>
              <Box>
                <Tag
                  textScale={'content'}
                  isRound
                  lss={{ bg: Colors.PINK as SystemColors, color: 'gray900' }}
                >
                  {q.question_name}{' '}
                  <Icon as={MdLock} lss={{ ml: 'sm' }} size={24} />
                </Tag>
              </Box>

              <Box lss={{ my: 'lg' }}>
                <QuestionTitle question={q} index={i} />
              </Box>

              <Box lss={{ mb: 'xl' }}>
                <AnswerSection question={q} onSave={() => null} readOnly />
              </Box>
            </ReviewLayout.Item>
          ))}
        </ReviewLayout.Content>
      )}
    </>
  );
};
