import { useApiFetchEffect } from '~/utils/fetch';

import { TRACKING, URLS } from '../../constants';
import { buildPreviewURL } from './mediaTrackingToolsHelpers';

export const useCreateUrl = (campaignId: string, macros: MacroType[]) => {
  const fallbackUrl = `https://tracker.samplicio.us/tracker/${campaignId}/pixel.gif`;
  const [
    isLoadingUrl,
    baseUrl = {
      base_url: fallbackUrl,
      gdpr_parameters: TRACKING.GDPR_PARAMS
    }
  ] = useApiFetchEffect<PixelBaseUrlResponse, ErrorResponse>(
    URLS.basePixelUrl(campaignId)
  );

  const { base_url: base, gdpr_parameters: gdpr } = baseUrl;

  const url = buildPreviewURL(macros, base, gdpr);
  const imgSrc = url ? `<img src="${url}" />` : '';

  return { isLoadingUrl, urlTag: url, imgSrc };
};
