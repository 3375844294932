import React, { useEffect } from 'react';

import { useAuth } from '@lucidhq/ui-auth';

export const LogoutPage = () => {
  const auth = useAuth();
  useEffect(() => {
    // eslint-disable-next-line no-return-await
    (async () => await auth.logout())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
