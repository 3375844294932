import React from 'react';
import { MdCheckCircle } from 'react-icons/md';

import { Flex, Icon, Text } from '@lucidhq/lucidium';

import { dialogSectionLss } from '../styles';

export const UploadSuccessfulMessage: React.FC = () => (
  <Flex lss={{ ...dialogSectionLss, alignItems: 'center' }}>
    <Text lss={{ fontWeight: 'bold', mr: 'xs' }}>CSV Upload Successful!</Text>
    <Icon as={MdCheckCircle} lss={{ color: 'emerald600' }} size={16} />
  </Flex>
);
