import { Campaign } from '~/types';
import { useMutation } from '~/utils/rq';

export const useUpsertDraftCampaign = (campaignId?: string) => {
  const qs = '?include_baseline_questions=true';
  const url = !!campaignId
    ? `/campaigns/${campaignId}${qs}`
    : `/campaigns${qs}`;
  const method = !!campaignId ? 'put' : 'post';

  return useMutation<Campaign, Campaign>(
    {},
    {
      url,
      method
    }
  );
};
