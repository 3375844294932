import React from 'react';

export const AIStarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2578 12.1723L16.315 12.3531H16.2001L16.2578 12.1723ZM15.7326 13.8192H16.7788L17.2914 15.4399L17.5559 15.7043L19.2958 16.2547H19.1765V17.2251H19.296L17.5549 17.7803L17.2919 18.0404L16.7891 19.6557H15.7399L15.2164 18.0398L14.9591 17.7796L13.211 17.225H13.3303V16.2547H13.2114L14.9581 15.705L15.2151 15.4423L15.7326 13.8192ZM11.7067 16.7283L11.6758 16.738L11.7067 16.7478V16.7283ZM20.8002 16.7454V16.7306L20.8235 16.738L20.8002 16.7454ZM16.254 15.4758L16.1318 15.859L16.0958 15.972L16.0129 16.0567L15.5853 16.4938L15.4976 16.5835L15.378 16.6212L14.9947 16.7418L15.3791 16.8638L15.4967 16.9011L15.5834 16.9888L16.0109 17.4211L16.0945 17.5056L16.1311 17.6186L16.2559 18.0039L16.3741 17.6241L16.4112 17.5049L16.4999 17.4172L16.9371 16.9849L17.0224 16.9005L17.1368 16.864L17.5199 16.7418L17.1379 16.621L17.0215 16.5841L16.9351 16.4978L16.498 16.0607L16.4116 15.9743L16.3748 15.8579L16.254 15.4758Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.82687 8.23609L9.06789 4.34427H10.3725L11.6027 8.23313L12.3646 8.99508L16.2533 10.2252V11.5299L12.3634 12.7703L11.6032 13.5222L10.3965 17.3995H9.08491L7.8285 13.5214L7.08502 12.7696L3.17645 11.5295V10.2256L7.08381 8.99585L7.82687 8.23609ZM9.71804 6.41985L8.97277 8.75703L8.92775 8.89821L8.82414 9.00416L7.86783 9.98195L7.7582 10.094L7.60863 10.1411L5.26179 10.8797L7.61001 11.6247L7.75699 11.6713L7.86541 11.781L8.82171 12.748L8.92612 12.8536L8.97189 12.9949L9.72996 15.3348L10.456 13.0018L10.5024 12.8528L10.6133 12.7431L11.5911 11.7761L11.6978 11.6706L11.8407 11.625L14.1779 10.8797L11.8421 10.1408L11.6966 10.0948L11.5887 9.98689L10.6109 9.00909L10.5029 8.90117L10.4569 8.75565L9.71804 6.41985Z"
        fill="white"
      />
    </svg>
  );
};
