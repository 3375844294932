import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Flex, LssProp } from '@lucidhq/lucidium';

import { ROUTES } from '~/constants';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../../CampaignManagerFooter';
import { useCampaignManager } from '../../CampaignManagerProvider';
import { StepLayout } from '../../Steps/StepLayout';
import { SurveyStepIds } from '../../useCampaignManagerSteps';
import { SurveyStepCarousel } from '../SurveyStepCarousel';
import { TemplateQuestionCard } from '../TemplateQuestions/TemplateQuestionCard';
import { TemplateQuestionEmptyCard } from '../TemplateQuestions/TemplateQuestionEmptyCard';
import {
  QuestionMoveDir,
  useStandardQuestionsProvider
} from './StandardQuestionsProvider';

const questionsContainerLss: LssProp = {
  flexBasis: '100%',
  height: 'auto',
  minWidth: '995px',
  overflowY: 'auto',
  flexDirection: 'column',
  gap: '1.5rem'
};

const overlayLss: LssProp = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  bg: 'rgba(255,255,255,0.5)' as any,
  zIndex: 99 as any
};

export const QuestionCardContainer = () => {
  const {
    standardQuestions,
    moveStandardQuestion,
    deleteStandardQuestion,
    saveStandardQuestion,
    submitStandardQuestions,
    setPrimaryQuestion,
    customKPIErrors,
    hasCustomKPIErrors,
    kpiErrors,
    hasKPIErrors
  } = useStandardQuestionsProvider();
  const { goToStep, steps, can } = useCampaignManager();
  const history = useHistory();

  const handleBack = () => {
    goToStep(steps.SURVEY.id, {
      activeChildStep: SurveyStepIds.BRAND
    });
  };

  const readOnly = !can.edit;

  return (
    <Box lss={{ width: 'fit-content', justifySelf: 'start' }}>
      <StepLayout.Header
        title={t('campaignManager.survey.standardQuestions.header.title')}
        lss={{ mb: 'xxl', height: 'fit-content' }}
      />

      <Flex lss={questionsContainerLss}>
        {!standardQuestions?.length && <TemplateQuestionEmptyCard />}
        {!!standardQuestions?.length &&
          standardQuestions.map((q, index) => (
            <Box key={index} lss={{ position: 'relative' }}>
              <TemplateQuestionCard
                readOnly={readOnly}
                question={q}
                index={index}
                onDelete={() => deleteStandardQuestion(index)}
                onSave={data => saveStandardQuestion(index, data)}
                onSetPrimary={() => setPrimaryQuestion(index)}
                {...(index !== 0 &&
                  can.moveKPI && {
                    onMoveUp: () =>
                      moveStandardQuestion(index, QuestionMoveDir.up)
                  })}
                {...(index !== standardQuestions.length - 1 &&
                  can.moveKPI && {
                    onMoveDown: () =>
                      moveStandardQuestion(index, QuestionMoveDir.down)
                  })}
                customKPIErrors={customKPIErrors}
                hasCustomKPIErrors={hasCustomKPIErrors}
                kpiErrors={kpiErrors}
                hasKPIErrors={hasKPIErrors}
              />

              {q.question_version !== 2 && (
                <Box lss={overlayLss}>
                  This question can only be edited in creator v1
                </Box>
              )}
            </Box>
          ))}
      </Flex>
      <Flex lss={{ mt: 'xxl', flexDirection: 'column' }}>
        <SurveyStepCarousel />
        <CampaignManagerFooter
          onBack={handleBack}
          saveCloseProps={{
            onClick: () => {
              submitStandardQuestions({
                onSuccess: () => {
                  history.push(ROUTES.adminCampaigns);
                }
              });
            }
          }}
          saveContinueProps={{
            isDisabled: hasCustomKPIErrors || hasKPIErrors,
            onClick: () => {
              submitStandardQuestions({
                onSuccess: () => {
                  goToStep(steps.SURVEY.id, {
                    activeChildStep: SurveyStepIds.BEHAVIORAL_QUESTIONS
                  });
                }
              });
            }
          }}
        />
      </Flex>
    </Box>
  );
};
