import React from 'react';

import { Flex, H4, LssProp, Text } from '@lucidhq/lucidium';

import { DeleteButton } from '~/impact-measurement/components';

import { useDeleteTvMappingId } from '../lib/useDeleteMappedId';

type AssociatedIdSectionProps = {
  partner: LinearTv;
};

const itemLss: LssProp = {
  borderBottom: 'regular',
  py: 'xs',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export const AssociatedIdSection: React.FC<AssociatedIdSectionProps> = ({
  partner: { partner_campaigns_ids: ids, partner_id, partner_name }
}) => {
  const idIsPlaceholder = ids.length === 1 && !ids[0];
  const hasNoIds = !ids.length || idIsPlaceholder;

  const { deleteMapping, isDeleting } = useDeleteTvMappingId(partner_id);

  return (
    <Flex lss={{ flexDirection: 'column', my: 'lg' }}>
      <H4>Associated {partner_name} IDs</H4>
      <Flex lss={{ borderBottom: 'thick', mt: 'lg' }} />
      {hasNoIds ? (
        <Text key={`add-ids-${partner_name}`} lss={{ textScale: 'control' }}>
          Add and save IDs to get started.
        </Text>
      ) : (
        ids.map((id, index) => (
          <Flex key={`${id}-${index}`} lss={itemLss}>
            <Text lss={{ ml: 'sm' }}>{id}</Text>
            <DeleteButton
              size={18}
              iconColor="gray600"
              ariaLabel={`delete-${partner_name}-mapping-${id}`}
              onClick={() => deleteMapping(id)}
              isDisabled={isDeleting}
            />
          </Flex>
        ))
      )}
    </Flex>
  );
};
