import React from 'react';

import { Flex, LssProp } from '@lucidhq/lucidium';

import { IPMatchingSection } from './IPMatchingSection';
import { IPMatchingSectionDescription } from './IPMatchingSectionDescription';

const ipManagementContainerLss: LssProp = {
  flexDirection: 'column',
  minWidth: '61.5rem',
  borderRadius: 'lg',
  bg: 'gray100',
  minHeight: 'fit-content',
  gap: '0.5rem',
  padding: '2.5rem 3rem',
  marginTop: '0.75rem',
  alignSelf: 'center',
  mobile: {
    minWidth: '30.75rem',
    flex: 0
  }
};

export const IPManagementSection = () => {
  return (
    <Flex lss={ipManagementContainerLss}>
      <IPMatchingSection />
      <IPMatchingSectionDescription />
      {/* TODO: integrate IP Filtering here when available */}
    </Flex>
  );
};
