import React, { useState } from 'react';

import { Label, LssProp, TextInput } from '@lucidhq/lucidium';

import { FieldErrorMessage } from '~/components/Shared/FieldErrorMessage';

type CustomTextFieldProps = {
  label: string;
  name: string;
  value?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (values: any) => void;
  showErrorMessage: boolean;
  errorMessage: string;
  lss?: LssProp;
};

export const CustomTextField = ({
  name,
  value,
  label,
  placeholder,
  required,
  onChange,
  showErrorMessage,
  errorMessage,
  lss
}: CustomTextFieldProps) => {
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);

  return (
    <>
      <Label htmlFor={name} required lss={{ mt: 'md', ...lss }}>
        {label}
      </Label>
      <TextInput
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        onBlur={(e: any) => {
          setDirty(true);
          setValid(e.target.value !== '');
        }}
        lss={lss}
      />
      {showErrorMessage && isDirty && !isValid && (
        <FieldErrorMessage message={errorMessage} />
      )}
    </>
  );
};
