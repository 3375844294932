import {
  BehavioralKPITypes,
  StandardKPITypes,
  SocialKPITypes,
  TemplateQuestion
} from '~/components/Shared/hooks/useFetchTemplateQuestions';

import { t } from './i18n';

enum QUESTION_PROPERTIES {
  RANDOM = 'random',
  NOTA = 'none of the above'
}

//NOTE: used in question logic section to replace hardcoded strings
export const NOTA = 'None of the above';

const answerOptions = [
  {
    label: 'Single Choice',
    value: 'radio'
  },
  {
    label: 'Multiple Choice',
    value: 'checkbox'
  },
  {
    label: 'Grid Single Choice',
    value: 'table-radio'
  },
  {
    label: 'Grid Multiple Choice',
    value: 'table-checkbox'
  },
  { label: 'Text', value: 'text' },
  { label: 'Text', value: 'text-interpreted' }
];

const CUSTOM = 'custom';

export type AnswerOption = { label: string; value: string };
// NOTE: the NOTA option added here to reduce inconsistencies with the API.
// A refactor of the templateQuestions API will greatly help improve all custom KPI functionality.
export const DEFAULT_CUSTOM_ANSWERS = ['', '', '', '', '', NOTA];

export const DEFAULT_GRID_VALUES = [
  {
    order: 0,
    text: '',
    title: ''
  },
  {
    order: 1,
    text: '',
    title: ''
  },
  {
    order: 2,
    text: '',
    title: ''
  }
];

/**
 * @returns An array of AnswerOptions for the QuestionType Select in CC2.0.
 *
 * @remarks At this time this method handles an issue between v1 and v2 campaigns since not all legacy campaigns
 * have the "allowed_questions_types" property on the campaign object. After v1 is deprecated this method can be refactored.
 * @param questionType
 * @param allowedOptions
 *
 */
export const getAnswerOptions = (
  questionType: string,
  allowedOptions?: string[]
): AnswerOption[] => {
  if (!allowedOptions || !allowedOptions?.length) {
    return answerOptions.filter(option => option.value === questionType);
  }

  return answerOptions.filter(
    option => !!allowedOptions?.find(el => option.value === el)
  );
};

export type EnhancedTemplateQuestion = TemplateQuestion & {
  meta: {
    isStandard: boolean;
    isBehavioral: boolean;
    isSocial: boolean;
    allowedQuestionTypeOptions: AnswerOption[];
    isAllowedToRandomize: boolean;
    isAllowedToAddNOTA: boolean;
    isCustomKPI: boolean;
  };
};

export const decorateTemplateQuestion = (
  templateQuestion: TemplateQuestion
): EnhancedTemplateQuestion => ({
  ...templateQuestion,
  meta: {
    isStandard: Object.values(StandardKPITypes).includes(
      templateQuestion.kpi_type as StandardKPITypes
    ),
    isBehavioral: Object.values(BehavioralKPITypes).includes(
      templateQuestion.kpi_type as BehavioralKPITypes
    ),
    isSocial: Object.values(SocialKPITypes).includes(
      templateQuestion.kpi_type as SocialKPITypes
    ),
    isCustomKPI: templateQuestion.kpi_type === CUSTOM,
    allowedQuestionTypeOptions: getAnswerOptions(
      templateQuestion.question_type,
      templateQuestion?.allowed_question_types
    ),
    isAllowedToRandomize: !!templateQuestion?.allowed_question_properties?.includes(
      QUESTION_PROPERTIES.RANDOM
    ),
    isAllowedToAddNOTA: !!templateQuestion?.allowed_question_properties?.includes(
      QUESTION_PROPERTIES.NOTA
    )
  }
});

// method that filters out NOTA and maps the brand answers array to data structure needed for rows
const getDefaultBrandRows = (answers?: string[]) => {
  if (!answers?.length) return [];

  const copy = [...answers];

  return copy
    .filter(a => !a.includes(NOTA))
    .map((answer, index) => ({
      order: index,
      text: answer,
      title: answer
    }));
};

/**
 * This func generates a custom KPI question. In due time the API should provide the client with this data.
 * @param questions
 * @param isBehavioralCustomKPI
 * @returns preset Custom Template Question.
 */
type getCustomKPIType = {
  questions: TemplateQuestion[];
  isBehavioralCustomKPI?: boolean;
};

export const getCustomKPI = ({
  questions,
  isBehavioralCustomKPI = false
}: getCustomKPIType) =>
  ({
    ...questions?.[0],
    kpi_type: 'custom',
    required: true,
    fields: [],
    multi_target_option_index: [0],
    multi_level_target_option_index: isBehavioralCustomKPI
      ? null
      : [
          [0, 0],
          [0, 1]
        ],
    is_custom_question_text: true,
    question_name: '',
    question_text: '',
    question_text_template: '',
    question_type: 'radio',
    allowed_question_types: [
      'radio',
      'checkbox',
      'table-radio',
      'table-checkbox',
      'text'
    ],
    defaultBrandAnswers: questions?.[0]?.answers,
    defaultBrandRows: getDefaultBrandRows(questions?.[0]?.answers),
    customAnswers: DEFAULT_CUSTOM_ANSWERS,
    rows: getDefaultBrandRows(questions?.[0]?.answers),
    customRows: DEFAULT_GRID_VALUES,
    columns: [],
    isBehavioralCustomKPI
  } as TemplateQuestion);

const getKPIerrors = (errors: Record<string, any>): boolean => {
  const kpiErrors = Object.values(errors);

  for (const kpi of kpiErrors) {
    if (Object.keys(kpi).length) return true;
  }

  return false;
};
/**
 * This method is used to reset Custom KPI Questions in both Standard and Behavioral providers.
 * The reset behavior should only occur when a user has chosen to use the "defaultBrandAnswers" instead of setting their own custom answers.
 * @param questions
 * @returns EnhancedTemplateQuestions[]
 */
export const resetCustomKPI = (
  questions: EnhancedTemplateQuestion[]
): EnhancedTemplateQuestion[] => {
  const copy = [...questions];

  return copy.map(q => {
    // Reset behavior for Custom KPI (non-grid)
    if (
      !!q.meta.isCustomKPI &&
      !!q.use_answer_meta &&
      !q.question_type.includes('table')
    ) {
      q.customAnswers = DEFAULT_CUSTOM_ANSWERS;
    }
    // Reset behavior for Custom KPI (grid)
    if (
      !!q.question_type.includes('table') &&
      !!q.meta.isCustomKPI &&
      !!q.use_answer_meta
    ) {
      q.customRows = DEFAULT_GRID_VALUES;
    }

    return q;
  });
};

/**
 * This func validates all Custom KPI required fields and returns an object
 * containing errors related to a specific question index and field name.
 * It additionally returns a "catch-all" boolean to be used by the CampaignManagerFooter buttons.
 * @param questions
 * @returns - { errors, hasCustomKPIErrors }
 */
export const validateCustomKPIs = (questions: any) => {
  //@TODO: add types for method once all conditions are added
  const customKPIErrors = {};

  questions.forEach((q: any, i: number) => {
    if (q.kpi_type !== 'custom') return;

    customKPIErrors[`customKPI${i}`] = {};

    if (q.question_name === '') {
      customKPIErrors[`customKPI${i}`]['question_name'] = t(
        'forms.errors.customKPIName'
      );
    }

    if (q.custom_question_text === '') {
      customKPIErrors[`customKPI${i}`]['custom_question_text'] = t(
        'forms.errors.customQuestionText'
      );
    }

    if (
      !q.use_answer_meta &&
      !!q.customAnswers.length &&
      !q.question_type.includes('table')
    ) {
      q?.customAnswers?.forEach((answer: string, j: number) => {
        if (j > 2) return;

        if (answer === '' && j <= 2) {
          customKPIErrors[`customKPI${i}`][
            `customAnswer${j}`
          ] = t('forms.errors.answerX', { index: j + 1 });
        }
      });
    }

    if (
      !q.use_answer_meta &&
      q.meta.isCustomKPI &&
      !!q.question_type.includes('table')
    ) {
      q?.rows.forEach((answer: any, j: number) => {
        if (j > 1) return;

        if (answer.text === '' && j <= 1) {
          customKPIErrors[`customKPI${i}`][
            `rows${j}`
          ] = t('forms.errors.answerX', { index: j + 1 });
        }
      });

      q?.columns.forEach((answer: any, j: number) => {
        if (j > 1) return;

        if (answer.text === '' && j <= 1) {
          customKPIErrors[`customKPI${i}`][
            `columns${j}`
          ] = t('forms.errors.answerX', { index: j + 1 });
        }
      });
    }
  });

  return {
    customKPIErrors,
    hasCustomKPIErrors: getKPIerrors(customKPIErrors)
  };
};

/**
 * This func validates conventional KPIs custom_question_text field and returns an object
 * containing errors related to a specific question index and the custom_question_text field name.
 * Additionally returns a "catch-all" boolean to be used by the CampaignManagerFooter buttons.
 * @param questions
 * @returns - { kpiErrors, hasKPIErrors }
 */
export const validateConventionalKPIs = (questions: any) => {
  const kpiErrors = {};

  questions.forEach((q: any, i: number) => {
    if (q.kpi_type === 'custom') return;

    kpiErrors[`kpi${i}`] = {};

    if (q.is_custom_question_text && q.custom_question_text.trim() === '') {
      kpiErrors[`kpi${i}`]['custom_question_text'] = t(
        'forms.errors.customQuestionText'
      );
    }
  });

  return {
    kpiErrors,
    hasKPIErrors: getKPIerrors(kpiErrors)
  };
};

/**
 * GRID HELPERS/UTILS
 */

type NumTuple = [number, number];

type AreTuplesEqual = (tup1: NumTuple, tup2: NumTuple) => boolean;

enum FIELD_NAMES {
  ROWS = 'rows',
  COLS = 'columns'
}

export const areTuplesEqual: AreTuplesEqual = (tup1, tup2) => {
  if (tup1.length !== tup2.length) return false;

  return tup1.every((val, index) => val === tup2[index]);
};

export const findTupleIndex = (
  targetOptionArr: NumTuple[] | null,
  tup: NumTuple
): number => {
  let indexOfTuple = -1;

  targetOptionArr?.forEach((currTup, idx) => {
    if (areTuplesEqual(currTup, tup)) {
      indexOfTuple = idx;
    }
  });

  return indexOfTuple;
};

export const updateGridInTargetAnswers = (
  targetOptionArr: NumTuple[] | null,
  tup: NumTuple
): NumTuple[] => {
  const copy = !!targetOptionArr?.length ? [...targetOptionArr] : [];
  const indexOfTuple = findTupleIndex(copy, tup);

  if (indexOfTuple > -1) {
    copy.splice(indexOfTuple, 1);
  } else {
    copy.push(tup);
  }

  if (!copy.length) {
    copy.push([0, 0]);
  } else {
    // sort tuples based off both values
    copy.sort((a, b) => {
      return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
    });
  }

  return copy;
};

export const updateMultiLevelInTargetAnswers = (
  index: number,
  fieldName: 'rows' | 'columns',
  targetIndices: NumTuple[]
) => {
  const copy = [...targetIndices];
  const newTuples: NumTuple[] = [];

  const selectedRows = [...new Set(copy.map((tup: NumTuple) => tup[0]))];
  const selectedCols = [...new Set(copy.map((tup: NumTuple) => tup[1]))];

  if (fieldName === FIELD_NAMES.ROWS) {
    selectedCols.forEach((col: number) => {
      const tuple: NumTuple = [index, col];
      newTuples.push(tuple);
    });
  }

  if (fieldName === FIELD_NAMES.COLS) {
    selectedRows.forEach((row: number) => {
      const tuple: NumTuple = [row, index];
      newTuples.push(tuple);
    });
  }

  newTuples.forEach((tup: NumTuple) => {
    const indexOfTuple = findTupleIndex(copy, tup);

    if (indexOfTuple > -1) {
      copy.splice(indexOfTuple, 1);
    } else {
      copy.push(tup);
    }
  });

  if (!copy.length) {
    copy.push([0, 0]);
  } else {
    // sort tuples based off both values
    copy.sort((a, b) => {
      return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
    });
  }

  return copy;
};

export const findInTargetGridAnswer = (
  fieldName: string,
  coords: NumTuple[],
  currentIndex: number
) => {
  const searchIndex = fieldName === FIELD_NAMES.ROWS ? 0 : 1;

  return coords.some(tuple => tuple[searchIndex] === currentIndex);
};
