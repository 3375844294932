import {
  NAV_HEIGHT,
  ADMIN_SUBNAV_HEIGHT,
  ADMIN_CAMPAIGN_HEADER_HEIGHT
} from '~/impact-measurement/constants';

const TABS_HEIGHT = { string: '3.875rem', rem: 3.875 };

export const PROJ_SETUP_SECTION_HEIGHT_DIFF = NAV_HEIGHT.rem + TABS_HEIGHT.rem;
export const TASK_SECTION_HEIGHT_DIFF =
  PROJ_SETUP_SECTION_HEIGHT_DIFF +
  ADMIN_SUBNAV_HEIGHT.rem +
  ADMIN_CAMPAIGN_HEADER_HEIGHT.rem;

export const TAG_KEYS = {
  site: 'site_served_tags' as TagKeys,
  universal: 'universal_tags' as TagKeys,
  bulk: 'bulk_tags' as TagKeys
};

export enum TAB_IDS {
  tv = 'tv',
  site = 'site',
  universal = 'universal'
}
