import { useQuery } from '~/utils/rq';

export enum TargetAudienceQuestionType {
  Numeric = 'Numeric - Open-end',
  Single = 'Single Punch',
  Multi = 'Multi Punch'
}

export type TargetAudienceQuestion = {
  name: string;
  question_id: number;
  question_text: string;
  question_type: TargetAudienceQuestionType;
};

type TargetAudienceQuestionsResponse = {
  questions: TargetAudienceQuestion[];
};

export const useFetchTargetAudienceQuestions = (countryId?: number) => {
  const URL = `admin/country-languages/${countryId}/questions`;

  return useQuery<TargetAudienceQuestionsResponse>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60,
      enabled: !!countryId
    },
    {
      method: 'get',
      url: URL
    }
  );
};
