import React, { useContext, useState } from 'react';

import { Flex, LssProp, useToggle } from '@lucidhq/lucidium';

import { PageIntro } from '~/impact-measurement/components';
import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';

import { BULK_SECTION_INTRO } from '../../constants';
import { handleFileUpload } from '../../lib';
import { CsvUploadDialog } from './CsvUploadDialog';
import { SiteServedBulkSetup } from './SiteServedBulkSetup';
import { SiteServedBulkUploader } from './SiteServedBulkUploader';

export const SiteServedBulkSection: React.FC = () => {
  const [bulkPixels, setBulkPixels] = useState<MappingBulkPixels>({
    titles: [],
    data: {}
  });
  const [isOpen, handleToggle] = useToggle();

  const {
    mediaToolData: { bulk_tags }
  } = useContext(MediaTrackingToolsFormContext);

  const { fileName, setFilename } = useContext(MediaTrackingToolsContext);

  const hasBulk = !!bulk_tags.length && !!fileName.length;

  const wrapperLss: LssProp = {
    mt: 'xl',
    flexDirection: 'column',
    height: hasBulk ? 'auto' : '20vh',
    minHeight: hasBulk ? 'none' : '15rem'
  };

  return (
    <Flex lss={wrapperLss}>
      <PageIntro {...BULK_SECTION_INTRO} />
      {hasBulk ? (
        <SiteServedBulkSetup
          fileName={fileName}
          handleToggle={handleToggle}
          setFilename={setFilename}
          setBulkPixels={setBulkPixels}
        />
      ) : (
        <SiteServedBulkUploader
          handleSelection={file => {
            handleFileUpload(file, setBulkPixels, setFilename);
            !!file && handleToggle();
          }}
        />
      )}
      <CsvUploadDialog
        isOpen={isOpen}
        fileName={fileName}
        handleToggle={handleToggle}
        setFilename={setFilename}
        setBulkPixels={setBulkPixels}
        bulkPixels={bulkPixels}
      />
    </Flex>
  );
};
