import React from 'react';

import { Flex, Text } from '@lucidhq/lucidium';

import { getValuesCount } from '../lib';

export type MappedSiteServedValuesProps = {
  values: string[];
  label: string;
};

export const MappedSiteServedValuesItem: React.FC<MappedSiteServedValuesProps> = ({
  values,
  label
}) => (
  <Flex lss={{ alignItems: 'center' }}>
    <Text lss={{ mr: 'sm' }}>
      <b>{getValuesCount(values)}</b> {label} values
    </Text>
  </Flex>
);
