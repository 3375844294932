import { useQuery } from '~/utils/rq';

type IPMatchingId = {
  ip_matching_type: string;
  ip_matching_id: string;
  enablement_status: string;
  is_enabled: boolean;
  // NOTE: this value is a date string
  added_at: string;
};

export type IPMatchingStatuses = 'auto' | 'migration' | 'manual';

export type IPMatchingResponseType = {
  enablement_status: IPMatchingStatuses | string | null;
  is_enabled: boolean;
  items: IPMatchingId[];
  page?: number;
  page_size?: number;
  total_items?: number;
};

type useFetchIPMatchingProps = {
  campaignId: string;
};

export const useFetchIPMatching = ({ campaignId }: useFetchIPMatchingProps) => {
  const IP_MATCHING_URL = `admin/campaigns/${campaignId}/ip-matching?page_size=50`;

  return useQuery<IPMatchingResponseType>(
    {
      queryKey: [IP_MATCHING_URL],
      staleTime: 10 * 60 * 60,
      enabled: !!campaignId
    },
    {
      method: 'get',
      url: IP_MATCHING_URL
    }
  );
};
