import React from 'react';
import { useHistory } from 'react-router-dom';

import { setupAnalytics, identify } from '@cint/analytics';
import * as Sentry from '@sentry/react';
import { History } from 'history';

import {
  SENTRY_DSN as sentryDsn,
  DEPLOYMENT_ENV as deploymentEnv,
  APP_VERSION,
  AMPLITUDE_ENABLED,
  AMPLITUDE_KEY
} from '~/constants';
import { useProfile } from '~/impact-measurement/utils/auth/useProfile';

const init = (sentryHistory: History<unknown>) => {
  const config = {
    deploymentEnv,
    releaseVersion: APP_VERSION,
    ...(AMPLITUDE_ENABLED && {
      amplitudeConfig: {
        apiKey: AMPLITUDE_KEY
      }
    }),
    sentryConfig: {
      dsn: sentryDsn,
      // session replays
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // events
      tracesSampleRate: 0.1,
      // errors
      sampleRate: 1,

      integrations: [
        Sentry.reactRouterV5BrowserTracingIntegration({
          history: sentryHistory
        })
      ]
    }
  };

  setupAnalytics(config);
};

const TrackUser = React.memo(() => {
  const profile = useProfile();

  React.useEffect(() => {
    if (profile) {
      identify({
        id: profile.id,
        email: profile.email
      });

      Sentry.setUser({
        id: profile.id,
        email: profile.email
      });
    }
  }, [profile]);

  return null;
});

TrackUser.displayName = 'TrackUser';

export const Analytics = () => {
  if (import.meta.env.DEV && !import.meta.env.TEST) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const sentryHistory = useHistory();
  init(sentryHistory);

  return <TrackUser />;
};
