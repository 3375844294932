import React from 'react';

import { ConfirmDialog, useToggle } from '@lucidhq/lucidium';

import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type DeleteAudienceFilterProps = {
  id: number;
  children: ({
    onDelete,
    isLoading
  }: {
    onDelete: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
  onDelete: () => void;
};

export const DeleteAudienceFilter = ({
  id,
  children,
  onDelete
}: DeleteAudienceFilterProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const { managedCampaign, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const handleDelete = () => {
    if (!managedCampaign) return;

    const currentTargets =
      managedCampaign.details.draft_settings.target_audiences || [];

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details?.draft_settings,
          target_audiences: currentTargets.filter((t, k) => k !== id)
        }
      },
      {
        onSuccess: () => {
          onDelete();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onDelete: handleToggle, isLoading })}
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleDelete}
        onClose={handleToggle}
      >
        {t('campaignManager.mediaTracking.delete.description')}
      </ConfirmDialog>
    </>
  );
};
