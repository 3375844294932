import { FormikErrors } from 'formik-legacy';

import { MediaTrackingToolsFormContextType } from '~/impact-measurement/pages/Admin/Contexts';
import { getArrayWithItemDeleted } from '~/impact-measurement/utils/getArrayWithItemDeleted';

import {
  HandleAddCustomMacroConfig,
  HandleRemoveCustomMacroConfig
} from './types';

export const getCustomParam = (num: number) => `c${num + 1}`;

export const handleRemoveCustomMacro = ({
  tagKey,
  macros,
  index,
  tagIndex,
  setFieldValue
}: HandleRemoveCustomMacroConfig) => {
  const newMacros = getArrayWithItemDeleted(macros, index);

  const customMacros = newMacros.filter(m => m.label.includes('Custom'));
  const nonCustomMacros = newMacros.filter(m => !m.label.includes('Custom'));

  const updatedCustomMacros = customMacros.map((m, index) => ({
    label: `Custom ${index + 1} (${getCustomParam(index)})`,
    param: getCustomParam(index),
    values: m.values
  }));

  setFieldValue(`${tagKey}.${tagIndex}.macros`, [
    ...nonCustomMacros,
    ...updatedCustomMacros
  ]);
};

export const handleAddCustomMacro = ({
  tagKey,
  macros,
  tagIndex,
  setFieldValue
}: HandleAddCustomMacroConfig) => {
  const customMacros = macros.filter(m => m.label.includes('Custom'));
  const totalCurrentCustomMacros = customMacros.length;
  const customParam = getCustomParam(totalCurrentCustomMacros);
  const newMacro = {
    label: `Custom ${totalCurrentCustomMacros + 1} (${customParam})`,
    param: customParam,
    values: []
  };
  setFieldValue(`${tagKey}.${tagIndex}.macros`, [...macros, newMacro]);
};

export const handleResetMacros = (
  macros: MacroType[],
  adServer: AdServerType | null,
  tagIndex: number,
  setFieldValue: MediaTrackingToolsFormContextType['setFieldValue']
) => {
  if (!adServer) return;

  const macroReset = Object.assign([], macros, adServer.macros);
  setFieldValue(`universal_tags.${tagIndex}.macros`, macroReset);
};

export const isInvalidField = (str: string): boolean => {
  const regex = new RegExp(/=|\?|&/, 'g');
  return regex.test(str);
};

export const validateMacrosArray = (
  pixel: SiteServedPixel | UniversalPixel,
  validityArray: boolean[]
) =>
  pixel.macros.map(macro => {
    const isInvalid = isInvalidField(macro.values[0]);
    macro.values[0] = isInvalid
      ? 'This field can’t contain "&", "?", or "="'
      : '';
    validityArray.push(isInvalid);
    return macro;
  });

export const pixelValidation = (
  pixel: SiteServedPixel | UniversalPixel,
  validityArray: boolean[]
) => {
  const newMacros = validateMacrosArray(pixel, validityArray);
  if (newMacros.length) {
    pixel.macros = newMacros;
  }
};

export const fieldValidation = (mediaToolData: MediaTrackingToolsData) => {
  const validatedMediaToolData: MediaTrackingToolsData = JSON.parse(
    JSON.stringify(mediaToolData)
  );
  const validForm = {
    universal_tags: [] as boolean[],
    site_served_tags: [] as boolean[]
  };

  validatedMediaToolData.universal_tags.forEach(pixel => {
    pixel.ad_server_id = '';
    pixelValidation(pixel, validForm.universal_tags);
  });

  validatedMediaToolData.site_served_tags.forEach(pixel =>
    pixelValidation(pixel, validForm.site_served_tags)
  );

  return {
    ...(validForm.universal_tags.includes(true) && {
      universal_tags: validatedMediaToolData.universal_tags
    }),
    ...(validForm.site_served_tags.includes(true) && {
      site_served_tags: validatedMediaToolData.site_served_tags
    })
  } as FormikErrors<MediaTrackingToolsData>;
};

export const getFieldIsModified = (
  adServer: AdServerType | null,
  macro: MacroType,
  index: number
) => {
  const defaultValue = adServer?.macros[index];
  const isModified =
    macro.values && defaultValue
      ? defaultValue.values[0] !== macro.values[0]
      : false;

  return isModified;
};
