import { SELECT_AD_SERVER } from '../constants';

type GetCardTitleParam = {
  adServers: AdServerType[];
  ad_server_id: string;
};

export type GetCardTitle = (config: GetCardTitleParam) => string;

export const getCardTitle: GetCardTitle = ({ adServers, ad_server_id }) => {
  if (!adServers.length || ad_server_id === '') return SELECT_AD_SERVER;

  const selectedAdServer = adServers.find(server => ad_server_id === server.id);

  return selectedAdServer?.name || SELECT_AD_SERVER;
};
