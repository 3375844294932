import React, { useState } from 'react';

import { Flex, Label, Radio } from '@lucidhq/lucidium';
import { useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { GeoLocationOption } from './GeoLocationOption';

type GeoLocationSectionProps = {
  label: string;
  regionOptions?: any[];
  stateOptions?: any[];
  dmaOptions?: any[];
};

enum GEO_LOC_OPTIONS {
  NONE = 'none',
  REGION = 'region',
  STATE = 'state',
  DMA = 'DMA'
}

const GEOGRAPHIC_OPTIONS = ['region', 'state', 'DMA'];

const getSelectedValue = (values: any) => {
  for (const key in values) {
    const value = values[key];
    if (GEOGRAPHIC_OPTIONS.includes(key) && !!value?.size) {
      const geoLocKey = key.toUpperCase();

      return GEO_LOC_OPTIONS[geoLocKey];
    }
  }

  return GEO_LOC_OPTIONS.NONE;
};

export const GeoLocationSection = ({
  label,
  regionOptions,
  stateOptions,
  dmaOptions
}: GeoLocationSectionProps) => {
  const { values, setFieldValue } = useFormikContext();
  const [selected, setSelected] = useState<string>(() =>
    getSelectedValue(values)
  );
  const { managedCampaign } = useCampaignManager();

  // resets values so that only one geo location option type is selected at any given time
  const handleOptionTypeChange = (values: any, optionType: string) => {
    for (const key in values) {
      const value = values[key];

      if (
        GEOGRAPHIC_OPTIONS.includes(key) &&
        value?.size > 0 &&
        key !== optionType
      ) {
        setFieldValue(key, new Set(), false);
      }
    }
  };

  const handleChange = (optionType: string) => {
    setSelected(optionType);
    handleOptionTypeChange(values, optionType);
  };

  const isCampaignUS = !!managedCampaign?.details?.meta?.isCampaignUSEnglish;

  return (
    <Flex lss={{ flexDirection: 'column', px: 'xl' }}>
      <Label lss={{ fontWeight: 'bold' }}>{label}</Label>
      <Flex lss={{ flexDirection: 'inherit', gap: '1rem' }}>
        <Radio
          name="geo_none"
          label="None"
          checked={selected === GEO_LOC_OPTIONS.NONE}
          onChange={() => handleChange(GEO_LOC_OPTIONS.NONE)}
        />
        <GeoLocationOption
          name="region"
          label="Region"
          isChecked={selected === GEO_LOC_OPTIONS.REGION}
          onChange={() => handleChange(GEO_LOC_OPTIONS.REGION)}
          options={regionOptions}
        />
        {isCampaignUS && (
          <>
            <GeoLocationOption
              name="state"
              label="State"
              isChecked={selected === GEO_LOC_OPTIONS.STATE}
              onChange={() => handleChange(GEO_LOC_OPTIONS.STATE)}
              options={stateOptions}
            />
            <GeoLocationOption
              name="DMA"
              label="DMA"
              isChecked={selected === GEO_LOC_OPTIONS.DMA}
              onChange={() => handleChange(GEO_LOC_OPTIONS.DMA)}
              options={dmaOptions}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
