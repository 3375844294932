import * as React from 'react';
import { MdAddCircle } from 'react-icons/md';

import { Text, Button, Icon, LssProp } from '@lucidhq/lucidium';

type AddItemButtonProps = {
  onClick: () => void;
  lss?: LssProp;
  children: React.ReactNode;
  isValid: boolean;
  targets?: TargetAudience[];
};

export const AddItemButton: React.FC<AddItemButtonProps> = ({
  onClick,
  lss,
  children,
  isValid,
  targets
}) => {
  const addButtonLss = {
    alignItems: 'center',
    display: 'flex',
    bg: 'gray100',
    textAlign: 'left',
    borderRadius: 'md',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: 'gray500',
    fontSize: 17,
    p: 'lg',

    ':not([disabled]):hover': {
      borderColor: 'gray500'
    },

    ...lss
  } as LssProp;

  return (
    <Button
      type="button"
      emphasis="medium"
      palette="primary"
      lss={addButtonLss}
      onClick={onClick}
      disabled={!isValid && !!targets?.length}
    >
      <Icon
        as={MdAddCircle}
        lss={{ mr: 'sm', textScale: 'heading6' }}
        aria-hidden={true}
      />

      <Text lss={{ textScale: 'content', fontWeight: 'regular' }}>
        {children}
      </Text>
    </Button>
  );
};
