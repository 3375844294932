import React, { useContext } from 'react';

import { Field, Select, FieldControlProps } from '@lucidhq/lucidium';

import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';
import { SIZES } from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';

type AdServerSelectProps = {
  tagIndex: number;
  adServers: AdServerType[];
};

export const AdServerSelect: React.FC<AdServerSelectProps> = ({
  tagIndex,
  adServers
}) => {
  const { setFieldValue } = useContext(MediaTrackingToolsFormContext);
  const { dispatchWarningTracker } = useContext(MediaTrackingToolsContext);

  const resetTracker = () =>
    dispatchWarningTracker({
      type: 'RESET_UNIVERSAL',
      tagIndex
    });

  const options = adServers.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Field
      id={`universal_tags-ad_server-${tagIndex}`}
      name={`universal_tags.${tagIndex}.ad_server_id`}
      render={(fieldProps: FieldControlProps<string>) => (
        <Select
          {...fieldProps}
          labelKey="name"
          valueKey="id"
          options={options}
          onChange={(value: string | null, option: AdServerType | null) => {
            fieldProps.onChange(value);
            setFieldValue(
              `universal_tags.${tagIndex}.macros`,
              option?.macros || []
            );
            resetTracker();
          }}
        />
      )}
      groupLss={{ width: SIZES.MOBILE_MACRO_FIELD_WIDTH, pb: 'none', mb: 'sm' }}
    />
  );
};
