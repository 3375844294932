import React from 'react';
import { MdEdit } from 'react-icons/md';

import {
  Box,
  Button,
  Flex,
  H3,
  Icon,
  LssProp,
  SystemColors
} from '@lucidhq/lucidium';

export enum Colors {
  BLUE = 'secondary500',
  PURPLE = 'amethyst600',
  GREEN = 'emerald400',
  YELLOW = 'warning500',
  PINK = 'hsl(327.57deg 100% 53.92% / 65.1%)'
}

type HeaderProps = {
  children: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => (
  <Flex>
    <H3 lss={{ width: '100%' }}>{children}</H3>
  </Flex>
);

type ContentProps = { children: React.ReactNode; color: Colors };

const contentLss: LssProp = {
  borderLeft: 'regular',
  borderLeftWidth: '0.25rem',
  flexDirection: 'column',
  px: 'xxxl',
  mb: 'md',
  py: 'xl',
  ':last-child': {
    mb: 'none'
  }
};

const Content = ({ children, color }: ContentProps) => (
  <Flex lss={{ ...contentLss, borderLeftColor: color as SystemColors }}>
    {children}
  </Flex>
);

type BlockProps = {
  children: React.ReactNode;
  title: string;
};

const blockLss: LssProp = {
  flexDirection: 'column',
  flex: '1',
  mb: 'xxxl',
  ':last-child': { mb: 'none' }
};

const containerLss: LssProp = {
  mt: 'lg',
  flexDirection: 'column',
  borderColor: 'gray300',
  borderRadius: 'md',
  border: 'regular',
  overflow: 'hidden',
  position: 'relative'
};

const Block = ({ children, title }: BlockProps) => (
  <Flex lss={blockLss}>
    <Header>{title}</Header>
    <Flex lss={containerLss}>{children}</Flex>
  </Flex>
);

type ItemProps = {
  children: React.ReactNode;
  lss?: LssProp;
};

const Item = ({ children, lss }: ItemProps) => (
  <Flex
    lss={{
      mb: 'xxl',
      flexDirection: 'column',
      ':last-child': { mb: 'none' },
      ...lss
    }}
  >
    {children}
  </Flex>
);

type EditProps = { onClick: () => void };

const Edit = ({ onClick }: EditProps) => (
  <Box lss={{ position: 'absolute', top: 'xl', right: 'xl' }}>
    <Button scale="sm" emphasis="low" onClick={onClick}>
      <Icon size={24} as={MdEdit} lss={{ color: 'secondary500' }} />
    </Button>
  </Box>
);

export const ReviewLayout: {
  Block: React.FC<BlockProps>;
  Content: React.FC<ContentProps>;
  Item: React.FC<ItemProps>;
  Edit: React.FC<EditProps>;
} = {
  Block,
  Content,
  Item,
  Edit
};
