import React, { useEffect } from 'react';

import { useCampaignManager } from '../CampaignManagerProvider';
import { SurveyStepIds } from '../useCampaignManagerSteps';
import { BehavioralQuestionsStep } from './BehavioralQuestionsStep/BehavioralQuestionsStep';
import { BrandsStep } from './BrandsStep/BrandsStep';
import { StandardQuestionsStep } from './StandardQuestionsStep/StandardQuestionsStep';

export const SurveyStep = () => {
  const { options, setOptions } = useCampaignManager();

  useEffect(() => {
    if (!options?.activeChildStep) {
      //@ts-ignore
      setOptions({
        activeChildStep: SurveyStepIds.BRAND
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!options?.activeChildStep) return null;

  return (
    <>
      {options.activeChildStep === SurveyStepIds.BRAND && <BrandsStep />}
      {options.activeChildStep === SurveyStepIds.STANDARD_QUESTIONS && (
        <StandardQuestionsStep />
      )}
      {options.activeChildStep === SurveyStepIds.BEHAVIORAL_QUESTIONS && (
        <BehavioralQuestionsStep />
      )}
    </>
  );
};
