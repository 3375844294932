import React from 'react';

import { Box, Flex, Text, List, LssProp, Button } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';
import { AudienceTheme } from '~/utils/styles';

import { getQuestionText } from '../TemplateQuestions/QuestionTitle';
import { useBehavioralQuestions } from './BehavioralQuestionsProvider';

const questionListLss: LssProp = {
  backgroundColor: 'white',
  flexDirection: 'column',
  border: 'regular',
  borderRadius: 'md',
  flexBasis: '20rem',
  maxWidth: '330px',
  px: 'md',
  py: 'sm'
};

const containerLss: LssProp = {
  display: 'grid',
  alignItems: 'start',
  mt: 'xxl',
  mr: 'md',
  justifySelf: 'end'
};

const itemLss: LssProp = {
  flexDirection: 'column',
  px: 'md',
  py: 'sm',
  my: 'md',
  border: 'regular',
  borderRadius: 'md'
};

const itemHeaderLss: LssProp = {
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  mb: 'sm'
};

const nameLss: LssProp = {
  alignSelf: 'flex-start',
  mt: 'xs',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

const addButtonLss: LssProp = {
  display: 'flex',
  color: AudienceTheme.colors.secondary500 as any,
  borderColor: AudienceTheme.colors.secondary500 as any,
  borderRadius: 'md',
  alignSelf: 'flex-end',
  p: 'sm',
  minWidth: '4rem',
  minHeight: '2.1rem',
  justifyContent: 'center'
};

export const BehavioralQuestionsSidebar = () => {
  const {
    templateQuestions,
    baselineQuestions,
    addQuestion
  } = useBehavioralQuestions();

  const currentKPIs = baselineQuestions.map(item => item.kpi_type);

  return (
    <Box lss={containerLss}>
      <Flex lss={questionListLss}>
        <Text as="strong" lss={{ fontWeight: 'bold', px: 'sm', ml: 'xxs' }}>
          {t(`campaignManager.survey.behavioralQuestions.sidebar.description`)}
        </Text>
        <List as="ul" lss={{ listStyle: 'none', px: 'sm' }}>
          {templateQuestions.map(
            item =>
              (!currentKPIs.includes(item.kpi_type) ||
                item.meta.isCustomKPI) && (
                <Flex key={item.kpi_type} lss={itemLss}>
                  <Flex lss={itemHeaderLss}>
                    <Text lss={nameLss}>
                      {item.meta.isCustomKPI
                        ? t('campaignManager.survey.customKPI')
                        : item.question_name}
                    </Text>
                    <Button
                      palette="secondary"
                      emphasis="medium"
                      lss={addButtonLss}
                      onClick={() => addQuestion(item.kpi_type)}
                    >
                      {t('campaignManager.actions.add')}
                    </Button>
                  </Flex>
                  <Text
                    lss={{
                      fontSize: '0.9rem',
                      fontStyle: 'italic',
                      fontWeight: 'light'
                    }}
                  >
                    "
                    {item.meta.isCustomKPI
                      ? t('campaignManager.survey.customKPIDescription')
                      : getQuestionText(
                          item.question_text_template,
                          item.fields
                        )}
                    "
                  </Text>
                </Flex>
              )
          )}
        </List>
      </Flex>
    </Box>
  );
};
