import React, { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Flex, Label, TextInput } from '@lucidhq/lucidium';
import { useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

import { TargetedRecruitmentForm } from './ManageTargetedRecruitment';

export const NameField = () => {
  const { setFieldValue, values } = useFormikContext<TargetedRecruitmentForm>();
  const [inputValue, setInputValue] = useState(() => values.name);

  const onChange = (value: string) => setFieldValue('name', value, false);
  const [onChangeDebounced] = useDebouncedCallback(onChange, 300);

  return (
    <Flex lss={{ flexDirection: 'column', pt: 'xl', px: 'xl' }}>
      <Label htmlFor="name" required lss={{ fontWeight: 'bold' }}>
        {t(
          'campaignManager.targetAudience.targetedRecruitment.modal.labels.name'
        )}
      </Label>
      <TextInput
        name="name"
        value={inputValue}
        onChange={e => {
          setInputValue(e.target.value);
          onChangeDebounced(e.target.value);
        }}
      />
    </Flex>
  );
};
