import * as React from 'react';

import {
  styled,
  Container as LucidiumContainer,
  Loading,
  Footer,
  LssProp
} from '@lucidhq/lucidium';

import { HeaderNav } from '~/impact-measurement/components';
import { ErrorPage } from '~/impact-measurement/components';
import { t } from '~/utils/i18n';

const containerHeight = 'calc(100vh - 156px)';

const Container = styled(LucidiumContainer)`
  min-height: ${containerHeight};
`;

type BasePageProps = {
  children: React.ReactNode;
  isFluid?: boolean;
  hasFooter?: boolean;
  containerLss?: LssProp;
  subNav?: React.ReactNode;
};

export const BasePage: React.FunctionComponent<BasePageProps> = ({
  children,
  isFluid = false,
  hasFooter = true,
  containerLss,
  subNav
}) => (
  <>
    <HeaderNav productName={t('header.title.im')} hideProductName={false} />
    {subNav}
    <Container isFluid={isFluid} lss={containerLss}>
      <React.Suspense fallback={<Loading spinnerSize="lg" />}>
        <ErrorPage>{children}</ErrorPage>
      </React.Suspense>
    </Container>
    {hasFooter && <Footer />}
  </>
);
