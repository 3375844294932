import React, { useContext } from 'react';

import { Flex } from '@lucidhq/lucidium';

import { MediaTrackingToolsContext } from '~/impact-measurement/pages/Admin/Contexts';

import { getMediaTrackingLeftColLss } from '../../styles';
import { TagsProps } from '../Tags';
import { SiteServedSetup } from './SiteServedSetup';
import { UniversalSetup } from './UniversalSetup';

export const TagsGeneration: React.FC<TagsProps> = ({
  isCampaignTask,
  adServers,
  adServerErrors,
  siteServedPixel,
  pixelParamsErrors
}) => {
  const { toolView } = useContext(MediaTrackingToolsContext);

  return (
    <Flex lss={getMediaTrackingLeftColLss(!isCampaignTask)}>
      {toolView === 'universal' ? (
        <UniversalSetup adServers={adServers} adServerErrors={adServerErrors} />
      ) : (
        <SiteServedSetup
          pixelParamsErrors={pixelParamsErrors}
          siteServedPixel={siteServedPixel}
        />
      )}
    </Flex>
  );
};
