import { LandingCardLinkProps } from '../LandingCardLink';

const toIsString = (to: LandingCardLinkProps['to']): to is string =>
  typeof to === 'string';

export const changePath = (to: LandingCardLinkProps['to']) => {
  if (toIsString(to)) {
    window.location.pathname = to;
  }
};
