type ReadFileData = (file: File) => Promise<FileReader['result']>;

export const readFileData: ReadFileData = file =>
  new Promise(resolve => {
    const reader = new FileReader();

    reader.onload = ({ target }: ProgressEvent<FileReader>) => {
      const content = target && target.result;

      resolve(content);
    };

    reader.readAsText(file);
  });
