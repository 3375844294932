import { FetchRequestOptions, TErrorResponse } from '@lucidhq/lucidium';
import { useAuthFetchEffect, useAuthFetch } from '@lucidhq/ui-auth';

export type Query<T> = {
  isLoading: boolean;
  error: TErrorResponse | undefined;
  data: T | undefined;
};

export const useFetchEffect = <T>(
  endpoint: string,
  options?: FetchRequestOptions,
  skip?: boolean
): Query<T> => {
  const [isLoading, data, error] = useAuthFetchEffect<T>(
    endpoint,
    options,
    skip
  );

  return { isLoading, data, error };
};

/**
 * DEPRECATED
 *
 * Moving forward, please use: useFetchEffect
 */
export const useApiFetchEffect = useAuthFetchEffect;
export const useApiFetch = useAuthFetch;
