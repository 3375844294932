import React, { useEffect, useRef, useState } from 'react';

import { Flex, Text, TextInput } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { FieldValue } from '~/types';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { CustomKPITitle } from './CustomKPITitle';
import { CustomTextField } from './CustomTextField';

type QuestionTitleProps = {
  question: EnhancedTemplateQuestion;
  onSave?: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
  customKPIErrors?: Record<string, any>;
  hasCustomKPIErrors?: boolean;
  index: number;
  kpiErrors?: Record<string, any>;
  hasKPIErrors?: boolean;
};

export function getQuestionText(
  template: string,
  fields: FieldValue[] = [],
  callback: (match: string, index: number) => any = str => str
): string[] {
  const regex = /{{(.*?)}}/g;
  const replaced = template.split(regex);

  const result: string[] = [];

  for (let i = 0; i < replaced.length; i++) {
    const match = fields.find(({ name }) => name === replaced[i]);
    const content = !match ? replaced[i] : callback(match.value, i);
    result.push(content);
  }

  return result;
}

const QuestionInput = ({
  value,
  onChange,
  readOnly
}: {
  value: string;
  onChange: (e: any) => void;
  readOnly: boolean;
}) => {
  const [cursor, setCursor] = useState(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = ref.current;
    if (input) input.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  const handleChange = (e: any) => {
    setCursor(e.target.selectionStart);
    onChange(e);
  };

  return (
    <TextInput
      ref={ref}
      value={value}
      onChange={handleChange}
      lss={{ mt: 'lg', maxWidth: '20rem' }}
      disabled={readOnly}
    />
  );
};

export const QuestionTitle = ({
  question,
  onSave,
  readOnly = false,
  customKPIErrors = {},
  hasCustomKPIErrors,
  index,
  kpiErrors = {},
  hasKPIErrors = false
}: QuestionTitleProps) => {
  const [fields, setFields] = useState<FieldValue[]>(question.fields);

  const isSocial = !!question?.meta?.isSocial;

  useEffect(() => {
    setFields(question.fields);
  }, [question, setFields]);

  const canEdit = !!(fields && onSave);
  const kpiTitleErrors = kpiErrors[`kpi${index}`];

  // If the question is from V1 CC, do not touch it.
  const questionText =
    question.question_version !== 2
      ? question.question_text
      : getQuestionText(
          question.question_text_template,
          question.fields,
          (str, i) => (
            <Text
              key={i}
              lss={{
                color: 'secondary500',
                fontWeight: 'bold',
                fontSize: '1.125rem'
              }}
            >
              {str}
            </Text>
          )
        );

  const handleSave = (name: string) => (e: any) => {
    if (!canEdit) return;
    const value = e.target.value;
    const newFields = fields.map(field => {
      return {
        ...field,
        value: field.name === name ? value : field.value
      };
    });

    onSave({ fields: newFields });
  };

  if (question.meta.isCustomKPI)
    return (
      <CustomKPITitle
        question={question}
        onSave={onSave}
        readOnly={readOnly}
        customKPIErrors={customKPIErrors}
        hasCustomKPIErrors={hasCustomKPIErrors}
        index={index}
      />
    );

  return (
    <Flex lss={{ flexDirection: 'column', flexGrow: 1 }}>
      {!question.is_custom_question_text && (
        <Text lss={{ fontSize: '1.125rem' }}>{questionText}</Text>
      )}
      {question.is_custom_question_text && readOnly && (
        <Text lss={{ fontSize: '1.125rem' }}>
          {question?.custom_question_text}
        </Text>
      )}
      {question.is_custom_question_text && !readOnly && (
        <CustomTextField
          name="custom_question_text"
          label={t('campaignManager.survey.customQuestion')}
          placeholder={t('campaignManager.survey.customQuestion')}
          value={question?.custom_question_text}
          required
          onChange={(e: any) => {
            onSave &&
              onSave({
                custom_question_text: e.target.value
              });
          }}
          showErrorMessage={
            kpiTitleErrors?.custom_question_text && hasKPIErrors
          }
          errorMessage={t('forms.errors.customQuestionText')}
          lss={{ maxWidth: '37.25rem' }}
        />
      )}
      {canEdit &&
        !isSocial &&
        !question.is_custom_question_text &&
        fields.map(field => (
          <QuestionInput
            key={field.name}
            value={field.value}
            onChange={handleSave(field.name)}
            readOnly={readOnly}
          />
        ))}
    </Flex>
  );
};
