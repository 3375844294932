import * as React from 'react';
import { MdInfoOutline } from 'react-icons/md';

import { Tooltip, Text, TooltipProps, LssProp, Icon } from '@lucidhq/lucidium';

type InfoTooltipProps = Omit<TooltipProps, 'children'> & {
  tooltipLss?: LssProp;
  iconLss?: LssProp;
};

export const InfoTooltip: React.FunctionComponent<InfoTooltipProps> = ({
  tooltipLss = {},
  iconLss = {},
  colorScheme = 'light',
  placement = 'top',
  title,
  id,
  ...props
}) => {
  const tooltipText =
    typeof title === 'string' ? <Text lss={{ px: 'md' }}>{title}</Text> : title;

  return (
    <Tooltip
      id={id}
      colorScheme={colorScheme}
      placement={placement}
      lss={{ px: 0, ...tooltipLss }}
      title={tooltipText}
      enforceRefWrapper
      {...props}
    >
      <Icon aria-label="info-icon" as={MdInfoOutline} lss={iconLss} />
    </Tooltip>
  );
};
