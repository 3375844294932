export const BULK_SECTION_INTRO = {
  title: 'Upload Site-Served CSV',
  description: 'Bulk upload values to generate site-served pixels.'
};

export const SINGLE_TAG_INTRO = {
  title: 'Create Site-Served Pixel',
  description: 'Create site-served pixels one at a time.'
};

export const SITE_SERVED_FAIL_MESSAGE =
  'Failed to load macros for Site-Served.';
