import React from 'react';

import { LssProp, Text } from '@lucidhq/lucidium';

const textLss: LssProp = {
  textScale: 'control',
  color: 'danger500',
  p: 'none',
  mt: 'sm',
  mb: 0
};

type FieldErrorMessageProps = {
  message: string;
  lss?: LssProp;
};

export const FieldErrorMessage = ({ message, lss }: FieldErrorMessageProps) => (
  <Text as="p" lss={{ ...textLss, ...lss }}>
    {message}
  </Text>
);
