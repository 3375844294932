import React from 'react';
import { MdDelete } from 'react-icons/md';

import { CardHeaderAction, Icon } from '@lucidhq/lucidium';

type DeleteCardActionProps = {
  id: string;
  handleDelete: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const DeleteCardAction: React.FC<DeleteCardActionProps> = ({
  id,
  handleDelete
}) => (
  <CardHeaderAction
    id={id}
    key="delete"
    icon={() => (
      <Icon
        as={MdDelete}
        lss={{ color: 'secondary500', mr: 'xs' }}
        size={24}
        aria-hidden
      />
    )}
    label="Remove"
    onClick={handleDelete}
    lss={{
      alignItems: 'center',
      py: 'xxs',
      mb: 'sm',
      tablet: { mb: 'none' }
    }}
  />
);
