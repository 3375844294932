import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { Channel } from '~/impact-measurement/pages/types';

import { ImpressionsField } from './ImpressionsField';
import { TrackedMediaCheckbox } from './TrackedMediaCheckbox';

export enum CHANNELS {
  'linear_tv' = 'linear_tv',
  digital = 'digital',
  social = 'social'
}

type TrackedMediaItemProps = {
  channels: Channel[];
  type: Channel;
  isChecked: boolean;
  setFieldValue: (field: string, value: any) => void;
  impressions?: number;
  showImpressionsField: boolean;
  readOnly: boolean;
};

export const TrackedMediaItem: React.FC<TrackedMediaItemProps> = ({
  channels,
  type,
  isChecked,
  setFieldValue,
  impressions,
  showImpressionsField,
  readOnly
}) => (
  <Flex
    lss={{
      flexDirection: 'column',
      mb: showImpressionsField ? 'md' : 'none',
      alignItems: 'flex-start'
    }}
  >
    <TrackedMediaCheckbox
      channels={channels}
      type={CHANNELS[type]}
      setFieldValue={setFieldValue}
      isChecked={isChecked}
      isDisabled={readOnly}
    />
    {showImpressionsField && (
      <ImpressionsField
        isChecked={isChecked}
        type={CHANNELS[type]}
        impressionsValue={impressions}
        isDisabled={!isChecked}
      />
    )}
  </Flex>
);
