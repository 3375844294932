import { LssProp } from '@lucidhq/lucidium';

import { SIZES } from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';

export const containerLss: LssProp = {
  alignItems: 'flex-start',
  my: 'sm',
  flexWrap: 'wrap',
  position: 'relative'
};
export const labelLss: LssProp = {
  width: SIZES.MACRO_ROW_LABEL_WIDTH,
  mt: 'md',
  textScale: 'control'
};

export const getFieldWrapperLss = (isBulk: boolean): LssProp => ({
  flexDirection: 'column',
  width: SIZES.MOBILE_MACRO_FIELD_WIDTH,
  minWidth: '10rem',
  tablet: {
    width: isBulk ? SIZES.BULK_MACRO_FIELD_WIDTH : SIZES.MACRO_FIELD_WIDTH
  }
});

export const getWarningLss = (hasWarning: boolean): LssProp =>
  hasWarning ? { borderColor: 'warning600' } : {};

export const macroCountLss: LssProp = {
  ml: 'xs',
  textScale: 'caption',
  alignItems: 'center',
  display: 'flex',
  lineHeight: 1,
  tablet: { whiteSpace: 'nowrap' }
};

export const appendContainerLss = (isBulk: boolean): LssProp => ({
  width: isBulk ? SIZES.VALUES_COUNT_WIDTH : SIZES.MACRO_ROW_APPEND_WIDTH,
  justifyContent: isBulk ? 'flex-end' : 'center'
});

export const addCustomButtonLss: LssProp = {
  mt: 'sm',
  width: SIZES.MOBILE_MACRO_FIELD_WIDTH,
  tablet: {
    width: SIZES.MACRO_FIELD_WIDTH,
    ml: SIZES.MACRO_ROW_LABEL_WIDTH as any,
    mr: SIZES.MACRO_ROW_APPEND_WIDTH as any
  }
};
