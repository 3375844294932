import React from 'react';

import { Box, Checkbox, Text, styled } from '@lucidhq/lucidium';

import { MultiOptionsFilterProps } from '~/impact-measurement/components';

type MultiOptionCheckboxProps = {
  option: FilterOption;
  isChecked: boolean;
  value: MultiOptionsFilterProps['value'];
  onChange: MultiOptionsFilterProps['onChange'];
  isFullscreen?: boolean;
  showAll?: boolean;
};

const AnimatedWrapper = styled(Box)<{ showAll: boolean }>(
  ({ showAll }) => `
  ${
    showAll
      ? `
      animation: fade 1s forwards;
    `
      : ''
  }

  @keyframes fade {
    0% {
      opacity: 0;
      translateY: 2px;
    }
    100% {
      opacity: 1;
      translateY: 0px;
    }
  }
`
);

export const MultiOptionCheckbox: React.FunctionComponent<MultiOptionCheckboxProps> = ({
  option,
  isChecked,
  value,
  onChange,
  isFullscreen,
  showAll = false
}) => {
  return (
    <AnimatedWrapper
      showAll={showAll}
      lss={{
        mb: 'md',
        width: '100%',
        tablet: {
          width: isFullscreen ? '50%' : '100%'
        },
        ':hover': {
          [Text]: { color: 'secondary500' }
        }
      }}
    >
      <Checkbox
        name={option.label}
        label={<Text>{option.label}</Text>}
        checked={isChecked}
        onChange={() => {
          let newValue = [...value];

          if (isChecked) {
            newValue = value.filter(
              (v: FilterOption['value']) => v !== option.value
            );
          } else {
            newValue.push(option.value as number);
          }
          onChange([...newValue]);
        }}
      />
    </AnimatedWrapper>
  );
};
