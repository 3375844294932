import React from 'react';

import { Button, DialogFooter, Flex, Text } from '@lucidhq/lucidium';

type CsvUploadDialogFooterProps = {
  tagsQuantity: number;
  handleCreatePixels: () => void;
};

export const CsvUploadDialogFooter: React.FC<CsvUploadDialogFooterProps> = ({
  tagsQuantity,
  handleCreatePixels
}) => (
  <DialogFooter lss={{ justifyContent: 'space-between', alignItems: 'center' }}>
    <Flex lss={{ alignItems: 'center' }}>
      <Text lss={{ fontWeight: 'bold', mr: 'sm' }}>
        Creating {tagsQuantity} pixels
      </Text>
    </Flex>
    <Button type="button" onClick={handleCreatePixels}>
      Create Pixels
    </Button>
  </DialogFooter>
);
