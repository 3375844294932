import { useMemo } from 'react';

import { PixelGroup } from '~/components/Shared/hooks/useFetchCampaignPixelGroups';
import { useFetchS2SPartners } from '~/components/Shared/hooks/useFetchS2SPartners';
import { PixelTypes } from '~/components/Shared/hooks/useMutateCampaignPixelGroup';

import { useCampaignManager } from '../CampaignManagerProvider';

// Keep spacing as is or it may break the CSV
enum PIXEL_GROUP_DOWNLOAD {
  HEADER = `
LUCID TRACKING PIXELS
`,
  INSTRUCTIONS = `Universal Pixel - Implementation Instructions,Site-Served Pixel - Implementation Instructions
"1. Lucid tags are industry-standard 1x1 image impression pixels. Implement as a 1x1 image pixel / impression tracker.
2. If using a tag with macros, implement that ""universal"" tag across the media in the campaign.
DCM users: https://support.lucidhq.com/s/article/Lucid-Implementation-In-DCM",
"1. If running in mobile app, publisher must replace the ENTER_MOBILE_AD_ID_MACRO placeholder with a macro that dynamically
passes IDFA / AAID. If media doesn't run on mobile app, disregard and leave as-is.
2. Publisher must replace the [InsertCachebusterHere] placeholder with a random number generator (cache buster macro).
3. If only a URL is needed, use the URL portion of the tag: the area between "" and "".
4. Implement as a 1x1 image impression tracker.
5. If applicable, implement in addition to the corresponding agency 1x1 tracker."
 `
}

const formatPixelRows = (data: PixelGroup[]) =>
  data.map(
    pixel =>
      `${pixel?.details?.[0]?.pixel_type},${
        pixel?.details?.[0]?.ad_server_name || 'N/A'
      },${pixel?.details?.[0]?.pixel_url},<img src="${
        pixel?.details?.[0]?.pixel_url
      }" />`
  );

export const parsePixelGroupDownload = (
  data: PixelGroup[],
  campaignName: string
) => {
  const csvHeader = 'data:text/csv;charset=utf-8,';
  const rows = formatPixelRows(data).join('\n');
  const tableHeader = 'Type, Ad Server, URL, IMG Tag';

  const instructions = `${PIXEL_GROUP_DOWNLOAD.HEADER}
${campaignName}
,
${PIXEL_GROUP_DOWNLOAD.INSTRUCTIONS}`;

  return `${csvHeader}${instructions}\n${tableHeader}\n${rows}`;
};

const providerMap = {
  s2s_samba: 'Samba',
  s2s_ispot: 'iSpot'
};

export const useMediaTracking = () => {
  const { managedCampaign, pixelGroups, s2sMappings } = useCampaignManager();
  const { data: s2sPartnersData } = useFetchS2SPartners();

  const isLinearTV = managedCampaign?.details?.meta.isLinearTV;
  const partners = managedCampaign?.details?.draft_settings.data_partners;
  const partnerId = isLinearTV ? partners[0] : null;

  const universalGroups = useMemo(() => {
    return pixelGroups.filter(
      pixelGroup => pixelGroup.pixel_type === PixelTypes.UNIVERSAL
    );
  }, [pixelGroups]);

  const siteServedGroups = useMemo(() => {
    return pixelGroups.filter(
      pixelGroup => pixelGroup.pixel_type === PixelTypes.SITE_SERVED
    );
  }, [pixelGroups]);

  const tvPartner = useMemo(() => {
    if (!s2sPartnersData || !partnerId) return undefined;

    return s2sPartnersData.partners.find(
      partner => partner.name === providerMap[partnerId]
    );
  }, [s2sPartnersData, partnerId]);

  const tvMapping = useMemo(() => {
    return s2sMappings.find(mapping => mapping.partner_id === tvPartner?.id);
  }, [s2sMappings, tvPartner]);

  return {
    universalGroups,
    siteServedGroups,
    tvPartner,
    tvMapping
  };
};
