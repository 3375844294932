/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { H2, Flex, Box } from '@lucidhq/lucidium';

import { Page } from '~/impact-measurement/components';
import { PageContentWrapper } from '~/impact-measurement/components';
import { useProfile } from '~/impact-measurement/utils/auth';
import { t } from '~/utils/i18n';

import { usePermissions } from '../Permissions/PermissionsProvider';
import { HomeCards } from './HomeCards';

export const Home = () => {
  const profile = useProfile();
  const history = useHistory();
  const { isLoading, permissions, isImt } = usePermissions();

  const name = profile.name || profile.username || profile.email;

  if (isLoading) return null;

  useEffect(() => {
    if (isImt && !permissions.admin) {
      return history.push('/impact-measurement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, isLoading]);

  return (
    <Page hideProductName>
      <PageContentWrapper>
        <Box lss={{ my: 'xl', tablet: { mb: 'xxxl' } }}>
          <H2 lss={{ textAlign: 'center' }}>{t('home.welcome', { name })}</H2>
        </Box>

        <Flex
          lss={{
            height: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            tablet: {
              height: '320px',
              flexDirection: 'row',
              alignItems: 'stretch'
            }
          }}
        >
          <HomeCards />
        </Flex>
      </PageContentWrapper>
    </Page>
  );
};
