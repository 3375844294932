import React, { useMemo } from 'react';

import { useRoles } from '@lucidhq/ui-auth';

import {
  getPermissions,
  Permissions,
  ROLES,
  SCOPES
} from '~/utils/permissions';

export type PermissionsProvider = {
  isLoading: boolean;
  permissions: Permissions;
  isImt: boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isBasicPlusUser: boolean;
};

type PermissionsProviderProps = {
  children: React.ReactNode;
};

const PermissionsProviderContext = React.createContext<PermissionsProvider>(
  {} as PermissionsProvider
);

export const usePermissions = () =>
  React.useContext<PermissionsProvider>(PermissionsProviderContext);

export const PermissionsProvider = ({ children }: PermissionsProviderProps) => {
  const { scopes, isLoading, roles } = useRoles();

  const permissions = useMemo(() => {
    return getPermissions(scopes);
  }, [scopes]);

  const isImt = useMemo(() => {
    return scopes.includes(SCOPES.imt);
  }, [scopes]);

  const isSuperAdmin = useMemo(() => {
    return !!roles.find(role => role.includes(ROLES.superAdmin));
  }, [roles]);

  const isAdmin = useMemo(() => {
    if (isSuperAdmin) return true;

    return !!roles.find(role => role.includes(ROLES.admin));
  }, [roles, isSuperAdmin]);

  const isBasicPlusUser = useMemo(() => {
    return !!roles.find(role => role.includes(ROLES.basicPlusUser));
  }, [roles]);

  const value = useMemo(
    () => ({
      isLoading,
      permissions,
      isImt,
      isAdmin,
      isSuperAdmin,
      isBasicPlusUser
    }),
    [isLoading, permissions, isImt, isAdmin, isSuperAdmin, isBasicPlusUser]
  );

  return (
    <PermissionsProviderContext.Provider value={value}>
      {children}
    </PermissionsProviderContext.Provider>
  );
};
