import React, { useContext } from 'react';

import { Dialog, DialogContent, DialogHeader } from '@lucidhq/lucidium';

import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';
import {
  BULK_CACHE_DEFAULT,
  BULK_MAID_DEFAULT
} from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';

import {
  handleCreatePixels,
  handleFileUpload,
  resetValuesAfterUpload
} from '../../../lib';
import { FileNameBar } from '../FileNameBar';
import { ColumnMappingSection } from './components/ColumnMappingSection';
import { CsvUploadDialogFooter } from './components/CsvUploadDialogFooter';
import { MappedSiteServedValuesSummary } from './components/MappedSiteServedValuesSummary';
import { UploadSuccessfulMessage } from './components/UploadSuccessfulMessage';
import { useMappedMacros } from './lib';
import { dialogContentLss, dialogSectionLss } from './styles';

type CsvUploadDialogProps = {
  fileName: string;
  handleToggle: () => void;
  isOpen: boolean;
  setFilename: (name: string) => void;
  setBulkPixels: React.Dispatch<React.SetStateAction<MappingBulkPixels>>;
  bulkPixels: MappingBulkPixels;
};

export const CsvUploadDialog: React.FC<CsvUploadDialogProps> = ({
  isOpen,
  handleToggle,
  fileName,
  setFilename,
  setBulkPixels,
  bulkPixels
}) => {
  const {
    setFieldValue,
    mediaToolData: { bulk_cache_default, bulk_maid_default }
  } = useContext(MediaTrackingToolsFormContext);

  const { setSelectedTag } = useContext(MediaTrackingToolsContext);

  const {
    bulkTags,
    mappedMacros,
    setMappedMacros,
    tagsQuantity
  } = useMappedMacros();

  const enhancedHandleUpload = (file: File) => {
    handleFileUpload(file, setBulkPixels, setFilename);
    setFieldValue('bulk_cache_default', BULK_CACHE_DEFAULT);
    setFieldValue('bulk_maid_default', BULK_MAID_DEFAULT);
    resetValuesAfterUpload(setFieldValue, setMappedMacros);
  };

  const createPixelsConfig = {
    setFieldValue,
    setBulkPixels,
    setSelectedTag,
    handleToggle,
    bulkTags,
    tagsQuantity,
    bulk_cache_default,
    bulk_maid_default
  };

  return (
    <Dialog isOpen={isOpen} lss={{ width: '95%', maxWidth: '40rem' }}>
      <DialogHeader onClose={handleToggle}>CSV Upload</DialogHeader>
      <DialogContent lss={dialogContentLss}>
        <UploadSuccessfulMessage />
        <FileNameBar
          fileName={fileName}
          handleUpload={enhancedHandleUpload}
          lss={dialogSectionLss}
        />
        <ColumnMappingSection
          mappedMacros={mappedMacros}
          setMappedMacros={setMappedMacros}
          bulkPixels={bulkPixels}
        />
        <MappedSiteServedValuesSummary mappedMacros={mappedMacros} />
      </DialogContent>
      <CsvUploadDialogFooter
        tagsQuantity={tagsQuantity}
        handleCreatePixels={() => handleCreatePixels(createPixelsConfig)}
      />
    </Dialog>
  );
};
