import React, { useMemo, useState } from 'react';

import {
  BusyButton,
  Button,
  Flex,
  LssProp,
  Select,
  Text
} from '@lucidhq/lucidium';
import { Field, Form } from '@lucidhq/lucidium/components/FormV2';

import { useFetchAdServers } from '~/components/Shared/hooks/useFetchAdServers';
import {
  CampaignPixelVariables,
  PixelQueryParams
} from '~/components/Shared/hooks/useMutateCampaignPixelGroup';
import { t } from '~/utils/i18n';

import { PixelQueryParamForm } from './PixelQueryParamsForm';

type UniversalTagFormProps = {
  onSubmit: (params: CampaignPixelVariables) => void;
  isLoading: boolean;
  onCancel: () => void;
  initialValues?: {
    ad_server_id: string;
  } & PixelQueryParams;
};

const INITIAL_VALUES = {
  ad_server_id: '',
  cachebuster: '',
  crid: '',
  device_id: '',
  pid: '',
  sid: '',
  c1: '',
  c2: '',
  c3: '',
  c4: '',
  c5: ''
};

const actionsLss: LssProp = {
  justifyContent: 'center',
  mt: 'xxl',
  mb: 'xxl'
};

export const UniversalTagForm = ({
  onSubmit,
  onCancel,
  isLoading,
  initialValues = INITIAL_VALUES
}: UniversalTagFormProps) => {
  const [selectedAdId, setSelectedAdId] = useState<string>(
    initialValues.ad_server_id
  );

  const { data } = useFetchAdServers();

  const adServers = data?.items;

  const selectedAdServer = useMemo(() => {
    const adServer = adServers?.find(ad => `${ad.id}` === selectedAdId);
    return adServer || null;
  }, [selectedAdId, adServers]);

  const finalInitialValues = useMemo(() => {
    return {
      ...INITIAL_VALUES,
      ...selectedAdServer?.query_parameters.reduce(
        (acc, param) => ({
          ...acc,
          [param.query_key]:
            initialValues[param.query_key] || param.default_macro
        }),
        {}
      ),
      ad_server_id: `${selectedAdServer?.id}`
    };
  }, [initialValues, selectedAdServer]);

  const formattedQueryParams = useMemo(() => {
    if (!selectedAdServer) return [];

    return selectedAdServer.query_parameters.map(param => ({
      name: param.query_key,
      label: param.query_label,
      initialValue: finalInitialValues[param.query_key]
    }));
  }, [selectedAdServer, finalInitialValues]);

  return (
    <>
      <Text lss={{ mb: 'xl' }}>
        {t('campaignManager.mediaTracking.universalTag.form.description')}
      </Text>
      <Form
        initialValues={finalInitialValues}
        enableReinitialize
        onSubmit={async ({ ad_server_id, ...queryParams }) => {
          await onSubmit({
            ad_server_id,
            query_params: queryParams
          });
        }}
      >
        <Field
          label={t('forms.labels.adServer')}
          name="ad_server_id"
          disabled={!!initialValues.ad_server_id}
          render={fieldProps => (
            <Select
              {...fieldProps}
              labelKey="label"
              valueKey="value"
              onChange={(value: string | null) => {
                if (!value) return;
                setSelectedAdId(value);
                fieldProps.onChange(value);
              }}
              options={adServers?.map(ad => ({
                value: `${ad.id}`,
                label: ad.ad_server_name
              }))}
            />
          )}
        />
        {!!formattedQueryParams.length && (
          <PixelQueryParamForm params={formattedQueryParams} />
        )}

        <Flex lss={actionsLss}>
          <BusyButton isBusy={isLoading} type="submit" lss={{ mr: 'md' }}>
            {t('campaignManager.actions.saveAndClose')}
          </BusyButton>
          <Button
            type="button"
            emphasis="medium"
            palette="secondary"
            onClick={onCancel}
          >
            {t('campaignManager.actions.cancel')}
          </Button>
        </Flex>
      </Form>
    </>
  );
};
