import React, { Fragment, useMemo } from 'react';
import {
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineRadioButtonUnchecked
} from 'react-icons/md';

import {
  Flex,
  Label,
  LssProp,
  TransparentButton,
  Text,
  Button,
  useToggle,
  Icon
} from '@lucidhq/lucidium';
import {
  FieldArray,
  useFormikContext
} from '@lucidhq/lucidium/components/FormV2';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion, NOTA } from '~/utils/templateQuestions';

import { getUpdatedAnswers } from './AnswersRadioGroup';
import { CustomAnswerField } from './CustomAnswerField';
import { CustomKPIData } from './CustomAnswersContainer';
import { CustomGridAnswerField } from './CustomGridAnswerField';
import { CustomGridPreviewModal } from './CustomGridPreviewModal';
import { DefaultRowOption } from './DefaultRowOption';

const answerOptionLss: LssProp = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
  my: 'md'
};

const optionsListLss: LssProp = {
  flexDirection: 'column',
  gap: '4px',
  listStyle: 'none',
  p: 'none',
  width: '100%'
};

const addCompetitorButtonLss: LssProp = {
  color: 'secondary500',
  display: 'flex',
  alignSelf: 'flex-start',
  flexBasis: 'fit-content',
  height: '4rem',
  py: 'sm',
  px: 'xs',
  ml: 'xxl'
};

type CustomAnswerOptionsProps = {
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
  hasCustomKPIErrors?: boolean;
  customKPIErrors?: Record<string, any>;
  questionIndex?: number;
  isGridAnswerType?: boolean;
};

export enum GRID_ANSWER_TYPES {
  ROW = 'Row',
  COLUMN = 'Column'
}

export const CustomAnswerOptions = ({
  question,
  readOnly,
  onSave,
  hasCustomKPIErrors,
  customKPIErrors,
  questionIndex,
  isGridAnswerType
}: CustomAnswerOptionsProps) => {
  const answerOptionIcon =
    question.question_type === 'radio'
      ? MdOutlineRadioButtonUnchecked
      : MdOutlineCheckBoxOutlineBlank;
  const [isOpen, setOpen] = useToggle(false);
  const { values, setFieldValue } = useFormikContext<CustomKPIData>();
  const hasMaxAnswers = useMemo(() => values?.customAnswers?.length === 10, [
    values
  ]);

  const hasNOTA = !!question?.customAnswers?.includes(NOTA);

  const handleOpen = () => setOpen();

  const hasError =
    hasCustomKPIErrors && !!customKPIErrors?.[`customKPI${questionIndex}`];

  return (
    <Flex as={'ul'} lss={optionsListLss}>
      <>
        {isGridAnswerType ? (
          <>
            {!question?.isBehavioralCustomKPI && (
              <Text lss={{ textScale: 'control', mb: 'md' }}>
                {t(
                  'campaignManager.survey.standardQuestions.questionCard.gridInTargetAnswers'
                )}
              </Text>
            )}
            <Label lss={{ my: 'sm', fontWeight: 'bold' }}>
              {t(`forms.labels.rows`)}
            </Label>
            <FieldArray
              name="customRows"
              render={helpers => (
                <Flex lss={{ flexDirection: 'column', mb: 'xs' }}>
                  {!!question.use_answer_meta &&
                    !!question?.defaultBrandRows?.length &&
                    question.defaultBrandRows.map((rowItem, index) => (
                      <DefaultRowOption
                        key={`${rowItem.text}_${index}`}
                        index={index}
                        question={question}
                        onSave={onSave}
                        readOnly={readOnly}
                        fieldName="rows"
                        rowItem={rowItem}
                      />
                    ))}

                  {!!values?.customRows.length &&
                    !question.use_answer_meta &&
                    values.customRows.map((_, index) => (
                      <CustomGridAnswerField
                        key={index}
                        index={index}
                        question={question}
                        onSave={onSave}
                        handleRemoveItem={helpers.remove}
                        readOnly={readOnly}
                        useAnswerMeta={!!question.use_answer_meta}
                        hasCustomKPIErrors={hasCustomKPIErrors}
                        customKPIErrors={customKPIErrors}
                        questionIndex={questionIndex}
                        fieldName="rows"
                        {...helpers}
                      />
                    ))}
                  {!question.use_answer_meta && (
                    <TransparentButton
                      type="button"
                      lss={{
                        ...addCompetitorButtonLss,
                        cursor: hasMaxAnswers ? 'not-allowed' : 'pointer',
                        ml: !!question?.isBehavioralCustomKPI ? 'none' : 'xxl'
                      }}
                      disabled={hasMaxAnswers}
                      onClick={() =>
                        helpers.push({
                          order: !!values?.customRows?.length
                            ? values.customRows.length
                            : 0,
                          text: '',
                          title: ''
                        })
                      }
                    >
                      {`+ ${t('buttons.addGridAnswer', {
                        type: GRID_ANSWER_TYPES.ROW
                      })}`}
                    </TransparentButton>
                  )}
                </Flex>
              )}
            />
            <Label lss={{ my: 'sm', fontWeight: 'bold' }}>
              {t(`forms.labels.columns`)}
            </Label>
            <FieldArray
              name="columns"
              render={helpers => (
                <Flex lss={{ flexDirection: 'column' }}>
                  {!!values?.columns?.length &&
                    values.columns.map((_, index) => (
                      <CustomGridAnswerField
                        key={index}
                        index={index}
                        question={question}
                        onSave={onSave}
                        handleRemoveItem={helpers.remove}
                        readOnly={readOnly}
                        useAnswerMeta={!!question.use_answer_meta}
                        hasCustomKPIErrors={hasCustomKPIErrors}
                        customKPIErrors={customKPIErrors}
                        questionIndex={questionIndex}
                        fieldName="columns"
                        {...helpers}
                      />
                    ))}
                  <TransparentButton
                    type="button"
                    lss={{
                      ...addCompetitorButtonLss,
                      cursor: hasMaxAnswers ? 'not-allowed' : 'pointer',
                      ml: !!question?.isBehavioralCustomKPI ? 'none' : 'xxl'
                    }}
                    disabled={hasMaxAnswers}
                    onClick={() =>
                      helpers.push({
                        order: !!values?.columns?.length
                          ? values.columns.length
                          : 0,
                        text: '',
                        title: ''
                      })
                    }
                  >
                    {`+ ${t('buttons.addGridAnswer', {
                      type: GRID_ANSWER_TYPES.COLUMN
                    })}`}
                  </TransparentButton>
                </Flex>
              )}
            />
            <Button
              lss={{ width: 'fit-content', alignSelf: 'center' }}
              onClick={handleOpen}
              disabled={hasError}
            >
              {t('campaignManager.buttonTexts.preview')}
            </Button>
            <CustomGridPreviewModal
              question={question}
              isOpen={isOpen}
              onClick={handleOpen}
            />
          </>
        ) : (
          <FieldArray
            name="customAnswers"
            render={helpers => (
              <Flex lss={{ flexDirection: 'column' }}>
                {!!values?.customAnswers?.length &&
                  values.customAnswers.map((answer, index) => (
                    <Fragment key={index}>
                      {answer !== NOTA && (
                        <CustomAnswerField
                          key={index}
                          index={index}
                          question={question}
                          onSave={onSave}
                          handleRemoveItem={helpers.remove}
                          readOnly={readOnly}
                          useAnswerMeta={!!question.use_answer_meta}
                          hasCustomKPIErrors={hasCustomKPIErrors}
                          customKPIErrors={customKPIErrors}
                          questionIndex={questionIndex}
                          {...helpers}
                        />
                      )}
                    </Fragment>
                  ))}
                <TransparentButton
                  type="button"
                  lss={{
                    ...addCompetitorButtonLss,
                    cursor: hasMaxAnswers ? 'not-allowed' : 'pointer',
                    ml: !!question?.isBehavioralCustomKPI ? 'none' : 'xxl'
                  }}
                  disabled={hasMaxAnswers}
                  onClick={() => {
                    const updatedAnswers = getUpdatedAnswers(
                      question,
                      values.customAnswers
                    );

                    setFieldValue('customAnswers', updatedAnswers);

                    return hasNOTA
                      ? helpers.insert(updatedAnswers.length - 1, '')
                      : helpers.push('');
                  }}
                >
                  {`+ ${t('buttons.addAnswer')}`}
                </TransparentButton>
                {!!question.none_of_the_above_answer && (
                  <Flex
                    as="li"
                    key={'none-of-the-above'}
                    lss={{ ...answerOptionLss, paddingLeft: '0.5rem' }}
                  >
                    <Icon as={answerOptionIcon} size={16} />
                    <Text lss={{ ml: 'xs', flex: 1 }}>{NOTA}</Text>
                  </Flex>
                )}
              </Flex>
            )}
          />
        )}
      </>
    </Flex>
  );
};
