import React from 'react';
import { MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';

import {
  BusyButton,
  Button,
  Flex,
  Icon,
  useFileDownload
} from '@lucidhq/lucidium';

import { usePermissions } from '~/components/Permissions/PermissionsProvider';
import { useFetchCampaignPixelGroups } from '~/components/Shared/hooks/useFetchCampaignPixelGroups';
import { PixelGroup } from '~/components/Shared/hooks/useFetchCampaignPixelGroups';
import { useFetchS2SMappings } from '~/components/Shared/hooks/useFetchS2SMappings';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../CampaignManagerFooter';
import { useCampaignManager } from '../CampaignManagerProvider';
import { AddNewButton } from '../Steps/AddNewButton';
import { StepLayout } from '../Steps/StepLayout';
import { CreateSiteServedTag } from './Actions/CreateSiteServedTag';
import { CreateUniversalTag } from './Actions/CreateUniversalTag';
import { DeletePixelGroup } from './Actions/DeletePixelGroup';
import { DownloadPixelsButton } from './Actions/DownloadPixelsButton';
import { DownloadTag } from './Actions/DownloadTag';
import { ManageS2SMappings } from './Actions/ManageS2SMappings';
import { UpdateSiteServedTag } from './Actions/UpdateSiteServedTag';
import { UpdateUniversalTag } from './Actions/UpdateUniversalTag';
import { parsePixelGroupDownload, useMediaTracking } from './useMediaTracking';

export const MediaTrackingStep = () => {
  const {
    managedCampaign,
    goToStep,
    steps,
    activeStep,
    pixelGroups,
    s2sMappings,
    can
  } = useCampaignManager();
  const { isAdmin } = usePermissions();
  const [, handleDownload] = useFileDownload();
  const campaignId = managedCampaign?.details.campaign_id;
  const isLinearTV = managedCampaign?.details?.meta.isLinearTV;

  const nextStepId = can.limitedEdit
    ? steps.REVIEW.id
    : steps[activeStep].nextId;
  const prevStepId = steps[activeStep].prevId;

  const { refetch: refetchPixelGroups } = useFetchCampaignPixelGroups(
    campaignId
  );

  const { refetch: refetchS2SMappings } = useFetchS2SMappings(campaignId);

  const {
    universalGroups,
    siteServedGroups,
    tvPartner,
    tvMapping
  } = useMediaTracking();

  if (!campaignId) return null;

  const readOnly = can.limitedEdit ? !isAdmin : !can.edit;

  const downloadPixelGroup = (pixelGroup: PixelGroup[]) => {
    handleDownload({
      url: encodeURI(
        parsePixelGroupDownload(
          pixelGroup,
          managedCampaign?.details?.campaign_name
        )
      ),
      fileName: `${
        managedCampaign?.details?.campaign_name
      } Lucid Tracking Tags - ${new Date().toLocaleDateString()}.csv`,
      contentType: 'text/csv'
    });
  };

  return (
    <StepLayout.Container>
      <StepLayout.Header
        title={t('campaignManager.mediaTracking.header.title')}
        description={t('campaignManager.mediaTracking.header.description')}
      />
      <StepLayout.Content>
        <Flex lss={{ flexDirection: 'column' }}>
          <StepLayout.Card>
            <StepLayout.CardHeader
              title={t('campaignManager.mediaTracking.universalTag.title')}
              description={t(
                'campaignManager.mediaTracking.universalTag.description'
              )}
            />

            <StepLayout.CardActions
              lss={{ flexDirection: 'row', gap: '0.5rem', width: '280px' }}
            >
              <CreateUniversalTag
                campaignId={campaignId}
                onCreate={refetchPixelGroups}
              >
                {({ onToggle }) => (
                  <AddNewButton
                    id="add-new-universal"
                    onClick={onToggle}
                    disabled={readOnly}
                    lss={{ maxHeight: '2.5rem' }}
                  />
                )}
              </CreateUniversalTag>
              <DownloadPixelsButton
                id="download-universal-pixels"
                onClick={() => downloadPixelGroup(universalGroups)}
                disabled={!universalGroups?.length}
              />
            </StepLayout.CardActions>
          </StepLayout.Card>

          <StepLayout.Card separator>
            <StepLayout.List>
              {universalGroups.map(pixelGroup => (
                <StepLayout.ListItem key={pixelGroup.id}>
                  <StepLayout.ListTitle>
                    {pixelGroup.details[0].ad_server_name}
                  </StepLayout.ListTitle>

                  <StepLayout.ListActions>
                    <DownloadTag
                      url={pixelGroup.details[0].pixel_url}
                      title={pixelGroup.details[0].ad_server_name as string}
                    >
                      {({ onToggle }) => (
                        <Button scale="sm" emphasis="low" onClick={onToggle}>
                          <Icon
                            size={24}
                            as={MdRemoveRedEye}
                            lss={{ color: 'secondary500' }}
                          />
                        </Button>
                      )}
                    </DownloadTag>
                    <UpdateUniversalTag
                      id={`${pixelGroup.id}`}
                      campaignId={campaignId}
                      onUpdate={refetchPixelGroups}
                      pixel={pixelGroup.details[0]}
                    >
                      {({ onToggle }) => (
                        <Button
                          scale="sm"
                          emphasis="low"
                          onClick={onToggle}
                          disabled={readOnly}
                        >
                          <Icon
                            size={24}
                            as={MdEdit}
                            lss={{ color: 'secondary500' }}
                          />
                        </Button>
                      )}
                    </UpdateUniversalTag>
                    <DeletePixelGroup
                      campaignId={campaignId}
                      id={`${pixelGroup.id}`}
                      onDelete={refetchPixelGroups}
                    >
                      {({ onDelete, isLoading }) => (
                        <BusyButton
                          isBusy={isLoading}
                          scale="sm"
                          emphasis="low"
                          onClick={onDelete}
                          disabled={readOnly}
                        >
                          <Icon
                            size={24}
                            as={MdDelete}
                            lss={{ color: 'secondary500' }}
                          />
                        </BusyButton>
                      )}
                    </DeletePixelGroup>
                  </StepLayout.ListActions>
                </StepLayout.ListItem>
              ))}
            </StepLayout.List>
          </StepLayout.Card>

          <StepLayout.Card>
            <StepLayout.CardHeader
              title={t('campaignManager.mediaTracking.siteServed.title')}
              description={t(
                'campaignManager.mediaTracking.siteServed.description'
              )}
            />
            <StepLayout.CardActions
              lss={{ flexDirection: 'row', gap: '0.5rem', width: '280px' }}
            >
              <CreateSiteServedTag
                campaignId={campaignId}
                onCreate={refetchPixelGroups}
              >
                {({ onToggle }) => (
                  <AddNewButton
                    id="add-new-site-served"
                    onClick={onToggle}
                    disabled={readOnly}
                    lss={{ maxHeight: '2.5rem' }}
                  />
                )}
              </CreateSiteServedTag>
              <DownloadPixelsButton
                id="download-site-served-pixels"
                onClick={() => downloadPixelGroup(siteServedGroups)}
                disabled={!siteServedGroups?.length}
              />
            </StepLayout.CardActions>
          </StepLayout.Card>

          <StepLayout.Card separator>
            <StepLayout.List>
              {siteServedGroups.map((pixelGroup, k) => (
                <StepLayout.ListItem key={pixelGroup.id}>
                  <StepLayout.ListTitle>
                    {t('campaignManager.mediaTracking.siteServed.label', {
                      id: k + 1
                    })}
                  </StepLayout.ListTitle>

                  <StepLayout.ListActions>
                    <DownloadTag
                      url={pixelGroup.details[0].pixel_url}
                      title={t(
                        'campaignManager.mediaTracking.siteServed.label',
                        {
                          id: k + 1
                        }
                      )}
                    >
                      {({ onToggle }) => (
                        <Button scale="sm" emphasis="low" onClick={onToggle}>
                          <Icon
                            size={24}
                            as={MdRemoveRedEye}
                            lss={{ color: 'secondary500' }}
                          />
                        </Button>
                      )}
                    </DownloadTag>
                    <UpdateSiteServedTag
                      title={t(
                        'campaignManager.mediaTracking.siteServed.label',
                        {
                          id: k + 1
                        }
                      )}
                      campaignId={campaignId}
                      onUpdate={refetchPixelGroups}
                      id={`${pixelGroup.id}`}
                      pixel={pixelGroup.details[0]}
                    >
                      {({ onToggle }) => (
                        <Button
                          scale="sm"
                          emphasis="low"
                          onClick={onToggle}
                          disabled={readOnly}
                        >
                          <Icon
                            size={24}
                            as={MdEdit}
                            lss={{ color: 'secondary500' }}
                          />
                        </Button>
                      )}
                    </UpdateSiteServedTag>
                    <DeletePixelGroup
                      campaignId={campaignId}
                      id={`${pixelGroup.id}`}
                      onDelete={refetchPixelGroups}
                    >
                      {({ onDelete, isLoading }) => (
                        <BusyButton
                          isBusy={isLoading}
                          scale="sm"
                          emphasis="low"
                          onClick={onDelete}
                          disabled={readOnly}
                        >
                          <Icon
                            size={24}
                            as={MdDelete}
                            lss={{ color: 'secondary500' }}
                          />
                        </BusyButton>
                      )}
                    </DeletePixelGroup>
                  </StepLayout.ListActions>
                </StepLayout.ListItem>
              ))}
            </StepLayout.List>
          </StepLayout.Card>

          <StepLayout.Card>
            <StepLayout.CardHeader
              title={t('campaignManager.mediaTracking.linearTv.title')}
              description={t(
                'campaignManager.mediaTracking.linearTv.description'
              )}
            />

            <StepLayout.CardActions>
              <ManageS2SMappings
                campaignId={campaignId}
                creativeIds={tvMapping?.partner_campaigns_ids}
                partner={tvPartner}
                onSave={refetchS2SMappings}
              >
                {({ onToggle }) => (
                  <AddNewButton
                    onClick={onToggle}
                    id="add-new-tv"
                    disabled={!isLinearTV || readOnly}
                    lss={{ maxHeight: '2.5rem' }}
                  />
                )}
              </ManageS2SMappings>
            </StepLayout.CardActions>
          </StepLayout.Card>

          <StepLayout.Card>
            <StepLayout.List>
              {isLinearTV && tvMapping && (
                <StepLayout.ListItem>
                  <StepLayout.ListTitle>
                    {tvMapping.partner_name}
                  </StepLayout.ListTitle>
                  <StepLayout.ListCount>
                    {t('campaignManager.mediaTracking.linearTv.mediaCount', {
                      count: tvMapping.partner_campaigns_ids.length
                    })}
                  </StepLayout.ListCount>
                  <StepLayout.ListActions>
                    <ManageS2SMappings
                      campaignId={campaignId}
                      creativeIds={tvMapping?.partner_campaigns_ids}
                      partner={tvPartner}
                      onSave={refetchS2SMappings}
                    >
                      {({ onToggle }) => (
                        <Button
                          scale="sm"
                          emphasis="low"
                          onClick={onToggle}
                          disabled={readOnly}
                        >
                          <Icon
                            size={24}
                            as={MdEdit}
                            lss={{ color: 'secondary500' }}
                          />
                        </Button>
                      )}
                    </ManageS2SMappings>
                  </StepLayout.ListActions>
                </StepLayout.ListItem>
              )}
            </StepLayout.List>
          </StepLayout.Card>
          <CampaignManagerFooter
            onBack={() => prevStepId && goToStep(prevStepId)}
            skipProps={
              !(s2sMappings.length || pixelGroups.length)
                ? {
                    onClick: () => nextStepId && goToStep(nextStepId)
                  }
                : undefined
            }
            nextProps={
              s2sMappings.length || pixelGroups.length
                ? {
                    onClick: () => nextStepId && goToStep(nextStepId)
                  }
                : undefined
            }
          />
        </Flex>
      </StepLayout.Content>
    </StepLayout.Container>
  );
};
