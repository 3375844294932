import React from 'react';

import { Text } from '@lucidhq/lucidium';

type BlankMacroWarningProps = {
  label: string;
  isSingle?: boolean;
};
export const BlankMacroWarning: React.FC<BlankMacroWarningProps> = ({
  label,
  isSingle = true
}) => (
  <Text lss={{ color: 'warning800', textScale: 'control', mt: 'sm' }}>
    Your pixel will include {isSingle ? 'a blank value' : 'blank values'} for{' '}
    {label}
  </Text>
);
