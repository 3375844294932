export type DateKeysType = 'startDate' | 'endDate';

export const DATE_KEYS: Record<'start' | 'end', DateKeysType> = {
  start: 'startDate',
  end: 'endDate'
};

const startTime = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  ms: 0
};

const endTime = {
  hours: 23,
  minutes: 59,
  seconds: 59,
  ms: 999
};

export const dateToISO = (
  date?: string | number,
  key?: DateKeysType,
  isLocalTZ?: boolean
) => {
  if (!date) return;

  const d = new Date(date);

  const time = key === 'startDate' ? startTime : endTime;

  const localDateTime = d.getTime() + d.getTimezoneOffset() * 60000;

  const remoteDateTime = Date.UTC(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    time.hours,
    time.minutes,
    time.seconds,
    time.ms
  );

  const utcD = new Date(isLocalTZ ? localDateTime : remoteDateTime);
  return utcD.toISOString();
};
