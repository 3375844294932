import React, { useState } from 'react';
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank
} from 'react-icons/md';

import { Flex, Icon, Label, LssProp } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion, NOTA } from '~/utils/templateQuestions';

import { QUESTION_LOGIC_ITEMS } from './QuestionLogicDrawer';

const itemWrapperLss: LssProp = {
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: 'regular',
  borderTop: 'regular',
  borderColor: 'gray500',
  height: '3rem',
  mx: 'xs',
  py: 'sm'
};

const labelLss: LssProp = {
  mb: 'none',
  fontSize: '1rem',
  color: 'gray700'
};

const readOnlyIconLss: LssProp = {
  cursor: 'not-allowed',
  color: 'gray700'
};

type QuestionLogicItemProps = {
  itemName: string;
  itemValue: any;
  isItemChecked: boolean;
  isReadOnly: boolean;
  onSave: (data: Partial<TemplateQuestion>) => void;
  wrapperLss?: LssProp;
  question?: EnhancedTemplateQuestion;
};

type NotaVal = 'None of the above' | null;

const getNoneOfTheAboveAnswer = (val: NotaVal) => (val === null ? NOTA : null);
/**
 * This method is needed in order to remove extra copies of the NOTA value
 * Due to the default set of answers containing the NOTA value there are scenarios in which duplicate NOTA values can exist
 * @param answers string array
 * @returns an array of answers either including or excluding a NOTA value based off if it exists already or not.
 */
const getUniqueAnswers = (answers: string[]) =>
  answers.includes(NOTA)
    ? answers.slice(0, answers.length - 1)
    : [...answers, NOTA];

/**
 * This method takes in the NOTA value and the question and returns an object of either { answers, defaultBrandAnswers } or {answers, customAnswers}.
 * The reason that there are duplicate conditions is due to having default answer set and for the fully custom answer set.
 * @param val NotaVal
 * @param question EnhancedTemplateQuestion
 * @returns - { answers, defaultBrandAnswers } || { answers, customAnswers }
 */
const getUpdatedCustomAnswers = (
  val: NotaVal,
  question?: EnhancedTemplateQuestion
): Partial<EnhancedTemplateQuestion> => {
  // If question is using default brand answers reset copies of answers and defaultBrandAnswers
  if (!!question?.use_answer_meta) {
    const updatedAnswers =
      val === null
        ? question?.answers?.filter(answer => answer !== NOTA)
        : getUniqueAnswers(question.answers);

    return { answers: updatedAnswers, defaultBrandAnswers: updatedAnswers };
  }

  const updatedCustomAnswers =
    val === null
      ? question?.customAnswers?.filter(answer => answer !== NOTA)
      : getUniqueAnswers(question?.customAnswers as string[]);

  return {
    customAnswers: updatedCustomAnswers,
    answers: updatedCustomAnswers
  };
};

export const QuestionLogicItem = ({
  itemName,
  itemValue,
  isItemChecked,
  isReadOnly,
  wrapperLss,
  onSave,
  question
}: QuestionLogicItemProps) => {
  const [isChecked, setChecked] = useState<boolean>(isItemChecked);

  return (
    <Flex lss={{ ...itemWrapperLss, ...wrapperLss }}>
      <Label lss={labelLss}>{t(`forms.labels.${itemName}`)}</Label>

      <Flex
        as="a"
        href="#"
        role="checkbox"
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          if (isReadOnly) return;
          setChecked(val => !val);
          if (itemName === QUESTION_LOGIC_ITEMS.NOTA) {
            const notaValue = getNoneOfTheAboveAnswer(itemValue);
            const updatedAnswerRecord = getUpdatedCustomAnswers(
              notaValue,
              question
            );

            onSave({
              [itemName]: getNoneOfTheAboveAnswer(itemValue),
              ...updatedAnswerRecord
            });
          } else {
            onSave({
              [itemName]: !isChecked,

              ...(itemName === QUESTION_LOGIC_ITEMS.CUSTOM_QUESTION_TEXT && {
                custom_question_text: ''
              })
            });
          }
        }}
      >
        <Icon
          as={isChecked ? MdOutlineCheckBox : MdOutlineCheckBoxOutlineBlank}
          size={28}
          lss={{
            cursor: 'pointer',
            color: 'secondary500',
            ...(isReadOnly && readOnlyIconLss)
          }}
        />
      </Flex>
    </Flex>
  );
};
