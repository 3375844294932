import React from 'react';

import { Flex, Text, LssProp, H6 } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { useIPMatchingContext } from '../IPMatchingProvider';

const containerLss: LssProp = {
  flexDirection: 'column',
  gap: '0.5rem',
  justifyContent: 'center',
  marginTop: '3rem'
};

const ENABLED = 'Enabled';
const DISABLED = 'Disabled';

export const NoMatchedIDsMessage = () => {
  const { rootLevelEnablement } = useIPMatchingContext();

  return (
    <Flex lss={containerLss}>
      <H6
        lss={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}
      >
        {t('ipMatching.noMatchedIDsTitle')}
      </H6>
      <Text lss={{ display: 'flex', justifyContent: 'center' }}>
        {t(
          `ipMatching.noMatchedIDs${rootLevelEnablement ? ENABLED : DISABLED}`
        )}
      </Text>
    </Flex>
  );
};
