export const UNIVERSAL_INTRO = {
  title: 'Create Universal Pixel',
  description: 'Create single Universal Pixel.'
};

export const SELECT_AD_SERVER = 'Select Your Ad Server';

export const LOADING_AD_SERVERS = 'Loading Ad Server Macros';

export const LOADING_PIXEL_CREATOR = 'Loading Pixel Creator';

export const AD_SERVER_ERROR_MESSAGE =
  'Failed to load ad servers for Universal Tags.';
