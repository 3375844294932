import { useQuery } from '~/utils/rq';

type S2SMapping = {
  partner_id: number;
  partner_name?: string;
  partner_campaigns_ids: string[];
};

export type S2SMappings = S2SMapping[];

export type S2SMappingsResponse = {
  items: S2SMappings;
};

export const useFetchS2SMappings = (campaignId?: string) => {
  const URL = `/admin/campaigns/${campaignId}/s2s-mappings?channels=linear_tv&page_size=100`;

  return useQuery<S2SMappingsResponse>(
    {
      queryKey: [URL],
      enabled: !!campaignId
    },
    {
      method: 'get',
      url: URL
    }
  );
};
