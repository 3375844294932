import React from 'react';

import { Flex, LssProp, Text } from '@lucidhq/lucidium';

import { BOX_SHADOW_TOP } from '~/impact-measurement/components/ShadowBox';

import { dialogSectionLss } from '../styles';
import { MappedSiteServedValuesItem } from './MappedSiteServedValuesItem';

export type MappedSiteServedValuesSummaryProps = {
  mappedMacros: MappingMacroType[];
};

const wrapperLss: LssProp = {
  borderTop: 'regular',
  boxShadow: BOX_SHADOW_TOP
};

export const MappedSiteServedValuesSummary: React.FC<MappedSiteServedValuesSummaryProps> = ({
  mappedMacros
}) => (
  <Flex lss={wrapperLss}>
    <Flex lss={{ ...dialogSectionLss, flexDirection: 'column' }}>
      <Text as="h2" lss={{ fontWeight: 'bold', my: 'sm' }}>
        Mapped Site-Served Values
      </Text>
      {mappedMacros.map(({ values, label }, index) => (
        <MappedSiteServedValuesItem
          key={`${label}-${index}`}
          values={values}
          label={label}
        />
      ))}
    </Flex>
  </Flex>
);
