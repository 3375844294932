import React, { useState } from 'react';

import { TransparentButton, LssProp } from '@lucidhq/lucidium';
import { SubtractIcon, AddIcon } from '@lucidhq/lucidium/icons';

type AddOrRemoveButtonProps = {
  onClick: () => void;
  type: 'add' | 'remove';
  size?: number;
  lss?: LssProp;
};

export const AddOrRemoveButton: React.FC<AddOrRemoveButtonProps> = ({
  onClick,
  type,
  size,
  lss
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const ButtonIcon = type === 'add' ? AddIcon : SubtractIcon;
  const hoverColor = type === 'add' ? 'emerald500' : 'ruby500';

  return (
    <TransparentButton
      type="button"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      lss={{ ':focus': { outline: 'none' }, ...lss }}
    >
      <ButtonIcon
        lss={{ color: isHovered ? hoverColor : 'gray300' }}
        size={size}
      />
    </TransparentButton>
  );
};
