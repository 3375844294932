import React from 'react';

import { Select, DefaultOptionType } from '@lucidhq/lucidium';
import BaseSelectComponents from '@lucidhq/lucidium/components/Select/BaseSelectComponents';

type ColumnMappingSelectProps = {
  id: string;
  options: DefaultOptionType[];
  onSelectHeader: (value: string | null) => void;
  matchedValue: string;
};

export const ColumnMappingSelect: React.FC<ColumnMappingSelectProps> = ({
  id,
  options,
  onSelectHeader,
  matchedValue
}) => {
  const Control = (props: any) => (
    <BaseSelectComponents.Control
      {...props}
      lss={{
        borderColor: !!matchedValue ? 'gray400' : 'warning800',
        bg: !!matchedValue ? 'white' : 'warning100'
      }}
    />
  );

  return (
    <Select
      id={id}
      labelKey="label"
      valueKey="value"
      options={options}
      onChange={(value: string | null) => {
        value && onSelectHeader(value);
      }}
      value={matchedValue}
      placeholder="Select CSV column"
      components={{ Control }}
    />
  );
};
