import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  TemplateQuestion,
  TemplateQuestionType,
  useFetchTemplateQuestions
} from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import {
  decorateTemplateQuestion,
  EnhancedTemplateQuestion,
  getCustomKPI,
  resetCustomKPI,
  validateConventionalKPIs,
  validateCustomKPIs
} from '~/utils/templateQuestions';

import { useCampaignManager } from '../../CampaignManagerProvider';

type SubmitProp = {
  onSuccess: () => void;
};

type BehavioralQuestionsProvider = {
  templateQuestions: EnhancedTemplateQuestion[];
  baselineQuestions: EnhancedTemplateQuestion[];
  socialQuestions: EnhancedTemplateQuestion[];
  hasPendingQuestions: boolean;
  setPendingQuestions: (val: boolean) => void;
  addQuestion: (id: string) => void;
  submitQuestions: (prop: SubmitProp) => void;
  deleteQuestion: (index: number) => void;
  saveQuestion: (index: number, data: Partial<TemplateQuestion>) => void;
  customKPIErrors: Record<string, any>;
  hasCustomKPIErrors: boolean;
  kpiErrors?: Record<string, any>;
  hasKPIErrors?: boolean;
};

type BehavioralQuestionsProviderProps = {
  children: React.ReactNode;
};

const BehavioralQuestionsContext = React.createContext<
  BehavioralQuestionsProvider
>({} as BehavioralQuestionsProvider);

export const useBehavioralQuestions = () =>
  useContext<BehavioralQuestionsProvider>(BehavioralQuestionsContext);

export const BehavioralQuestionsProvider: React.FC<BehavioralQuestionsProviderProps> = ({
  children
}) => {
  const [hasPendingQuestions, setPendingQuestions] = useState<boolean>(false);
  const {
    managedCampaign,
    refetchCampaign,
    onMutateError,
    can
  } = useCampaignManager();
  const [questionMeta, setQuestionMeta] = useState<{ [key: string]: any }>(
    managedCampaign?.details.draft_settings?.question_meta
  );

  const questions = managedCampaign?.details.draft_settings.baseline_questions;
  const socialKPIs = managedCampaign?.details.draft_settings?.social_questions;

  const { mutate: mutateCampaign } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const { data } = useFetchTemplateQuestions({
    type: TemplateQuestionType.Baseline,
    campaignId: managedCampaign?.details.campaign_id,
    isEnabled: true
  });

  // Current campaign custom questions duplicated from templates
  const [baselineQuestions, setBaselineQuestions] = useState<
    EnhancedTemplateQuestion[]
  >(
    questions
      ? (questions.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[])
      : []
  );

  // Current source of truth for social questions. Updates will most likely need to be made as social offering grows.
  const [socialQuestions] = useState<EnhancedTemplateQuestion[]>(
    socialKPIs
      ? (socialKPIs.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[])
      : []
  );

  // Default questions that should be duplicated and possibly customized
  const templateQuestions = useMemo(() => {
    if (!data?.items) return [];
    const itemsCopy = [...data.items];

    if (can.addCustomKPI) {
      itemsCopy.push(
        getCustomKPI({
          questions: data.items,
          isBehavioralCustomKPI: true
        })
      );
    }

    return itemsCopy.map(decorateTemplateQuestion);
  }, [data, can]);

  const { customKPIErrors, hasCustomKPIErrors } = useMemo(() => {
    return validateCustomKPIs(baselineQuestions);
  }, [baselineQuestions]);

  const { kpiErrors, hasKPIErrors } = useMemo(() => {
    return validateConventionalKPIs(baselineQuestions);
  }, [baselineQuestions]);

  const addQuestion = useCallback(
    (id: string) => {
      const currentQuestion = templateQuestions.find(
        item => item.kpi_type === id
      );

      if (!currentQuestion) return;

      // Keep existing order
      const updatedQuestions = [...baselineQuestions, currentQuestion];
      const sortedQuestions = [...updatedQuestions].sort(
        (a, b) => a.order - b.order
      );

      setBaselineQuestions(sortedQuestions);
      setPendingQuestions(true);
    },
    [setBaselineQuestions, templateQuestions, baselineQuestions]
  );

  const deleteQuestion = useCallback(
    (index: number) => {
      const questions = [...baselineQuestions];
      const questionToDelete = questions.find((q, iq) => iq === index);

      if (!questionToDelete) return;

      const filteredQuestions = questions.filter((q, iq) => iq !== index);

      setBaselineQuestions(filteredQuestions);
      setPendingQuestions(true);
    },
    [setBaselineQuestions, baselineQuestions]
  );

  const saveQuestion = useCallback(
    (index: number, data: Partial<TemplateQuestion>) => {
      const questions = [...baselineQuestions];
      const updatedQuestion = {
        ...questions[index],
        ...data
      };
      const updatedQuestionMeta = { ...questionMeta };

      questions[index] = updatedQuestion;

      // sync fields with question meta
      if (updatedQuestion.fields.length > 0) {
        updatedQuestion.fields.forEach(field => {
          if (updatedQuestionMeta[field.name])
            updatedQuestionMeta[field.name] = field.value;
        });
      }

      setBaselineQuestions(questions);
      setQuestionMeta(updatedQuestionMeta);
      setPendingQuestions(true);
    },
    [setBaselineQuestions, baselineQuestions, questionMeta]
  );

  const submitQuestions = useCallback(
    ({ onSuccess }: SubmitProp) => {
      if (!managedCampaign) return;
      if (!hasPendingQuestions) return onSuccess?.();

      const resetBaselineQuestions = resetCustomKPI(baselineQuestions);

      mutateCampaign(
        {
          ...managedCampaign?.details,
          draft_settings: {
            ...managedCampaign?.details?.draft_settings,
            baseline_questions: resetBaselineQuestions,
            question_meta: questionMeta
          }
        },
        {
          onSuccess: () => {
            refetchCampaign();
            setPendingQuestions(false);
            onSuccess?.();
          },
          ...onMutateError
        }
      );
    },
    [
      mutateCampaign,
      setPendingQuestions,
      baselineQuestions,
      questionMeta,
      managedCampaign,
      hasPendingQuestions,
      onMutateError,
      refetchCampaign
    ]
  );

  const value = useMemo(
    () => ({
      templateQuestions,
      baselineQuestions,
      socialQuestions,
      addQuestion,
      deleteQuestion,
      saveQuestion,
      hasPendingQuestions,
      setPendingQuestions,
      submitQuestions,
      customKPIErrors,
      hasCustomKPIErrors,
      kpiErrors,
      hasKPIErrors
    }),
    [
      templateQuestions,
      baselineQuestions,
      socialQuestions,
      addQuestion,
      deleteQuestion,
      saveQuestion,
      hasPendingQuestions,
      setPendingQuestions,
      submitQuestions,
      customKPIErrors,
      hasCustomKPIErrors,
      kpiErrors,
      hasKPIErrors
    ]
  );

  return (
    <BehavioralQuestionsContext.Provider value={value}>
      {children}
    </BehavioralQuestionsContext.Provider>
  );
};
