import React, { useMemo } from 'react';

import { Flex, Label, Select } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

// @TODO: update once Auth issues are resolved
// import { useFetchSocialPlatforms } from '~/components/Shared/hooks/useFetchSocialPlatforms';
import { SOCIAL_PLATFORMS } from '~/constants';
import { SocialPlatform } from '~/types';
import { t } from '~/utils/i18n';

type SocialPlatformFieldProps = {
  isSocialChecked: boolean;
  name: string;
  readOnly: boolean;
};

type SocialPlatformOption = SocialPlatform & {
  label: string;
  value: number;
};

const mapSocialPlatformOptions = (
  options: SocialPlatform[]
): SocialPlatformOption[] =>
  options.map(option => ({
    ...option,
    label: option.social_platform_name,
    value: option.id
  }));

export const SocialPlatformsField = ({
  isSocialChecked,
  name,
  readOnly
}: SocialPlatformFieldProps) => {
  const [field, meta, helpers] = useField(name);
  // @TODO: Add social platforms fetch once auth issue is resolved.
  // const { data, isLoading, error } = useFetchSocialPlatforms();

  //NOTE: these values are hardcoded coming from admin fixtures
  const mappedSocialPlatforms = useMemo(
    () => mapSocialPlatformOptions(SOCIAL_PLATFORMS),
    []
  );

  if (!isSocialChecked) return null;

  return (
    <Flex
      id="social-platform-field"
      lss={{
        flexDirection: 'column',
        mb: isSocialChecked ? 'md' : 'none'
      }}
    >
      <Label lss={{ my: 'sm' }} required>
        {t(`forms.labels.socialPlatforms`)}
      </Label>
      <Select
        isMulti
        id="social-platform"
        name={name}
        value={field?.value}
        error={meta?.error}
        disabled={readOnly}
        placeholder={t('forms.placeholders.socialPlatforms')}
        labelKey="label"
        valueKey="value"
        options={mappedSocialPlatforms}
        onChange={(option: number[] | null | undefined) => {
          const val = [...new Set(option)];
          helpers.setValue(val);
        }}
        lss={{ width: '100%' }}
        styles={{
          container: (baseStyles: any) => ({
            ...baseStyles,
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '8.5rem'
          }),
          menu: (baseStyles: any) => ({
            ...baseStyles,
            overflow: 'auto',
            maxHeight: '160px',
            display: 'flex',
            width: 'fill-available'
          }),
          menuList: (baseStyles: any) => ({
            ...baseStyles,
            width: 'fill-available'
          }),
          option: (baseStyles: any) => ({
            ...baseStyles,
            display: 'flex',
            width: 'fit-content'
          })
        }}
      />
    </Flex>
  );
};
