import React, { useContext } from 'react';

import { Flex } from '@lucidhq/lucidium';

import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';

import { useCreateUrl } from '../../lib';
import { mediaTrackingRightColLss } from '../../styles';
import { LOADING_TAG_PREVIEWS, TAGS_PREVIEW_LOADED } from './constants/texts';
import { PreviewSection } from './PreviewSection';

export const TagsPreview: React.FC = () => {
  const { campaignId, selectedTag } = useContext(MediaTrackingToolsContext);
  const { mediaToolData } = useContext(MediaTrackingToolsFormContext);

  const macros = selectedTag
    ? mediaToolData[selectedTag.tagType][selectedTag.index]?.macros
    : [];

  const { urlTag, imgSrc, isLoadingUrl } = useCreateUrl(campaignId, macros);

  return (
    <Flex
      lss={mediaTrackingRightColLss}
      aria-label={isLoadingUrl ? LOADING_TAG_PREVIEWS : TAGS_PREVIEW_LOADED}
    >
      <PreviewSection type="url" tag={urlTag} />
      <PreviewSection type="html" tag={imgSrc} />
    </Flex>
  );
};
