import { FieldValue } from '~/types';
import { useQuery } from '~/utils/rq';

export enum StandardKPITypes {
  BrandAwareness = 'brand_awareness',
  BrandConsideration = 'brand_consideration',
  PurchaseIntent = 'purchase_intent',
  AdRecal = 'ad_recall',
  BrandFavorability = 'brand_favorability',
  BrandRecomendation = 'brand_recommendation',
  MessageAssociation = 'message_association',
  MessageAwareness = 'message_awareness',
  BrandAttributes = 'brand_attributes',
  BrandFavorabilityGrid = 'brand_favorability_grid'
}

export enum BehavioralKPITypes {
  BrandUsage = 'brand_usage',
  DeviceUsage = 'device_usage',
  CategoryRecency = 'category_recency',
  CategoryUsage = 'category_usage',
  UsageHistory = 'usage_history',
  MediaConsumption = 'media_consumption',
  PastMediaExposure = 'past_media_exposure'
}

export enum SocialKPITypes {
  PastMediaExposure = 'past_media_exposure_social'
}

export enum TemplateQuestionType {
  Lift = 'lift',
  Baseline = 'baseline',
  Social = 'social'
}

// WIP
export type TemplateQuestion = {
  allowed_question_properties: string[];
  allowed_question_types: string[];
  kpi_type: string; // Add correct types here without breaking old test specs
  rows?: any[];
  customRows?: any[];
  columns?: any[];
  custom_question_text?: string;
  is_custom_question_text: boolean;
  defaultBrandAnswers?: string[];
  defaultBrandRows?: any[];
  customAnswers?: string[];
  fields: FieldValue[];
  answers: string[];
  question_name: string;
  question_type: string;
  none_of_the_above_answer: string | null;
  multi_target_option_index: number[];
  multi_level_target_option_index: [number, number][] | null;
  question_text: string;
  question_text_template: string;
  default: boolean;
  randomize: boolean;
  order: number;
  question_version?: number;
  required?: boolean;
  anchored_answer?: string;
  use_answer_meta?: boolean;
  use_question_meta?: boolean;
  target_option_index: number;
  isBehavioralCustomKPI?: boolean;
};

export type TemplateQuestions = TemplateQuestion[];

type TemplateQuestionsResponse = {
  items: TemplateQuestions;
};

type useFetchTemplateQuestionsProps = {
  type: TemplateQuestionType;
  campaignId?: string;
  isEnabled: boolean;
};

export const useFetchTemplateQuestions = ({
  type,
  campaignId,
  isEnabled
}: useFetchTemplateQuestionsProps) => {
  const URL = `/campaigns/${campaignId}/template-questions/${type}/`;

  return useQuery<TemplateQuestionsResponse>(
    {
      queryKey: [URL],
      enabled: !!campaignId && isEnabled
    },
    {
      method: 'get',
      url: URL
    }
  );
};
