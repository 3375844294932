import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { StepLayout } from '../Steps/StepLayout';
import { CampaignDetailsForm } from './CampaignDetailsForm/CampaignDetailsForm';

export const CampaignDetailsStep = () => {
  return (
    <StepLayout.Container>
      <StepLayout.Header
        title={t('campaignManager.campaignDetails.header.title')}
        description={t('campaignManager.campaignDetails.header.description')}
      />
      <StepLayout.Content>
        <Flex lss={{ flexDirection: 'column', flexBasis: '100%' }}>
          <CampaignDetailsForm />
        </Flex>
      </StepLayout.Content>
    </StepLayout.Container>
  );
};
