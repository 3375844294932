import React from 'react';

import { Flex, LssProp } from '@lucidhq/lucidium';

import { BackButton, GoBackButtonProps } from '~/impact-measurement/components';
import { CAMPAIGN_STATUS } from '~/impact-measurement/constants';
import { ExportButton } from '~/impact-measurement/pages/Admin/pages/CampaignDraft/ImpactMeasurement/components/IMCampaignSetupFooter/ExportButton';

import { CampaignClientName } from './CampaignClientName';

export type NavBarCampaignInfoProps = {
  campaign: AdminNavBarCampaign;
  lss?: LssProp;
  backToPath?: string;
  isUrlParamsEnabled?: boolean;
  isReview?: boolean;
} & Pick<GoBackButtonProps, 'isLinkStateEnabled'>;

const getinfoSectionLss = (lss: LssProp): LssProp => ({
  width: '100%',
  px: 'xl',
  py: 'lg',
  alignItems: 'center',
  ...lss,
  tablet: {
    width: 'auto',
    px: 0,
    py: 0,
    ...lss.tablet
  }
});

export const NavBarCampaignInfo: React.FunctionComponent<NavBarCampaignInfoProps> = ({
  campaign,
  lss = {},
  backToPath = '/',
  isUrlParamsEnabled = false,
  isLinkStateEnabled = false,
  isReview
}) => {
  const isServicesCampaign = campaign.product_type === 'services';
  const params = isUrlParamsEnabled
    ? isServicesCampaign
      ? `?status__in=proof/services`
      : `?status__in=${campaign.status}`
    : '';
  const isReviewingLiveCampaign =
    campaign.status === CAMPAIGN_STATUS.live && isReview;

  return (
    <>
      <Flex lss={getinfoSectionLss(lss)}>
        <BackButton
          to={`${backToPath}${params}`}
          linkState={{ campaign }}
          isLinkStateEnabled={isLinkStateEnabled}
        />
        <CampaignClientName campaign={campaign} />
      </Flex>
      {isReviewingLiveCampaign && (
        <ExportButton
          campaignId={campaign.campaign_id}
          palette="secondary"
          emphasis="high"
          buttonLss={{ mr: 'xxs' }}
          iconLss={{ color: 'white', ml: 'xs' }}
          tooltipPlacement="auto"
          showDownloadText
        />
      )}
    </>
  );
};
