import React from 'react';

import {
  Box,
  Header,
  TransparentButton,
  useBreakpoint
} from '@lucidhq/lucidium';
import { usePasswordChange } from '@lucidhq/ui-auth/hooks';

import { DisneyLogo } from '~/components/Logos/Disney';
import { useTheme } from '~/components/Themes/ThemeProvider';
import { NavProductDropdown } from '~/impact-measurement/components';
import { IM_FLAGS } from '~/impact-measurement/constants';
import { useIsAdmin } from '~/impact-measurement/utils/auth/useIsAdmin';
import { useProfile } from '~/impact-measurement/utils/auth/useProfile';

import { useSplit } from '../../impact-measurement/hooks';
import { ADMIN_TYPES } from '../../impact-measurement/utils/auth';
import { CustomUserActions } from './HeaderNavCustomUserActions';
import { QuestionMarkIcon } from './Icons';

type PageProps = {
  productName: string;
  isAdmin?: boolean;
  hideProductName?: boolean;
};

export const HeaderNav: React.FunctionComponent<PageProps> = ({
  productName,
  hideProductName
}) => {
  const { isDisney } = useTheme();
  const { changePassword } = usePasswordChange();
  const { isOn: isAppCuesOn } = useSplit(IM_FLAGS.APPCUES_TESTING);
  const isTablet = useBreakpoint('tablet');
  const profile = useProfile();
  const isSuperAdmin = useIsAdmin(ADMIN_TYPES.SUPER_ADMIN);

  const adminPath = '/admin';

  const user = {
    name: profile.name,
    nickname: profile.username,
    email: profile.email
  };

  const logoutPath = `${window.location.origin}/logout`;

  const headerTitle = isDisney
    ? 'Disney Impact Measurement Powered by Lucid'
    : productName;

  return (
    <Header
      productName={
        window.location.pathname.includes(adminPath)
          ? `${headerTitle} | Admin`
          : headerTitle
      }
      productNameIsVisible={!hideProductName && isTablet}
      homeUrl={window.location.origin}
      {...(isDisney && {
        productNameLss: { color: 'primary500' },
        logo: (
          <Box>
            <DisneyLogo />
          </Box>
        ),
        userDropdownLss: {
          bg: 'secondary500',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          color: 'white',
          ':not([disabled]):hover': {
            bg: 'secondary600',
            color: 'white',
            textDecoration: 'none'
          },
          ':focus': {
            outline: 'none',
            bg: 'secondary600',
            boxShadow: '0px 0px 5px #1166DD' as any
          },
          ':active': {
            bg: 'secondary600'
          }
        }
      })}
      lss={{
        display: 'flex',
        alignItems: 'center',
        height: '5rem',
        px: 'md',
        ...(isDisney && {
          background: 'white',
          borderBottom: 'regular',
          borderColor: 'primary500'
        })
      }}
      user={user}
      customUserActions={
        isSuperAdmin
          ? ({ onToggle }) => <CustomUserActions onToggle={onToggle} />
          : undefined
      }
      changePassword={changePassword}
      supportUrl="https://support.lucidhq.com/s/topic/0TO0P000000N1J5WAK/impact-measurement-dashboard"
      headerActions={[
        isAppCuesOn && (
          <TransparentButton
            as="a"
            lss={{ mr: 'xs' }}
            className="nav-question-mark"
            key="nav-question-mark"
          >
            <QuestionMarkIcon light={isDisney} />
          </TransparentButton>
        ),
        !hideProductName && isSuperAdmin && (
          <NavProductDropdown
            key="nav-product-dropdown"
            isSuperAdmin={isSuperAdmin}
          />
        )
      ]}
      logoutUrl={logoutPath}
    />
  );
};
