import React from 'react';
import {
  MdOutlineRadioButtonUnchecked,
  MdOutlineStarBorder,
  MdOutlineStar,
  MdOutlineCheckBoxOutlineBlank
} from 'react-icons/md';

import { Flex, Icon, LssProp, Text } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

export const updateInTargetAnswers = (
  targetOptionArr: number[],
  index: number
): number[] => {
  const copy = [...targetOptionArr];

  if (copy.includes(index)) {
    const deleteIdx = copy.indexOf(index);
    copy.splice(deleteIdx, 1);
  } else {
    copy.push(index);
  }

  // NOTE: if there are no in target options populate the copy with the in-target-brand
  // Otherwise sort the numerical options
  if (!copy.length) {
    copy.push(0);
  } else {
    copy.sort((a: number, b: number) => a - b);
  }

  return copy;
};

const targetAnswerWrapperLss: LssProp = {
  display: 'flex',
  alignItems: 'center',
  p: 'xs',
  borderRadius: 'xl'
};

const answerOptionLss: LssProp = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px'
};

const optionsListLss: LssProp = {
  flexDirection: 'column',
  gap: '4px',
  listStyle: 'none',
  p: 'none'
};

type NonGridAnswerOptionsProps = {
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
  hasNoneOfTheAbove: boolean;
};

const NONE_OF_THE_ABOVE = 'None of the above';

export const NonGridAnswerOptions = ({
  question,
  onSave,
  readOnly = false,
  hasNoneOfTheAbove
}: NonGridAnswerOptionsProps) => {
  const answerOptionIcon =
    question.question_type === 'radio'
      ? MdOutlineRadioButtonUnchecked
      : MdOutlineCheckBoxOutlineBlank;

  const hasFoundNOTA = !!question?.answers?.find(
    answer => answer === question?.none_of_the_above_answer
  );

  const answersCopy =
    hasNoneOfTheAbove && !hasFoundNOTA
      ? [...question?.answers, question?.none_of_the_above_answer]
      : [...question?.answers];

  const isTargetAnswerOptionVisible =
    !!question?.meta?.isStandard ||
    (question.kpi_type === 'custom' && !question?.isBehavioralCustomKPI);

  return (
    <Flex as={'ul'} lss={optionsListLss}>
      {answersCopy?.map((answer: any, index: number) => {
        const isSelected = !!question?.multi_target_option_index?.includes(
          index
        );
        const isAnswerNOTA = answer === NONE_OF_THE_ABOVE;

        // NOTE: the NOTA answer type can be either a string or null.
        if (!hasNoneOfTheAbove && isAnswerNOTA) {
          return null;
        }

        return (
          <Flex
            as="li"
            key={answer}
            lss={{
              ...answerOptionLss,
              paddingLeft:
                isAnswerNOTA && isTargetAnswerOptionVisible ? '38px' : 'none'
            }}
          >
            {!isAnswerNOTA && isTargetAnswerOptionVisible && (
              <Flex
                as="a"
                href="#"
                role="button"
                lss={{
                  ...targetAnswerWrapperLss,
                  ...(!isSelected && readOnly && { visibility: 'hidden' }),
                  ...(isSelected && readOnly && { cursor: 'default' })
                }}
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  onSave({
                    multi_target_option_index: updateInTargetAnswers(
                      question.multi_target_option_index,
                      index
                    )
                  });
                }}
              >
                <Icon
                  as={isSelected ? MdOutlineStar : MdOutlineStarBorder}
                  size={22}
                  lss={{ color: 'secondary500' }}
                />
              </Flex>
            )}
            <Icon as={answerOptionIcon} size={16} />
            <Text lss={{ ml: 'xs', flex: 1 }}>{answer}</Text>
          </Flex>
        );
      })}
    </Flex>
  );
};
