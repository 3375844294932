import React, { Fragment } from 'react';
import { MdCheck, MdCircle } from 'react-icons/md';

import {
  styled,
  Flex,
  Text,
  LssProp,
  Icon,
  ThemeInterface,
  TransparentButton,
  Box
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { useCampaignManager } from './CampaignManagerProvider';

type StyledStepProps = {
  theme: ThemeInterface;
  isInactive: boolean;
  isComplete: boolean;
  isStepActive: boolean;
};

const StyledStep = styled(Flex)(
  ({ theme, isInactive, isComplete, isStepActive }: StyledStepProps) => `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 50%;
  color: ${isInactive ? theme.colors.gray300 : theme.colors.secondary200};
  z-index: 1;
  background: ${
    isInactive
      ? theme.colors.gray300
      : isStepActive || isComplete
      ? theme.colors.secondary500
      : theme.colors.secondary200
  };
  svg {
    fill: ${theme.colors.white};
  }
`
);

type StyledStepLinkProps = {
  theme: ThemeInterface;
  isDisabled: boolean;
};

const StyledStepLink = styled(Box)(
  ({ theme, isDisabled }: StyledStepLinkProps) => `
  position: relative;
  min-width: 30px;
  width: calc(5% + 12px);
  height: 2px;
  border-radius: 8px;
  background-color: ${
    isDisabled ? theme.colors.gray300 : theme.colors.secondary500
  };
  margin-top: 1rem;
`
);

type StepProps = {
  id: string;
  activeStep: string;
  isOpen: boolean;
  isInactive: boolean;
  isComplete: boolean;
};

const Step = ({
  id,
  activeStep,
  isOpen,
  isInactive,
  isComplete
}: StepProps) => {
  const isCurrentStepActive = activeStep === id;
  const StepIcon = isCurrentStepActive ? MdCircle : isComplete ? MdCheck : '';
  const isOpenOrComplete = isOpen || isComplete;

  return (
    <StyledStep
      isInactive={isInactive}
      isComplete={isComplete}
      isStepActive={isCurrentStepActive}
    >
      {isOpenOrComplete && (
        <Icon as={StepIcon as any} size={16} lss={{ height: '24px' }} />
      )}
    </StyledStep>
  );
};

const stepColumnLss: LssProp = {
  flexDirection: 'column',
  gap: '0.5rem',
  p: 'xs',
  height: 'fit-content',
  alignItems: 'center',
  minWidth: '68px'
};

export const CampaignManagerStepper = () => {
  const { steps, goToStep, activeStep, can } = useCampaignManager();
  const stepsArr = Object.values(steps);

  if (!can.navigate) return null;

  return (
    <Flex lss={{ flexDirection: 'row', justifyContent: 'center' }}>
      {stepsArr.map(currentStep => {
        const isCurrentStepInactive = currentStep.meta.isInactive;

        return (
          <Fragment key={currentStep.id}>
            {currentStep.id !== steps.DETAILS.id && (
              <StyledStepLink isDisabled={currentStep.meta.isInactive} />
            )}
            <TransparentButton
              disabled={
                currentStep.meta.isInactive || activeStep === currentStep.id
              }
              lss={{ mx: 'xs' }}
              onClick={() => goToStep(currentStep.id)}
            >
              <Flex lss={stepColumnLss}>
                <Step
                  id={currentStep.id}
                  activeStep={activeStep}
                  {...currentStep.meta}
                />
                <Text
                  lss={{
                    color: isCurrentStepInactive ? 'gray500' : 'secondary500'
                  }}
                >
                  {t(`campaignManager.steps.${currentStep.id}`)}
                </Text>
              </Flex>
            </TransparentButton>
          </Fragment>
        );
      })}
    </Flex>
  );
};
