import React from 'react';
import { MdDelete } from 'react-icons/md';

import {
  Button,
  Flex,
  Icon,
  Label,
  LssProp,
  TransparentButton
} from '@lucidhq/lucidium';
import {
  FieldArray,
  useFormikContext
} from '@lucidhq/lucidium/components/FormV2';

import { useFetchTargetAudienceQuestions } from '~/components/Shared/hooks/useFetchTargetAudienceQuestions';
import { InfoTooltip } from '~/impact-measurement/components';
import { DraftTargetQualification } from '~/types';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { QualificationAnswer } from './QualificationAnswer';
import { QualificationQuestion } from './QualificationQuestion';

const deleteCta: LssProp = {
  color: 'secondary500',
  display: 'flex',
  alignSelf: 'flex-start',
  flexBasis: 'fit-content'
};

export const Qualifications = () => {
  const { values } = useFormikContext<{
    qualifications: DraftTargetQualification[];
  }>();

  const { managedCampaign } = useCampaignManager();
  const { data } = useFetchTargetAudienceQuestions(
    managedCampaign?.details.country_language_id
  );

  return (
    <FieldArray
      name="qualifications"
      render={arrayHelpers => (
        <Flex lss={{ flexDirection: 'column' }}>
          {values.qualifications.map((qualification, index) => (
            <Flex key={index} lss={{ flexDirection: 'column', my: 'md' }}>
              <Flex
                lss={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 'xxs'
                }}
              >
                <Flex
                  lss={{
                    flexDirection: 'row',
                    gap: '2px',
                    alignItems: 'center'
                  }}
                >
                  <Label lss={{ my: 'sm' }} required>
                    {t('forms.labels.attributeX', { index: index + 1 })}
                  </Label>
                  <InfoTooltip
                    title={t(
                      `campaignManager.targetAudience.tooltips.attributes`
                    )}
                    placement="top-start"
                    iconLss={{ height: '1.125rem', width: '1.125rem' }}
                  />
                </Flex>

                <Flex>
                  <TransparentButton
                    type="button"
                    lss={deleteCta}
                    onClick={() => {
                      arrayHelpers.remove(index);
                    }}
                  >
                    <Icon as={MdDelete} size={28} />
                  </TransparentButton>
                </Flex>
              </Flex>

              <QualificationQuestion
                showLabel={false}
                key={`q-${index}`}
                questions={data?.questions}
                index={index}
              />
              {qualification && data && (
                <QualificationAnswer
                  index={index}
                  key={`a-${index}`}
                  qualification={qualification}
                  questions={data.questions}
                />
              )}
            </Flex>
          ))}

          {!values.qualifications.length && (
            <QualificationQuestion
              showLabel={true}
              key={'q-0'}
              questions={data?.questions}
              index={0}
            />
          )}

          <Flex>
            <Button
              emphasis="low"
              disabled={
                !values.qualifications.length ||
                values.qualifications.findIndex(q => q === null) >= 0
              }
              type="button"
              onClick={() => arrayHelpers.push(null)}
            >
              {`+ ${t('buttons.addAttribute')}`}
            </Button>
          </Flex>
        </Flex>
      )}
    />
  );
};
