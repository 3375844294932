import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';

import { CampaignTypes } from '~/types';
import {
  decorateManagedCampaign,
  ManagedCampaign
} from '~/utils/campaignManager';
import { t } from '~/utils/i18n';

import { usePermissions } from '../Permissions/PermissionsProvider';
import { useErrorDialogProvider } from '../Shared/ErrorDialogProvider';
import { useFetchCampaign } from '../Shared/hooks/useFetchCampaign';
import {
  PixelGroup,
  useFetchCampaignPixelGroups
} from '../Shared/hooks/useFetchCampaignPixelGroups';
import {
  S2SMappings,
  useFetchS2SMappings
} from '../Shared/hooks/useFetchS2SMappings';
import {
  CampaignManagerEnhancedSteps,
  CampaignManagerStepIds,
  useCampaignManagerSteps
} from './useCampaignManagerSteps';

type GoToStep = (
  stepId: CampaignManagerStepIds,
  stepActions?: Record<string, any>
) => void;

type Checklist = {
  brands: boolean;
  kpis: boolean;
  audience: boolean;
  mediaTracking: boolean;
  launch: boolean;
  baselineKpis: boolean;
};

type CampaignManager = {
  steps: CampaignManagerEnhancedSteps;
  activeStep: CampaignManagerStepIds;
  options: Record<string, any>;
  setOptions: Dispatch<SetStateAction<{}>>;
  goToStep: GoToStep;
  managedCampaign: ManagedCampaign;
  refetchCampaign: () => void;
  pixelGroups: PixelGroup[];
  s2sMappings: S2SMappings;
  can: {
    edit: boolean;
    limitedEdit: boolean;
    navigate: boolean;
    editAccount: boolean;
    editSFO: boolean;
    addCustomKPI: boolean;
    editKPI: boolean;
    moveKPI: boolean;
    addBaselineKPI: boolean;
    addExtraKPI: boolean;
  };
  checklist: Checklist;
  onMutateError: {
    onError: () => void;
  };
  isSoftware: boolean;
  isService: boolean;
};

export type CampaignManagerProviderProps = {
  children: React.ReactNode;
  campaignId?: string;
  type: CampaignTypes;
  stepId?: CampaignManagerStepIds;
};

const CampaignManagerContext = React.createContext<CampaignManager>(
  {} as CampaignManager
);

export const useCampaignManager = () =>
  useContext<CampaignManager>(CampaignManagerContext);

export const CampaignManagerProvider: React.FC<CampaignManagerProviderProps> = ({
  children,
  campaignId,
  stepId,
  type
}) => {
  const campaign = useFetchCampaign(type, campaignId);
  const pixels = useFetchCampaignPixelGroups(campaignId);
  const mappings = useFetchS2SMappings(campaignId);
  const { isAdmin } = usePermissions();
  const { setError } = useErrorDialogProvider();

  const {
    steps,
    activeStep,
    options,
    setOptions,
    setActiveStep,
    setStepAsComplete,
    setStepAsOpen
  } = useCampaignManagerSteps(type);

  const isService = type === CampaignTypes.SERVICE;
  const isSoftware = !isService;

  const managedCampaign = useMemo(() => {
    return decorateManagedCampaign(campaign.data);
  }, [campaign.data]);

  const pixelGroups = useMemo(() => {
    if (!pixels?.data?.items) return [];
    const { items } = pixels.data;
    return items;
  }, [pixels?.data]);

  const s2sMappings = useMemo(() => {
    if (!mappings?.data?.items) return [];
    const { items } = mappings.data;
    return items;
  }, [mappings?.data]);

  const can = useMemo(() => {
    const isDraft = !!managedCampaign?.details.meta.isDraft;
    const isLive = !!managedCampaign?.details.meta.isLive;
    const isDraftOrLive = isDraft || isLive;
    const isV2 = !!managedCampaign?.details.meta.isV2;

    return {
      // Campaign related regardless of Role
      edit: isService ? isDraft : isV2 && isDraft,
      navigate: isService ? isDraftOrLive : isV2 && isDraftOrLive,
      limitedEdit: isService ? isLive : isV2 && isLive,

      // Admin only
      editAccount: isAdmin,
      editSFO: isAdmin, // SalesForce
      addCustomKPI: isAdmin,
      editKPI: isAdmin,
      moveKPI: isAdmin,
      addExtraKPI: isAdmin,

      // Advocacy 13
      // Political 10
      addBaselineKPI: [10, 13].includes(
        managedCampaign?.details.industry_id || -1
      )
    };
  }, [managedCampaign, isAdmin, isService]);

  const onMutateError = useMemo(
    () => ({
      onError: () => {
        setError(t('errors.generalApiError'));
      }
    }),
    [setError]
  );

  // Step validator
  // This Fn is responsible to check existing data
  // and how that affect the status steps by marking them
  // as complete, open or inactive
  useEffect(() => {
    if (managedCampaign?.details && !steps.DETAILS.meta.isComplete) {
      setStepAsComplete(steps.DETAILS.id);
      setStepAsOpen(steps.MEDIA_TRACKING.id);
      if (isSoftware) {
        setStepAsOpen(steps.SURVEY.id);
        setStepAsOpen(steps.AUDIENCE.id);
      }
      setStepAsOpen(steps.REVIEW.id);
      if (stepId) setActiveStep(stepId);
    }

    // If Media Tracking is open but met reqs
    // set as complete
    if (
      steps.MEDIA_TRACKING.meta.isOpen &&
      (pixelGroups.length || s2sMappings.length)
    ) {
      setStepAsComplete(steps.MEDIA_TRACKING.id);
    }

    // If Media Tracking is complete but stopped
    // meeting reqs, then move back to open
    if (
      steps.MEDIA_TRACKING.meta.isComplete &&
      !pixelGroups.length &&
      !s2sMappings.length
    ) {
      setStepAsOpen(steps.MEDIA_TRACKING.id);
    }

    if (isSoftware) {
      // If Survey is open but met reqs
      // set as complete
      if (
        steps.SURVEY.meta.isOpen &&
        managedCampaign?.details.meta.hasBaselineQuestions &&
        managedCampaign?.details.meta.hasStandardQuestions
      ) {
        setStepAsComplete(steps.SURVEY.id);
      }

      // If Survey is complete but stopped
      // meeting reqs, then move back to open
      if (
        steps.SURVEY.meta.isComplete &&
        (!managedCampaign?.details.meta.hasBaselineQuestions ||
          !managedCampaign?.details.meta.hasStandardQuestions)
      ) {
        setStepAsOpen(steps.SURVEY.id);
      }

      // if Audience step is open but met reqs
      // set as complete
      if (
        steps.AUDIENCE.meta.isOpen &&
        managedCampaign?.details.draft_settings.target_audiences?.length
      ) {
        setStepAsComplete(steps.AUDIENCE.id);
      }

      // If Audience step is complete but stopped
      // meeting reqs, then move back to open
      if (
        steps.AUDIENCE.meta.isComplete &&
        !managedCampaign?.details.draft_settings.target_audiences?.length
      ) {
        setStepAsOpen(steps.AUDIENCE.id);
      }
    }
  }, [managedCampaign, pixelGroups, s2sMappings, can, isSoftware, isService]); // eslint-disable-line

  const goToStep = useCallback<GoToStep>(
    (stepId, stepActions) => {
      if (!can.navigate) return;

      if (stepActions && Object.keys(stepActions).length) {
        setOptions(stepActions);
      }

      if (!steps[stepId].meta.isInactive) {
        setActiveStep(stepId);
      } else {
        // @TODO Should throw error?
      }
    },
    [steps, setActiveStep, can, setOptions]
  );

  const checklist = useMemo(() => {
    const hasMediaTracking = !!(pixelGroups.length || s2sMappings.length);

    if (isSoftware) {
      const hasKPIQuestions = !!managedCampaign?.details.meta
        .hasStandardQuestions;
      const hasBaselineQuestions = !!managedCampaign?.details.meta
        .hasEnoughBaselineQuestions;
      const hasTargetAud = !!managedCampaign?.details.draft_settings
        .target_audiences?.length;
      const hasBrandName = !!managedCampaign?.details.meta.hasBrand;
      const hasCompetitors = !!managedCampaign?.details.meta
        .hasEnoughCompetitors;
      const hasBrand = hasBrandName && hasCompetitors;

      return {
        brands: hasBrand,
        kpis: hasKPIQuestions,
        baselineKpis: hasBaselineQuestions,
        audience: hasTargetAud,
        mediaTracking: hasMediaTracking,
        launch: hasBrand && hasKPIQuestions && hasBaselineQuestions
      };
    }

    return {
      brands: false,
      baselineKpis: false,
      kpis: false,
      audience: false,
      mediaTracking: hasMediaTracking,
      launch: true
    };
  }, [managedCampaign, s2sMappings, pixelGroups, isSoftware]);

  const value = useMemo(
    () => ({
      steps,
      activeStep,
      options,
      setOptions,
      goToStep,
      managedCampaign,
      refetchCampaign: campaign.refetch,
      pixelGroups,
      s2sMappings,
      can,
      checklist,
      onMutateError,
      isService,
      isSoftware
    }),
    [
      steps,
      activeStep,
      options,
      setOptions,
      goToStep,
      managedCampaign,
      campaign.refetch,
      pixelGroups,
      s2sMappings,
      can,
      checklist,
      onMutateError,
      isService,
      isSoftware
    ]
  );

  return (
    <CampaignManagerContext.Provider value={value}>
      {children}
    </CampaignManagerContext.Provider>
  );
};
