import React, { useState } from 'react';

import {
  BusyButton,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  InlineNotification,
  List,
  ListItem,
  Text,
  useToggle
} from '@lucidhq/lucidium';

import { useLaunchIMCampaign } from '~/components/Shared/hooks/useLaunchCampaign';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type LaunchCampaignProps = {
  onLaunch?: () => void;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const LaunchCampaign = ({ children, onLaunch }: LaunchCampaignProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const [launched, setLaunched] = useState<boolean>(false);
  const [launchedMessage, setLaunchedMessage] = useState<string>('');
  const { managedCampaign, checklist, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useLaunchIMCampaign(
    managedCampaign?.details.campaign_id
  );

  const handleLaunched = () => {
    onLaunch?.();
    handleToggle();
  };

  const handleLaunch = () => {
    mutate(
      {},
      {
        onSuccess: ({ detail }) => {
          setLaunched(true);
          setLaunchedMessage(detail);
          handleLaunched();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onToggle: handleToggle })}

      <Dialog
        isOpen={isOpen}
        onClose={handleToggle}
        lss={{ maxWidth: '43rem' }}
      >
        <DialogHeader
          palette="disabled"
          onClose={launched ? handleLaunched : handleToggle}
        >
          {t('campaignManager.review.launch.dialog.header')}
        </DialogHeader>

        <DialogContent>
          {launched && (
            <Text
              lss={{
                textScale: 'heading2',
                m: 'xxxl',
                textAlign: 'center'
              }}
            >
              {launchedMessage}
            </Text>
          )}

          {checklist.launch && !launched && (
            <>
              <Text
                lss={{
                  textScale: 'heading2',
                  mb: 'xxl',
                  textAlign: 'center'
                }}
              >
                {t('campaignManager.review.launch.dialog.description')}
              </Text>
              <InlineNotification
                lss={{
                  mb: 'xxl',
                  mx: 'xxl'
                }}
                palette="warning"
                title={t('campaignManager.review.audience.warning.title')}
              >
                <Text lss={{ mb: 'lg', display: 'block' }}>
                  {t('campaignManager.review.launch.dialog.warning')}
                </Text>
              </InlineNotification>
              <Flex lss={{ flexDirection: 'column', ml: 'xxxl' }}>
                <Text>
                  {t(
                    'campaignManager.review.launch.dialog.requirementsDescription'
                  )}
                </Text>

                {(!checklist.audience || !checklist.mediaTracking) && (
                  <>
                    <Text lss={{ mb: 'md' }}>
                      {t(
                        'campaignManager.review.launch.dialog.requirementsOptional'
                      )}
                    </Text>
                    <List lss={{ lineHeight: 1.5 }}>
                      {!checklist.audience && (
                        <ListItem>
                          {t('campaignManager.review.audience.targetAudience')}
                        </ListItem>
                      )}
                      {!checklist.mediaTracking && (
                        <ListItem>
                          {t('campaignManager.review.mediaTracking.title')}
                        </ListItem>
                      )}
                    </List>
                  </>
                )}
              </Flex>

              <Flex lss={{ justifyContent: 'center', mt: 'xxl' }}>
                <BusyButton
                  isBusy={isLoading}
                  onClick={handleLaunch}
                  lss={{ mr: 'lg' }}
                >
                  {t('campaignManager.actions.launch')}
                </BusyButton>

                <Button
                  type="button"
                  emphasis="medium"
                  palette="secondary"
                  onClick={handleToggle}
                >
                  {t('campaignManager.actions.cancel')}
                </Button>
              </Flex>
            </>
          )}

          {!checklist.launch && !launched && (
            <>
              <InlineNotification
                lss={{
                  mb: 'xxl',
                  mx: 'xxl'
                }}
                palette="warning"
                title={t('campaignManager.review.audience.warning.title')}
              >
                <Flex lss={{ flexDirection: 'column' }}>
                  <Text lss={{ mb: 'lg', display: 'block' }}>
                    {t(
                      'campaignManager.review.launch.dialog.validation.warning'
                    )}
                  </Text>

                  <List lss={{ lineHeight: 1.5 }}>
                    {!checklist.brands && (
                      <ListItem>
                        {t(
                          'campaignManager.review.launch.dialog.validation.brands'
                        )}
                      </ListItem>
                    )}
                    {!checklist.kpis && (
                      <ListItem>
                        {t(
                          'campaignManager.review.launch.dialog.validation.kpiQuestions'
                        )}
                      </ListItem>
                    )}
                    {!checklist.baselineKpis && (
                      <ListItem>
                        {t(
                          'campaignManager.review.launch.dialog.validation.baselineQuestions'
                        )}
                      </ListItem>
                    )}
                    {(!checklist.audience || !checklist.mediaTracking) && (
                      <>
                        <ListItem>
                          {t(
                            'campaignManager.review.launch.dialog.validation.optional'
                          )}
                        </ListItem>
                        <List>
                          {!checklist.audience && (
                            <ListItem>
                              {t(
                                'campaignManager.review.audience.targetAudience'
                              )}
                            </ListItem>
                          )}
                          {!checklist.mediaTracking && (
                            <ListItem>
                              {t('campaignManager.review.mediaTracking.title')}
                            </ListItem>
                          )}
                        </List>
                      </>
                    )}
                  </List>
                </Flex>
              </InlineNotification>

              <Flex lss={{ justifyContent: 'center', mt: 'lg' }}>
                <Button type="button" onClick={handleToggle}>
                  {t('campaignManager.actions.ok')}
                </Button>
              </Flex>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
