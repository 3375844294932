import { BaseTheme, LssProp } from '@lucidhq/lucidium';

type ThemeColors = {
  [key: string]: LssProp['color'];
};
export const THEME_COLORS: ThemeColors = {
  positive: 'emerald500',
  slightlyPositive: '#c9eb71' as any, //No equivalent in color system
  none: 'citrine500',
  slightlyNegative: 'topaz500',
  negative: 'ruby500'
};

export const COLORS: Color = {
  positive: BaseTheme.colors[THEME_COLORS.positive as string],
  slightlyPositive: THEME_COLORS.slightlyPositive as string,
  none: BaseTheme.colors[THEME_COLORS.none as string],
  slightlyNegative: BaseTheme.colors[THEME_COLORS.slightlyNegative as string],
  negative: BaseTheme.colors[THEME_COLORS.negative as string]
};

export type LiftColorAndLabel = {
  themeColor: LssProp['color'];
  hex: string;
  label: string;
  range: string;
};

type LiftStatus = {
  [key: string]: LiftColorAndLabel;
};

export const LIFT_STATUS: LiftStatus = {
  POSITIVE: {
    themeColor: THEME_COLORS.positive as LssProp['color'],
    hex: COLORS.positive,
    label: 'Positive',
    range: '5% and Above'
  },
  SLIGHTLY_POSITIVE: {
    themeColor: COLORS.slightlyPositive as any,
    hex: COLORS.slightlyPositive,
    label: 'Slightly Positive',
    range: '1% to 4.9%'
  },
  NONE: {
    themeColor: THEME_COLORS.none as LssProp['color'],
    hex: COLORS.none,
    label: 'Neutral',
    range: '-0.9% to 0.9%'
  },
  SLIGHTLY_NEGATIVE: {
    themeColor: THEME_COLORS.slightlyNegative as LssProp['color'],
    hex: COLORS.slightlyNegative,
    label: 'Slightly Negative',
    range: '-4.9% to -1%'
  },
  NEGATIVE: {
    themeColor: THEME_COLORS.negative as LssProp['color'],
    hex: COLORS.negative,
    label: 'Negative',
    range: '-5% and Below'
  }
};
