import React from 'react';
import {
  MdOutlineRadioButtonUnchecked,
  MdOutlineStarBorder,
  MdOutlineStar,
  MdOutlineCheckBoxOutlineBlank
} from 'react-icons/md';

import { Flex, Icon, LssProp } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

type NumTuple = [number, number];

type AreTuplesEqual = (tup1: NumTuple, tup2: NumTuple) => boolean;

const areTuplesEqual: AreTuplesEqual = (tup1, tup2) => {
  if (tup1.length !== tup2.length) return false;

  return tup1.every((val, index) => val === tup2[index]);
};

const findTupleIndex = (
  targetOptionArr: NumTuple[] | null,
  tup: NumTuple
): number => {
  let indexOfTuple = -1;

  targetOptionArr?.forEach((currTup, idx) => {
    if (areTuplesEqual(currTup, tup)) {
      indexOfTuple = idx;
    }
  });

  return indexOfTuple;
};

const updateGridInTargetAnswers = (
  targetOptionArr: NumTuple[] | null,
  tup: NumTuple
): NumTuple[] => {
  const copy = !!targetOptionArr?.length ? [...targetOptionArr] : [];
  const indexOfTuple = findTupleIndex(copy, tup);

  if (indexOfTuple > -1) {
    copy.splice(indexOfTuple, 1);
  } else {
    copy.push(tup);
  }

  if (!copy.length) {
    copy.push([0, 0]);
  } else {
    // sort tuples based off both values
    copy.sort((a, b) => {
      return a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];
    });
  }

  return copy;
};

const wrapperLss: LssProp = {
  flexDirection: 'column',
  width: '100%',
  px: 'sm',
  gap: '12px'
};

const columnHeaderLss: LssProp = {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  overflowWrap: 'break-word',
  textScale: 'control',
  alignSelf: 'flex-end'
};

const rowTextLss: LssProp = {
  whiteSpace: 'normal',
  wordBreak: 'break-word',
  justifyContent: 'flex-start',
  alignItems: 'center',
  pl: 'sm'
};

const gridItemLss: LssProp = {
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  overflowWrap: 'break-word',
  alignSelf: 'center',
  px: 'md'
};

type GridAnswerOptionsProps = {
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
};

export const GridAnswerOptions = ({
  question,
  onSave,
  readOnly = false
}: GridAnswerOptionsProps) => {
  //@ts-ignore
  const width = `${(1 / question?.columns?.length) * 100}%`;
  const answerOptionIcon = question.question_type.includes('radio')
    ? MdOutlineRadioButtonUnchecked
    : MdOutlineCheckBoxOutlineBlank;

  return (
    <Flex lss={wrapperLss}>
      <Flex lss={{ flexDirection: 'row' }}>
        <Flex
          lss={{
            ...columnHeaderLss,
            flexDirection: 'column',
            minWidth: '101px',
            px: 'sm',
            width
          }}
        />
        {question?.columns?.map((col, colIdx) => (
          <Flex
            key={`${col?.text} ${colIdx}`}
            lss={{
              ...columnHeaderLss,
              flexDirection: 'column',
              px: 'sm',
              width
            }}
          >
            {col?.text}
          </Flex>
        ))}
      </Flex>
      {question?.rows?.map((row, rowIndex) => {
        return (
          <Flex key={rowIndex} lss={{ flexDirection: 'row' }}>
            <Flex lss={{ ...rowTextLss, width }}>{row.text.trim()}</Flex>

            {question?.columns?.map((col, colIndex) => {
              const isValidTargetOption =
                (colIndex <= 1 && !!question?.meta?.isStandard) ||
                (question?.meta?.isCustomKPI && readOnly);
              const targetOptionArr = question?.multi_level_target_option_index;
              const currTuple: NumTuple = [rowIndex, colIndex];
              const isSelected = !!(
                findTupleIndex(targetOptionArr, currTuple) > -1
              );

              return (
                <Flex
                  key={`${col} ${colIndex}`}
                  lss={{ ...gridItemLss, width }}
                >
                  {isValidTargetOption && (
                    <Flex
                      as="a"
                      href="#"
                      role="button"
                      lss={{
                        display: 'flex',
                        alignItems: 'center',
                        p: 'xs',
                        borderRadius: 'xl',
                        ...(!isSelected &&
                          readOnly && { visibility: 'hidden' }),
                        ...(isSelected && readOnly && { cursor: 'default' })
                      }}
                      onClick={(e: React.SyntheticEvent) => {
                        e.preventDefault();
                        onSave({
                          multi_level_target_option_index: updateGridInTargetAnswers(
                            targetOptionArr,
                            currTuple
                          )
                        });
                      }}
                    >
                      <Icon
                        as={isSelected ? MdOutlineStar : MdOutlineStarBorder}
                        size={22}
                        lss={{ color: 'secondary500' }}
                      />
                    </Flex>
                  )}
                  <Icon as={answerOptionIcon} size={22} />
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
};
