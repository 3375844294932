import React from 'react';

import {
  BulkDialog,
  Flex,
  InputRowGroup,
  LssProp,
  Message
} from '@lucidhq/lucidium';

import {
  LoadingContentWrapper,
  PageIntro,
  PageIntroProps
} from '~/impact-measurement/components';
import { MediaTrackingToolsFormContextType } from '~/impact-measurement/pages/Admin/Contexts';
import { getArrayWithItemDeleted } from '~/impact-measurement/utils/getArrayWithItemDeleted';

type MappingSetupProps = {
  sectionIntro: PageIntroProps;
  index: number;
  linearTv: LinearTv;
  setFieldValue: MediaTrackingToolsFormContextType['setFieldValue'];
};

const buttonLss: LssProp = {
  width: 'max-content',
  mt: 'sm'
};

export const MappingSetup: React.FC<MappingSetupProps> = ({
  sectionIntro,
  index,
  linearTv: { partner_campaigns_ids },
  setFieldValue
}) => {
  const fieldName = `linearTv.${index}.partner_campaigns_ids`;

  const handleBulk = (values: string[]) => {
    const bulkValues =
      partner_campaigns_ids[0] !== ''
        ? [...partner_campaigns_ids, ...values]
        : values;

    setFieldValue(fieldName, [...bulkValues]);
  };

  const onAddInput = () => {
    setFieldValue(fieldName, [...partner_campaigns_ids, '']);
  };

  const onRemoveInput = (index: number) => {
    setFieldValue(
      fieldName,
      getArrayWithItemDeleted(partner_campaigns_ids, index)
    );
  };

  return (
    <LoadingContentWrapper
      isLoading={false}
      showContent
      error={undefined}
      errorType="message"
      fallBackComponent={<Message message={'SITE_SERVED_FAIL_MESSAGE'} />}
      spinnerSize="md"
    >
      <Flex lss={{ mt: 'xl', flexDirection: 'column', display: 'inline-flex' }}>
        <PageIntro {...sectionIntro} lss={{ mb: 'xxl' }} />

        <InputRowGroup
          value={partner_campaigns_ids}
          nameKey={fieldName}
          onAddInput={onAddInput}
          onRemoveInput={index => onRemoveInput(index)}
          rowsAreRequired={false}
        />

        <BulkDialog
          id={`add-bulk-${index}`}
          onAdd={handleBulk}
          dialogTitle={`Add bulk ${sectionIntro.title}`}
          buttonLss={buttonLss}
          palette="secondary"
        />
      </Flex>
    </LoadingContentWrapper>
  );
};
