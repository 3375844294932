import React, { useMemo, useState } from 'react';
import { MdCheckCircle, MdDoDisturbAlt } from 'react-icons/md';

import { Flex, H5, Icon, Text, Toggle } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { IPMatchingConfirmDialog } from './IPMatchingConfirmDialog';
import { useIPMatchingContext } from './IPMatchingProvider';

// NOTE: we only show auto and manual to users to avoid confusion with migration status
enum STATUS_LABELS {
  auto = 'Automatically',
  manual = 'Manually'
}

export const IPMatchingSection = () => {
  const { rootLevelEnablement, ipMatching } = useIPMatchingContext();
  const [enablement, handleEnablement] = useState<boolean>(rootLevelEnablement);

  // NOTE: potentially move to provider
  const status = useMemo(() => {
    if (ipMatching?.enablement_status === undefined) return;

    const key = ipMatching.enablement_status;

    return key === null || key === 'migration'
      ? STATUS_LABELS.auto
      : STATUS_LABELS[key];
  }, [ipMatching?.enablement_status]);

  return (
    <>
      <Flex
        lss={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <H5>{t('ipMatching.title')}</H5>
        <IPMatchingConfirmDialog
          enablement={enablement}
          handleEnablement={handleEnablement}
        >
          {({ onToggle }) => (
            <Toggle
              lss={{ borderRadius: 'lg' }}
              value={enablement}
              onChange={onToggle}
            />
          )}
        </IPMatchingConfirmDialog>
      </Flex>
      <Flex
        lss={{ flexDirection: 'row', gap: '0.25rem', alignItems: 'center' }}
      >
        <Icon as={enablement ? MdCheckCircle : MdDoDisturbAlt} size={24} />
        <Text lss={{ fontWeight: 'bold', mr: 'sm' }}>
          {t(`labels.${enablement ? 'on' : 'off'}`)}
        </Text>
        {enablement && (
          <>
            <Text>|</Text>
            <Text lss={{ ml: 'sm' }}>
              {t('ipMatching.labels.enablement', { status })}
            </Text>
          </>
        )}
      </Flex>
    </>
  );
};
