import React from 'react';

import { Flex, LssProp, Text } from '@lucidhq/lucidium';

import {
  NavBar,
  NavBarCampaignInfo,
  NavBarCampaignInfoProps,
  BackButton
} from '~/impact-measurement/components';

export type AdminNavBarProps = {
  campaign: AdminNavBarCampaign;
  hasCampaign?: boolean;
  title?: string;
  showBackButton?: boolean;
  children?: React.ReactNode;
  backToPath?: string;
  isReview?: boolean;
  navBarLss?: LssProp;
} & Pick<NavBarCampaignInfoProps, 'isLinkStateEnabled' | 'isUrlParamsEnabled'>;

export const AdminNavBar: React.FC<AdminNavBarProps> = ({
  children,
  campaign,
  hasCampaign = true,
  title,
  showBackButton = false,
  backToPath,
  isUrlParamsEnabled = false,
  isLinkStateEnabled = true,
  isReview,
  navBarLss = {}
}) => (
  <NavBar lss={{ tablet: { top: 118 }, ...navBarLss }}>
    {hasCampaign ? (
      <NavBarCampaignInfo
        campaign={campaign}
        backToPath={backToPath || `/admin/campaign/${campaign.campaign_id}`}
        isUrlParamsEnabled={isUrlParamsEnabled}
        isLinkStateEnabled={isLinkStateEnabled}
        lss={{ tablet: { py: 'xxs' } }}
        isReview={isReview}
      />
    ) : (
      <Flex lss={{ alignItems: 'center' }}>
        {showBackButton && <BackButton to={backToPath || '/admin'} />}
        <Text as="h1" lss={{ textScale: 'heading4', my: 'sm' }}>
          {title}
        </Text>
      </Flex>
    )}
    {children}
  </NavBar>
);
