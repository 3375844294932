import { useContext } from 'react';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';

import { getTabsAndCounts } from './mediaTrackingToolsHelpers';

export const useGetHasUnsavedItems = () => {
  const {
    mediaToolData: { bulk_tags, site_served_tags, universal_tags, linearTv }
  } = useContext(MediaTrackingToolsFormContext);

  const { pixelCount, linearTvLength } = getTabsAndCounts(
    bulk_tags,
    site_served_tags,
    universal_tags,
    linearTv,
    'on'
  );

  return pixelCount > 0 || linearTvLength > 0;
};
