import React, { useState } from 'react';

import {
  TextInput,
  Flex,
  Label,
  BusyButton,
  Icon,
  Tag,
  Text,
  LssProp
} from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { AIStarIcon } from './AIStarIcon';

const tagLss: LssProp = {
  borderColor: 'secondary500',
  color: 'secondary500',
  mr: 'xs',
  mb: 'xs',
  py: 'xs',
  height: 'fit-content',
  alignItems: 'center'
};

const tagTextLss: LssProp = {
  fontSize: '14px',
  textOverflow: 'ellipsis',
  fontWeight: 'regular',
  textAlign: 'center'
};

const buttonLss: LssProp = {
  display: 'flex',
  padding: '0.5rem',
  width: '15rem',
  alignItems: 'center'
};

const formatAnswerVariations = (value: string): string[] => {
  if (!value.length) return [];

  if (!value.includes(',') && !!value.length) {
    return [value.trim()];
  }

  const variations = value
    .split(',')
    .map(item => item.trim())
    .filter(val => val !== '');

  return [...new Set(variations)];
};

type TextInterpretedAnswerOptionProps = {
  readOnly: boolean;
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
};

export const TextInterpretedAnswerOption = ({
  readOnly,
  question,
  onSave
}: TextInterpretedAnswerOptionProps) => {
  const [value, setValue] = useState<string>('');

  const handleRemove = (item: string) => {
    const updatedAnswers = question.answers.filter(val => val !== item);

    onSave({ answers: updatedAnswers });
  };

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        width: '100%',
        maxWidth: readOnly ? '32rem' : 'none',
        marginTop: !readOnly ? '2.625rem' : '0.5rem'
      }}
    >
      {!readOnly && (
        <>
          <Label required>
            {t('campaignManager.survey.standardQuestions.questionCard.answer')}
          </Label>
          <Flex lss={{ flexDirection: 'row', gap: '1rem' }}>
            <BusyButton
              palette="secondary"
              type="button"
              onClick={() => null}
              scale="md"
              lss={buttonLss}
              disabled
            >
              <Icon as={AIStarIcon} />
              {t('campaignManager.buttonTexts.generateVariations')}
            </BusyButton>
            <TextInput
              placeholder={t('forms.placeholders.textAnswerVariations')}
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter' && !!value.length) {
                  const formattedAnswers = formatAnswerVariations(value);
                  const answers = [...question.answers, ...formattedAnswers];

                  onSave({ answers });
                  setValue('');
                }
              }}
            />
          </Flex>
        </>
      )}
      <Flex
        lss={{
          flexDirection: 'row',
          gap: '0.5rem',
          pt: !readOnly ? 'lg' : 'none'
        }}
      >
        {!!question?.answers?.length &&
          question.answers.map(item => {
            return (
              <Tag
                key={item}
                palette="primary"
                textScale="control"
                lss={tagLss}
                isRound
                hasOutline
                {...(!readOnly && {
                  onRemove: () => handleRemove(item)
                })}
              >
                <Text lss={tagTextLss}>{item}</Text>
              </Tag>
            );
          })}
      </Flex>
    </Flex>
  );
};
