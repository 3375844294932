import React from 'react';

import { Flex, LssProp } from '@lucidhq/lucidium';
import { Field, Label } from '@lucidhq/lucidium/components/FormV2';

import { InfoTooltip } from '~/impact-measurement/components';
import { t } from '~/utils/i18n';

const fieldWrapperLss: LssProp = {
  flexDirection: 'column',
  flexBasis: '100%',
  my: 'md'
};

export const BrandInformationSection = ({
  readOnly
}: {
  readOnly: boolean;
}) => {
  const fields: Record<string, any> = t(
    'campaignManager.survey.brands.brandInformation.fields',
    {
      returnObjects: true
    }
  );

  return (
    <>
      <Flex lss={{ ...fieldWrapperLss, mt: 'lg' }}>
        <Label htmlFor="product_category">
          {fields.productCategory.label}
          <InfoTooltip
            title={fields.productCategory.tooltip}
            iconLss={{ ml: 'md' }}
            tooltipLss={{ py: 'md', minWidth: '25rem' }}
          />
        </Label>
        <Field
          name="product_category"
          groupLss={{ pb: 'md' }}
          disabled={readOnly}
        />
      </Flex>

      <Flex lss={fieldWrapperLss}>
        <Label>
          {fields.purchaseWindow.label}
          <InfoTooltip
            title={fields.purchaseWindow.tooltip}
            iconLss={{ ml: 'md' }}
            tooltipLss={{ py: 'md', minWidth: '25rem' }}
          />
        </Label>
        <Field name="period" groupLss={{ pb: 'md' }} disabled={readOnly} />
      </Flex>

      <Flex lss={fieldWrapperLss}>
        <Label>
          {fields.adMessage.label}
          <InfoTooltip
            title={fields.adMessage.tooltip}
            iconLss={{ ml: 'md' }}
            tooltipLss={{ py: 'md', minWidth: '25rem' }}
          />
        </Label>
        <Field name="message" groupLss={{ pb: 'md' }} disabled={readOnly} />
      </Flex>
    </>
  );
};
