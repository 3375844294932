import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { MediaTrackingToolsContentProps } from '../MediaTrackingToolsContent';
import { mediaTrackingWrapperLss } from '../styles';
import { TagsGeneration } from './TagsGeneration';
import { TagsPreview } from './TagsPreview';

export type TagsProps = Omit<
  MediaTrackingToolsContentProps,
  'setHasUnsavedChanges'
>;

export const Tags: React.FC<TagsProps> = ({ isCampaignTask, ...tagProps }) => (
  <Flex lss={mediaTrackingWrapperLss}>
    <TagsGeneration isCampaignTask={isCampaignTask} {...tagProps} />
    <TagsPreview />
  </Flex>
);
