import React from 'react';

import { Flex, Select } from '@lucidhq/lucidium';
import { Field } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

type Quota = {
  value: string;
  label: string;
};

export const QUOTA_TEMPLATES = {
  open: {
    id: 'open',
    label: 'Natural Fallout'
  },
  census: {
    id: 'census',
    label: 'Census Representation'
  }
};

export const QuotaTemplateField = ({ readOnly }: { readOnly: boolean }) => {
  const options = Object.keys(QUOTA_TEMPLATES).map(k => ({
    value: k,
    label: QUOTA_TEMPLATES[k].label
  }));

  return (
    <Flex lss={{ mt: 'xl' }}>
      <Field
        name="draft_settings.quota_scheme"
        label={t('forms.labels.quotaTemplate')}
        required
        groupLss={{ width: '100%' }}
        render={fieldProps => (
          <Select<Quota, 'value'>
            {...fieldProps}
            placeholder={t('forms.placeholders.quotaTemplate')}
            labelKey="label"
            valueKey="value"
            options={options}
            disabled={readOnly}
          />
        )}
      />
    </Flex>
  );
};
