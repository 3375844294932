import React from 'react';

import {
  Flex,
  LssProp,
  Skeleton,
  SkeletonProps,
  styled
} from '@lucidhq/lucidium';

type SkeletonWrapperProps = SkeletonProps & {
  isLoading: boolean;
  ariaLabel?: string;
  lss?: LssProp;
};

export const SkeletonWrapper: React.FC<SkeletonWrapperProps> = ({
  isLoading,
  width = '100%',
  height = '1rem',
  animation = 'wave',
  variant = 'rect',
  ariaLabel,
  lss = {},
  children
}) =>
  isLoading ? (
    <Flex aria-label={ariaLabel} lss={lss}>
      <Skeleton
        height={height}
        width={width}
        animation={animation}
        variant={variant}
      />
    </Flex>
  ) : (
    <>{children}</>
  );

const BaseLoader = styled(Flex)`
  background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.7) 20%,
      rgba(255, 255, 255, 0) 80%
    ),
    #eeeeee;
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 3s infinite;

  @keyframes shine {
    to {
      background-position: 105% 0, 0 0;
    }
  }
`;

export const SkeletonBar: React.FC<{ lss?: LssProp; ariaLabel?: string }> = ({
  lss,
  ariaLabel
}) => (
  <BaseLoader
    aria-label={ariaLabel}
    lss={{
      width: '100%',
      height: '1.5rem',
      bg: 'gray200',
      borderRadius: 'sm',
      ...lss
    }}
  />
);
