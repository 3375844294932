import { useQuery } from '~/utils/rq';

import { CampaignPixel, PixelTypes } from './useMutateCampaignPixelGroup';

export type PixelGroup = {
  id: number;
  pixel_type: PixelTypes;
  details: CampaignPixel[];
  ad_server_id?: number | string;
  query_params: { [key: string]: string | number | undefined };
};

type CampaignPixelGroupsResponse = {
  items: PixelGroup[];
};

export const useFetchCampaignPixelGroups = (campaignId?: string) => {
  const URL = `/admin/campaigns/${campaignId}/pixel-groups?include_details=true`;

  return useQuery<CampaignPixelGroupsResponse>(
    {
      queryKey: [URL],
      enabled: !!campaignId
    },
    {
      method: 'get',
      url: URL
    }
  );
};
