import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { mediaTrackingRightColLss } from '../../styles';
import { useAssociatedMappings } from '../lib';
import { AssociatedIdSection } from './AssociatedIdSection';

export const MappingPreview: React.FC = () => {
  const { associatedMappings } = useAssociatedMappings();

  return (
    <Flex lss={mediaTrackingRightColLss}>
      {associatedMappings.map(partner => (
        <AssociatedIdSection key={partner.partner_id} partner={partner} />
      ))}
    </Flex>
  );
};
