import React, { SyntheticEvent } from 'react';
import {
  MdArrowUpward,
  MdArrowDownward,
  MdDelete,
  MdLock,
  MdExpandMore
} from 'react-icons/md';

import {
  Flex,
  Icon,
  TransparentButton,
  Text,
  LssProp,
  useToggle
} from '@lucidhq/lucidium';
import { OutlineColorProperty } from '@lucidhq/lucidium/lss/csstypes';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { AnswerSection } from './AnswerSection';
import { PrimaryKpiButton } from './PrimaryKPIButton';
import { QuestionLogicDrawer } from './QuestionLogicDrawer';
import { QuestionTitle } from './QuestionTitle';

type TemplateQuestionCardProps = {
  question: EnhancedTemplateQuestion;
  onDelete?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onSave: (data: Partial<TemplateQuestion>) => void;
  isRequired?: boolean;
  onSetPrimary?: () => void;
  customKPIErrors?: Record<string, any>;
  hasCustomKPIErrors?: boolean;
  index: number;
  readOnly: boolean;
  kpiErrors?: Record<string, any>;
  hasKPIErrors?: boolean;
};

const wrapperLss: LssProp = {
  flexBasis: '100%',
  border: 'regular',
  flexDirection: 'column',
  height: 'auto',
  minHeight: '7.5rem',
  borderRadius: 'md',
  gap: '8px',
  p: 'md'
};

const headerLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 'sm'
};

const expandButtonLss: LssProp = {
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  color: 'gray900',
  minHeight: '40px',
  minWidth: '2rem',
  ':focus': {
    outlineColor: 'hsla(214, 76%, 49%, 0.5)' as OutlineColorProperty
  }
};

const answerSectionWrapperLss: LssProp = {
  flexBasis: '100%',
  maxWidth: '842px',
  p: 'sm',
  paddingLeft: '3rem',
  paddingRight: '3rem',
  width: '100%'
};

export const TemplateQuestionCard = ({
  question,
  onDelete,
  onMoveUp,
  onMoveDown,
  onSave,
  onSetPrimary,
  isRequired = false,
  customKPIErrors,
  hasCustomKPIErrors = false,
  index,
  readOnly,
  kpiErrors,
  hasKPIErrors = false
}: TemplateQuestionCardProps) => {
  const [isOpen, handleToggle] = useToggle(!readOnly);

  return (
    <Flex lss={wrapperLss}>
      <Flex lss={{ flexDirection: 'column', gap: '8px' }}>
        <Flex lss={headerLss}>
          <Flex lss={{ gap: '0.5rem', alignItems: 'center' }}>
            {!readOnly && (
              <Flex
                as="a"
                href="#"
                role="button"
                lss={expandButtonLss}
                onClick={(e: SyntheticEvent) => {
                  e.preventDefault();
                  handleToggle();
                }}
              >
                <Icon
                  as={MdExpandMore}
                  lss={{
                    transform: isOpen ? 'rotate(-180deg)' : 'none',
                    transition: 'fast'
                  }}
                  size={24}
                />
              </Flex>
            )}
            {onSetPrimary && !readOnly && (
              <PrimaryKpiButton
                onClick={onSetPrimary}
                isSelected={question.default}
              />
            )}
            <Text lss={{ fontSize: '1.25rem' }}>
              {question.meta.isCustomKPI
                ? t('campaignManager.survey.customKPI')
                : question.question_name}
            </Text>
          </Flex>
          <Flex lss={{ gap: '0.5rem' }}>
            {isRequired && (
              <Flex
                lss={{
                  gap: '4px',
                  px: 'sm',
                  minHeight: '1.8rem'
                }}
              >
                <Icon
                  as={MdLock}
                  lss={{ color: 'secondary500', pt: 'xxs' }}
                  size={24}
                />{' '}
                <Text lss={{ pt: 'xs' }}>{t('forms.labels.required')}</Text>
              </Flex>
            )}
            {onMoveUp && !readOnly && (
              <TransparentButton onClick={() => onMoveUp()}>
                <Icon
                  as={MdArrowUpward}
                  size={24}
                  lss={{ color: 'secondary500' }}
                />
              </TransparentButton>
            )}
            {onMoveDown && !readOnly && (
              <TransparentButton onClick={() => onMoveDown()}>
                <Icon
                  as={MdArrowDownward}
                  size={24}
                  lss={{ color: 'secondary500' }}
                />
              </TransparentButton>
            )}
            {onDelete && !readOnly && (
              <TransparentButton onClick={() => onDelete()}>
                <Icon as={MdDelete} size={24} lss={{ color: 'secondary500' }} />
              </TransparentButton>
            )}
          </Flex>
        </Flex>
        <Flex lss={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
          <QuestionTitle
            question={question}
            onSave={onSave}
            customKPIErrors={customKPIErrors}
            hasCustomKPIErrors={hasCustomKPIErrors}
            index={index}
            readOnly={readOnly}
            kpiErrors={kpiErrors}
            hasKPIErrors={hasKPIErrors}
          />
        </Flex>
      </Flex>
      {isOpen && (
        <Flex
          lss={{
            height: 'fit-content',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <Flex lss={answerSectionWrapperLss}>
            <AnswerSection
              question={question}
              onSave={onSave}
              hasCustomKPIErrors={hasCustomKPIErrors}
              customKPIErrors={customKPIErrors}
              //NOTE: this is primarily used for the custom Answers field level validation
              questionIndex={index}
            />
          </Flex>
          <QuestionLogicDrawer question={question} onSave={onSave} />
        </Flex>
      )}
    </Flex>
  );
};
