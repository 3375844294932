import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';

import {
  Flex,
  Button,
  TransparentButton,
  Icon,
  LssProp
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type ButtonProps = {
  onClick: () => void;
  isDisabled?: boolean;
  type?: 'submit' | 'button';
};

type CampaignManagerFooterProps = {
  onBack: () => void;
  saveCloseProps?: ButtonProps;
  nextProps?: ButtonProps;
  skipProps?: ButtonProps;
  saveContinueProps?: ButtonProps;
};

const campaignFooterContainerLss: LssProp = {
  flex: '1 1 100%',
  height: '3rem',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '10rem',
  my: 'xxl'
};

const backButtonLss: LssProp = {
  display: 'flex',
  alignItems: 'center',
  justifySelf: 'flex-start',
  p: 'xs',
  color: 'gray800'
};

const skipAndNextButtonLss: LssProp = {
  display: 'flex',
  alignSelf: 'center',
  borderRadius: 'sm'
};

export const CampaignManagerFooter = ({
  onBack,
  skipProps,
  nextProps,
  saveCloseProps,
  saveContinueProps
}: CampaignManagerFooterProps) => {
  return (
    <Flex lss={campaignFooterContainerLss}>
      <TransparentButton onClick={onBack} lss={backButtonLss} type={'button'}>
        <Icon
          as={MdArrowBackIosNew}
          size={12}
          lss={{ mr: 'sm', alignSelf: 'center', color: 'gray800' }}
        />{' '}
        {t(`campaignManager.buttonTexts.back`)}
      </TransparentButton>
      <Flex>
        {saveCloseProps && (
          <Button
            emphasis="medium"
            lss={{ marginRight: '1rem' }}
            disabled={saveCloseProps.isDisabled}
            onClick={saveCloseProps.onClick}
            type={saveCloseProps.type || 'button'}
          >
            {t(`campaignManager.buttonTexts.saveClose`)}
          </Button>
        )}
        {skipProps && (
          <Button
            disabled={skipProps.isDisabled}
            onClick={skipProps.onClick}
            type={skipProps.type || 'button'}
            lss={skipAndNextButtonLss}
          >
            {t(`campaignManager.buttonTexts.skip`)}
          </Button>
        )}
        {nextProps && (
          <Button
            disabled={nextProps.isDisabled}
            onClick={nextProps.onClick}
            type={nextProps.type || 'button'}
            lss={skipAndNextButtonLss}
          >
            {t(`campaignManager.buttonTexts.next`)}
          </Button>
        )}
        {saveContinueProps && (
          <Button
            disabled={saveContinueProps.isDisabled}
            onClick={saveContinueProps.onClick}
            type={saveContinueProps.type || 'button'}
            lss={skipAndNextButtonLss}
          >
            {t(`campaignManager.buttonTexts.saveContinue`)}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
