import React from 'react';

import { Select, Label, Text, Flex } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

import { useFetchIndustries } from '~/components/Shared/hooks/useFetchIndustries';
import { InfoTooltip } from '~/impact-measurement/components';
import { EMPTY_ITEMS } from '~/impact-measurement/constants';
import { t } from '~/utils/i18n';

type IndustryFieldProps = {
  name: string;
  readOnly: boolean;
};

const getIndustryOptions = (industries: Industry[]): NumberValueOption[] =>
  industries.map(industry => ({
    value: industry.id,
    label: industry.industry_name
  }));

export const IndustryField = ({ name, readOnly }: IndustryFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { isLoading, data = EMPTY_ITEMS, error } = useFetchIndustries();
  const industryOptions = getIndustryOptions(data.items);

  return (
    <Flex id="industry-field" lss={{ flexDirection: 'column', my: 'md' }}>
      <Text
        lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
        as="strong"
      >
        {t(`campaignManager.campaignDetails.questions.industry`)}
      </Text>
      <Flex lss={{ flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
        <Label lss={{ my: 'sm' }} required>
          {t(`forms.labels.industry`)}
        </Label>
        <InfoTooltip
          title={t('campaignManager.campaignDetails.tooltips.industry')}
          placement="top-start"
          iconLss={{ height: '1.125rem', width: '1.125rem' }}
        />
      </Flex>

      <Select
        name={field.name}
        error={meta.error}
        value={field.value}
        id="industry"
        disabled={!!error || readOnly}
        placeholder={
          isLoading
            ? t(`forms.placeholders.loadingIndustry`)
            : t(`forms.placeholders.industry`)
        }
        labelKey="label"
        valueKey="value"
        options={industryOptions}
        onChange={(option: any) => {
          helpers.setValue(option, false);
        }}
      />
    </Flex>
  );
};
