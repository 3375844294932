import React, { useMemo } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  useToggle
} from '@lucidhq/lucidium';

import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { DraftTargetAudience } from '~/types';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { AudienceFilterForm } from './AudienceFilterForm';

type ManageAudienceFilterProps = {
  id?: number;
  onSave: () => void;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const ManageAudienceFilter = ({
  children,
  onSave,
  id
}: ManageAudienceFilterProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const { managedCampaign, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const initialValues = useMemo(() => {
    if (!managedCampaign || id === undefined) return;
    return managedCampaign.details.draft_settings.target_audiences?.[id];
  }, [managedCampaign, id]);

  const currentTargets =
    managedCampaign?.details.draft_settings.target_audiences || [];

  const handleSubmit = (targetAudience: DraftTargetAudience) => {
    if (!managedCampaign) return;
    let targetAudiences: DraftTargetAudience[] = [];

    // reset default state of current targets
    const updatedTargets = targetAudience.is_default
      ? currentTargets.map(t => ({ ...t, is_default: false }))
      : currentTargets;

    if (id !== undefined) {
      targetAudiences = updatedTargets.map((target, k) => {
        return k !== id ? target : targetAudience;
      });
    } else {
      targetAudiences = [...updatedTargets, targetAudience];
    }

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details?.draft_settings,
          target_audiences: targetAudiences
        }
      },
      {
        onSuccess: () => {
          onSave();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog isOpen={isOpen} onClose={handleToggle} lss={{ width: '48rem' }}>
        <DialogHeader palette="disabled" onClose={handleToggle}>
          {initialValues?.target_name ||
            t('campaignManager.targetAudience.audienceFilters.form.create')}
        </DialogHeader>
        <DialogContent lss={{ minHeight: '38rem' }}>
          <AudienceFilterForm
            initialValues={initialValues}
            isLoading={isLoading}
            onCancel={handleToggle}
            onSubmit={handleSubmit}
            hasDefault={currentTargets?.some(
              t => t.is_default && !initialValues?.is_default
            )}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
