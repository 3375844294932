import React from 'react';

import { ConfirmDialog, useToggle } from '@lucidhq/lucidium';

import { useDeleteS2sMappings } from '~/components/Shared/hooks/useMutateS2SMappings';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type DeleteS2SMappingProps = {
  campaignId: string;
  creativeId: string;
  partnerId: number;
  children: ({
    onDelete,
    isLoading
  }: {
    onDelete: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
  onDelete: () => void;
};

export const DeleteS2SMapping = ({
  campaignId,
  creativeId,
  partnerId,
  children,
  onDelete
}: DeleteS2SMappingProps) => {
  const { onMutateError } = useCampaignManager();
  const [isOpen, handleToggle] = useToggle(false);
  const { mutate, isLoading } = useDeleteS2sMappings(campaignId, creativeId);

  const handleDelete = () => {
    mutate(
      { partner_id: partnerId },
      {
        onSuccess: () => {
          onDelete();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onDelete: handleToggle, isLoading })}
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleDelete}
        onClose={handleToggle}
      >
        {t('campaignManager.mediaTracking.delete.description')}
      </ConfirmDialog>
    </>
  );
};
