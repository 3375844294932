import React, { useState } from 'react';
import { MdDelete, MdOutlineStar, MdOutlineStarBorder } from 'react-icons/md';

import { Flex, Icon, Label, LssProp, TextInput } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

import { FieldErrorMessage } from '~/components/Shared/FieldErrorMessage';
import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import {
  EnhancedTemplateQuestion,
  updateMultiLevelInTargetAnswers,
  findInTargetGridAnswer
} from '~/utils/templateQuestions';

const fieldWrapperLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mb: 'xxs',
  paddingLeft: '2.5rem'
};

const targetAnswerWrapperLss: LssProp = {
  display: 'flex',
  alignItems: 'center',
  p: 'xs',
  borderRadius: 'xl'
};

const deleteAnswerLss: LssProp = {
  flexBasis: '2rem',
  height: '2rem',
  justifyContent: 'center',
  textDecoration: 'none',
  color: 'secondary500',
  p: 'xxs'
};

type CustomGridAnswerFieldProps = {
  index: number;
  questionIndex?: number;
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  handleRemoveItem: (index: number) => void | undefined;
  useAnswerMeta: boolean;
  readOnly?: boolean;
  hasCustomKPIErrors?: boolean;
  customKPIErrors?: Record<string, any>;
  fieldName: 'rows' | 'columns';
};

const getFieldValues = (
  question: EnhancedTemplateQuestion,
  fieldName: 'rows' | 'columns'
) => {
  if (
    fieldName === 'rows' &&
    !!question?.customRows &&
    !!question?.customRows?.length
  ) {
    return question.customRows;
  }

  if (
    fieldName === 'columns' &&
    !!question.columns &&
    !!question.columns.length
  ) {
    return question.columns;
  }

  return [];
};

export const CustomGridAnswerField = ({
  index,
  question,
  questionIndex,
  onSave,
  handleRemoveItem,
  useAnswerMeta,
  readOnly,
  hasCustomKPIErrors,
  customKPIErrors,
  fieldName
}: CustomGridAnswerFieldProps) => {
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [field, , helpers] = useField(`[${fieldName}.${index}]`);
  const isRequired = index <= 1;

  const selectedGridAnswerOptions = !!question?.multi_level_target_option_index
    ?.length
    ? question.multi_level_target_option_index
    : [];

  const isSelected = findInTargetGridAnswer(
    fieldName,
    selectedGridAnswerOptions,
    index
  );

  const isDefaultBrandValues =
    !!question?.use_answer_meta && fieldName === 'rows';

  const fieldValues = getFieldValues(question, fieldName);

  const inputValue =
    !useAnswerMeta && !!fieldValues?.length
      ? fieldValues[index]?.text
      : field.value.text;

  const hasError =
    hasCustomKPIErrors &&
    !!customKPIErrors?.[`customKPI${questionIndex}`]?.[`${fieldName}${index}`];

  const customFieldName = fieldName === 'rows' ? 'customRows' : 'customColumns';

  return (
    <Flex lss={{ flexDirection: 'column', my: 'md', gap: '4px' }}>
      <Flex
        lss={{
          ...fieldWrapperLss,
          paddingLeft: !!question.isBehavioralCustomKPI ? 'none' : '2.5rem'
        }}
      >
        {!isDefaultBrandValues && (
          <Label lss={{ my: 'sm' }} required={isRequired}>
            {t(`forms.labels.customAnswerX`, { index: index + 1 })}
          </Label>
        )}

        {!isRequired && !isDefaultBrandValues && (
          <Flex
            as="a"
            href="#"
            role="button"
            aria-label={t(
              `campaignManager.survey.brands.ariaLabels.deleteField`,
              { field: fieldName, index: index + 1 }
            )}
            lss={deleteAnswerLss}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const incomingAnswers = [...fieldValues];
              incomingAnswers.splice(index, 1);

              handleRemoveItem(index);

              onSave({
                [fieldName]: incomingAnswers,
                [customFieldName]: incomingAnswers
              });
            }}
          >
            <Icon as={MdDelete} size={28} />
          </Flex>
        )}
      </Flex>

      <Flex
        lss={{
          flexDirection: 'row',
          gap: '0.5rem',
          flexBasis: '100%',
          alignItems: 'center',
          width: '100%',
          maxWidth: '744px'
        }}
      >
        {!question?.isBehavioralCustomKPI && (
          <Flex
            as="a"
            href="#"
            role="button"
            lss={{
              ...targetAnswerWrapperLss,
              ...(!isSelected && readOnly && { visibility: 'hidden' }),
              ...(isSelected && readOnly && { cursor: 'default' })
            }}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              const gridIndices = !!question?.multi_level_target_option_index
                ?.length
                ? question.multi_level_target_option_index
                : [];
              updateMultiLevelInTargetAnswers(index, fieldName, gridIndices);

              onSave({
                multi_level_target_option_index: updateMultiLevelInTargetAnswers(
                  index,
                  fieldName,
                  gridIndices
                )
              });
            }}
          >
            <Icon
              as={isSelected ? MdOutlineStar : MdOutlineStarBorder}
              size={22}
              lss={{ color: 'secondary500' }}
            />
          </Flex>
        )}

        <TextInput
          required={isRequired}
          value={inputValue}
          onChange={e => {
            const fieldAnswers = [...fieldValues];

            const action = {
              order: index,
              text: e.target.value,
              title: e.target.value
            };

            fieldAnswers[index] = action;

            helpers.setValue(action);

            onSave({
              [fieldName]: fieldAnswers,
              [customFieldName]: fieldAnswers
            });
          }}
          onBlur={e => {
            setDirty(true);
            setValid(e.target.value !== '');
          }}
          lss={{
            p: 'none',
            width: '100%',
            maxWidth: '46rem'
          }}
        />
      </Flex>
      {hasError && isDirty && !isValid && (
        <FieldErrorMessage
          message={t('forms.errors.answerX', { index: index + 1 })}
          lss={{
            paddingLeft: question?.isBehavioralCustomKPI ? 'none' : '2.5rem'
          }}
        />
      )}
    </Flex>
  );
};
