import React from 'react';

import { FileUploader, Text } from '@lucidhq/lucidium';
import { CsvBasicIcon } from '@lucidhq/lucidium/icons';

type SiteServedBulkUploaderProps = {
  handleSelection: (file: File) => void;
};

export const SiteServedBulkUploader: React.FC<SiteServedBulkUploaderProps> = ({
  handleSelection
}) => (
  <FileUploader
    isUploading={false}
    onFileSelection={handleSelection}
    fileIcon={() => <CsvBasicIcon lss={{ color: 'secondary500' }} />}
    acceptedFileTypes=".csv"
    dragLabel={
      <Text lss={{ color: 'secondary500', my: 'none', fontWeight: 'bold' }}>
        Drag & Drop CSV Here
      </Text>
    }
    selectLabel={
      <Text
        lss={{
          color: 'secondary500',
          my: 'none',
          textDecoration: 'underline'
        }}
      >
        Choose CSV File
      </Text>
    }
  />
);
