import React from 'react';

import { Flex, Text } from '@lucidhq/lucidium';

import { AddOrRemoveButton } from '~/impact-measurement/components';

import { appendContainerLss, macroCountLss } from '../../styles';

type MacroFieldAppendProps = {
  isCustom: boolean;
  isBulk: boolean;
  onRemove: () => void;
  macroSize: number;
};

export const MacroFieldAppend: React.FC<MacroFieldAppendProps> = ({
  isCustom,
  macroSize,
  onRemove,
  isBulk
}) => (
  <Flex lss={appendContainerLss(isBulk)}>
    {isCustom && !isBulk && (
      <AddOrRemoveButton type="remove" onClick={onRemove} size={20} />
    )}
    {isBulk && <Text lss={macroCountLss}>1 of {macroSize} values</Text>}
  </Flex>
);
