import React, { useState } from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { addNotification, Notification } from '@lucidhq/lucidium';

import { MediaTrackingToolsContext } from '~/impact-measurement/pages/Admin/Contexts';
import { useApiFetch } from '~/utils/fetch';

import { useAssociatedMappings } from '.';
import { URLS } from '../../../constants';
import { handleDeleteMapping } from './handleDeleteMapping';

export const useDeleteTvMappingId = (partnerId: LinearTv['partner_id']) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const { campaignId } = useParams<{ campaignId: string }>();

  const apiFetch = useApiFetch();

  const { setSavedMappings } = useContext(MediaTrackingToolsContext);

  const { associatedMappings } = useAssociatedMappings();

  const deleteMapping = async (partnerCampaignId: string) => {
    const json = { partner_id: partnerId };

    setIsDeleting(true);

    const { error, response } = await apiFetch<LinearTvDeleteResponseItem>({
      url: URLS.linearTvMappingDelete(campaignId, partnerCampaignId),
      method: 'DELETE',
      json
    });

    if (error) {
      addNotification(
        <Notification palette="danger">{error.message}</Notification>
      );
      setIsDeleting(false);
    }

    if (response?.value) {
      addNotification(
        <Notification palette="success">
          ID Deleted - {response.value}!
        </Notification>
      );

      handleDeleteMapping(
        associatedMappings,
        partnerId,
        response.value,
        setSavedMappings
      );
      setIsDeleting(false);
    }
  };

  return { deleteMapping, isDeleting };
};
