import React, { useMemo } from 'react';

import { ClipboardButton, Flex, LssProp, Text } from '@lucidhq/lucidium';

import { usePermissions } from '~/components/Permissions/PermissionsProvider';
import { useFetchAccounts } from '~/components/Shared/hooks/useFetchAccounts';
import { useFetchCountryLanguages } from '~/components/Shared/hooks/useFetchCountryLanguages';
import { useFetchIndustries } from '~/components/Shared/hooks/useFetchIndustries';
import { useFetchProjectManagers } from '~/components/Shared/hooks/useFetchProjectManagers';
import { useFetchSalesforce } from '~/components/Shared/hooks/useFetchSalesforce';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { Colors, ReviewLayout } from '../Misc/ReviewLayout';

const getDate = (date: string) => date.substring(0, 10).replace(/-/g, '/');

const headingLss: LssProp = {
  fontSize: '1.1rem',
  fontWeight: 'bold',
  mb: 'sm'
};

const valueLss: LssProp = {
  fontSize: '1.1rem'
};

const formatImpressions = (n: number) => n && n.toLocaleString();

export const CampaignBlock = () => {
  const {
    managedCampaign,
    goToStep,
    steps,
    can,
    isService
  } = useCampaignManager();
  const { data: industries } = useFetchIndustries();
  const { data: languages } = useFetchCountryLanguages();
  const { isAdmin } = usePermissions();
  //@NOTE: these are internal user only fields for now
  const { data: accounts } = useFetchAccounts({ isEnabled: isAdmin });
  const { data: salesforceOpps } = useFetchSalesforce({
    value: managedCampaign?.details.salesforce_id,
    isEnabled: isAdmin
  });
  const { data: projectManagers } = useFetchProjectManagers({ isService });

  const industry = useMemo(() => {
    if (!industries) return '';
    const item = industries.items.find(
      ind => ind.id === managedCampaign?.details.industry_id
    );

    return item?.industry_name;
  }, [industries, managedCampaign]);

  const language = useMemo(() => {
    if (!languages) return null;
    const item = languages.items.find(
      lang => lang.id === managedCampaign?.details.country_language_id
    );

    return item;
  }, [languages, managedCampaign]);

  const account = useMemo(() => {
    if (!accounts) return null;
    const item = accounts?.items.find(
      acc => acc.permission_id === managedCampaign?.details.permission_id
    );

    return item;
  }, [accounts, managedCampaign]);

  const salesforceName = useMemo(() => {
    if (!salesforceOpps) return null;
    const item = salesforceOpps?.items.find(
      opp => opp.salesforce_id === managedCampaign?.details.salesforce_id
    );

    return item?.salesforce_name;
  }, [salesforceOpps, managedCampaign]);

  const projectManager = useMemo(() => {
    if (!projectManagers) return null;
    const item = projectManagers.items.find(
      pm =>
        pm.project_manager_user_id ===
        managedCampaign?.details.project_manager_user_id
    );

    return item;
  }, [projectManagers, managedCampaign]);

  const meta = managedCampaign?.details.meta;

  const trackedMedia = [];
  if (meta?.isDigital)
    trackedMedia.push([
      t('forms.labels.digital'),
      managedCampaign?.details.draft_settings.expected_impressions_count
    ]);
  if (meta?.isLinearTV)
    trackedMedia.push([
      t('forms.labels.linear_tv'),
      managedCampaign?.details.draft_settings
        .expected_impressions_count_linear_tv
    ]);
  if (meta?.isSocial)
    trackedMedia.push([
      t('forms.labels.social'),
      managedCampaign?.details.draft_settings.expected_impressions_count_social
    ]);

  return (
    <ReviewLayout.Content color={Colors.BLUE}>
      {(can.edit || can.limitedEdit) && (
        <ReviewLayout.Edit onClick={() => goToStep(steps.DETAILS.id)} />
      )}
      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.campaignId')}
        </Text>

        <Flex lss={{ alignItems: 'center' }}>
          <Text lss={valueLss}>{managedCampaign?.details.campaign_id}</Text>
          <ClipboardButton
            buttonText={t('campaignManager.review.campaign.clipboard.label')}
            copyText={managedCampaign?.details.campaign_id}
            iconPosition="left"
            showIcon
            tooltipText={t('campaignManager.review.campaign.clipboard.tooltip')}
            emphasis="low"
            lss={{
              display: 'flex',
              padding: '.25rem',
              marginLeft: '0.5rem'
            }}
          />
        </Flex>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.campaignName')}
        </Text>
        <Text lss={valueLss}>{managedCampaign?.details.campaign_name}</Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.dateRange')}
        </Text>
        <Text lss={valueLss}>
          {managedCampaign?.details &&
            `${getDate(managedCampaign?.details.start_date)} - ${getDate(
              managedCampaign?.details.end_date
            )}`}
        </Text>
      </ReviewLayout.Item>

      {isAdmin && !!account?.group_name && (
        <ReviewLayout.Item>
          <Text lss={headingLss}>
            {t('campaignManager.review.campaign.items.account')}
          </Text>
          <Text lss={valueLss}>{account?.group_name}</Text>
        </ReviewLayout.Item>
      )}

      {isAdmin && (
        <>
          <ReviewLayout.Item>
            <Text lss={headingLss}>
              {t('campaignManager.review.campaign.items.projectManager')}
            </Text>
            <Text lss={valueLss}>
              {projectManager
                ? projectManager.full_name
                : t('campaignManager.review.campaign.items.na')}
            </Text>
          </ReviewLayout.Item>

          <ReviewLayout.Item>
            <Text lss={headingLss}>
              {t('campaignManager.review.campaign.items.salesforce')}
            </Text>
            <Text lss={valueLss}>
              {salesforceName ?? t('campaignManager.review.campaign.items.na')}
            </Text>
          </ReviewLayout.Item>
        </>
      )}

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.surveyLanguage')}
        </Text>
        <Text lss={valueLss}>
          {language?.country_name} - {language?.language_name}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.brandIndustry')}
        </Text>
        <Text lss={valueLss}>{industry}</Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.trackedMedia')}
        </Text>
        <Text lss={valueLss}>
          {trackedMedia.map(([label, count], index) => (
            <React.Fragment key={index}>
              {index !== 0 && <br />}
              {label}: {formatImpressions(count)}
            </React.Fragment>
          ))}
        </Text>
      </ReviewLayout.Item>

      {!!meta?.isLinearTV && (
        <ReviewLayout.Item>
          <Text lss={headingLss}>
            {t('campaignManager.review.campaign.items.mediaPartner')}
          </Text>

          <Text lss={valueLss}>
            {t(`forms.labels.${meta?.selectedDataPartner}`)}
          </Text>
        </ReviewLayout.Item>
      )}

      {!!(meta?.isSocial && meta.socialPlatformsLabel) && (
        <ReviewLayout.Item>
          <Text lss={headingLss}>
            {t('campaignManager.review.campaign.items.socialPlatforms')}
          </Text>
          <Text lss={valueLss}>{meta.socialPlatformsLabel}</Text>
        </ReviewLayout.Item>
      )}
    </ReviewLayout.Content>
  );
};
