import React from 'react';

import { ConfirmDialog, useToggle } from '@lucidhq/lucidium';

import { useDeleteCampaignPixelGroup } from '~/components/Shared/hooks/useMutateCampaignPixelGroup';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type DeletePixelGroupProps = {
  campaignId: string;
  id: string;
  children: ({
    onDelete,
    isLoading
  }: {
    onDelete: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
  onDelete: () => void;
};

export const DeletePixelGroup = ({
  campaignId,
  id,
  children,
  onDelete
}: DeletePixelGroupProps) => {
  const { onMutateError } = useCampaignManager();
  const [isOpen, handleToggle] = useToggle(false);
  const { mutate, isLoading } = useDeleteCampaignPixelGroup(campaignId, id);

  const handleDelete = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          onDelete();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onDelete: handleToggle, isLoading })}
      <ConfirmDialog
        isOpen={isOpen}
        onConfirm={handleDelete}
        onClose={handleToggle}
      >
        {t('campaignManager.mediaTracking.delete.description')}
      </ConfirmDialog>
    </>
  );
};
