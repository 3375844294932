import { URLS as SHARED_URLS } from '~/impact-measurement/constants';
import { encodeQueryData } from '~/impact-measurement/utils';

/**
 *
 * Deprecation Notice
 *
 * Once CSV export report UX has changed the getQueryStrings, and the exportCsv URL, methods can be removed.
 */

const getQueryStrings = (filtersValues: FiltersValues) =>
  !!Object.keys(filtersValues).length
    ? `?${encodeQueryData(filtersValues)}`
    : '';

export const URLS = {
  campaignExport: (campaignId: CampaignId, exportType = '') =>
    `${SHARED_URLS.campaign(campaignId)}/export/${exportType}`,
  exportCsv: (campaignId: CampaignId, filtersValues: FiltersValues) =>
    `${SHARED_URLS.campaign(campaignId)}/export${getQueryStrings(
      filtersValues
    )}`
};
