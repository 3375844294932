import React from 'react';

import {
  Card,
  Flex,
  Text,
  Box,
  TransparentButton,
  useBreakpoint,
  LssProp
} from '@lucidhq/lucidium';

const landingCardLss: LssProp = {
  textAlign: 'center',
  boxShadow: 'xs',
  border: 'none'
};

const landingCardContentLss: LssProp = {
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  py: 'xxl'
};

const landingCardBtnLss: LssProp = {
  height: 'fit-content',
  width: 'fit-content',
  m: 'md',
  px: 'none'
};

const landingCardTitleLss: LssProp = {
  textScale: 'heading4',
  mt: 'md',
  color: 'primary500',
  mb: 'sm'
};

const landingCardDescriptionLss: LssProp = {
  mt: 'xs',
  textScale: 'control',
  fontSize: '1rem',
  color: 'primary500',
  px: 'xxxl'
};

type LandingCardBtnProps = {
  name: string;
  description: string;
  icon?: React.FC<any>;
  disabled?: boolean;
  children?: React.ReactNode;
  handleClick: () => void;
  cardContentLss?: LssProp;
};

export const LandingCardBtn: React.FC<LandingCardBtnProps> = ({
  name,
  description,
  icon: Icon,
  disabled = false,
  handleClick,
  cardContentLss
}) => {
  const isTablet = useBreakpoint('tablet');

  return (
    <TransparentButton
      disabled={disabled}
      lss={landingCardBtnLss}
      onClick={handleClick}
    >
      <Card
        lss={{
          ...landingCardLss,
          height: isTablet ? '18.5rem' : '16.5rem',
          width: isTablet ? '23.75rem' : '20.75rem',
          opacity: disabled ? 0.5 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
          ...(!Icon && { height: 'auto' })
        }}
      >
        <Flex lss={{ ...landingCardContentLss, ...cardContentLss }}>
          {Icon && (
            <Box lss={{ ml: 'sm', mb: name === 'Admin' ? 'sm' : 'none' }}>
              <Icon disabled={disabled} />
            </Box>
          )}
          <Text as="h2" lss={landingCardTitleLss}>
            {name}
          </Text>
          <Text as="p" lss={landingCardDescriptionLss}>
            {description}
          </Text>
        </Flex>
      </Card>
    </TransparentButton>
  );
};
