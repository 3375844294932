import { useQuery } from '~/utils/rq';

type ProjectManager = {
  email: string;
  full_name: string;
  project_manager_user_id: number;
};

type ProjectManagersResponse = {
  items: ProjectManager[];
};

type UseFetchProjectManagersProps = {
  isService: boolean;
};

export const useFetchProjectManagers = ({
  isService
}: UseFetchProjectManagersProps) => {
  const productType = isService ? 'services' : 'software';
  const URL = `admin/project-managers?product_type=${productType}`;

  return useQuery<ProjectManagersResponse>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60
    },
    {
      method: 'get',
      url: URL
    }
  );
};
