import React from 'react';

import { Footer, LssProp } from '@lucidhq/lucidium';

import { Container, HeaderNav } from './';

type PageProps = {
  children: React.ReactNode;
  isAdmin?: boolean;
  hideProductName?: boolean;
  containerLss?: LssProp;
};

const containerOffsetHeight = 150; // Header + Footer height

export const Page: React.FunctionComponent<PageProps> = ({
  children,
  hideProductName,
  ...props
}) => {
  return (
    <>
      <HeaderNav productName={''} hideProductName={hideProductName} />
      <Container
        {...props}
        offsetHeight={containerOffsetHeight}
        lss={{ ...props.containerLss }}
      >
        {children}
      </Container>
      <Footer />
    </>
  );
};
