export enum CHANNELS {
  'linear_tv' = 'linear_tv',
  digital = 'digital',
  social = 'social'
}
export const MIN_DIGITAL_IMPRESSIONS = 1_000_000;
export const MIN_LINEAR_TV_IMPRESSIONS = 50_000_000;

export const getUpdatedChannels = (channels: string[], channelType: string) =>
  !!channels.length && channels.includes(channelType)
    ? channels.filter(type => type !== channelType)
    : [...channels, channelType];

export const getTrackedMediaStates = (
  channels: string[],
  digitalImpressions?: number,
  tvImpressions?: number,
  socialImpressions?: number
) => {
  const isDigitalChecked = channels.includes(CHANNELS.digital);
  const isLinearTVChecked = channels.includes(CHANNELS.linear_tv);
  const isSocialChecked = channels.includes(CHANNELS.social);

  const showDigitalError =
    isDigitalChecked &&
    (!digitalImpressions || digitalImpressions < MIN_DIGITAL_IMPRESSIONS);

  const showTvError =
    isLinearTVChecked &&
    (!tvImpressions || tvImpressions < MIN_LINEAR_TV_IMPRESSIONS);

  const showSocialError =
    isSocialChecked &&
    (!socialImpressions || socialImpressions < MIN_DIGITAL_IMPRESSIONS);

  return {
    isChecked: {
      digital: isDigitalChecked,
      linear_tv: isLinearTVChecked,
      social: isSocialChecked
    },
    showError: {
      digital: showDigitalError,
      linear_tv: showTvError,
      social: showSocialError
    }
  };
};
