import React, { useState } from 'react';

import { Checkbox } from '@lucidhq/lucidium/components';

import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { useBehavioralQuestions } from './BehavioralQuestionsProvider';

type DisableUsageCheckboxProps = {
  value: boolean;
};

const DISABLE_BASELINE_VALIDATION = 'isBaselineValidationDisabled';

export const DisableBaselineValidationCheckbox = ({
  value = false
}: DisableUsageCheckboxProps) => {
  const [isChecked, setChecked] = useState<boolean>(value);
  const { managedCampaign } = useCampaignManager();
  const { setPendingQuestions } = useBehavioralQuestions();

  return (
    <Checkbox
      name={DISABLE_BASELINE_VALIDATION}
      label={t('forms.labels.baselineQuestionsOverride')}
      checked={isChecked}
      onChange={() => {
        setPendingQuestions(true);
        setChecked(!isChecked);
        if (!!managedCampaign?.details) {
          managedCampaign.details.draft_settings.ui = {
            ...managedCampaign?.details?.draft_settings?.ui,
            isBaselineValidationDisabled: !isChecked
          };
        }
      }}
    />
  );
};
