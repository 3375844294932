import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';

import {
  DataTable,
  Column,
  useDataSort,
  Toggle,
  Flex,
  LssProp
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { IPMatchedID, useIPMatchingContext } from '../IPMatchingProvider';
import { IDsTableColHead } from './IDsTableColHead';
import { NoMatchedIDsMessage } from './NoMatchedIDsMessage';

const tableContainerLss: LssProp = {
  width: '100%',
  px: 'lg',
  maxHeight: '78rem',
  overflow: 'auto'
};

const AUTO = 'Auto';
const MANUAL = 'Manual';

const getStatusLabel = (cellData: string | null) => {
  return cellData === null || cellData === 'migration' ? AUTO : MANUAL;
};

export const IDsTable = () => {
  const [toggleStates, setToggleStates] = useState<Record<string, boolean>>({});
  const { matchedIds, updatedIds } = useIPMatchingContext();
  // TODO: once update logic is in place revisit this solution.
  const ids = !!updatedIds?.length ? updatedIds : matchedIds;

  const [sortedData, sortBy, sortOrder, onSort] = useDataSort(
    ids,
    'added_at',
    'asc',
    true
  );

  // TODO: eval if this effect is still needed
  useEffect(() => {
    // Initialize toggle states from data
    const initialToggles = matchedIds.reduce((acc, curr) => {
      if (curr.ip_matching_id !== undefined) {
        acc[curr.ip_matching_id] = curr.is_enabled ?? false; // Default to false if status is undefined
      }
      return acc;
    }, {} as Record<string, boolean>);

    // Set the initial toggle states
    setToggleStates(initialToggles);
  }, [matchedIds]);

  const handleToggleChange = (ipID: string) => {
    if (!ipID) return;

    const updatedStatus = !toggleStates[ipID];

    // Update the local state with the new status
    setToggleStates(prev => ({
      ...prev,
      [ipID]: updatedStatus
    }));
  };

  return (
    <>
      {!!matchedIds?.length ? (
        <Flex lss={tableContainerLss}>
          <DataTable
            data={sortedData}
            onSort={onSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
            rowKeySelector="ip_matching_id"
            hasFooterRow={false}
          >
            <Column<IPMatchedID, 'ip_matching_type'>
              dataKey="ip_matching_type"
              label={t('ipMatching.labels.idType')}
              cellRenderer={cellData => {
                return cellData.includes('placement')
                  ? t('ipMatching.labels.placement')
                  : t('ipMatching.labels.site');
              }}
            />
            <Column<IPMatchedID, 'ip_matching_id'>
              dataKey="ip_matching_id"
              label={t('ipMatching.labels.ipID')}
            />
            <Column<IPMatchedID, 'enablement_status'>
              dataKey="enablement_status"
              label={
                <IDsTableColHead
                  label={t('ipMatching.labels.ipMatch')}
                  tooltip={t('ipMatching.tooltips.ipMatch')}
                />
              }
              cellRenderer={cellData => getStatusLabel(cellData)}
            />
            <Column<IPMatchedID, 'added_at'>
              dataKey="added_at"
              label={
                <IDsTableColHead
                  label={t('ipMatching.labels.dateAdded')}
                  tooltip={t('ipMatching.tooltips.dateAdded')}
                />
              }
              cellRenderer={cellData =>
                format(new Date(cellData), 'yyyy/dd/MM')
              }
            />
            <Column<IPMatchedID, 'is_enabled'>
              dataKey="is_enabled"
              label={
                <IDsTableColHead
                  label={t('ipMatching.labels.status')}
                  tooltip={t('ipMatching.tooltips.status')}
                />
              }
              cellRenderer={(_, rowData) => (
                <Toggle
                  onChange={() =>
                    handleToggleChange(rowData.ip_matching_id || '')
                  }
                  value={toggleStates[rowData.ip_matching_id || '']}
                  scale="sm"
                  lss={{ borderRadius: 'lg' }}
                />
              )}
            />
          </DataTable>
        </Flex>
      ) : (
        <NoMatchedIDsMessage />
      )}
    </>
  );
};
