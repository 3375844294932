import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { mediaTrackingWrapperLss } from '../styles';
import { MappingGeneration } from './MappingGeneration';
import { MappingPreview } from './MappingPreview';

type MappingProps = {
  isCampaignTask?: boolean;
};

export const Mapping: React.FC<MappingProps> = ({ isCampaignTask = false }) => (
  <Flex lss={mediaTrackingWrapperLss}>
    <MappingGeneration isCampaignTask={isCampaignTask} />
    <MappingPreview />
  </Flex>
);
