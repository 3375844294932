import React from 'react';

import { Flex, Select } from '@lucidhq/lucidium';
import { Label, useField } from '@lucidhq/lucidium/components/FormV2';

import { useFetchAccounts } from '~/components/Shared/hooks/useFetchAccounts';
import { t } from '~/utils/i18n';

const GROUP_NAME = 'group_name';
const PERMISSION_ID = 'permission_id';

export const AccountField = ({ readOnly }: { readOnly: boolean }) => {
  const [field, meta, helpers] = useField(PERMISSION_ID);
  const { data, error, isLoading, isFetching } = useFetchAccounts({
    isEnabled: true
  });

  return (
    <Flex id="account-field" lss={{ flexDirection: 'column', pb: 'xl' }}>
      <Label lss={{ my: 'md' }} htmlFor={field.name} required>
        {t(`forms.labels.account`)}
      </Label>
      <Select
        required
        name={field.name}
        value={field?.value}
        error={meta.error}
        disabled={!!error || readOnly}
        labelKey={GROUP_NAME}
        valueKey={PERMISSION_ID}
        placeholder={
          isLoading || isFetching
            ? t(`forms.placeholders.loadingAccounts`)
            : t(`forms.placeholders.account`)
        }
        options={data?.items}
        onChange={(option: any) => {
          helpers.setValue(option, false);
        }}
      />
    </Flex>
  );
};
