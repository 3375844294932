import React from 'react';

import { Box, LssProp } from '@lucidhq/lucidium';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { BehavioralQuestions } from './BehavioralQuestions';
import { BehavioralQuestionsSidebar } from './BehavioralQuestionsSidebar';

const stepContainerLss: LssProp = {
  display: 'grid',
  gridTemplateColumns: '2fr 6fr',
  gap: '3rem',
  width: '100%',
  p: 'xl'
};

export const BehavioralQuestionsContainer = () => {
  const { can } = useCampaignManager();
  const hasColumn = can.edit && (can.addBaselineKPI || can.addCustomKPI);

  return (
    <Box lss={hasColumn ? stepContainerLss : {}}>
      {hasColumn && <BehavioralQuestionsSidebar />}
      <BehavioralQuestions hasColumn={hasColumn} />
    </Box>
  );
};
