import { useQuery } from '~/utils/rq';

export type TargetAudienceOption = {
  option_text: string;
  precode: number;
};

type TargetAudienceOptionsResponseType = {
  question_options: TargetAudienceOption[];
};

export const useFetchTargetAudienceOptions = (
  questionId?: number,
  countryId?: number
) => {
  const URL = `admin/country-languages/${countryId}/questions/${questionId}/options`;

  return useQuery<TargetAudienceOptionsResponseType>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60,
      enabled: !!countryId && !!questionId
    },
    {
      method: 'get',
      url: URL
    }
  );
};
