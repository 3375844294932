import React, { useEffect } from 'react';

import { Select } from '@lucidhq/lucidium';
import { Field, useFormikContext } from '@lucidhq/lucidium/components/FormV2';
import { useAuth } from '@lucidhq/ui-auth';

import { useFetchProjectManagers } from '~/components/Shared/hooks/useFetchProjectManagers';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type ProjectManagerFieldProps = { readOnly?: boolean };

export const ProjectManagerField = ({ readOnly }: ProjectManagerFieldProps) => {
  const {
    user: { email }
  } = useAuth();

  const { isService } = useCampaignManager();

  const {
    values: { project_manager_user_id },
    setFieldValue
  } = useFormikContext<{ project_manager_user_id: number }>();

  const { isLoading, data } = useFetchProjectManagers({ isService });

  const options = data?.items.map(pm => ({
    label: pm.full_name,
    value: pm.project_manager_user_id
  }));

  useEffect(() => {
    if (!project_manager_user_id) {
      const defaultPm = data?.items.find(pm => pm.email === email);

      if (defaultPm)
        setFieldValue(
          'project_manager_user_id',
          defaultPm.project_manager_user_id
        );
    }
  }, [project_manager_user_id, data, setFieldValue, email]);

  return (
    <Field<number | null>
      name="project_manager_user_id"
      label={t(`forms.labels.projectManager`)}
      render={fieldProps => (
        <Select
          {...fieldProps}
          placeholder={
            isLoading
              ? t(`forms.placeholders.loadingProjectManager`)
              : t(`forms.placeholders.projectManager`)
          }
          disabled={readOnly}
          labelKey="label"
          valueKey="value"
          options={options}
          isClearable
        />
      )}
    />
  );
};
