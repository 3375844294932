import { useContext, useEffect } from 'react';

import { MediaTrackingToolsContext } from '~/impact-measurement/pages/Admin/Contexts';

type UseWarningTrackingConfig = {
  tagKey: TagKeys;
  tagIndex: number;
  index: number;
  valuesCount: number;
  showBulkWarning: boolean;
  isModified: boolean;
};

export const useWarningTracking = ({
  tagKey,
  tagIndex,
  index,
  valuesCount,
  showBulkWarning,
  isModified
}: UseWarningTrackingConfig) => {
  const { warningTracker, dispatchWarningTracker } = useContext(
    MediaTrackingToolsContext
  );

  const handleBlurOrChange = () => {
    dispatchWarningTracker({
      type: 'HANDLE_BLUR_OR_CHANGE',
      tagKey,
      tagIndex,
      index,
      valuesCount
    });
  };

  const hasWarning =
    !!warningTracker[tagKey] &&
    !!warningTracker[tagKey][tagIndex] &&
    !!warningTracker[tagKey][tagIndex][index];

  useEffect(() => {
    // We want to avoid updating the tracker/showing warnings before the user engages with fields (some will have missing values by default).  So we run handleBlurOrChange onBlur to track empty values when we've left a field, and here, with some checks, if the field then gets updated with a value.
    const isEmptyField = !valuesCount;
    const isSiteServedTag = tagKey === 'site_served_tags';
    const isModifiedUniversalField = !isEmptyField && isModified;
    const isModifiedSingleSiteServeField = !isEmptyField && isSiteServedTag;

    (isModifiedUniversalField ||
      isModifiedSingleSiteServeField ||
      showBulkWarning) &&
      handleBlurOrChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesCount, tagKey, showBulkWarning, isModified]);

  return {
    hasWarning,
    handleBlurOrChange
  };
};
