import { useQuery } from '~/utils/rq';

export type Salesforce = {
  salesforce_id: string;
  salesforce_name: string;
};

type SalesforceResponse = {
  items: Salesforce[];
};

type useFetchSalesforceProps = {
  value?: string | null;
  isEnabled: boolean;
};

const CX_PREFIX = 'CX-';

export const useFetchSalesforce = ({
  value,
  isEnabled
}: useFetchSalesforceProps) => {
  const formattedValue = value?.trim();

  const hasId = value?.trim().toUpperCase().slice(0, 3) === CX_PREFIX;

  const URL = `admin/salesforce?${hasId ? 'id=' : 'name='}${formattedValue}`;

  return useQuery<SalesforceResponse>(
    {
      queryKey: [URL],
      // NOTE: the isEnabled flag is specifically only for internal users and is being used solely on the review step of campaign creation 2.0
      // If at any point we need to use this query for external users, we will be able to remove this flag.
      enabled:
        isEnabled &&
        ((!!formattedValue && formattedValue?.length >= 3) ||
          (hasId && !!formattedValue && formattedValue?.length > 3))
    },
    {
      method: 'get',
      url: URL
    }
  );
};
