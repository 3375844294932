import * as React from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';

import { Icon, LssProp, ButtonLink, Button } from '@lucidhq/lucidium';

export type GoBackButtonProps = {
  to?: string;
  onClick?: () => void;
  lss?: LssProp;
  children?: React.ReactNode;
  linkState?: any;
  isLinkStateEnabled?: boolean;
  ariaLabel?: string;
};

const backButtonSize = 30;

export const BackButton = ({
  to: pathname,
  onClick,
  children,
  lss,
  linkState: state,
  isLinkStateEnabled = false,
  ariaLabel
}: GoBackButtonProps) => {
  const linkState = {
    pathname,
    state
  };
  const to = isLinkStateEnabled ? linkState : pathname;

  const ButtonComponent = to ? ButtonLink : Button;

  return (
    <ButtonComponent
      id="go-back"
      palette="primary"
      emphasis="low"
      to={to}
      onClick={onClick}
      lss={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 'rounded',
        bg: 'gray300',
        ':hover': {
          bg: 'gray500'
        },
        m: 0,
        mr: 'lg',
        p: 0,
        width: backButtonSize,
        height: backButtonSize,
        ...lss
      }}
      aria-label={ariaLabel || 'Go back'}
    >
      {children || (
        <Icon
          as={MdKeyboardArrowLeft}
          size={24}
          lss={{ mt: '1px' as any, mr: '1px' as any }}
        />
      )}
    </ButtonComponent>
  );
};
