import React, { useMemo } from 'react';

import {
  BusyButton,
  Button,
  Dialog,
  DialogContent,
  Flex,
  H2,
  InlineNotification,
  useToggle
} from '@lucidhq/lucidium';

import { Trans, t } from '~/utils/i18n';

type IPMatchingConfirmDialogProps = {
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
  enablement: boolean;
  handleEnablement: React.Dispatch<React.SetStateAction<boolean>>;
};

export const IPMatchingConfirmDialog = ({
  children,
  enablement,
  handleEnablement
}: IPMatchingConfirmDialogProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const enablementStatus = useMemo(() => (!enablement ? 'on' : 'off'), [
    enablement
  ]);

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog
        isOpen={isOpen}
        onClose={handleToggle}
        lss={{
          width: '45rem',
          height: '28.1875rem',
          p: 'xxl',
          justifyContent: 'space-between'
        }}
      >
        <DialogContent
          lss={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3rem',
            justifyContent: 'space-between'
          }}
        >
          <H2 as="h4" lss={{ textAlign: 'center', px: 'xs' }}>
            <Trans
              i18nKey="ipMatching.confirmDialog.title"
              values={{
                enablement: !enablement ? 'enable' : 'disable'
              }}
              components={{
                underlined: <span style={{ textDecoration: 'underline' }} />
              }}
            />
          </H2>
          <InlineNotification
            iconLss={{ color: 'primary' }}
            palette="warning"
            title={t('ipMatching.confirmDialog.notificationTitle')}
            lss={{ wordWrap: 'normal' }}
          >
            {t('ipMatching.confirmDialog.notificationMsg')}
          </InlineNotification>
        </DialogContent>

        <Flex
          lss={{
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: 'center'
          }}
        >
          <BusyButton
            type="submit"
            scale="md"
            lss={{ mr: 'md' }}
            palette="secondary"
            onClick={() => {
              handleEnablement(prev => !prev);
              handleToggle();
            }}
          >
            {t('ipMatching.actions.confirmIPMatch', {
              enablement: enablementStatus
            })}
          </BusyButton>
          <Button
            type="button"
            scale="md"
            palette="primary"
            emphasis="medium"
            onClick={handleToggle}
          >
            {t('ipMatching.actions.cancel')}
          </Button>
        </Flex>
      </Dialog>
    </>
  );
};
