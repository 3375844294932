import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  useToggle
} from '@lucidhq/lucidium';

import {
  CampaignPixelVariables,
  useAddCampaignPixelGroup
} from '~/components/Shared/hooks/useMutateCampaignPixelGroup';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { UniversalTagForm } from './UniversalTagForm';

type CreateUniversalTagProps = {
  campaignId: string;
  onCreate: () => void;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const CreateUniversalTag = ({
  children,
  campaignId,
  onCreate
}: CreateUniversalTagProps) => {
  const { onMutateError } = useCampaignManager();
  const [isOpen, handleToggle] = useToggle(false);
  const { mutate, isLoading } = useAddCampaignPixelGroup(campaignId);

  const handleSubmit = (params: CampaignPixelVariables) => {
    mutate(
      {
        campaign_pixels: [params]
      },
      {
        onSuccess: () => {
          onCreate();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog isOpen={isOpen} onClose={handleToggle} lss={{ width: '43rem' }}>
        <DialogHeader palette="disabled" onClose={handleToggle}>
          {t('campaignManager.mediaTracking.universalTag.title')}
        </DialogHeader>
        <DialogContent lss={{ minHeight: '30rem' }}>
          <UniversalTagForm
            isLoading={isLoading}
            onCancel={handleToggle}
            onSubmit={handleSubmit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
