import React, { useContext } from 'react';

import { Flex, Button } from '@lucidhq/lucidium';

import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';

import { SingleUrlSetup } from '../../components/ConfigureMacros';
import { TAG_KEYS } from '../../constants';
import { handleResetMacros, useSingleUrlSetup } from '../../lib';

type UniversalUrlSetupProps = {
  tagIndex: number;
  adServers: AdServerType[];
};

export const UniversalUrlSetup: React.FC<UniversalUrlSetupProps> = ({
  tagIndex,
  adServers
}) => {
  const { mediaToolData, setFieldValue } = useContext(
    MediaTrackingToolsFormContext
  );
  const { dispatchWarningTracker } = useContext(MediaTrackingToolsContext);

  const {
    macros,
    adServer,
    hasModifiedMacros,
    setHasModifiedMacros
  } = useSingleUrlSetup({
    mediaToolData,
    tagIndex,
    adServers
  });
  const hasMacros = !!macros.length;

  const resetTracker = () =>
    dispatchWarningTracker({
      type: 'RESET_UNIVERSAL',
      tagIndex
    });

  return (
    <>
      <Flex lss={{ justifyContent: 'flex-end' }}>
        {hasMacros && (
          <Button
            emphasis="low"
            palette="secondary"
            lss={{ px: 'none', py: 'sm', mr: 'xxl' }}
            type="button"
            onClick={() => {
              handleResetMacros(macros, adServer, tagIndex, setFieldValue);
              setHasModifiedMacros([false]);
              resetTracker();
            }}
            disabled={!hasModifiedMacros.includes(true)}
          >
            Reset to Default Values
          </Button>
        )}
      </Flex>

      <SingleUrlSetup
        adServer={adServer}
        macros={macros}
        tagKey={TAG_KEYS.universal}
        hasMacros={hasMacros}
        tagIndex={tagIndex}
        hasModifiedMacros={hasModifiedMacros}
        setHasModifiedMacros={setHasModifiedMacros}
      />
    </>
  );
};
