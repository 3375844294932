import React from 'react';

import {
  BusyButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDeleteIcon,
  DialogProps,
  LssProp,
  TextOrNode
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

interface BusyDeleteDialogProps extends DialogProps {
  onClose: () => void;
  onDelete: () => void;
  onCancel?: () => void;
  deleteBtnText?: string;
  cancelBtnText?: string;
  dialogLss?: LssProp;
  contentLss?: LssProp;
  isLoading: boolean;
  children: React.ReactNode;
}

export const BusyDeleteDialog = ({
  onClose,
  onDelete,
  children,
  deleteBtnText,
  cancelBtnText,
  dialogLss = {},
  contentLss = {},
  isLoading = false,
  ...props
}: BusyDeleteDialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      closeOnEscPress
      {...props}
      lss={{
        minWidth: 'xs',
        ...dialogLss
      }}
    >
      <DialogContent
        lss={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          ...contentLss
        }}
      >
        <DialogDeleteIcon />
        <TextOrNode>{children}</TextOrNode>
      </DialogContent>
      <DialogActions>
        <BusyButton
          scale="sm"
          palette="danger"
          onClick={onDelete}
          isBusy={isLoading}
          lss={{
            width: 80,
            minWidth: 0,
            mx: 'sm'
          }}
        >
          {deleteBtnText || t('actions.delete')}
        </BusyButton>
        <Button
          scale="sm"
          palette="primary"
          emphasis="medium"
          onClick={onClose}
          lss={{
            width: 80,
            mx: 'sm'
          }}
        >
          {cancelBtnText || t('actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
