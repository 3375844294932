import { useQuery } from '~/utils/rq';

type CountryLanguageResponseType = {
  items: CountryLanguage[];
};

const COUNTRY_LANG_URL = `admin/country-languages`;

export const useFetchCountryLanguages = () => {
  return useQuery<CountryLanguageResponseType>(
    {
      queryKey: [COUNTRY_LANG_URL],
      staleTime: 10 * 60 * 60
    },
    {
      method: 'get',
      url: COUNTRY_LANG_URL
    }
  );
};
