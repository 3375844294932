import React from 'react';

import { Flex, Text, Button, LssProp } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';
import { AudienceTheme } from '~/utils/styles';

type StandardQuestionsListItemProps = {
  name: string;
  disabled: boolean;
  onClick: () => void;
};

const listItemLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  px: 'md',
  py: 'sm',
  my: 'md',
  border: 'regular',
  borderRadius: 'md'
};

const addButtonLss: LssProp = {
  display: 'flex',
  color: AudienceTheme.colors.secondary500 as any,
  borderColor: AudienceTheme.colors.secondary500 as any,
  borderRadius: 'md',
  alignSelf: 'flex-end',
  p: 'sm',
  minWidth: '4rem',
  minHeight: '2.1rem',
  justifyContent: 'center'
};

export const StandardQuestionsListItem = ({
  name,
  disabled,
  onClick
}: StandardQuestionsListItemProps) => (
  <Flex lss={listItemLss}>
    <Text lss={{ alignSelf: 'flex-start', mt: 'xs' }}>{name}</Text>
    <Button
      palette="secondary"
      emphasis="medium"
      lss={addButtonLss}
      disabled={disabled}
      onClick={onClick}
    >
      {t('campaignManager.actions.add')}
    </Button>
  </Flex>
);
