import React from 'react';

import { Flex, Text } from '@lucidhq/lucidium';
import { WarningIcon } from '@lucidhq/lucidium/icons';

type TagCardTitleProps = {
  title: string;
  hasWarning?: boolean;
};

export const TagCardTitle: React.FC<TagCardTitleProps> = ({
  title,
  hasWarning
}) => (
  <Flex lss={{ alignItems: 'center' }}>
    {hasWarning && <WarningIcon size={22} wrapperLss={{ mr: 'xs' }} />}
    <Text>{title}</Text>
  </Flex>
);
