import { CampaignManagerStepIds } from '~/components/CampaignManager/useCampaignManagerSteps';
import { ROUTES, SOCIAL_PLATFORMS } from '~/constants';
import {
  Campaign,
  CampaignStatus,
  CampaignTypes,
  Channels,
  SocialPlatform
} from '~/types';

type EnhancedCampaign = Campaign & {
  meta: {
    isDraft: Boolean;
    isLive: Boolean;
    isLinearTV: Boolean;
    isDigital: Boolean;
    isSocial: Boolean;
    hasBaselineQuestions: Boolean;
    hasStandardQuestions: Boolean;
    hasEnoughBaselineQuestions: Boolean;
    mediaTrackingAck: Boolean;
    targetAudAck: Boolean;
    hasBrand: Boolean;
    hasEnoughCompetitors: Boolean;
    isV2: Boolean;
    selectedDataPartner: string;
    hasTargetedRecruitment: Boolean;
    isCampaignUSEnglish: Boolean;
    hasValidCountryLang: Boolean;
    socialPlatformsLabel: string;
  };
};

export type ManagedCampaign = null | {
  details: EnhancedCampaign;
};

enum COUNTRY_LANG_CODES {
  US = 1,
  UK = 2,
  // NOTE: In QA - dev-aud - Australia is represented as 3 not 4. Production is represented as 4.
  AUSTRALIA = 4,
  CANADA = 5
}

// NOTE: this list will need changes as census data is updated
const VALID_TR_COUNTRY_LANGS: number[] = [
  COUNTRY_LANG_CODES.US,
  COUNTRY_LANG_CODES.UK,
  COUNTRY_LANG_CODES.AUSTRALIA,
  // NOTE: add AUS back after quals/quotas issue is resolved.
  COUNTRY_LANG_CODES.CANADA
];

const getSelectedDataPartner = (partners: string[]) => {
  if (!partners?.length) return '';

  const [, keyword] = partners?.[0].split('_');

  return keyword;
};

const findSelectedPlatforms = (
  socialPlatforms?: SocialPlatform[],
  fieldValues?: number[]
): SocialPlatform[] => {
  if (!socialPlatforms || !socialPlatforms?.length || !fieldValues?.length) {
    return [];
  }

  const filteredOptions = socialPlatforms.filter(
    option => !!fieldValues?.length && fieldValues.includes(option.id)
  );

  return filteredOptions;
};

const getSelectedSocialPlatforms = (campaign: Campaign): string => {
  if (!campaign.channels.includes(Channels.SOCIAL)) return '';

  const socialPlatforms = findSelectedPlatforms(
    SOCIAL_PLATFORMS,
    campaign?.draft_settings?.social_platforms
  );

  return !!socialPlatforms?.length
    ? socialPlatforms?.map(option => option?.social_platform_name).join(', ')
    : '';
};

const decorateCampaign = (campaign: Campaign): EnhancedCampaign => {
  return {
    ...campaign,
    meta: {
      isDraft: campaign.status === CampaignStatus.DRAFT,
      isLive: CampaignStatus.LIVE === campaign.status,
      isLinearTV: campaign.channels.includes(Channels.LINEAR_TV),
      isDigital: campaign.channels.includes(Channels.DIGITAL),
      isSocial: campaign.channels.includes(Channels.SOCIAL),
      hasBaselineQuestions: !!campaign.draft_settings.baseline_questions
        ?.length,
      hasEnoughBaselineQuestions: !!(
        campaign.draft_settings.baseline_questions?.length >= 4 ||
        !!campaign.draft_settings?.ui?.isBaselineValidationDisabled
      ),
      hasStandardQuestions: !!campaign.draft_settings.questions?.length,
      mediaTrackingAck: !!campaign.draft_settings.ui?.mediaTrackingAck,
      targetAudAck: !!campaign.draft_settings.ui?.targetAudAck,
      hasBrand: !!campaign.client_name,
      hasEnoughCompetitors: !!(
        campaign.draft_settings.answer_meta?.competitors?.length >= 2
      ),
      isV2: campaign.draft_settings.campaign_version === 2,
      selectedDataPartner: !!campaign.channels.includes(Channels.LINEAR_TV)
        ? getSelectedDataPartner(campaign.draft_settings.data_partners)
        : '',
      hasTargetedRecruitment: !!(
        campaign.draft_settings?.targeted_recruitment?.name &&
        campaign.draft_settings?.targeted_recruitment?.qualifications
      ),
      isCampaignUSEnglish:
        campaign.country_language_id === COUNTRY_LANG_CODES.US,
      hasValidCountryLang: VALID_TR_COUNTRY_LANGS.includes(
        campaign.country_language_id
      ),
      socialPlatformsLabel: getSelectedSocialPlatforms(campaign)
    }
  };
};

export const decorateManagedCampaign = (
  campaign?: Campaign
): ManagedCampaign => {
  if (!campaign) return null;

  return {
    // Is this namespace still needed? @TODO Refactor after beta
    details: decorateCampaign(campaign)
  };
};

export const getCampaignManagerRoute = (
  typeId: CampaignTypes = CampaignTypes.SOFTWARE,
  campaignId?: string,
  stepId?: CampaignManagerStepIds
): string => {
  const typeSegment = typeId;
  const campaignSegment = campaignId ? `/${campaignId}` : '';
  const stepSegment = stepId ? `/${stepId.toLowerCase()}` : '';
  return `${ROUTES.campaignManager}${typeSegment}${campaignSegment}${stepSegment}`;
};
