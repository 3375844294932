import React from 'react';

import { List, ListItem } from '@lucidhq/lucidium';

import { useCampaignManager } from '~/components/CampaignManager/CampaignManagerProvider';
import { useFetchTargetAudienceOptions } from '~/components/Shared/hooks/useFetchTargetAudienceOptions';

type AudienceBlockAnswersProps = {
  questionId: number;
  answerIds: (number | string)[];
};

export const AudienceBlockAnswers = React.memo(
  ({ questionId, answerIds }: AudienceBlockAnswersProps) => {
    const { managedCampaign } = useCampaignManager();

    const { data } = useFetchTargetAudienceOptions(
      questionId,
      managedCampaign?.details.country_language_id
    );

    const options = data?.question_options;

    if (!options) return null;

    return (
      <List>
        {options
          .filter(option => answerIds.includes(option.precode))
          .map(option => (
            <ListItem key={option.precode}>{option.option_text}</ListItem>
          ))}
      </List>
    );
  }
);
