import * as React from 'react';

import { QuestionsTemplatesResult } from '~/impact-measurement/pages/types';

type QuestionsTemplatesContextType = {
  isLoading: boolean;
  isTemplatesAvailable: boolean;
  questionsTemplates: QuestionsTemplatesResult;
  error: ErrorResponse;
};

export const QuestionsTemplateContext = React.createContext<
  QuestionsTemplatesContextType
>({
  isLoading: false,
  isTemplatesAvailable: false,
  questionsTemplates: {
    standard: [],
    usage: []
  },
  error: null
});
