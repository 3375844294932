import React from 'react';

import { Flex, Text } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { CustomTextField } from './CustomTextField';

type CustomKPITitleProps = {
  question: EnhancedTemplateQuestion;
  onSave?: (data: Partial<TemplateQuestion>) => void;
  readOnly: boolean;
  customKPIErrors?: Record<string, any>;
  hasCustomKPIErrors?: boolean;
  index: number;
};

export const CustomKPITitle = ({
  question,
  onSave,
  readOnly,
  customKPIErrors = {},
  hasCustomKPIErrors,
  index
}: CustomKPITitleProps) => {
  const canSave = !!(question && onSave);
  const readOnlyTitle = question.custom_question_text || question.question_text;
  const customKPITitleErrors = customKPIErrors[`customKPI${index}`];

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        maxWidth: '37.25rem',
        width: '100%',
        mt: 'sm'
      }}
    >
      {readOnly ? (
        <Text lss={{ fontSize: '1.125rem' }}>{readOnlyTitle}</Text>
      ) : (
        <>
          <CustomTextField
            name="question_name"
            label={t('campaignManager.survey.kpiName')}
            placeholder={t('campaignManager.survey.customKPI')}
            required
            value={question?.question_name}
            onChange={(e: any) => {
              if (!canSave) return;

              onSave({ question_name: e.target.value });
            }}
            showErrorMessage={
              !!(customKPITitleErrors?.question_name && hasCustomKPIErrors)
            }
            errorMessage={customKPITitleErrors?.question_name}
          />
          <CustomTextField
            name="custom_question_text"
            label={t('campaignManager.survey.customQuestion')}
            placeholder={t('campaignManager.survey.customQuestion')}
            value={question?.custom_question_text}
            required
            onChange={(e: any) => {
              if (!canSave) return;

              onSave({
                custom_question_text: e.target.value,
                question_text: e.target.value,
                question_text_template: e.target.value
              });
            }}
            showErrorMessage={
              !!(
                customKPITitleErrors?.custom_question_text && hasCustomKPIErrors
              )
            }
            errorMessage={customKPITitleErrors?.custom_question_text}
          />
        </>
      )}
    </Flex>
  );
};
