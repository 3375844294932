import React from 'react';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';

import { Text, useToggle } from '@lucidhq/lucidium';
import { Field, Form } from '@lucidhq/lucidium/components/FormV2';

import { ErrorDialog } from '~/components/Shared/ErrorDialog';
import { FormProps } from '~/components/Shared/FormProps';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { ROUTES } from '~/constants';
import { Campaign } from '~/types';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../../CampaignManagerFooter';
import { useCampaignManager } from '../../CampaignManagerProvider';
import { StepLayout } from '../../Steps/StepLayout';
import { SurveyStepIds } from '../../useCampaignManagerSteps';
import { SurveyStepCarousel } from '../SurveyStepCarousel';
import { BrandAttributesSection } from './BrandAttributesSection';
import { BrandInformationSection } from './BrandInformationSection';
import { CompetitorsSection } from './CompetitorsSection';

/**
 * The types "product_category", "period", and "message" are used for the following KPIs:
 *  - Purchase Intent
 *  - Message Association
 *  - Message Awareness
 *
 * "period" and "message" are used to fill in question text templated values just like "product_category".
 * However they do not match up with the labels for these fields which is the reason for this descriptive comment.
 */

export type BrandsFormData = {
  client_name: string;
  competitors: string[];
  product_category: string;
  period: string;
  message: string;
  brand_attributes: string[];
};

const getInitValues = (managedCampaign?: Campaign) => {
  const answerMeta = managedCampaign?.draft_settings?.answer_meta;
  const questionMeta = managedCampaign?.draft_settings?.question_meta;

  return {
    client_name: managedCampaign?.client_name || '',
    competitors: answerMeta?.competitors || ['', '', '', ''],
    product_category: questionMeta?.product_category || '',
    period: questionMeta?.period || '',
    message: questionMeta?.message || '',
    brand_attributes: !!answerMeta?.brand_attributes?.length
      ? answerMeta?.brand_attributes
      : ['', '', '', '']
  };
};

const validationSchema = Yup.object().shape({
  client_name: Yup.string(),
  competitors: Yup.array().of(Yup.string()).min(2).max(10),
  product_category: Yup.string(),
  period: Yup.string(),
  message: Yup.string(),
  brand_attributes: Yup.array().of(Yup.string()).max(10)
});

const filterExcessValues = (options: string[]) =>
  options.filter(option => !!option);

export const BrandsForm = () => {
  const [isOpen, onToggle] = useToggle();
  const {
    goToStep,
    steps,
    managedCampaign,
    refetchCampaign,
    can
  } = useCampaignManager();
  const handleBack = () => goToStep(steps.MEDIA_TRACKING.id);
  const { mutate } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );
  const brandInitialValues = getInitValues(managedCampaign?.details);
  const history = useHistory();

  const handleSubmit = (action: 'close' | 'continue') => (
    values: BrandsFormData
  ) => {
    if (!managedCampaign) return;
    const brandAttrs = filterExcessValues(values.brand_attributes);

    // @ts-ignore
    const incomingUpdates: Campaign = {
      ...managedCampaign.details,
      client_name: values?.client_name,
      draft_settings: {
        ...managedCampaign.details.draft_settings,
        question_meta: {
          in_target_brand: values.client_name,
          product_category: values.product_category,
          period: values.period,
          message: values.message,
          //@TODO: API needs to fix this odd field name this is a patch fix until internal user flow
          your_brand: values.client_name
        },
        answer_meta: {
          in_target_brand: values.client_name,
          competitors: filterExcessValues(values.competitors),
          ...(brandAttrs.length && { brand_attributes: brandAttrs })
        }
      }
    };

    mutate(incomingUpdates, {
      onSuccess: () => {
        if (action === 'close') return history.push(ROUTES.adminCampaigns);
        if (action === 'continue') {
          refetchCampaign();

          if (can.limitedEdit) {
            goToStep(steps.REVIEW.id);
          } else {
            goToStep(steps.SURVEY.id, {
              activeChildStep: SurveyStepIds.STANDARD_QUESTIONS
            });
          }
        }
      },
      onError: () => {
        onToggle();
      }
    });
  };

  return (
    <>
      <Form
        initialValues={brandInitialValues}
        // @ts-ignore
        onSubmit={() => null}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
      >
        <Text
          lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
          as="strong"
        >
          {t(`campaignManager.survey.brands.questions.brand`)}
        </Text>

        <Field
          name="client_name"
          label={t('forms.labels.brand')}
          labelLss={{ mt: 'lg' }}
          groupLss={{ mb: 'sm' }}
          required
          requiredMessage={t('forms.errors.brandName')}
          disabled={!can.edit}
        />

        <Text
          lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
          as="strong"
        >
          {t(`campaignManager.survey.brands.questions.competitors`)}
        </Text>

        <CompetitorsSection readOnly={!can.edit} />

        <StepLayout.Header
          title={t('campaignManager.survey.brands.brandInformation.title')}
          lss={{ mb: 'xl' }}
        />

        <Text lss={{ fontWeight: 'bold', fontSize: '1rem' }} as="strong">
          {t(`campaignManager.survey.brands.questions.brandInfo`)}
        </Text>

        <BrandInformationSection readOnly={!can.edit} />

        <BrandAttributesSection readOnly={!can.edit} />

        <SurveyStepCarousel />

        <FormProps>
          {({ validateForm, values }) => (
            <CampaignManagerFooter
              onBack={handleBack}
              saveCloseProps={{
                onClick: async () => {
                  const errors = await validateForm();
                  if (Object.values(errors).length) return;
                  const formHandler = handleSubmit('close');
                  formHandler(values as BrandsFormData);
                },
                type: 'submit'
              }}
              saveContinueProps={{
                onClick: async () => {
                  const errors = await validateForm();
                  if (Object.values(errors).length) return;
                  const formHandler = handleSubmit('continue');
                  formHandler(values as BrandsFormData);
                },
                type: 'submit'
              }}
            />
          )}
        </FormProps>
      </Form>
      <ErrorDialog
        isOpen={isOpen}
        onClose={onToggle}
        errorText={t('errors.brand')}
      />
    </>
  );
};
