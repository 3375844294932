import React from 'react';

import { Button, LssProp } from '@lucidhq/lucidium';
import { DownloadIcon } from '@lucidhq/lucidium/icons';

import { t } from '~/utils/i18n';

const downloadPixelsLss: LssProp = {
  borderRadius: 'md',
  display: 'flex',
  alignItems: 'center',
  maxHeight: '2.5rem',
  justifyContent: 'center'
};

type DownloadPixelsButtonProps = {
  onClick: () => void;
  disabled?: boolean;
  id: string;
};

export const DownloadPixelsButton = ({
  onClick,
  disabled,
  id
}: DownloadPixelsButtonProps) => {
  return (
    <Button
      id={id}
      emphasis="medium"
      disabled={disabled}
      lss={downloadPixelsLss}
      onClick={onClick}
    >
      <DownloadIcon
        size={14}
        lss={{
          color: 'secondary600'
        }}
        wrapperLss={{
          opacity: disabled ? 0.3 : 1,
          cursor: disabled ? 'not-allowed' : 'pointer',
          pt: 'xxs',
          pr: 'xs'
        }}
      />
      {t('campaignManager.actions.downloadPixels')}
    </Button>
  );
};
