import React from 'react';
import { MdMyLocation } from 'react-icons/md';

import styled from 'styled-components';

import { Box, Flex, Icon } from '@lucidhq/lucidium';

import { DummyRadioButton, DummyCheckbox } from './DummyAnswerElements';

type gridProps = { rowIndex: number; colIndex: number };

type GridOptionsDisplayProps = {
  rows: { text: string; order: number }[];
  columns: { text: string; order: number }[];
  allowsMultipleSelections?: boolean;
  targetOptionIndex?: number;
  multiTargetOptionIndices?: number[];
  selectorComponent?:
    | React.ComponentClass<gridProps>
    | React.FunctionComponent<gridProps>;
};

const Container = styled(Box)(
  ({ theme }) =>
    `
    width: 100%;
  > div:nth-child(even) {
    background-color: ${theme.colors.gray200};
  }
`
);

const GridRow: React.FC = ({ children }) => (
  <Flex lss={{ py: 'sm' }}>{children}</Flex>
);
const ColHeader = ({ children, width }: any) => (
  <Flex
    lss={{
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: width,
      overflowWrap: 'break-word'
    }}
  >
    {children}
  </Flex>
);
const RowHeader = ({ children, width }: any) => (
  <Flex
    lss={{
      width: width,
      whiteSpace: 'normal',
      wordBreak: 'break-word',
      pl: 'sm'
    }}
  >
    {children}
  </Flex>
);

type ColumnLabelProps = {
  width: string;
  isColumnTargetOption: boolean;
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
};

type InnerCellProps = Pick<ColumnLabelProps, 'width'>;

const InnerCell: React.FC<InnerCellProps> = ({ width, children }) => (
  <ColHeader width={width}>{children}</ColHeader>
);

const ColumnLabel: React.FC<ColumnLabelProps> = ({
  width,
  isColumnTargetOption,
  component: Component
}) => (
  <InnerCell width={width}>
    <Component lss={{ mx: 'none' }} />
    {isColumnTargetOption && (
      <Icon
        as={MdMyLocation}
        size={18}
        lss={{ color: 'primary500', ml: 'lg' }}
      />
    )}
  </InnerCell>
);

export const GridQuestionPreview: React.FC<GridOptionsDisplayProps> = ({
  rows,
  columns,
  allowsMultipleSelections = false,
  targetOptionIndex,
  multiTargetOptionIndices = [],
  selectorComponent
}) => {
  const width = `${(1 / columns?.length) * 100}%`;

  const Input =
    selectorComponent ||
    (allowsMultipleSelections ? DummyCheckbox : DummyRadioButton);

  return (
    <Container>
      <GridRow>
        <ColHeader width={width} />
        {columns?.map((column, i) => (
          <ColHeader key={i} width={width}>
            {column.text}
          </ColHeader>
        ))}
      </GridRow>
      {rows?.map((row: any, rowIndex) => {
        const isRowTargetOption = targetOptionIndex === rowIndex;
        return (
          <GridRow key={rowIndex}>
            <RowHeader width={width}>{row.text}</RowHeader>

            {columns?.map((_, colIndex) => (
              <ColumnLabel
                key={colIndex}
                isColumnTargetOption={
                  multiTargetOptionIndices.includes(colIndex) &&
                  isRowTargetOption
                }
                component={() => (
                  <Input rowIndex={rowIndex} colIndex={colIndex} />
                )}
                width={width}
              />
            ))}
          </GridRow>
        );
      })}
    </Container>
  );
};
