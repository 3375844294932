import React from 'react';
import { MdAdd } from 'react-icons/md';

import {
  Flex,
  Text,
  H5,
  Button,
  Icon,
  LssProp,
  useToggle
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { AddIDsDialog } from './AddIDsDialog';
import { IPMatchedID } from './IPMatchingProvider';

type HeaderProps = {
  title: string;
  description: string;
  matchedIds?: IPMatchedID[];
};

const buttonLss: LssProp = {
  display: 'flex',
  height: 'fit-content',
  alignItems: 'center',
  gap: '0.5rem',
  borderRadius: 'sm'
};

export const MatchedIDsHeader = ({ description, title }: HeaderProps) => {
  const [isOpen, handleToggle] = useToggle(false);

  return (
    <Flex
      lss={{
        justifyContent: 'space-between',
        p: 'xl',
        pb: 'lg',
        marginTop: '2rem'
      }}
    >
      <Flex lss={{ flexDirection: 'column' }}>
        <H5>{title}</H5>
        <Text
          lss={{
            textScale: 'content',
            maxWidth: '734px',
            mt: 'xl',
            whiteSpace: 'pre-line'
          }}
        >
          {description}
        </Text>
      </Flex>
      <Flex lss={{ fontSize: '16px' }}>
        <Button
          onClick={handleToggle}
          scale="md"
          palette="secondary"
          emphasis="high"
          lss={buttonLss}
        >
          <Icon as={MdAdd} size={24} />
          {t('ipMatching.actions.addNewIDs')}
        </Button>
        <AddIDsDialog isOpen={isOpen} handleToggle={handleToggle} />
      </Flex>
    </Flex>
  );
};
