import React from 'react';
import { MdDelete } from 'react-icons/md';

import { Flex, Icon, Label, LssProp } from '@lucidhq/lucidium';
import { Field } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

const addCompetitorLss: LssProp = {
  flexBasis: '2rem',
  height: '2rem',
  justifyContent: 'center',
  textDecoration: 'none',
  color: 'secondary500',
  p: 'xxs'
};

const fieldWrapperLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mb: 'xxs'
};

type CompetitorFieldProps = {
  index: number;
  handleRemoveItem: (index: number) => void | undefined;
  readOnly: boolean;
};

export const AttributeField = ({
  index,
  handleRemoveItem,
  readOnly
}: CompetitorFieldProps) => {
  const isAdditionalAttr = index >= 4;

  return (
    <Flex lss={{ flexDirection: 'column', my: 'md' }}>
      <Flex lss={fieldWrapperLss}>
        <Label lss={{ my: 'sm' }}>
          {t(`forms.labels.attributeX`, { index: index + 1 })}
        </Label>

        {isAdditionalAttr && !readOnly && (
          <Flex
            as="a"
            href="#"
            role="button"
            aria-label={t(
              `campaignManager.survey.brands.ariaLabels.deleteField`,
              { field: 'Attribute', index: index + 1 }
            )}
            lss={addCompetitorLss}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              handleRemoveItem(index);
            }}
          >
            <Icon as={MdDelete} size={28} />
          </Flex>
        )}
      </Flex>

      <Field name={`[brand_attributes.${index}]`} disabled={readOnly} />
    </Flex>
  );
};
