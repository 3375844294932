import React from 'react';

import { Flex, LssProp, Text, Box, DefaultOptionType } from '@lucidhq/lucidium';

import { AddOrRemoveButton } from '~/impact-measurement/components';
import { BlankMacroWarning } from '~/impact-measurement/pages/Admin/pages/MediaTracking/MediaTrackingTools/Tags/TagsGeneration/components';
import { MAPPING_SELECT_WIDTH } from '~/impact-measurement/pages/Admin/pages/MediaTracking/MediaTrackingTools/Tags/TagsGeneration/SiteServedSetup/constants';
import { convertToSnakeCase } from '~/impact-measurement/pages/Admin/pages/MediaTracking/MediaTrackingTools/Tags/TagsGeneration/SiteServedSetup/lib';

import { mappingRowLss } from '../styles';
import { ColumnMappingSelect } from './ColumnMappingSelect';

type ColumnMappingSelectRowProps = {
  label: string;
  options: DefaultOptionType[];
  onSelectHeader: (value: string | null) => void;
  handleRemoveCustomColumn?: () => void;
  matchedValue: string;
};

export const ColumnMappingSelectRow: React.FC<ColumnMappingSelectRowProps> = ({
  label,
  options,
  onSelectHeader,
  handleRemoveCustomColumn,
  matchedValue
}) => {
  const isCustom = typeof handleRemoveCustomColumn === 'function';

  const selectWrapperLss: LssProp = {
    width: MAPPING_SELECT_WIDTH,
    mr: isCustom ? 'none' : 'xxl'
  };

  return (
    <Flex lss={mappingRowLss}>
      <Text lss={{ width: '150px', mt: 'sm' }}>{label}:</Text>
      <Box lss={selectWrapperLss}>
        <ColumnMappingSelect
          id={`mapping-column-select-${convertToSnakeCase(label)}`}
          options={options}
          onSelectHeader={onSelectHeader}
          matchedValue={matchedValue}
        />
        {!matchedValue && <BlankMacroWarning label={label} isSingle={false} />}
      </Box>
      {typeof handleRemoveCustomColumn === 'function' && (
        <AddOrRemoveButton
          type="remove"
          onClick={handleRemoveCustomColumn}
          size={20}
          lss={{ mt: 'sm' }}
        />
      )}
    </Flex>
  );
};
