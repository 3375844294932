import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';

import { Link, Flex, Text, LssProp } from '@lucidhq/lucidium';

const backToLinkLss: LssProp = {
  alignItems: 'center',
  color: 'primary500',
  ':hover': {
    color: 'secondary500',
    textDecoration: 'underline'
  },
  ':visited': {
    color: 'primary500'
  }
};

type BackToLinkProps = {
  to: string;
  label: string;
};

export const BackToLink: React.FC<BackToLinkProps> = ({ to, label }) => {
  return (
    <Link to={to} lss={{ textDecoration: 'none' }}>
      <Flex lss={backToLinkLss}>
        <MdArrowBackIosNew />
        <Text lss={{ ml: 'md' }}>{label}</Text>
      </Flex>
    </Link>
  );
};
