import { useMutation } from '~/utils/rq';

import { S2SMappings, S2SMappingsResponse } from './useFetchS2SMappings';

export const useManageS2SMappings = (campaignId?: string) => {
  const URL = `/admin/campaigns/${campaignId}/s2s-mappings?channels=linear_tv`;

  return useMutation<S2SMappings, S2SMappingsResponse>(
    {},
    {
      method: 'post',
      url: URL
    }
  );
};

type DeleteS2SMappingRequest = {
  partner_id: number;
};

export const useDeleteS2sMappings = (
  campaignId: string,
  creativeId: string
) => {
  const URL = `/admin/campaigns/${campaignId}/s2s-mappings/${encodeURIComponent(
    creativeId
  )}`;

  return useMutation<DeleteS2SMappingRequest, null>(
    {},
    {
      method: 'delete',
      url: URL
    }
  );
};
