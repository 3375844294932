import { useState } from 'react';

type UseMappedMacros = () => {
  mappedMacros: MappingMacroType[];
  setMappedMacros: React.Dispatch<React.SetStateAction<MappingMacroType[]>>;
  bulkTags: SiteServedPixel[];
  tagsQuantity: number;
  isDisabled: boolean;
};

export const useMappedMacros: UseMappedMacros = () => {
  const [mappedMacros, setMappedMacros] = useState<MappingMacroType[]>([]);

  const tags = mappedMacros.map(({ label, param, values }) => ({
    label,
    param,
    values
  }));

  const tagsValuesCounts = tags.map(tag => tag.values?.length || 0).sort();
  const tagsQuantity = tagsValuesCounts[tagsValuesCounts.length - 1];

  const isDisabled = mappedMacros.some(
    macro => !macro.values || !macro.values.length
  );

  const bulkTags = [{ macros: tags }];

  return {
    mappedMacros,
    setMappedMacros,
    bulkTags,
    tagsQuantity,
    isDisabled
  };
};
