import React, { useCallback, useContext, useMemo, useState } from 'react';

import {
  TemplateQuestion,
  TemplateQuestionType,
  useFetchTemplateQuestions
} from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import {
  decorateTemplateQuestion,
  EnhancedTemplateQuestion,
  getCustomKPI,
  resetCustomKPI,
  validateConventionalKPIs,
  validateCustomKPIs
} from '~/utils/templateQuestions';

import { useCampaignManager } from '../../CampaignManagerProvider';

export enum QuestionMoveDir {
  'up' = 'up',
  'down' = 'down'
}

type SubmitProp = {
  onSuccess: () => void;
};

type StandardQuestionsProvider = {
  standardTemplateQuestions: EnhancedTemplateQuestion[];
  standardQuestions: EnhancedTemplateQuestion[];
  hasPendingQuestions: boolean;
  addStandardQuestion: (id: string) => void;
  submitStandardQuestions: (prop: SubmitProp) => void;
  setPrimaryQuestion: (index: number) => void;
  deleteStandardQuestion: (index: number) => void;
  moveStandardQuestion: (index: number, dir: QuestionMoveDir) => void;
  saveStandardQuestion: (
    index: number,
    data: Partial<TemplateQuestion>
  ) => void;
  customKPIErrors: Record<string, any>;
  hasCustomKPIErrors: boolean;
  kpiErrors: Record<string, any>;
  hasKPIErrors: boolean;
};

type StandardQuestionsProviderProps = {
  children: React.ReactNode;
};

const StandardQuestionsContext = React.createContext<StandardQuestionsProvider>(
  {} as StandardQuestionsProvider
);

export const useStandardQuestionsProvider = () =>
  useContext<StandardQuestionsProvider>(StandardQuestionsContext);

export const StandardQuestionsProvider: React.FC<StandardQuestionsProviderProps> = ({
  children
}) => {
  const [hasPendingQuestions, setPendingQuestions] = useState<boolean>(false);
  const {
    managedCampaign,
    refetchCampaign,
    onMutateError,
    can
  } = useCampaignManager();
  const [questionMeta, setQuestionMeta] = useState<{ [key: string]: any }>(
    managedCampaign?.details.draft_settings?.question_meta
  );
  const questions = managedCampaign?.details.draft_settings.questions;

  const { mutate: mutateCampaign } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const { data } = useFetchTemplateQuestions({
    type: TemplateQuestionType.Lift,
    campaignId: managedCampaign?.details.campaign_id,
    isEnabled: true
  });

  // Current campaign custom questions duplicated from templates
  const [standardQuestions, setStandardQuestions] = useState<
    EnhancedTemplateQuestion[]
  >(
    questions
      ? (questions.map(decorateTemplateQuestion) as EnhancedTemplateQuestion[])
      : []
  );

  // Default questions that should be duplicated and possibly customized
  const standardTemplateQuestions = useMemo(() => {
    if (!data?.items) return [];
    const itemsCopy = [...data.items];

    if (can.addCustomKPI) {
      itemsCopy.push(
        getCustomKPI({
          questions: data.items,
          isBehavioralCustomKPI: false
        })
      );
    }

    return itemsCopy.map(decorateTemplateQuestion);
  }, [data, can]);

  const { customKPIErrors, hasCustomKPIErrors } = useMemo(() => {
    return validateCustomKPIs(standardQuestions);
  }, [standardQuestions]);

  const { kpiErrors, hasKPIErrors } = useMemo(() => {
    return validateConventionalKPIs(standardQuestions);
  }, [standardQuestions]);

  const addStandardQuestion = useCallback(
    (id: string) => {
      const currentQuestion = standardTemplateQuestions.find(
        item => item.kpi_type === id
      );

      if (!currentQuestion) return;

      const sanitizedQuestion = {
        ...currentQuestion,
        // Should always be true when the first question is added
        default: !standardQuestions.length,
        question_version: 2
      };

      // Keep existing order
      const updatedQuestions = [...standardQuestions, sanitizedQuestion];
      const sortedQuestions = [...updatedQuestions].sort(
        (a, b) => a.order - b.order
      );

      setStandardQuestions(sortedQuestions);
      setPendingQuestions(true);
    },
    [setStandardQuestions, standardTemplateQuestions, standardQuestions]
  );

  const setPrimaryQuestion = useCallback(
    (index: number) => {
      const updatedQuestions = standardQuestions.map((q, idxQ) => ({
        ...q,
        default: idxQ === index
      }));

      setStandardQuestions(updatedQuestions);
      setPendingQuestions(true);
    },
    [standardQuestions, setStandardQuestions]
  );

  const deleteStandardQuestion = useCallback(
    (index: number) => {
      const questions = [...standardQuestions];
      const questionToDelete = questions.find((q, iq) => iq === index);

      if (!questionToDelete) return;

      const filteredQuestions = questions.filter((q, iq) => iq !== index);

      if (questionToDelete.default && filteredQuestions.length >= 1) {
        filteredQuestions[0].default = true;
      }

      setStandardQuestions(filteredQuestions);
      setPendingQuestions(true);
    },
    [setStandardQuestions, standardQuestions]
  );

  const moveStandardQuestion = useCallback(
    (index: number, dir: QuestionMoveDir) => {
      const questions = [...standardQuestions];
      const [currentQ] = questions.splice(index, 1);

      const pos = dir === QuestionMoveDir.up ? index - 1 : index + 1;
      questions.splice(pos, 0, currentQ);

      const orderedQuestions = questions.map((q, i) => ({
        ...q,
        order: i + 1
      }));

      setStandardQuestions(orderedQuestions);
      setPendingQuestions(true);
    },
    [setStandardQuestions, standardQuestions]
  );

  const saveStandardQuestion = useCallback(
    (index: number, data: Partial<TemplateQuestion>) => {
      const questions = [...standardQuestions];
      const updatedQuestion = {
        ...questions[index],
        ...data
      };

      const updatedQuestionMeta = { ...questionMeta };

      questions[index] = updatedQuestion;

      // sync fields with question meta
      if (updatedQuestion.fields.length > 0) {
        updatedQuestion.fields.forEach(field => {
          if (updatedQuestionMeta[field.name])
            updatedQuestionMeta[field.name] = field.value;
        });
      }

      setStandardQuestions(questions);
      setQuestionMeta(updatedQuestionMeta);
      setPendingQuestions(true);
    },
    [setStandardQuestions, standardQuestions, questionMeta]
  );

  const submitStandardQuestions = useCallback(
    ({ onSuccess }: SubmitProp) => {
      if (!managedCampaign) return;

      const resetStandardQuestions = resetCustomKPI(standardQuestions);

      mutateCampaign(
        {
          ...managedCampaign?.details,
          draft_settings: {
            ...managedCampaign?.details?.draft_settings,
            questions: resetStandardQuestions,
            question_meta: questionMeta
          }
        },
        {
          onSuccess: () => {
            refetchCampaign();
            setPendingQuestions(false);
            onSuccess?.();
          },
          ...onMutateError
        }
      );
    },
    [
      mutateCampaign,
      setPendingQuestions,
      standardQuestions,
      managedCampaign,
      questionMeta,
      onMutateError,
      refetchCampaign
    ]
  );

  const value = useMemo(
    () => ({
      standardTemplateQuestions,
      standardQuestions,
      addStandardQuestion,
      deleteStandardQuestion,
      moveStandardQuestion,
      saveStandardQuestion,
      hasPendingQuestions,
      submitStandardQuestions,
      setPrimaryQuestion,
      customKPIErrors,
      hasCustomKPIErrors,
      kpiErrors,
      hasKPIErrors
    }),
    [
      standardTemplateQuestions,
      standardQuestions,
      addStandardQuestion,
      deleteStandardQuestion,
      moveStandardQuestion,
      saveStandardQuestion,
      hasPendingQuestions,
      submitStandardQuestions,
      setPrimaryQuestion,
      customKPIErrors,
      hasCustomKPIErrors,
      kpiErrors,
      hasKPIErrors
    ]
  );

  return (
    <StandardQuestionsContext.Provider value={value}>
      {children}
    </StandardQuestionsContext.Provider>
  );
};
