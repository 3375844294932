import React, { Fragment } from 'react';

import { Tag, Flex, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type SelectedQualificationsProps = {
  qualifications?: any[];
};

export const SelectedQualifications = ({
  qualifications
}: SelectedQualificationsProps) => {
  return (
    <Flex
      lss={{
        flexDirection: 'row',
        gap: '0.25rem',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      {qualifications?.map(({ label, readOnlyValue }, i) => {
        return (
          <Fragment key={`${label}_${i}`}>
            <Tag
              palette="disabled"
              lss={{
                color: 'gray800',
                overflow: 'hidden',
                whiteSpace: 'normal'
              }}
            >
              {label}: {readOnlyValue}
            </Tag>{' '}
            {i !== qualifications?.length - 1 && (
              <Text lss={{ color: 'gray900' }}>{t('labels.and')}</Text>
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
};
