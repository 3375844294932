import * as Sentry from '@sentry/react';
import {
  QueryClient,
  UseMutationOptions,
  useQuery as useRQuery,
  useMutation as useRMutation,
  UseQueryOptions
} from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';

import { useAuth } from '@lucidhq/ui-auth';

import { URLS } from '~/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

axios.interceptors.response.use(
  (response: any) => response,
  function (error) {
    const { response } = error;
    const errorCode: string | null = response?.status
      ? `${response?.status}`
      : null;

    if (errorCode) {
      Sentry.withScope(scope => {
        // Intentionally not skipping 403 and 404 to collect more data since this is the initial implementation
        // Later on after beate a triage will be done to cover only statuses that matters to the client.
        if (errorCode[0] === '4') {
          scope.setLevel('info');
        }

        if (errorCode[0] === '5') {
          scope.setLevel('warning');
        }

        scope.setExtra('request', JSON.stringify(error.request));
        scope.setExtra('response', JSON.stringify(error.response?.data));

        Sentry.captureException(error);
      });
    }

    return Promise.reject(error);
  }
);

export function useQuery<TResponse>(
  opts: UseQueryOptions<TResponse>,
  queryOpts: AxiosRequestConfig
) {
  const { getAccessToken } = useAuth();

  return useRQuery<TResponse>({
    ...opts,
    queryFn: async (): Promise<TResponse> => {
      const accessToken = await getAccessToken();

      const { data } = await axios<TResponse>({
        baseURL: URLS.API,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        ...queryOpts
      });

      return data;
    }
  });
}

export function useMutation<TVariables, TResponse>(
  opts: UseMutationOptions<TResponse, unknown, TVariables>,
  mutationOpts: AxiosRequestConfig
) {
  const { getAccessToken } = useAuth();

  return useRMutation<TResponse, unknown, TVariables>({
    ...opts,
    mutationFn: async (variables: TVariables): Promise<TResponse> => {
      const accessToken = await getAccessToken();

      const { data } = await axios<TResponse>({
        baseURL: URLS.API,
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        ...mutationOpts,
        ...(variables && { data: variables })
      });

      return data;
    }
  });
}
