import React from 'react';

import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';

import { useCampaignManager } from '../../CampaignManagerProvider';

type SetDefaultAudienceFilterProps = {
  id: number;
  children: ({
    isLoading,
    onSetAsDefault
  }: {
    isLoading: boolean;
    onSetAsDefault: () => void;
  }) => React.ReactNode;
  onSave: () => void;
};

export const SetDefaultAudienceFilter = ({
  id,
  children,
  onSave
}: SetDefaultAudienceFilterProps) => {
  const { managedCampaign, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const handleSetAsDefault = () => {
    if (!managedCampaign) return;

    const currentTargets =
      managedCampaign.details.draft_settings.target_audiences || [];

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details?.draft_settings,
          target_audiences: currentTargets.map((t, k) => ({
            ...t,
            is_default: k === id
          }))
        }
      },
      {
        onSuccess: () => {
          onSave();
        },
        ...onMutateError
      }
    );
  };

  return <>{children({ onSetAsDefault: handleSetAsDefault, isLoading })}</>;
};
