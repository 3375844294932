import React from 'react';
import { useParams } from 'react-router-dom';

import { CampaignTypes } from '~/types';

import { ErrorDialogProvider } from '../Shared/ErrorDialogProvider';
import { CampaignManagerContainer } from './CampaignManagerContainer';
import { CampaignManagerProvider } from './CampaignManagerProvider';
import { CampaignManagerStepIds } from './useCampaignManagerSteps';

export const CampaignManager = () => {
  const { campaignId, stepId, typeId } = useParams<{
    campaignId?: string;
    typeId: CampaignTypes;
    // Coming formatted in lower case to keep URL consistent
    stepId?: CampaignManagerStepIds;
  }>();

  return (
    <ErrorDialogProvider>
      <CampaignManagerProvider
        campaignId={campaignId}
        stepId={stepId && (stepId.toUpperCase() as CampaignManagerStepIds)}
        type={typeId}
      >
        <CampaignManagerContainer />
      </CampaignManagerProvider>
    </ErrorDialogProvider>
  );
};
