import {
  CampaignFormValues,
  CampaignReportFetchResponse
} from '~/impact-measurement/pages/types';

export const EMPTY_ITEMS = { items: [] };

export const EMPTY_QUALIFICATION: Omit<TargetQualification, 'question_type'> = {
  marketplace_question_id: 0,
  qual_name: '',
  precodes: [],
  answer_type: 'contains'
};

export const EMPTY_TARGET: TargetAudience = {
  target_name: '',
  is_default: false,
  qualifications: [EMPTY_QUALIFICATION]
};

export const initialCampaignReport: CampaignReportFetchResponse = {
  account_id: '',
  campaign_data_updated_at: '',
  campaign_id: '',
  campaign_name: '',
  client_name: '',
  control_survey_id: 0,
  created_at: '',
  country_language_id: 1,
  default_question_id: 0,
  default_question_name: '',
  draft_settings: {
    questions: [],
    baseline_questions: [],
    expected_impressions_count: 0,
    expected_impressions_count_linear_tv: 0,
    target_audiences: [],
    // Custom Only
    quota_size: 0,
    quota_scheme: 'open',
    control_survey_enabled: true,
    control_gemini_enabled: true,
    // Impact Measurement Only
    extra_questions_enabled: false
  },
  end_date: '',
  errors: null,
  exposed_survey_id: 0,
  industry_id: -1,
  permission_id: 0,
  start_date: '',
  salesforce_id: '',
  status: 'draft',
  updated_at: '',
  channels: [],
  // Custom Only
  project_manager_user_id: 0
};

export const initialCampaignFormValues: CampaignFormValues = {
  campaign_name: '',
  start_date: '',
  end_date: '',
  client_name: '',
  country_language_id: 1,
  industry_id: -1,
  permission_id: 0,
  questions: [],
  baseline_questions: [],
  salesforce_id: '',
  draft_settings: initialCampaignReport.draft_settings,
  channels: [],
  is_usage_disabled: false,
  project_manager_user_id: 0
};

export const initialQuestionsLibraryQuestions: QuestionsLibraryQuestion[] = [];

export const initialQuestions: QuestionsResponse = EMPTY_ITEMS;

export const initialDemographics: DemographicsResponse = EMPTY_ITEMS;

export const initialLiftCampaignReport: LiftCampaignReportResponse = EMPTY_ITEMS;

export const initialLiftReports: LiftReportReponseTypes = {
  lift: {
    confidence_level: 0,
    control_count: 0,
    control_percentage: 0,
    enough_sample_size: false,
    enough_sample_size_on: [],
    exposed_count: 0,
    exposed_percentage: 0,
    in_target_control_count: 0,
    in_target_exposed_count: 0,
    lift_percentage: 0,
    margin_of_error: 0,
    question: {
      question_type: 'lift',
      question_has_predefined_answers: false,
      question_id: 0,
      question_name: '',
      question_text: '',
      in_target_answers: [],
      id: 0
    },
    statistically_significant: false,
    total_count: 0
  },
  publishers: EMPTY_ITEMS,
  placements: EMPTY_ITEMS,
  creatives: EMPTY_ITEMS,
  adchannels: EMPTY_ITEMS,
  devices: EMPTY_ITEMS,
  frequency: EMPTY_ITEMS,
  adlengths: EMPTY_ITEMS,
  networks: EMPTY_ITEMS,
  dayparts: EMPTY_ITEMS,
  media_channel: EMPTY_ITEMS
};

export const initialOverviewLiftReports: OverviewLiftReports = {
  age: EMPTY_ITEMS,
  gender: EMPTY_ITEMS,
  frequency: EMPTY_ITEMS,
  cross_channel: EMPTY_ITEMS,
  media_channel: EMPTY_ITEMS
};

export const initialCampaignStats: CampaignStats = {
  avg_lift: 0,
  days_completed: 0,
  days_left: 0,
  default_question_lift: initialLiftReports.lift,
  statistically_significant: false,
  total_impressions: 0
};

export const initialConfidenceLevels: ConfidenceLevelResponse = {
  confidence_levels: []
};

export const initialLiftReportsTarget: TargetResponse = {
  target_audiences: []
};

export const initialIndustryById = {};

export const initialTargetAndDemo: TargetAndDemo = {
  demographics: initialDemographics.items,
  target_audiences: initialLiftReportsTarget.target_audiences
};
