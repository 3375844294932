import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, BusyButton, Button, Flex } from '@lucidhq/lucidium';

import { ROUTES } from '~/constants';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../CampaignManagerProvider';
import { StepLayout } from '../Steps/StepLayout';
import { LaunchCampaign } from './Actions/LaunchCampaign';
import { LaunchServiceCampaign } from './Actions/LaunchServiceCampaign';
import { AudienceBlock } from './Blocks/AudienceBlock';
import { CampaignBlock } from './Blocks/CampaignBlock';
import { MediaTrackingBlock } from './Blocks/MediaTrackingBlock';
import { ServiceCampaignBlock } from './Blocks/ServiceCampaignBlock';
import { SurveyBlock } from './Blocks/SurveyBlock';
import { ExportPreview, EXPORT_PREVIEW_ID } from './Misc/ExportPreview';
import { ReviewLayout } from './Misc/ReviewLayout';

export const CampaignReviewStep = () => {
  const history = useHistory();
  const { isService, isSoftware, can } = useCampaignManager();

  const handleLaunch = () => history.push(ROUTES.adminCampaigns);

  return (
    <Box id={EXPORT_PREVIEW_ID} lss={{ background: 'white' }}>
      <StepLayout.Container>
        <StepLayout.Header
          lss={{ position: 'relative' }}
          title={t('campaignManager.review.header.title')}
          description={t(
            `campaignManager.review.header.${
              isSoftware ? 'softwareDescription' : 'serviceDescription'
            }`
          )}
        >
          <Box lss={{ position: 'absolute', top: 0, right: 0 }}>
            <ExportPreview />
          </Box>
        </StepLayout.Header>
        <StepLayout.Content>
          <Flex lss={{ flexDirection: 'column', flex: '1' }}>
            <ReviewLayout.Block
              title={t('campaignManager.review.campaign.title')}
            >
              {isService ? <ServiceCampaignBlock /> : <CampaignBlock />}
            </ReviewLayout.Block>

            <ReviewLayout.Block
              title={t('campaignManager.review.mediaTracking.title')}
            >
              <MediaTrackingBlock />
            </ReviewLayout.Block>

            {isSoftware && (
              <>
                <ReviewLayout.Block
                  title={t('campaignManager.review.survey.title')}
                >
                  <SurveyBlock />
                </ReviewLayout.Block>

                <ReviewLayout.Block
                  title={t('campaignManager.review.audience.title')}
                >
                  <AudienceBlock />
                </ReviewLayout.Block>
              </>
            )}

            <Flex lss={{ mb: 'xxxl', justifyContent: 'center' }}>
              {can.edit && (
                <>
                  {isSoftware && (
                    <LaunchCampaign onLaunch={handleLaunch}>
                      {({ onToggle }) => (
                        <Button onClick={onToggle}>
                          {t('campaignManager.actions.launch')}
                        </Button>
                      )}
                    </LaunchCampaign>
                  )}

                  {isService && (
                    <LaunchServiceCampaign onLaunch={handleLaunch}>
                      {({ onToggle, isLoading }) => (
                        <BusyButton onClick={onToggle} isBusy={isLoading}>
                          {t('campaignManager.actions.launch')}
                        </BusyButton>
                      )}
                    </LaunchServiceCampaign>
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </StepLayout.Content>
      </StepLayout.Container>
    </Box>
  );
};
