import React from 'react';

import { Flex, Text, TextArea } from '@lucidhq/lucidium';

type PreviewProps = {
  tag: string;
  type: 'url' | 'html';
};

export const PreviewSection: React.FC<PreviewProps> = ({ tag, type }) => (
  <Flex lss={{ flexDirection: 'column' }}>
    <Flex
      lss={{
        alignItems: 'center',
        justifyContent: 'space-between',
        my: 'sm'
      }}
    >
      <Text lss={{ fontWeight: 'bold' }}>
        {`${type.toUpperCase()}`} Preview:
      </Text>
    </Flex>
    <TextArea value={tag} style={{ height: 150, lineHeight: 1.3 }} readOnly />
  </Flex>
);
