import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// As of right now, Appcues does not support dynamic URLs when triggering permalink flows.
// This route was created to extract the appcue id and redirect the user back to the page where the flow should be started.

export const AppcueRedirectPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.location.href = document.referrer + location.search;
  });

  return <></>;
};
