import React from 'react';

import {
  Box,
  Loading,
  LoadingProps,
  LssProp,
  Message
} from '@lucidhq/lucidium';

type LoadingContentWrapperProps = {
  isLoading: boolean;
  loadingMessage?: LoadingProps['message'];
  spinnerSize?: LoadingProps['spinnerSize'];
  error?: ErrorResponse;
  showContent: boolean;
  fallBackComponent?: React.ReactNode;
  wrapperLss?: LssProp;
  errorType?: 'message' | 'response';
};

export const LoadingContentWrapper: React.FC<LoadingContentWrapperProps> = ({
  isLoading,
  loadingMessage,
  spinnerSize = 'lg',
  error = null,
  showContent,
  fallBackComponent = null,
  wrapperLss,
  errorType = 'response',
  children
}) => {
  const messageProp =
    error && errorType === 'message'
      ? { message: error.message }
      : { response: error };
  return (
    <Box lss={wrapperLss}>
      {isLoading ? (
        <Loading spinnerSize={spinnerSize} message={loadingMessage} />
      ) : error ? (
        <Message {...messageProp} />
      ) : showContent ? (
        children
      ) : (
        fallBackComponent
      )}
    </Box>
  );
};
