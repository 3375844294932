import React, { useCallback, useMemo, useState } from 'react';

import { LssProp, Text } from '@lucidhq/lucidium';

import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { useMediaTracking } from '../../MediaTrackingStep/useMediaTracking';
import { StepLayout } from '../../Steps/StepLayout';
import { Colors, ReviewLayout } from '../Misc/ReviewLayout';
import { MediaTrackingNotRequired } from './MediaTrackingBlock/MediaTrackingNotRequired';
import { MediaTrackingWarning } from './MediaTrackingBlock/MediaTrackingWarning';

const headingLss: LssProp = {
  fontSize: '1.1rem',
  mb: 'xl'
};

export const MediaTrackingBlock = () => {
  const {
    goToStep,
    steps,
    managedCampaign,
    can,
    refetchCampaign
  } = useCampaignManager();
  const [mediaTrackingAck, setMediaTrackingAck] = useState<Boolean>(
    !!managedCampaign?.details.meta.mediaTrackingAck
  );
  const { universalGroups, siteServedGroups, tvMapping } = useMediaTracking();
  const { mutate } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const hasMediaTracking = useMemo(() => {
    return !!universalGroups.length || !!siteServedGroups.length || !!tvMapping;
  }, [universalGroups, siteServedGroups, tvMapping]);

  const handleDecline = useCallback(() => {
    if (!managedCampaign) return;

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details.draft_settings,
          ui: {
            ...managedCampaign.details.draft_settings.ui,
            mediaTrackingAck: true
          }
        }
      },
      {
        onSuccess: () => {
          refetchCampaign();
        }
      }
    );

    setMediaTrackingAck(true);
  }, [mutate, setMediaTrackingAck, managedCampaign, refetchCampaign]);

  return (
    <ReviewLayout.Content color={Colors.BLUE}>
      {!hasMediaTracking && (
        <ReviewLayout.Item>
          {!mediaTrackingAck && (
            <MediaTrackingWarning
              showCTAs={can.edit}
              onConfirm={() => goToStep(steps.MEDIA_TRACKING.id)}
              onDecline={() => handleDecline()}
            />
          )}

          {mediaTrackingAck && (
            <MediaTrackingNotRequired
              disabled={!can.edit}
              onClick={() => goToStep(steps.MEDIA_TRACKING.id)}
            />
          )}
        </ReviewLayout.Item>
      )}
      {hasMediaTracking && (
        <>
          {(can.edit || can.limitedEdit) && (
            <ReviewLayout.Edit
              onClick={() => goToStep(steps.MEDIA_TRACKING.id)}
            />
          )}

          {!!universalGroups.length && (
            <ReviewLayout.Item>
              <Text lss={headingLss}>
                {t('campaignManager.mediaTracking.universalTag.title')}
              </Text>
              <StepLayout.List>
                {universalGroups.map(pixelGroup => (
                  <StepLayout.ListItem key={pixelGroup.id}>
                    <StepLayout.ListTitle>
                      {pixelGroup.details[0].ad_server_name}
                    </StepLayout.ListTitle>
                  </StepLayout.ListItem>
                ))}
              </StepLayout.List>
            </ReviewLayout.Item>
          )}

          {!!siteServedGroups.length && (
            <ReviewLayout.Item>
              <Text lss={headingLss}>
                {t('campaignManager.mediaTracking.siteServed.title')}
              </Text>
              <StepLayout.List>
                {siteServedGroups.map((pixelGroup, k) => (
                  <StepLayout.ListItem key={pixelGroup.id}>
                    <StepLayout.ListTitle>
                      {t('campaignManager.mediaTracking.siteServed.label', {
                        id: k + 1
                      })}
                    </StepLayout.ListTitle>
                  </StepLayout.ListItem>
                ))}
              </StepLayout.List>
            </ReviewLayout.Item>
          )}

          {!!(managedCampaign?.details.meta.isLinearTV && tvMapping) && (
            <ReviewLayout.Item>
              <Text lss={headingLss}>
                {t('campaignManager.mediaTracking.linearTv.title')}
              </Text>
              <StepLayout.List>
                {managedCampaign?.details.meta.isLinearTV && tvMapping && (
                  <StepLayout.ListItem>
                    <StepLayout.ListTitle>
                      {tvMapping.partner_name}
                    </StepLayout.ListTitle>
                    <StepLayout.ListCount>
                      {t('campaignManager.mediaTracking.linearTv.mediaCount', {
                        count: tvMapping.partner_campaigns_ids.length
                      })}
                    </StepLayout.ListCount>
                  </StepLayout.ListItem>
                )}
              </StepLayout.List>
            </ReviewLayout.Item>
          )}
        </>
      )}
    </ReviewLayout.Content>
  );
};
