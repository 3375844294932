import React, { useMemo } from 'react';

import { t } from 'i18next';
import * as Yup from 'yup';

import {
  Dialog,
  DialogContent,
  useToggle,
  Flex,
  InlineNotification
} from '@lucidhq/lucidium';
import { Form } from '@lucidhq/lucidium/components/FormV2';

import { useFetchTargetedRecruitment } from '~/components/Shared/hooks/useFetchTargetedRecruitment';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import {
  decorateTROptions,
  getInitialValues,
  mapValuesToSegment
} from '~/utils/targetedRecruitment';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { DemographicSection } from './DemographicSection';
import { FormActions } from './FormActions';
import { GeoLocationSection } from './GeoLocationSection';
import { NameField } from './NameField';
import { TargetedRecruitmentDialogHeader } from './TargetedRecruitmentDialogHeader';

export type TargetedRecruitmentForm = {
  name: string;
  age?: Set<string>;
  gender?: Set<string>;
  hhi?: Set<string>;
  region?: Set<string>;
  state?: Set<string>;
  DMA?: Set<string>;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  age: Yup.mixed(),
  gender: Yup.mixed(),
  hhi: Yup.mixed(),
  region: Yup.mixed(),
  state: Yup.mixed(),
  DMA: Yup.mixed()
});

type ManageTargetedRecruitmentProps = {
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const ManageTargetedRecruitment = ({
  children
}: ManageTargetedRecruitmentProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const {
    managedCampaign,
    refetchCampaign,
    onMutateError
  } = useCampaignManager();
  //TODO: add loading and error states
  const { data } = useFetchTargetedRecruitment({
    campaignId: managedCampaign?.details.campaign_id
  });

  const { mutate, isLoading } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const trOptions = decorateTROptions(data?.items);

  const initValues = useMemo(() => getInitialValues(managedCampaign), [
    managedCampaign
  ]);

  const handleSubmit = (formValues: any) => {
    if (!managedCampaign) return;
    const targeted_recruitment = mapValuesToSegment(formValues, trOptions);

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...managedCampaign?.details?.draft_settings,
          targeted_recruitment
        }
      },
      {
        onSuccess: () => {
          refetchCampaign();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog
        isOpen={isOpen}
        onClose={handleToggle}
        lss={{ width: '48rem', height: '-webkit-fill-available' }}
      >
        <TargetedRecruitmentDialogHeader>
          {t('campaignManager.targetAudience.targetedRecruitment.modal.header')}
        </TargetedRecruitmentDialogHeader>
        <DialogContent
          lss={{
            display: 'flex',
            minHeight: '38rem',
            flexGrow: 2,
            padding: 'none'
          }}
        >
          <Flex
            lss={{
              p: 'xl',
              pb: 'xxs'
            }}
          >
            <InlineNotification
              palette="danger"
              lss={{ p: 'lg', paddingBottom: 'none', flexGrow: 1 }}
            >
              {t(
                'campaignManager.targetAudience.targetedRecruitment.modal.alert'
              )}
            </InlineNotification>
          </Flex>

          <Form
            initialValues={initValues}
            validationSchema={validationSchema}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            wrapperLss={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: '100%'
            }}
          >
            <Flex
              lss={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2.5rem',
                paddingBottom: '3rem',
                flexGrow: 2
              }}
            >
              <NameField />
              <DemographicSection
                label={t(
                  'campaignManager.targetAudience.targetedRecruitment.modal.labels.age'
                )}
                name="age"
                options={trOptions?.age?.decoratedOptions}
              />
              <DemographicSection
                label={t(
                  'campaignManager.targetAudience.targetedRecruitment.modal.labels.gender'
                )}
                name="gender"
                options={trOptions?.gender?.decoratedOptions}
              />
              <DemographicSection
                label={t(
                  'campaignManager.targetAudience.targetedRecruitment.modal.labels.hhi'
                )}
                name="hhi"
                options={trOptions?.hhi?.decoratedOptions}
              />
              <GeoLocationSection
                label={t(
                  'campaignManager.targetAudience.targetedRecruitment.modal.labels.location'
                )}
                regionOptions={trOptions?.region?.decoratedOptions}
                stateOptions={trOptions?.state?.decoratedOptions}
                dmaOptions={trOptions?.DMA?.decoratedOptions}
              />
            </Flex>
            <FormActions handleToggle={handleToggle} isLoading={isLoading} />
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};
