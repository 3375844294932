import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  Text,
  useToggle
} from '@lucidhq/lucidium';

import { useLaunchCustomCampaign } from '~/components/Shared/hooks/useLaunchCampaign';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type LaunchServiceCampaignProps = {
  onLaunch?: () => void;
  children: ({
    onToggle,
    isLoading
  }: {
    onToggle: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
};

export const LaunchServiceCampaign = ({
  children,
  onLaunch
}: LaunchServiceCampaignProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const [launched, setLaunched] = useState<boolean>(false);
  const { managedCampaign, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useLaunchCustomCampaign(
    managedCampaign?.details.campaign_id
  );

  const handleLaunched = () => {
    onLaunch?.();
    handleToggle();
  };

  const handleLaunch = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          handleToggle();
          setLaunched(true);
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onToggle: handleLaunch, isLoading })}

      <Dialog
        isOpen={isOpen}
        onClose={handleToggle}
        lss={{ maxWidth: '43rem' }}
      >
        <DialogHeader
          palette="disabled"
          onClose={launched ? handleLaunched : handleToggle}
        >
          {t('campaignManager.review.launch.dialog.header')}
        </DialogHeader>

        <DialogContent>
          {launched && (
            <Text
              lss={{
                textScale: 'heading2',
                m: 'xxxl',
                textAlign: 'center'
              }}
            >
              {t('campaignManager.review.launch.dialog.successMessage')}
            </Text>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
