import React from 'react';

import { Flex, Text } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { AnswersRadioGroup } from './AnswersRadioGroup';
import { CustomAnswerOptions } from './CustomAnswerOptions';
import { CustomAnswersContainer } from './CustomAnswersContainer';
import { GridAnswerOptions } from './GridAnswerOptions';
import { NonGridAnswerOptions } from './NonGridAnswerOptions';
import { TextAnswerOption } from './TextAnswerOption';
import { TextInterpretedAnswerOption } from './TextInterpretedAnswerOption';

type AnswerSectionProps = {
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
  readOnly?: boolean;
  hasCustomKPIErrors?: boolean;
  customKPIErrors?: Record<string, any>;
  questionIndex?: number;
};

const TEXT = 'text';
const TEXT_INTERPRETED = 'text-interpreted';

export const AnswerSection = ({
  question,
  onSave,
  readOnly = false,
  hasCustomKPIErrors,
  customKPIErrors,
  questionIndex
}: AnswerSectionProps) => {
  const { can } = useCampaignManager();

  const isGridAnswerType = question?.question_type?.includes('table');
  const isTextAnswerType = question?.question_type === TEXT;
  const isTextInterpretedAnswerType =
    question?.question_type === TEXT_INTERPRETED;
  const isMultiOrSingleCustomKPI =
    question.meta.isCustomKPI &&
    !question?.isBehavioralCustomKPI &&
    !isGridAnswerType &&
    !isTextAnswerType;
  const isTextVisible =
    !readOnly && (!!question?.meta?.isStandard || isMultiOrSingleCustomKPI);

  //NOTE: this boolean is aware of too many pieces of logic a refactor of this entire section/component would be beneficial
  const isCustomKPI =
    !readOnly &&
    question.meta.isCustomKPI &&
    (!question.use_answer_meta ||
      (question.use_answer_meta && isGridAnswerType));
  !question?.question_type.includes('table');
  const isRadioGroupVisible =
    !readOnly &&
    question.meta.isCustomKPI &&
    can.editKPI &&
    question.question_type !== 'text';

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        gap: '0.5rem',
        borderTop: isTextAnswerType && !readOnly ? 'regular' : 'none',
        width: isTextAnswerType && !readOnly ? '100%' : 'inherit',
        mt: isTextAnswerType && !readOnly ? 'xxl' : 'none'
      }}
    >
      {isRadioGroupVisible && (
        <AnswersRadioGroup
          value={question?.use_answer_meta}
          onSave={onSave}
          question={question}
        />
      )}
      <Flex>
        {isTextVisible && (
          <Text lss={{ textScale: 'control' }}>
            {t(
              'campaignManager.survey.standardQuestions.questionCard.inTargetAnswers'
            )}
          </Text>
        )}
      </Flex>
      {isTextAnswerType ? (
        <TextAnswerOption readOnly={readOnly} />
      ) : isTextInterpretedAnswerType ? (
        <TextInterpretedAnswerOption
          readOnly={readOnly}
          question={question}
          onSave={onSave}
        />
      ) : (isGridAnswerType && !question.meta.isCustomKPI) ||
        (isGridAnswerType && question.meta.isCustomKPI && readOnly) ? (
        <GridAnswerOptions
          question={question}
          onSave={onSave}
          readOnly={readOnly}
        />
      ) : isCustomKPI ? (
        <CustomAnswersContainer question={question}>
          <CustomAnswerOptions
            question={question}
            onSave={onSave}
            readOnly={readOnly}
            hasCustomKPIErrors={hasCustomKPIErrors}
            customKPIErrors={customKPIErrors}
            questionIndex={questionIndex}
            isGridAnswerType={isGridAnswerType}
          />
        </CustomAnswersContainer>
      ) : (
        <NonGridAnswerOptions
          question={question}
          onSave={onSave}
          readOnly={readOnly}
          hasNoneOfTheAbove={!!question.none_of_the_above_answer}
        />
      )}
    </Flex>
  );
};
