import React from 'react';
import ReactDOM from 'react-dom';

import {
  Button,
  Dialog,
  Text,
  DialogContent,
  DialogActions
} from '@lucidhq/lucidium';
import { LucidiumProvider } from '@lucidhq/lucidium';
import { WarningIcon } from '@lucidhq/lucidium/icons';

import { AudienceTheme } from '~/utils/styles';

export const UserConfirmation = (
  message: string,
  callback: (callbackState: boolean) => void
) => {
  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);
  const closeModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container);
    callback(callbackState);
  };

  const textObj = JSON.parse(message);

  ReactDOM.render(
    <LucidiumProvider theme={AudienceTheme}>
      <Dialog
        isOpen={true}
        closeOnEscPress={false}
        lss={{
          width: 400
        }}
      >
        <DialogContent
          lss={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <WarningIcon size={60} />
          <Text
            lss={{
              textScale: 'heading4',
              fontWeight: 'bold',
              textAlign: 'center',
              my: 'sm'
            }}
          >
            {textObj.messageHeading}
          </Text>
          <Text lss={{ textScale: 'content', textAlign: 'center' }}>
            {textObj.messageText}
          </Text>
        </DialogContent>

        <DialogActions>
          <Button
            emphasis="high"
            palette="danger"
            lss={{ mr: 'xs' }}
            onClick={() => closeModal(true)}
          >
            {textObj.confirmText}
          </Button>
          <Button
            emphasis="medium"
            palette="primary"
            onClick={() => closeModal(false)}
          >
            {textObj.cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </LucidiumProvider>,
    container
  );
};
