import React from 'react';

import { Box, Flex, Text } from '@lucidhq/lucidium';

import { StatusDot } from './StatusDot';

type CampaignClientNameProps = {
  campaign: AdminNavBarCampaign;
};

export const CampaignClientName: React.FunctionComponent<CampaignClientNameProps> = ({
  campaign
}) => (
  <Box
    lss={{
      mr: 'sm',
      whiteSpace: 'normal',
      tablet: {
        maxWidth: 340 as any,
        whiteSpace: 'nowrap'
      }
    }}
  >
    <Text
      as="h1"
      lss={{
        textScale: 'content',
        lineHeight: 1,
        my: 0,
        tablet: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          height: 18
        }
      }}
    >
      {campaign.campaign_name || campaign.campaign_id}
    </Text>
    <Flex>
      {campaign.client_name && (
        <>
          <Text
            as="p"
            lss={{
              m: 0,
              mr: 'sm',
              textScale: 'control',
              tablet: {
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          >
            {campaign.client_name}
          </Text>
          <Text lss={{ textScale: 'caption', color: 'gray600' }}>|</Text>
        </>
      )}
      <Box lss={{ height: 20 }}>
        {campaign.status && (
          <StatusDot
            status={campaign.status}
            lss={{
              ml: campaign.client_name ? undefined : (-10 as any)
            }}
          />
        )}
      </Box>
    </Flex>
  </Box>
);
