import React from 'react';

import { Box, Button, Flex, InlineNotification, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type AudienceWarningProps = {
  showCTAs: boolean;
  onConfirm: () => void;
  onDecline: () => void;
};

export const AudienceWarning = ({
  showCTAs,
  onConfirm,
  onDecline
}: AudienceWarningProps) => {
  return (
    <Flex lss={{ flexDirection: 'column' }}>
      <InlineNotification
        lss={
          showCTAs
            ? {
                mb: 'xxl'
              }
            : {}
        }
        palette="warning"
        title={t('campaignManager.review.audience.warning.title')}
      >
        <Box>
          <Text lss={{ mb: 'lg', display: 'block' }}>
            {t('campaignManager.review.audience.warning.description')}
          </Text>
        </Box>
      </InlineNotification>
      {showCTAs && (
        <Flex lss={{ justifyContent: 'center', gap: '1rem' }}>
          <Button emphasis="medium" onClick={onDecline}>
            {t('campaignManager.review.audience.warning.decline')}
          </Button>
          <Button onClick={onConfirm}>
            {t('campaignManager.review.audience.warning.confirm')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
