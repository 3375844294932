import { useMutation } from '~/utils/rq';

type LaunchCampaignResponse = {
  detail: string;
};

export const useLaunchIMCampaign = (campaignId?: string) => {
  const URL = `/campaigns/${campaignId}/launch`;

  return useMutation<{}, LaunchCampaignResponse>(
    {},
    {
      method: 'post',
      url: URL
    }
  );
};

export const useLaunchCustomCampaign = (campaignId?: string) => {
  const URL = `/internal/admin/services/campaigns/${campaignId}/launch`;

  return useMutation(
    {},
    {
      method: 'post',
      url: URL
    }
  );
};
