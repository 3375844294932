import React, { useEffect } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Flex, RangeSlider, RangeSliderValue, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

export const MIN_AGE = 13;
export const MAX_AGE = 99;

type RangeInputProps = {
  value?: RangeSliderValue;
  onChange: (value: RangeSliderValue) => void;
};

export const RangeInput = ({ value, onChange }: RangeInputProps) => {
  const [onChangeDebounced] = useDebouncedCallback(onChange, 1000);

  useEffect(() => {
    if (!value) onChange([MIN_AGE, MAX_AGE]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex lss={{ flexDirection: 'column', my: 'md', maxWidth: 400 }}>
      <RangeSlider
        value={value || [MIN_AGE, MAX_AGE]}
        onChange={onChangeDebounced}
        min={MIN_AGE}
        max={MAX_AGE}
        leftInputLabel={
          <Text lss={{ color: 'primary500', textScale: 'control' }}>
            {t(`form.labels.minAge`)}
          </Text>
        }
        rightInputLabel={
          <Text lss={{ color: 'primary500', textScale: 'control' }}>
            {t(`form.labels.maxAge`)}
          </Text>
        }
      />
    </Flex>
  );
};
