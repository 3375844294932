export const initialAdminCampaign: AdminNavBarCampaign = {
  status: '' as CampaignStatus,
  campaign_id: '',
  campaign_name: '',
  control_survey_id: 0,
  end_date: '',
  exposed_survey_id: 0,
  product_type: 'software',
  start_date: '',
  client_name: ''
};
