import React, { useEffect, useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { Select } from '@lucidhq/lucidium';
import { Field, useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import {
  Salesforce,
  useFetchSalesforce
} from '~/components/Shared/hooks/useFetchSalesforce';
import { searchIcon } from '~/impact-measurement/pages/Admin/lib';
import { t } from '~/utils/i18n';

export const SalesforceField = ({
  readOnly = false
}: {
  readOnly?: boolean;
}) => {
  const {
    values: { salesforce_id },
    touched
  } = useFormikContext<{ salesforce_id: string }>();

  const [value, setValue] = useState('');
  const { data } = useFetchSalesforce({ value, isEnabled: true });

  const [debouncedSetValue] = useDebouncedCallback(setValue, 500);

  useEffect(() => {
    if (!touched.salesforce_id) {
      setValue(salesforce_id || '');
    }
  }, [salesforce_id, setValue, touched]);

  return (
    <>
      <Field
        id="salesforceId"
        name="salesforce_id"
        label={t(`forms.labels.salesforce`)}
        readOnly={readOnly}
        render={fieldProps => (
          <Select<Salesforce, 'salesforce_id'>
            {...fieldProps}
            labelKey="salesforce_name"
            valueKey="salesforce_id"
            isClearable
            components={searchIcon(false)}
            options={data?.items}
            loadingMessage={() => t('forms.placeholders.searchingSalesforce')}
            noOptionsMessage={({ inputValue = '' }) =>
              inputValue.length < 3
                ? t('forms.placeholders.reqsSalesforce')
                : t('forms.placeholders.noopSalesforce')
            }
            onInputChange={value => value && debouncedSetValue(value)}
          />
        )}
      />
    </>
  );
};
