import React from 'react';
import { MdSettings } from 'react-icons/md';

import { UserDropdownAction } from '@lucidhq/lucidium';

export type CustomUserActionsProps = {
  onToggle: () => void;
};
export const CustomUserActions = ({ onToggle }: CustomUserActionsProps) => (
  <>
    <UserDropdownAction
      icon={MdSettings}
      text="Admin"
      to={'/admin'}
      onClick={onToggle}
    />
  </>
);
