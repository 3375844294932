export enum IM_FLAGS {
  TV_CAMPAIGN_MAPPING = 'TV_CAMPAIGN_MAPPING',
  BENCHMARKS = 'BENCHMARKS',
  CUSTOM_CAMPAIGNS = 'CUSTOM_CAMPAIGNS',
  DISABLE_USAGE = 'DISABLE_USAGE',
  APPCUES_TESTING = 'APPCUES_TESTING',
  SOCIAL_OTS = 'SOCIAL_OTS', //TODO: This was only for an experimental toggle and should be removed during beta testing
  SPC_GROUPS = 'SPC_GROUPS',
  CCv2 = 'CCv2',
  DISNEY_THEME = 'DISNEY_THEME',
  TARGETED_RECRUITMENT = 'TARGETED_RECRUITMENT',
  SOCIAL_REPORTING_GA = 'SOCIAL_REPORTING_GA',
  IP_MATCHING = 'IP_MATCHING',
  UNAIDED_AWARENESS = 'UNAIDED_AWARENESS'
}
