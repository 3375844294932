/**
 * Environment Variables
 */
const env = window['LUCID_SPA_CONFIG'];

export const APP_VERSION = env?.APP_VERSION || '';
export const DEPLOYMENT_ENV = env?.REACT_APP_DEPLOYMENT_ENV || 'local';
export const API_MOCK_ENABLED = import.meta.env.VITE_APP_API_MOCK || false;
export const SENTRY_DSN = env?.REACT_APP_SENTRY_DSN || '';
export const AMPLITUDE_KEY = env?.AMPLITUDE_KEY || '';
export const AMPLITUDE_ENABLED = env?.AMPLITUDE_ENABLED || false;

/**
 * Routes
 */
export const ROUTES = {
  home: '/',
  logout: '/logout',
  admin: '/impact-measurement/admin',
  im: '/impact-measurement/',
  adminCampaign: '/admin/campaign',
  adminCampaigns: '/admin/campaigns',
  appcues: '/appcues-redirect/',
  campaignManager: '/admin/campaign-manager/',
  campaignManagerLanding: '/admin/campaign-manager/new'
};

/**
 * API URLs
 */
export const URLS = {
  API: '/api/v1'
};

/**
 * Social Platforms - remove after endpoint fix
 */
export const SOCIAL_PLATFORMS = [
  {
    id: 1,
    social_platform_name: 'Facebook'
  },
  {
    id: 2,
    social_platform_name: 'Instagram'
  },
  {
    id: 3,
    social_platform_name: 'Linkedin'
  },
  {
    id: 4,
    social_platform_name: 'Pinterest'
  },
  {
    id: 5,
    social_platform_name: 'Snapchat'
  },
  {
    id: 6,
    social_platform_name: 'TikTok'
  },
  {
    id: 7,
    social_platform_name: 'Tumblr'
  },
  {
    id: 8,
    social_platform_name: 'X (Formerly Twitter)'
  },
  {
    id: 9,
    social_platform_name: 'WhatsApp'
  },
  {
    id: 10,
    social_platform_name: 'YouTube'
  },
  {
    id: 99,
    social_platform_name: 'Other'
  }
];
