import React from 'react';
import { MdDelete } from 'react-icons/md';

import { LssProp, TransparentButton } from '@lucidhq/lucidium';

type DeleteButtonProps = {
  id?: string;
  ariaLabel?: string;
  onClick?: () => void;
  size?: number;
  iconColor?: LssProp['color'];
  isDisabled?: boolean;
};

export const DeleteButton = ({
  id,
  onClick,
  size = 22,
  iconColor = 'secondary500',
  ariaLabel,
  isDisabled = false
}: DeleteButtonProps) => (
  <TransparentButton
    id={id}
    aria-label={ariaLabel}
    onClick={onClick}
    disabled={isDisabled}
    type="button"
    lss={{
      width: '40px',
      height: '40px',
      color: iconColor,
      borderRadius: 'rounded',
      transition: 'fast',
      ':hover': {
        textDecoration: 'none !important' as any,
        bg: 'gray300'
      },
      ':focus': {
        outline: 'none',
        bg: 'gray300'
      },
      ':active': {
        bg: 'gray300'
      }
    }}
  >
    <MdDelete size={size} />
  </TransparentButton>
);
