import { useQuery } from '~/utils/rq';

export type S2SPartner = {
  id: number;
  name: string;
};

type S2SPartners = S2SPartner[];

type S2SPartnersResponse = {
  partners: S2SPartners;
};

export const useFetchS2SPartners = () => {
  const URL = '/admin/s2s-partners?channels=linear_tv';

  return useQuery<S2SPartnersResponse>(
    {
      queryKey: [URL]
    },
    {
      method: 'get',
      url: URL
    }
  );
};
