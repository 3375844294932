import React from 'react';

import { Box, Button, Flex, InlineNotification, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type StandardQuestionProps = {
  onClick: () => void;
};

export const StandardQuestionWarning = ({ onClick }: StandardQuestionProps) => {
  return (
    <Flex lss={{ flexDirection: 'column' }}>
      <InlineNotification
        lss={{
          mb: 'xxl'
        }}
        palette="warning"
        title={t('campaignManager.review.survey.warning.title')}
      >
        <Box>
          <Text lss={{ mb: 'lg', display: 'block' }}>
            {t('campaignManager.review.survey.warning.description')}
          </Text>
        </Box>
      </InlineNotification>

      <Flex lss={{ justifyContent: 'center', gap: '1rem' }}>
        <Button onClick={onClick}>
          {t('campaignManager.review.survey.warning.confirm')}
        </Button>
      </Flex>
    </Flex>
  );
};
