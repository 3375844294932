import React from 'react';

import { H4, Text } from '@lucidhq/lucidium';

import { CenterBox } from '~/impact-measurement/components/CenterBox';

/**
 * This is a generic Error Boundary
 */
export class ErrorPage extends React.Component {
  state = { error: false };

  componentDidCatch() {
    this.setState({ error: true });
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    }

    return (
      <CenterBox>
        <H4>Ooops, there was an error processing this page.</H4>
        <Text>Our support staff is on it!</Text>
      </CenterBox>
    );
  }
}
