import { useCallback, useState } from 'react';

import { CampaignTypes } from '~/types';

/**
 * STATUS NOTE:
 * - OPEN defined as a page that can be visited
 * - INACTIVE is when all steps besides Campaign Details are inactive and cannot be visited if details is not completed
 * - COMPLETE is defined as a completed step (this varies on page by page basis)
 */

enum CampaignManagerStepStatus {
  OPEN = 'OPEN',
  COMPLETE = 'COMPLETE',
  INACTIVE = 'INACTIVE'
}

export enum CampaignManagerStepIds {
  DETAILS = 'DETAILS',
  MEDIA_TRACKING = 'MEDIA_TRACKING',
  SURVEY = 'SURVEY',
  AUDIENCE = 'AUDIENCE',
  REVIEW = 'REVIEW'
}

export enum SurveyStepIds {
  BRAND = 'BRAND',
  STANDARD_QUESTIONS = 'STANDARD_QUESTIONS',
  BEHAVIORAL_QUESTIONS = 'BEHAVIORAL_QUESTIONS'
}

type StatusItem = {
  id: CampaignManagerStepIds | SurveyStepIds;
  status: CampaignManagerStepStatus;
};

type CampaignManagerStep = {
  id: CampaignManagerStepIds;
  status: CampaignManagerStepStatus;
  isOptional: Boolean;
  nextId: CampaignManagerStepIds | null;
  prevId: CampaignManagerStepIds | null;
  childSteps?: StatusItem[];
};

type CampaignManagerSteps = {
  [v in CampaignManagerStepIds]: CampaignManagerStep;
};

export type CampaignManagerEnhancedStep = CampaignManagerStep & {
  meta: {
    isOpen: boolean;
    isComplete: boolean;
    isInactive: boolean;
  };
};

export type CampaignManagerEnhancedSteps = {
  [v in CampaignManagerStepIds]: CampaignManagerEnhancedStep;
};

/**
 * Default Steps
 */
export const SOFTWARE_STEPS: CampaignManagerSteps = {
  [CampaignManagerStepIds.DETAILS]: {
    id: CampaignManagerStepIds.DETAILS,
    status: CampaignManagerStepStatus.OPEN,
    isOptional: false,
    nextId: CampaignManagerStepIds.MEDIA_TRACKING,
    prevId: null
  },
  [CampaignManagerStepIds.MEDIA_TRACKING]: {
    id: CampaignManagerStepIds.MEDIA_TRACKING,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: true,
    nextId: CampaignManagerStepIds.SURVEY,
    prevId: CampaignManagerStepIds.DETAILS
  },
  [CampaignManagerStepIds.SURVEY]: {
    id: CampaignManagerStepIds.SURVEY,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: false,
    nextId: CampaignManagerStepIds.AUDIENCE,
    prevId: CampaignManagerStepIds.MEDIA_TRACKING,
    childSteps: [
      {
        id: SurveyStepIds.BRAND,
        status: CampaignManagerStepStatus.OPEN
      },
      {
        id: SurveyStepIds.STANDARD_QUESTIONS,
        status: CampaignManagerStepStatus.INACTIVE
      },
      {
        id: SurveyStepIds.BEHAVIORAL_QUESTIONS,
        status: CampaignManagerStepStatus.INACTIVE
      }
    ]
  },
  [CampaignManagerStepIds.AUDIENCE]: {
    id: CampaignManagerStepIds.AUDIENCE,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: false,
    nextId: CampaignManagerStepIds.REVIEW,
    prevId: CampaignManagerStepIds.SURVEY
  },
  [CampaignManagerStepIds.REVIEW]: {
    id: CampaignManagerStepIds.REVIEW,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: false,
    nextId: null,
    prevId: CampaignManagerStepIds.REVIEW
  }
};

// @TODO Improve type checking for service campaign types
// @ts-ignore
const SERVICE_STEPS: CampaignManagerSteps = {
  [CampaignManagerStepIds.DETAILS]: {
    id: CampaignManagerStepIds.DETAILS,
    status: CampaignManagerStepStatus.OPEN,
    isOptional: false,
    nextId: CampaignManagerStepIds.MEDIA_TRACKING,
    prevId: null
  },
  [CampaignManagerStepIds.MEDIA_TRACKING]: {
    id: CampaignManagerStepIds.MEDIA_TRACKING,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: true,
    nextId: CampaignManagerStepIds.REVIEW,
    prevId: CampaignManagerStepIds.DETAILS
  },
  [CampaignManagerStepIds.REVIEW]: {
    id: CampaignManagerStepIds.REVIEW,
    status: CampaignManagerStepStatus.INACTIVE,
    isOptional: false,
    nextId: null,
    prevId: CampaignManagerStepIds.REVIEW
  }
};

const decorateSteps = (
  steps: CampaignManagerSteps
): CampaignManagerEnhancedSteps =>
  Object.values(steps).reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: {
        ...cur,
        meta: {
          isOpen: cur.status === CampaignManagerStepStatus.OPEN,
          isComplete: cur.status === CampaignManagerStepStatus.COMPLETE,
          isInactive: cur.status === CampaignManagerStepStatus.INACTIVE
        }
      }
    }),
    {} as Record<CampaignManagerStepIds, CampaignManagerEnhancedStep>
  );

const setStepStatus = (
  steps: CampaignManagerEnhancedSteps,
  id: CampaignManagerStepIds,
  status: CampaignManagerStepStatus
) => {
  const updatedSteps = {
    ...steps,
    [id]: {
      ...steps[id],
      status
    }
  };

  return decorateSteps(updatedSteps);
};

export const useCampaignManagerSteps = (type: CampaignTypes) => {
  const [steps, setSteps] = useState<CampaignManagerEnhancedSteps>(
    decorateSteps(
      type === CampaignTypes.SOFTWARE ? SOFTWARE_STEPS : SERVICE_STEPS
    )
  );

  const [options, setOptions] = useState({});

  const [activeStep, setActiveStepState] = useState<CampaignManagerStepIds>(
    CampaignManagerStepIds.DETAILS
  );

  const setActiveStep = useCallback(
    (id: CampaignManagerStepIds) => {
      if (id !== activeStep) setActiveStepState(id);
    },
    [activeStep, setActiveStepState]
  );

  return {
    steps,
    activeStep,
    setActiveStep,
    options,
    setOptions,
    setStepAsComplete: (id: CampaignManagerStepIds) =>
      setSteps(prevSteps =>
        setStepStatus(prevSteps, id, CampaignManagerStepStatus.COMPLETE)
      ),
    setStepAsOpen: (id: CampaignManagerStepIds) =>
      setSteps(prevSteps =>
        setStepStatus(prevSteps, id, CampaignManagerStepStatus.OPEN)
      ),
    setStepAsInactive: (id: CampaignManagerStepIds) =>
      setSteps(prevSteps =>
        setStepStatus(prevSteps, id, CampaignManagerStepStatus.INACTIVE)
      )
  };
};
