import React from 'react';
import { MdAddCircle } from 'react-icons/md';

import { Button, Flex, Icon, Text, LssProp } from '@lucidhq/lucidium';

type AdServerConfigProps = {
  onClick: () => void;
  lss?: LssProp;
};

export const AddCustomMacroButton: React.FC<AdServerConfigProps> = ({
  onClick,
  lss = {}
}) => (
  <Button
    emphasis="medium"
    scale="sm"
    palette="secondary"
    onClick={onClick}
    type="button"
    lss={{ pl: 'sm', ...lss }}
  >
    <Flex lss={{ alignItems: 'center' }}>
      <Icon as={MdAddCircle} size={18} />
      <Text lss={{ textScale: 'control', ml: 'xs' }}>Add Custom</Text>
    </Flex>
  </Button>
);
