export const SCOPES = {
  seg: 'app:seg',
  imt: 'app:imt',
  aud: 'app:aud'
};

export const ROLES = {
  superAdmin: 'imt:admin#pg_3',
  admin: 'imt:admin', // @TODO Remove this role and func related to it
  basicPlusUser: 'imt:basic_plus_user'
};

const PERMISSION_PREFIX = 'imt:';

export const PERMISSIONS = {
  admin: 'uiadmin#read',
  createCampaign: 'campaign#create',
  updateCampaign: 'campaign#update'
};

enum Actions {
  read = 'read',
  update = 'update',
  create = 'create',
  delete = 'delete'
}

enum Priority {
  read = 1,
  update = 2,
  create = 3,
  delete = 4
}

export type Permissions = {
  [key in keyof typeof PERMISSIONS]: boolean;
};

export const getPermissionsFromScope = (
  scopes: string[],
  prefix = PERMISSION_PREFIX
): { [key: string]: true } => {
  const permissions = scopes
    // Remove scopes not related to current prefix
    .filter(scope => scope.indexOf(prefix) !== -1)
    // Remove prefix
    .map(perm => perm.replace(prefix, ''))
    // Expand permissions using object
    // to remove dupes
    .reduce((acc, cur) => {
      // Ignore perm group from [2]
      const [perm, action] = cur.split('#');

      if (Priority[action] >= Priority.read)
        acc[`${perm}#${Actions.read}`] = true;
      if (Priority[action] >= Priority.update)
        acc[`${perm}#${Actions.update}`] = true;
      if (Priority[action] >= Priority.create)
        acc[`${perm}#${Actions.create}`] = true;
      if (Priority[action] >= Priority.delete)
        acc[`${perm}#${Actions.delete}`] = true;

      return acc;
    }, {});

  return permissions;
};

export const getPermissions = (scopes: string[]): Permissions => {
  const fromScope = getPermissionsFromScope(scopes);

  return Object.keys(PERMISSIONS).reduce((acc, key) => {
    const perm = PERMISSIONS[key];
    acc[key] = !!fromScope[perm];
    return acc;
  }, {} as Permissions);
};
