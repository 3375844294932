import React from 'react';

import { Checkbox, Text } from '@lucidhq/lucidium';

import { Channel } from '~/impact-measurement/pages/types';
import { t } from '~/utils/i18n';

import { getUpdatedChannels } from './helpers';

export enum IMPRESSIONS_FIELD_NAME {
  'digital' = 'draft_settings.expected_impressions_count',
  'linear_tv' = 'draft_settings.expected_impressions_count_linear_tv',
  'social' = 'draft_settings.expected_impressions_count_social'
}

export enum CHANNELS_CHECKBOX_LABEL {
  'digital' = 'Digital',
  'linear_tv' = 'Linear TV',
  'social' = 'Social'
}

export enum CHANNELS {
  'linear_tv' = 'linear_tv',
  digital = 'digital'
}

type TrackedMediaCheckboxProps = {
  channels: string[];
  type: Channel;
  setFieldValue: (field: string, value: any, shouldValidate: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
};

export const TrackedMediaCheckbox: React.FC<TrackedMediaCheckboxProps> = ({
  channels,
  type,
  setFieldValue,
  isChecked,
  isDisabled
}) => (
  <Checkbox
    lss={{ mt: 'xxs', mb: 'md' }}
    name={`${type}_checkbox`}
    label={<Text lss={{ fontSize: 14 }}>{t(`forms.labels.${type}`)}</Text>}
    checked={isChecked}
    onChange={() => {
      isChecked && setFieldValue(IMPRESSIONS_FIELD_NAME[type], 0, false);
      if (type === CHANNELS.linear_tv && isChecked) {
        setFieldValue('draft_settings.data_partners', undefined, false);
      }
      setFieldValue('channels', getUpdatedChannels(channels, type), false);
    }}
    disabled={isDisabled}
  />
);
