import React from 'react';

import { Flex, Label, Text, Toggle } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

type ToggleFieldProps = {
  name: string;
  label: string;
  description?: string;
  readOnly: boolean;
};

export const ToggleField = ({
  name,
  description,
  label,
  readOnly
}: ToggleFieldProps) => {
  const [field, , helpers] = useField(name);
  const handleToggle = () => helpers.setValue(!field.value);

  return (
    <Flex lss={{ mt: 'md', mb: 'xl', justifyContent: 'space-between' }}>
      <Flex lss={{ flexDirection: 'column' }}>
        <Label lss={{ mb: 'sm' }}>{label}</Label>
        {!!description && (
          <Text as="span" lss={{ fontSize: '12px' }}>
            {description}
          </Text>
        )}
      </Flex>
      <Toggle
        scale="md"
        value={field.value}
        onChange={handleToggle}
        disabled={readOnly}
      />
    </Flex>
  );
};
