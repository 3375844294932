import React from 'react';

import { Box, Button, Flex, InlineNotification, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type AudienceNotRequiredProps = {
  disabled: boolean;
  onClick: () => void;
};

export const AudienceNotRequired = ({
  disabled,
  onClick
}: AudienceNotRequiredProps) => {
  return (
    <Flex lss={{ flexDirection: 'column' }}>
      <InlineNotification
        palette="secondary"
        title={t('campaignManager.review.audience.notRequired.title')}
      >
        <Box>
          <Text lss={{ mb: 'lg', display: 'block' }}>
            {t('campaignManager.review.audience.notRequired.description')}{' '}
          </Text>

          <Flex lss={{ justifyContent: 'center' }}>
            <Button emphasis="medium" disabled={disabled} onClick={onClick}>
              {t('campaignManager.review.audience.notRequired.confirm')}
            </Button>
          </Flex>
        </Box>
      </InlineNotification>
    </Flex>
  );
};
