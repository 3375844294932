import React, { useState, useEffect } from 'react';

import { TabConfig } from '@lucidhq/lucidium';

interface TabWithCountConfig {
  id: string;
  title?: string;
  count?: number;
  hasUnsavedChanges?: boolean;
}

type UseTabsWithCounts = (
  tabsConfig: TabWithCountConfig[],
  initialTabId: string,
  hasUnsavedChanges?: boolean
) => {
  tabs: TabConfig[];
  selectedTabId: string;
  setSelectedTabId: (id: string) => void;
};

type LabelProps = {
  tab: TabWithCountConfig;
  hasUnsavedChanges?: boolean;
};

const Label: React.FC<LabelProps> = ({ tab }) => (
  <>
    {tab.title} {!!tab.count && `(${tab.count})`}
  </>
);

export const useTabsWithCounts: UseTabsWithCounts = (
  tabsConfig,
  initialTabId
) => {
  const [selectedTabId, setSelectedTabId] = useState(initialTabId);
  const tabs: TabConfig[] = tabsConfig.map(tab => {
    return {
      id: tab.id,
      title: <Label tab={tab} />,
      accessibilityLabel: tab.title,
      lss: {
        mr: 'lg'
      }
    };
  });

  useEffect(() => {
    setSelectedTabId(initialTabId);
  }, [initialTabId]);

  return { tabs, selectedTabId, setSelectedTabId } as const;
};
