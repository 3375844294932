import React from 'react';

import {
  Flex,
  Text,
  TransparentButton,
  useToggle,
  LssProp
} from '@lucidhq/lucidium';
import {
  FieldArray,
  useFormikContext
} from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

import { StepLayout } from '../../Steps/StepLayout';
import { AttributeField } from './AttributeField';
import { BrandsFormData } from './BrandsForm';

const addAttributesButtonLss: LssProp = {
  color: 'secondary500',
  display: 'flex',
  alignSelf: 'flex-start',
  flexBasis: 'fit-content',
  height: '4rem',
  mt: 'sm',
  py: 'sm',
  px: 'xs'
};

export const BrandAttributesSection = ({ readOnly }: { readOnly: boolean }) => {
  const { values } = useFormikContext<BrandsFormData>();
  const hasValidAttrs = !!values.brand_attributes.filter(attr => !!attr).length;
  const [isOpen, handleToggle] = useToggle(hasValidAttrs);
  const hasMaxAttrs = values?.brand_attributes?.length === 10;

  return (
    <Flex lss={{ flexDirection: 'column', mt: 'sm', mb: 'xl' }}>
      <StepLayout.Header
        title={t('campaignManager.survey.brands.brandAttributes.title')}
        lss={{ mb: 'xl' }}
      />

      <Text
        lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
        as="strong"
      >
        {t('campaignManager.survey.brands.brandAttributes.description')}
      </Text>

      {!isOpen && (
        <TransparentButton
          type="button"
          lss={addAttributesButtonLss}
          onClick={() => handleToggle()}
          disabled={readOnly}
        >
          {`+ ${t('buttons.addAttributes')}`}
        </TransparentButton>
      )}
      {isOpen && (
        <FieldArray
          name="brand_attributes"
          render={helpers => (
            <Flex lss={{ flexDirection: 'column' }}>
              {values.brand_attributes.map((_, index) => (
                <AttributeField
                  key={t(`forms.labels.attributeX`, { index }) as any}
                  index={index}
                  handleRemoveItem={helpers.remove}
                  readOnly={readOnly}
                />
              ))}
              <TransparentButton
                type="button"
                disabled={hasMaxAttrs || readOnly}
                lss={addAttributesButtonLss}
                onClick={() => helpers.push('')}
              >
                {`+ ${t('buttons.addAttribute')}`}
              </TransparentButton>
            </Flex>
          )}
        />
      )}
    </Flex>
  );
};
