import React from 'react';
import { useHistory } from 'react-router-dom';

import { isBefore, isAfter, isEqual, format, add } from 'date-fns';

import { Flex, Text, useToggle } from '@lucidhq/lucidium';
import { Form } from '@lucidhq/lucidium/components/FormV2';

import { usePermissions } from '~/components/Permissions/PermissionsProvider';
import { ErrorDialog } from '~/components/Shared/ErrorDialog';
import { FormProps } from '~/components/Shared/FormProps';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { ROUTES } from '~/constants';
import { Campaign, CampaignStatus, CampaignTypes, Channels } from '~/types';
import { getCampaignManagerRoute } from '~/utils/campaignManager';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../../CampaignManagerFooter';
import { useCampaignManager } from '../../CampaignManagerProvider';
import { ProjectManagerField } from '../../ServiceCampaignDetailsStep/Form/ProjectManagerField';
import { AccountField } from './AccountField';
import { CampaignNameField } from './CampaignNameField';
import { CountryLanguageField } from './CountryLanguageField';
import { EndDateField } from './EndDateField';
import { IndustryField } from './IndustryField';
import { SalesforceField } from './SalesforceField';
import { StartDateField } from './StartDateField';
import { TrackedMediaSection } from './TrackedMediaSection';

// @TODO Review data not needed in the form and remove it
const initialValues: Campaign = {
  account_id: undefined,
  campaign_data_updated_at: '',
  campaign_id: '',
  campaign_name: '',
  client_name: '',
  created_at: '',
  country_language_id: 1,
  default_question_name: '',
  draft_settings: {
    questions: [],
    baseline_questions: [],
    expected_impressions_count: 0,
    expected_impressions_count_linear_tv: 0,
    expected_impressions_count_social: 0,
    data_partners: [],
    social_platforms: [],
    target_audiences: [],
    // Impact Measurement Only
    extra_questions_enabled: false
  },
  end_date: '',
  errors: null,
  industry_id: -1,
  permission_id: 0,
  start_date: '',
  salesforce_id: '',
  status: CampaignStatus.DRAFT,
  updated_at: '',
  channels: [],
  project_manager_user_id: 0
};

const getInitValues = (managedCampaign?: Campaign) => {
  return !!managedCampaign ? { ...managedCampaign } : initialValues;
};

export enum FIELD_NAMES {
  COUNTRY_LANGUAGE = 'country_language_id',
  CAMPAIGN_NAME = 'campaign_name',
  INDUSTRY = 'industry_id',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  DATA_PARTNERS = 'data_partners',
  DIGITAL_IMPRESSIONS = 'expected_impressions_count',
  LINEAR_TV_IMPRESSIONS = 'expected_impressions_count_linear_tv'
}

enum MIN_IMPRESSIONS {
  DIGITAL = 1_000_000,
  LINEAR_TV = 50_000_000,
  SOCIAL = 300_000
}

const handleValidate = (
  values: Campaign,
  isAdmin: boolean,
  initialValues?: Campaign
) => {
  const errors: Record<string, any> = {};
  const draft_settings: Record<string, any> = {};

  const isDigitalChecked = values.channels.includes(Channels.DIGITAL);
  const isLinearTVChecked = values.channels.includes(Channels.LINEAR_TV);
  const isSocialChecked = values.channels.includes(Channels.SOCIAL);
  // NOTE: this is a temp validation for Social OTS beta testing this checks for UK-eng, US-eng, and AU-eng.
  // This will need to be updated before GA release for Social OTS.
  const acceptedCountryLangs = [1, 2, 3, 5];
  const isValidCountryLang = acceptedCountryLangs.includes(
    values.country_language_id
  );

  if (!values.campaign_name) {
    errors.campaign_name = t('forms.errors.requiredField');
  }

  if (!values.start_date) {
    errors.start_date = t('forms.errors.requiredField');
  }

  if (!values.end_date) {
    errors.end_date = t('forms.errors.requiredField');
  }

  if (!!values?.start_date && !!values?.end_date) {
    // This adjustment is mainly to check only the day value and validate the difference between dates.
    const adjustedStartDate = add(new Date(values?.start_date), { days: 1 });
    const startDate = new Date(format(adjustedStartDate, 'MM/dd/yyyy'));
    const endDate = new Date(format(new Date(values.end_date), 'MM/dd/yyyy'));

    if (isAfter(startDate, endDate)) {
      errors.start_date = t('forms.errors.startDate');
    }

    if (isBefore(endDate, startDate)) {
      errors.end_date = t('forms.errors.endDate');
    }

    if (isEqual(startDate, endDate)) {
      errors.start_date = t('forms.errors.startDate');
      errors.end_date = t('forms.errors.endDate');
    }

    if (isBefore(endDate, new Date())) {
      errors.end_date = t('forms.errors.endDatePast');
    }

    if (initialValues?.end_date) {
      const initialEndDate = new Date(
        format(new Date(initialValues.end_date), 'MM/dd/yyyy')
      );

      if (isBefore(endDate, initialEndDate)) {
        errors.end_date = t('forms.errors.endDateLive');
      }
    }
  }

  if (isAdmin && !values.permission_id) {
    errors.permission_id = t('forms.errors.requiredField');
  }

  if (values.industry_id < 0) {
    errors.industry_id = t('forms.errors.requiredField');
  }
  // NOTE: if linear tv is checked and country language is not US or UK english
  // Also, we do not support a country_lang_id that is set to 0.
  if (isLinearTVChecked && values.country_language_id > 2) {
    errors.country_language_id = t('forms.errors.countryLanguage');
  }

  if (isSocialChecked && !isValidCountryLang) {
    errors.country_language_id = t('forms.errors.countryLanguageSocial');
  }

  if (!values.channels.length) {
    errors.channels = t('forms.errors.channels');
  }

  // if channels includes digital and does not have any impressions show error
  if (
    isDigitalChecked &&
    values?.draft_settings?.expected_impressions_count < MIN_IMPRESSIONS.DIGITAL
  ) {
    draft_settings.expected_impressions_count = t(
      'forms.errors.digitalImpressions'
    );
  }

  if (
    isDigitalChecked &&
    initialValues &&
    values?.draft_settings?.expected_impressions_count <
      initialValues?.draft_settings?.expected_impressions_count
  ) {
    draft_settings.expected_impressions_count = t(
      'forms.errors.smallerThanInitial',
      {
        min: initialValues?.draft_settings?.expected_impressions_count.toLocaleString()
      }
    );
  }

  // if channels includes tv and does include any tv impressions show error
  if (
    isLinearTVChecked &&
    values?.draft_settings?.expected_impressions_count_linear_tv <
      MIN_IMPRESSIONS.LINEAR_TV
  ) {
    draft_settings.expected_impressions_count_linear_tv = t(
      'forms.errors.tvImpressions'
    );
  }

  if (
    isLinearTVChecked &&
    initialValues &&
    values?.draft_settings?.expected_impressions_count_linear_tv <
      initialValues?.draft_settings?.expected_impressions_count_linear_tv
  ) {
    draft_settings.expected_impressions_count_linear_tv = t(
      'forms.errors.smallerThanInitial',
      {
        min: initialValues?.draft_settings?.expected_impressions_count_linear_tv.toLocaleString()
      }
    );
  }

  if (isLinearTVChecked && !values?.draft_settings?.data_partners?.length) {
    draft_settings.data_partners = t('forms.errors.requiredField');
  }

  // Social
  if (
    isSocialChecked &&
    values?.draft_settings?.expected_impressions_count_social <
      MIN_IMPRESSIONS.SOCIAL
  ) {
    draft_settings.expected_impressions_count_social = t(
      'forms.errors.socialImpressions'
    );
  }

  if (
    isSocialChecked &&
    initialValues &&
    values?.draft_settings?.expected_impressions_count_social <
      initialValues?.draft_settings?.expected_impressions_count_social
  ) {
    draft_settings.expected_impressions_count_social = t(
      'forms.errors.smallerThanInitial',
      {
        min: initialValues?.draft_settings?.expected_impressions_count_social.toLocaleString()
      }
    );
  }

  if (isSocialChecked && !isDigitalChecked) {
    draft_settings.expected_impressions_count_social = t(
      'forms.errors.socialWithoutOtherMedia'
    );
  }

  if (isSocialChecked && values?.draft_settings?.social_platforms?.length < 1) {
    draft_settings.social_platforms = t('forms.errors.minSocialPlatforms');
  }

  if (isSocialChecked && values?.draft_settings?.social_platforms?.length > 5) {
    draft_settings.social_platforms = t('forms.errors.maxSocialPlatforms');
  }

  return {
    ...errors,
    ...(Object.keys(draft_settings).length && { draft_settings })
  };
};

export const CampaignDetailsForm = () => {
  const [isOpen, onToggle] = useToggle();
  const history = useHistory();
  const {
    goToStep,
    steps,
    activeStep,
    managedCampaign,
    refetchCampaign,
    can
  } = useCampaignManager();
  const { isAdmin } = usePermissions();
  const initValues = getInitValues(managedCampaign?.details);

  const { mutate } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const hasCampaign = managedCampaign?.details?.campaign_id;

  const handleSubmit = (action: 'close' | 'continue') => (values: Campaign) => {
    // @TODO @ccv2 Remove this logic after campaign v1 is deprecated
    const formattedValues = !!hasCampaign
      ? values
      : {
          ...values,
          draft_settings: { ...values.draft_settings, campaign_version: 2 }
        };

    mutate(formattedValues, {
      onSuccess: data => {
        if (!hasCampaign) {
          if (action === 'close') return handleBack();
          if (action === 'continue') {
            history.push(
              getCampaignManagerRoute(
                CampaignTypes.SOFTWARE,
                data.campaign_id,
                steps.MEDIA_TRACKING.id
              )
            );
          }
        } else {
          if (action === 'close') return handleBack();
          if (action === 'continue') {
            refetchCampaign();
            const nextStep = can.limitedEdit
              ? steps.REVIEW.id
              : steps[activeStep].nextId;
            if (nextStep) goToStep(nextStep);
          }
        }
      },
      onError: () => {
        onToggle();
      }
    });
  };

  const handleBack = () => history.push(ROUTES.adminCampaigns);

  return (
    <>
      <Form
        initialValues={initValues}
        validate={values =>
          handleValidate(
            values,
            isAdmin,
            can.limitedEdit ? initValues : undefined
          )
        }
        // @ts-ignore
        onSubmit={() => null}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
      >
        <CampaignNameField
          readOnly={!!hasCampaign && !(can.edit || can.limitedEdit)}
        />

        <Text
          lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
          as="strong"
        >
          {t(`campaignManager.campaignDetails.questions.dates`)}
        </Text>

        <Flex lss={{ justifyContent: 'space-between', my: 'md', mt: 'xl' }}>
          <StartDateField
            name={FIELD_NAMES.START_DATE}
            readOnly={!!hasCampaign && !can.edit}
          />
          <EndDateField
            name={FIELD_NAMES.END_DATE}
            readOnly={!!hasCampaign && !(can.edit || can.limitedEdit)}
          />
        </Flex>

        {isAdmin && (
          <Flex lss={{ flexDirection: 'column', gap: '0.5rem' }}>
            <AccountField readOnly={!!hasCampaign && !can.edit} />

            <ProjectManagerField readOnly={!!hasCampaign && !can.edit} />

            <SalesforceField
              readOnly={!!hasCampaign && !(can.edit || can.limitedEdit)}
            />
          </Flex>
        )}

        <CountryLanguageField
          name={FIELD_NAMES.COUNTRY_LANGUAGE}
          readOnly={!!hasCampaign && !can.edit}
        />

        <IndustryField
          name={FIELD_NAMES.INDUSTRY}
          readOnly={!!hasCampaign && !can.edit}
        />

        <TrackedMediaSection limitedEdit={!!hasCampaign && can.limitedEdit} />

        <FormProps>
          {({ validateForm, values }) => (
            <CampaignManagerFooter
              onBack={handleBack}
              saveCloseProps={{
                onClick: async () => {
                  const errors = await validateForm();
                  if (Object.values(errors).length) return;
                  const formHandler = handleSubmit('close');
                  formHandler(values as Campaign);
                },
                type: 'button'
              }}
              saveContinueProps={{
                onClick: async () => {
                  const errors = await validateForm();
                  if (Object.values(errors).length) return;
                  const formHandler = handleSubmit('continue');
                  formHandler(values as Campaign);
                },
                type: 'submit'
              }}
            />
          )}
        </FormProps>
      </Form>
      <ErrorDialog
        isOpen={isOpen}
        onClose={onToggle}
        errorText={t('errors.campaignDraft')}
      />
    </>
  );
};
