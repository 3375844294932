import React from 'react';
import { MdAdd } from 'react-icons/md';

import { t } from 'i18next';

import { Button, Icon, LssProp } from '@lucidhq/lucidium';

const addNewLss: LssProp = {
  borderRadius: 'md',
  display: 'flex',
  alignItems: 'center'
};

type AddNewButtonProps = {
  id: string;
  disabled?: boolean;
  onClick: () => void;
  lss?: LssProp;
};

export const AddNewButton = ({
  onClick,
  disabled = false,
  id,
  lss
}: AddNewButtonProps) => (
  <Button
    id={id}
    emphasis="medium"
    disabled={disabled}
    lss={{ ...addNewLss, ...lss }}
    onClick={onClick}
  >
    <Icon as={MdAdd} lss={{ marginRight: '0.5rem' }} />{' '}
    {t('campaignManager.actions.addNew')}
  </Button>
);
