import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useToggle } from '@lucidhq/lucidium';

import { ErrorDialog } from './ErrorDialog';

type ErrorDialogProvider = {
  setError: (msg: string) => void;
};

type ErrorDialogProviderProps = {
  children: React.ReactNode;
};

const ErrorDialogContext = React.createContext<ErrorDialogProvider>(
  {} as ErrorDialogProvider
);

export const useErrorDialogProvider = () =>
  useContext<ErrorDialogProvider>(ErrorDialogContext);

export const ErrorDialogProvider: React.FC<ErrorDialogProviderProps> = ({
  children
}) => {
  const [isOpen, onToggle] = useToggle();
  const [message, setMessage] = useState<string>('');

  const setError = useCallback(
    (msg: string) => {
      setMessage(msg);
      onToggle();
    },
    [setMessage, onToggle]
  );

  const value = useMemo(
    () => ({
      setError
    }),
    [setError]
  );

  return (
    <ErrorDialogContext.Provider value={value}>
      {children}
      <ErrorDialog isOpen={isOpen} onClose={onToggle} errorText={message} />
    </ErrorDialogContext.Provider>
  );
};
