import React, { useMemo } from 'react';

import { useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import {
  TargetAudienceQuestion,
  TargetAudienceQuestionType
} from '~/components/Shared/hooks/useFetchTargetAudienceQuestions';
import { DraftTargetQualification } from '~/types';

import { NumericInput } from '../QualificationInputs/NumericInput';
import { RangeInput } from '../QualificationInputs/RangeInput';
import { SelectInput } from '../QualificationInputs/SelectInput';

type QualificationAnswerProps = {
  qualification: DraftTargetQualification;
  questions: TargetAudienceQuestion[];
  index: number;
};

export const QualificationAnswer = ({
  qualification,
  questions,
  index
}: QualificationAnswerProps) => {
  const { setFieldValue } = useFormikContext<{
    qualifications: DraftTargetQualification[];
  }>();

  const question = useMemo(() => {
    return questions.find(
      q => q.question_id === qualification.marketplace_question_id
    );
  }, [qualification, questions]);

  const isNumeric =
    question?.question_type === TargetAudienceQuestionType.Numeric;

  const isRange = qualification.qual_name === 'AGE';
  const value = qualification.precodes || [];

  if (!question) return null;

  const handleChange = (value: (string | number)[]) => {
    setFieldValue(`qualifications.${index}.precodes`, value);
  };

  return (
    <>
      {isRange && (
        <RangeInput
          {...(value.length && { value: [+value[0], +value[1]] })}
          onChange={handleChange}
        />
      )}
      {isNumeric && !isRange && (
        <NumericInput value={value[0]} onChange={handleChange} />
      )}
      {!isRange && !isNumeric && (
        <SelectInput
          questionId={question.question_id}
          value={value}
          onChange={handleChange}
        />
      )}
    </>
  );
};
