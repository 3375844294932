import React from 'react';

export const AdminHome = React.lazy(() => import('./Admin'));
export const AdminCampaigns = React.lazy(() => import('./AdminCampaigns'));
export const CampaignTasks = React.lazy(() => import('./CampaignTasks'));
export const CampaignNew = React.lazy(() => import('./CampaignNew'));
export const CampaignDraft = React.lazy(() => import('./CampaignDraft'));
export const FrequencyReporting = React.lazy(() => import('./Frequency'));
export const MediaTracking = React.lazy(() => import('./MediaTracking'));
export const ProjectSetup = React.lazy(() => import('./ProjectSetup'));
export const Mapping = React.lazy(() => import('./Mapping'));
export const CampaignDetails = React.lazy(() => import('./CampaignDetails'));
export const SurveyReview = React.lazy(() => import('./SurveyReview'));

export * from './Accounts';
export * from './Users';
export * from './DiscrepancyCheck';
