import {
  REQUIRED_BULK_COLUMNS,
  CUSTOM_MACRO_ADJUSTMENT
} from '../../../../constants';
import { ColumnMappingProps } from '../components/ColumnMappingSection';

export const getMacroParam = (label: string) => {
  const requiredParam = {
    ['Site ID']: 'sid',
    ['Placement ID']: 'pid',
    ['Creative ID']: 'crid'
  }[label];

  const labelSplit = label.split(' ');
  const param =
    labelSplit[0] === 'Custom' ? `c${labelSplit[1]}` : requiredParam;

  return param || '';
};

export type ValidData = { validData: MappingBulkPixels['data'] };

export const getValidColumns = (data: MappingBulkPixels['data']): ValidData => {
  const validData = {};

  Object.keys(data).forEach(key => {
    if (!data[key].errors.invalid) {
      validData[key] = data[key];
    }
  });

  return { validData };
};

export const getInitialMappedMacros = (
  validData: MappingBulkPixels['data']
): MappingMacroType[] => [
  ...REQUIRED_BULK_COLUMNS.map(({ colKey, label }) => {
    const columnData = validData[colKey];
    return {
      label: columnData?.label || label,
      param: getMacroParam(columnData?.label || label),
      values: columnData?.values || [],
      dataLabel: columnData?.label || ''
    };
  })
];

export const getUpdatedMacrosOnSelect = (
  macros: MappingMacroType[],
  macro: MappingMacroType,
  columnData: DataPixelObject
) => [
  ...macros.map(m =>
    macro.label === m.label
      ? {
          ...m,
          values: columnData?.values,
          dataLabel: columnData?.label
        }
      : m
  )
];

export const getUpdatedMacrosOnRemoveCustom = (
  macros: MappingMacroType[],
  macro: MappingMacroType
) => [
  ...macros
    .filter(m => m.label !== macro.label)
    .map((m, i) =>
      m.label.includes('Custom')
        ? {
            ...m,
            label: `Custom ${i - CUSTOM_MACRO_ADJUSTMENT}`,
            param: `c${i - CUSTOM_MACRO_ADJUSTMENT}`
          }
        : m
    )
];

export const getUpdatedMacrosOnAddCustom = (macros: MappingMacroType[]) => [
  ...macros,
  {
    label: `Custom ${macros.length - CUSTOM_MACRO_ADJUSTMENT}`,
    param: `c${macros.length - CUSTOM_MACRO_ADJUSTMENT}`,
    values: [],
    dataLabel: ''
  }
];

export const getMacroMappingOptions = (data: MappingBulkPixels['data']) => {
  return Object.keys(data)
    .filter(col => !data[col].errors.invalid)
    .map(col => ({ label: data[col].label, value: data[col].label }));
};

export const handleAddCustom = (
  setMappedMacros: ColumnMappingProps['setMappedMacros'],
  mappedMacros: ColumnMappingProps['mappedMacros']
) => setMappedMacros(getUpdatedMacrosOnAddCustom(mappedMacros));

export const handleRemoveCustom = (
  macro: MappingMacroType,
  setMappedMacros: ColumnMappingProps['setMappedMacros'],
  mappedMacros: ColumnMappingProps['mappedMacros']
) => setMappedMacros(getUpdatedMacrosOnRemoveCustom(mappedMacros, macro));

type HandleSelectConfig = {
  macro: MappingMacroType;
  setMappedMacros: ColumnMappingProps['setMappedMacros'];
  mappedMacros: ColumnMappingProps['mappedMacros'];
  columnData: DataPixelObject;
};
export const handleSelect = ({
  macro,
  setMappedMacros,
  mappedMacros,
  columnData
}: HandleSelectConfig) => {
  setMappedMacros(getUpdatedMacrosOnSelect(mappedMacros, macro, columnData));
};

export const getValuesCount = (values: string[]) => {
  return values.reduce(
    (accumulator, value) => (!!value ? ++accumulator : accumulator),
    0
  );
};
