import { LssProp } from '@lucidhq/lucidium';

import { SIZES } from '../constants';
import { PROJ_SETUP_SECTION_HEIGHT_DIFF } from './constants/constants';

export const mediaTrackingToolsWrapperLss: LssProp = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%'
};

export const mediaTrackingWrapperLss: LssProp = {
  flexDirection: 'column',
  tablet: { flexDirection: 'row', height: '100%' }
};

export const getMediaTrackingLeftColLss = (
  isProjectSetup: boolean
): LssProp => {
  return {
    flexDirection: 'column',
    width: '95%',
    mx: 'auto',

    tablet: {
      width: '55%',
      pr: 'xxl',
      pb: '9.375rem' as any,
      height: isProjectSetup
        ? `calc(100vh - ${PROJ_SETUP_SECTION_HEIGHT_DIFF}rem)`
        : 'auto',
      overflowY: 'scroll'
    }
  };
};

export const mediaTrackingRightColLss: LssProp = {
  minHeight: `calc(100vh - ${SIZES.MAPPING_TASK_HEADER_HEIGHT})`,
  flexDirection: 'column',
  width: '95%',
  mx: 'auto',
  mt: 'xxl',
  bg: 'gray100',
  tablet: {
    py: 'xxxl',
    px: 'xxl',
    width: '45%',
    mt: 'none'
  }
};

export const getButtonLss = (treatment: string, isTablet: boolean): LssProp => {
  const buttonSize = treatment === 'on' ? '' : isTablet ? '12rem' : '2.8rem';

  return {
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    position: 'absolute',
    right: '0',
    bottom: '10px',
    height: '2.6875rem',
    width: buttonSize
  };
};
