import React from 'react';
import { MdDelete } from 'react-icons/md';

import { Flex, Icon, Label, LssProp } from '@lucidhq/lucidium';
import { Field } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

const deleteCompetitorLss: LssProp = {
  flexBasis: '2rem',
  height: '2rem',
  justifyContent: 'center',
  textDecoration: 'none',
  color: 'secondary500',
  p: 'xxs'
};

const fieldWrapperLss: LssProp = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mb: 'xxs'
};

type CompetitorFieldProps = {
  index: number;
  handleRemoveItem: (index: number) => void | undefined;
  readOnly: boolean;
};

export const CompetitorField = ({
  index,
  handleRemoveItem,
  readOnly
}: CompetitorFieldProps) => {
  const isRequired = index <= 1;

  return (
    <Flex lss={{ flexDirection: 'column', my: 'md' }}>
      <Flex lss={fieldWrapperLss}>
        <Label lss={{ my: 'sm' }} required={isRequired}>
          {t(`forms.labels.competitorX`, { index: index + 1 })}
        </Label>

        {!isRequired && !readOnly && (
          <Flex
            as="a"
            href="#"
            role="button"
            aria-label={t(
              `campaignManager.survey.brands.ariaLabels.deleteField`,
              { field: 'Competitor', index: index + 1 }
            )}
            lss={deleteCompetitorLss}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              handleRemoveItem(index);
            }}
          >
            <Icon as={MdDelete} size={28} />
          </Flex>
        )}
      </Flex>

      <Field
        name={`[competitors.${index}]`}
        required={isRequired}
        disabled={readOnly}
        requiredMessage={t('forms.errors.competitorX', { index: index + 1 })}
      />
    </Flex>
  );
};
