import React, { useEffect } from 'react';

import { Flex, Text } from '@lucidhq/lucidium';

import { AddCustomMacroButton } from '~/impact-measurement/pages/Admin/pages/MediaTracking/MediaTrackingTools/Tags/TagsGeneration/components';
import { convertToSnakeCase } from '~/impact-measurement/pages/Admin/pages/MediaTracking/MediaTrackingTools/Tags/TagsGeneration/SiteServedSetup/lib';

import { BULK_DEFAULTS_TEXT } from '../constants';
import {
  getValidColumns,
  useMappingOptions,
  getInitialMappedMacros,
  handleSelect,
  handleRemoveCustom,
  handleAddCustom
} from '../lib';
import { mappingSectionWrapperLss, addCustomButtonLss } from '../styles';
import { ColumnMappingDefaultRow } from './ColumnMappingDefaultRow';
import { ColumnMappingSectionIntro } from './ColumnMappingSectionIntro';
import { ColumnMappingSelectRow } from './ColumnMappingSelectRow';

export type ColumnMappingProps = {
  mappedMacros: MappingMacroType[];
  setMappedMacros: React.Dispatch<React.SetStateAction<MappingMacroType[]>>;
  bulkPixels: MappingBulkPixels;
};

export const ColumnMappingSection: React.FC<ColumnMappingProps> = ({
  mappedMacros,
  setMappedMacros,
  bulkPixels
}) => {
  const { data } = bulkPixels;

  const { validData } = getValidColumns(data);

  const { options } = useMappingOptions(bulkPixels);

  useEffect(() => {
    setMappedMacros(getInitialMappedMacros(validData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Flex lss={mappingSectionWrapperLss}>
      <ColumnMappingSectionIntro />
      {mappedMacros.map(macro => (
        <ColumnMappingSelectRow
          key={macro.label}
          label={macro.label}
          options={options}
          matchedValue={macro.dataLabel}
          onSelectHeader={value =>
            value &&
            handleSelect({
              macro,
              setMappedMacros,
              mappedMacros,
              columnData: validData[convertToSnakeCase(value)]
            })
          }
          handleRemoveCustomColumn={
            macro.label.includes('Custom')
              ? () => handleRemoveCustom(macro, setMappedMacros, mappedMacros)
              : undefined
          }
        />
      ))}
      <AddCustomMacroButton
        onClick={() => handleAddCustom(setMappedMacros, mappedMacros)}
        lss={addCustomButtonLss}
      />
      <Text as="p" lss={{ my: 'xs', mr: 'xs' }}>
        {BULK_DEFAULTS_TEXT}
      </Text>
      <ColumnMappingDefaultRow
        label="Cachebuster"
        fieldName="bulk_cache_default"
      />
      <ColumnMappingDefaultRow
        label="Mobile Advertising ID"
        fieldName="bulk_maid_default"
      />
    </Flex>
  );
};
