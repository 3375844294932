import React from 'react';

import { BehavioralQuestionsContainer } from './BehavioralQuestionsContainer';
import { BehavioralQuestionsProvider } from './BehavioralQuestionsProvider';

export const BehavioralQuestionsStep = () => {
  return (
    <BehavioralQuestionsProvider>
      <BehavioralQuestionsContainer />
    </BehavioralQuestionsProvider>
  );
};
