import React, { useContext } from 'react';

import { ExpandableCard, CardContent } from '@lucidhq/lucidium';

import {
  MediaTrackingToolsContext,
  MediaTrackingToolsFormContext
} from '~/impact-measurement/pages/Admin/Contexts';

import {
  getHasWarningIcon,
  handleDeleteTagCard,
  handleToggle,
  isSelectedTag
} from '../lib';
import { DeleteCardAction } from './DeleteCardAction';
import { TagCardTitle } from './TagCardTitle';

type TagCardProps = {
  index: number;
  tagKey: TagKeys;
  title: string;
};

export const TagCard: React.FC<TagCardProps> = ({
  index,
  tagKey,
  title,
  children
}) => {
  const { mediaToolData, setFieldValue, isValid } = useContext(
    MediaTrackingToolsFormContext
  );
  const {
    selectedTag,
    setSelectedTag,
    warningTracker,
    dispatchWarningTracker
  } = useContext(MediaTrackingToolsContext);

  const tags = mediaToolData[tagKey];

  const isExpanded = isSelectedTag(selectedTag, index, tagKey);
  const hasWarning = getHasWarningIcon(warningTracker, tagKey, index);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // reset tag selected toggle
    isExpanded && setSelectedTag(null);

    handleDeleteTagCard({
      index,
      tagKey,
      tags,
      setFieldValue
    });

    dispatchWarningTracker({
      type: 'HANDLE_DELETE_TAG',
      tagIndex: index,
      tagKey
    });
  };

  return (
    <ExpandableCard
      lss={{ mb: 'md' }}
      headerLss={{ px: 'md', py: 'sm' }}
      isExpanded={isExpanded}
      onToggle={() => {
        if (!isValid) return;

        handleToggle({
          index,
          tagKey,
          selectedTag,
          setSelectedTag
        });
      }}
      actions={
        <DeleteCardAction
          id={`delete-${tagKey}-${index}`}
          handleDelete={handleDelete}
        />
      }
      title={<TagCardTitle title={title} hasWarning={hasWarning} />}
    >
      <CardContent>{children}</CardContent>
    </ExpandableCard>
  );
};
