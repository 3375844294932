import {
  MediaTrackingToolsFormContextType,
  MediaTrackingToolsContextType
} from '~/impact-measurement/pages/Admin/Contexts';
import { readFileData } from '~/impact-measurement/pages/Admin/lib';
import {
  BULK_CACHE_DEFAULT,
  BULK_MAID_DEFAULT,
  EMPTY_DATA_PIXELS_BULK
} from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';

import { isInvalidField } from '../../lib';
import { ColumnMappingProps } from '../components/BulkCreate/CsvUploadDialog/components/ColumnMappingSection';
import { EMPTY_DATA_PIXELS_BULK_ITEM, REGEX_FILE_SPLIT } from '../constants';

type SplitTitleAndContent = {
  titles: string[];
  content: string[];
};

export const renamesEmptyDuplicated = (
  titles: SplitTitleAndContent['titles']
): SplitTitleAndContent['titles'] => {
  const newArrayTitles: string[] = [];
  const repeated: { [key: string]: { count: number } } = {};

  for (const index in titles) {
    let newTitle = titles[index];
    const isEmptyTitle = newTitle === '';

    if (titles.indexOf(newTitle) !== +index && !isEmptyTitle) {
      const key = repeated[newTitle] || (repeated[newTitle] = { count: 0 });
      key.count = key.count + 1;

      newTitle = `${newTitle}_${key.count}`;
    }

    if (isEmptyTitle) {
      newTitle = `Header ${+index + 1}`;
    }

    newArrayTitles.push(newTitle);
  }

  return newArrayTitles;
};

export const splitTitleAndContent = (file: string): SplitTitleAndContent => {
  const content = file.split(/\r?\n/);
  const titles = content.shift()?.split(REGEX_FILE_SPLIT) || [];

  return {
    titles: renamesEmptyDuplicated(titles),
    content
  };
};

export const getEmptyObjectData = (): DataPixelObject =>
  JSON.parse(JSON.stringify(EMPTY_DATA_PIXELS_BULK_ITEM));

export const convertToSnakeCase = (str: string): string =>
  str ? str.toLowerCase().replace(/ /g, '_') : '';

type CreatesDataObject = {
  rowArray: string[];
  titles: MappingBulkPixels['titles'];
  data: MappingBulkPixels['data'];
  totalItemsInRow: number;
};

const createsDataObject = ({
  rowArray,
  titles,
  data,
  totalItemsInRow
}: CreatesDataObject) => {
  rowArray.forEach((rowItem: any, idx: number) => {
    const columnTitle = convertToSnakeCase(titles[idx]);
    const key = data[columnTitle] || (data[columnTitle] = getEmptyObjectData());

    if (isInvalidField(rowItem)) {
      key.errors.invalid = key.errors.invalid + 1;
    }
    if (!rowItem) {
      key.errors.missing = key.errors.missing + 1;
    }

    key.values.push(rowItem);
    key.label = titles[idx];
    key.totalItemsInRow = totalItemsInRow;
  });
};

export const parseMappingPixelsFile = (
  fileContent: FileReader['result']
): MappingBulkPixels => {
  if (!fileContent || typeof fileContent !== 'string')
    return EMPTY_DATA_PIXELS_BULK;
  const { titles, content } = splitTitleAndContent(fileContent);

  const data = {};

  content.forEach(row => {
    const rowArray = row.split(REGEX_FILE_SPLIT);
    createsDataObject({
      rowArray,
      titles,
      data,
      totalItemsInRow: content.length
    });
  });

  return {
    titles,
    data
  };
};

export const handleFileUpload = (
  file: File,
  setBulkPixels: React.Dispatch<React.SetStateAction<MappingBulkPixels>>,
  setFilename: any
) => {
  setFilename(file.name);

  readFileData(file).then((content: FileReader['result']) => {
    const { titles, data } = parseMappingPixelsFile(content);

    setBulkPixels({
      data,
      titles
    });
  });
};

type HandleCreatePixelsConfig = {
  setFieldValue: MediaTrackingToolsFormContextType['setFieldValue'];
  handleToggle: () => void;
  setSelectedTag: MediaTrackingToolsContextType['setSelectedTag'];
  setBulkPixels: React.Dispatch<React.SetStateAction<MappingBulkPixels>>;
  bulkTags: SiteServedPixel[];
  tagsQuantity: number;
  bulk_cache_default: string;
  bulk_maid_default: string;
};

const addDefaults = (
  bulkTags: SiteServedPixel[],
  tagsQuantity: number,
  bulk_cache_default: string,
  bulk_maid_default: string
): SiteServedPixel[] => {
  const cachebusterValues: string[] = [];
  const maidValues: string[] = [];

  let i = 0;
  while (i < tagsQuantity) {
    bulk_cache_default && cachebusterValues.push(bulk_cache_default);
    bulk_maid_default && maidValues.push(bulk_maid_default);
    i++;
  }

  return [
    {
      macros: [
        ...bulkTags[0].macros,
        {
          label: 'Cachebuster',
          param: 'cachebuster',
          values: cachebusterValues
        },
        {
          label: 'Mobile Advertising ID',
          param: 'device_id',
          values: maidValues
        }
      ]
    }
  ];
};

export const handleCreatePixels = ({
  setFieldValue,
  handleToggle,
  setSelectedTag,
  setBulkPixels,
  bulkTags,
  tagsQuantity,
  bulk_cache_default,
  bulk_maid_default
}: HandleCreatePixelsConfig) => {
  const tagsWithDefaults = addDefaults(
    bulkTags,
    tagsQuantity,
    bulk_cache_default,
    bulk_maid_default
  );
  setFieldValue('bulk_tags', tagsWithDefaults);
  handleToggle();
  setSelectedTag({ index: 0, tagType: 'bulk_tags' });
  setBulkPixels({ titles: [], data: {} });
};

export const resetValuesAfterUpload = (
  setFieldValue: MediaTrackingToolsFormContextType['setFieldValue'],
  setMappedMacros: ColumnMappingProps['setMappedMacros']
) => {
  setFieldValue('bulk_tags', []);
  setFieldValue('bulk_cache_default', BULK_CACHE_DEFAULT);
  setFieldValue('bulk_maid_default', BULK_MAID_DEFAULT);
  setMappedMacros([]);
};
