import { useState, useEffect } from 'react';

import { useApiFetchEffect } from '~/utils/fetch';

export const useCache = () => {
  const [cache, setCache] = useState<GenericCache>({});

  function useFetchWithCache<TResponse>(
    url: string,
    initialValue: TResponse,
    skip?: boolean
  ): CachedResponseReturn<TResponse> {
    const isResponseCached = cache.hasOwnProperty(url);
    const cachedResponse = cache[url];

    const [isLoading, response = initialValue, error] = useApiFetchEffect<
      TResponse,
      ErrorResponse
    >(url, {}, isResponseCached || skip);

    useEffect(() => {
      !isResponseCached &&
        !isLoading &&
        !error &&
        setCache({ ...cache, [url]: response });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response, isLoading]);

    return {
      data: cachedResponse || initialValue,
      isLoading: !!cachedResponse ? false : isLoading,
      error
    };
  }

  return { cache, setCache, useFetchWithCache };
};
