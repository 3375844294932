import React from 'react';

import { Flex, LssProp, TransparentButton } from '@lucidhq/lucidium';
import {
  useFormikContext,
  FieldArray
} from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

import { BrandsFormData } from './BrandsForm';
import { CompetitorField } from './CompetitorField';

const addCompetitorButtonLss: LssProp = {
  color: 'secondary500',
  display: 'flex',
  alignSelf: 'flex-start',
  flexBasis: 'fit-content',
  height: '4rem',
  py: 'sm',
  px: 'xs'
};

export const CompetitorsSection = ({ readOnly }: { readOnly: boolean }) => {
  const { values } = useFormikContext<BrandsFormData>();
  const hasMaxCompetitors = values?.competitors?.length === 10;

  return (
    <FieldArray
      name="competitors"
      render={helpers => (
        <Flex lss={{ flexDirection: 'column' }}>
          {!!values?.competitors?.length &&
            values.competitors.map((_, index) => (
              <CompetitorField
                key={index}
                index={index}
                handleRemoveItem={helpers.remove}
                readOnly={readOnly}
              />
            ))}
          {!readOnly && (
            <TransparentButton
              type="button"
              lss={addCompetitorButtonLss}
              disabled={hasMaxCompetitors}
              onClick={() => helpers.push('')}
            >
              {`+ ${t('buttons.addCompetitor')}`}
            </TransparentButton>
          )}
        </Flex>
      )}
    />
  );
};
