import React from 'react';
import { OptionProps, components, ControlProps } from 'react-select';

import { Box, Text, Select } from '@lucidhq/lucidium';
import { BackgroundColorProperty } from '@lucidhq/lucidium/lss/csstypes';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import {
  EnhancedTemplateQuestion,
  AnswerOption,
  NOTA
} from '~/utils/templateQuestions';

type QuestionTypeSelectProps = {
  isReadOnly: boolean;
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
};

const TEXT = 'text';

const getAnswersFromContext = (question: EnhancedTemplateQuestion) => {
  const selectedAnswers = question.use_answer_meta
    ? question.answers
    : (question?.customAnswers as string[]);

  return selectedAnswers.includes(NOTA)
    ? selectedAnswers
    : [...selectedAnswers, NOTA];
};

export const QuestionTypeSelect = ({
  isReadOnly,
  question,
  onSave
}: QuestionTypeSelectProps) => {
  return (
    <Select
      labelKey="label"
      valueKey="value"
      options={question.meta.allowedQuestionTypeOptions}
      value={question.question_type}
      onChange={(value: any) => {
        const action = { question_type: value };
        const isGridType = value.includes('table');

        if (value.includes(TEXT) && !!question.meta.isCustomKPI) {
          action['randomize'] = false;
          action['none_of_the_above_answer'] = null;
        } else if (
          !value.includes(TEXT) &&
          !!question.meta.isCustomKPI &&
          !isGridType
        ) {
          action['randomize'] = true;
          action['none_of_the_above_answer'] = NOTA;
          action['answers'] = getAnswersFromContext(question);
          action['customAnswers'] = question?.customAnswers?.includes(NOTA)
            ? question.customAnswers
            : [...(question.customAnswers as string[]), NOTA];
          action[
            'defaultBrandAnswers'
          ] = question.defaultBrandAnswers?.includes(NOTA)
            ? question.defaultBrandAnswers
            : [...(question.defaultBrandAnswers as string[]), NOTA];
        } else if (isGridType && !!question.meta.isCustomKPI) {
          action['randomize'] = true;
          action['none_of_the_above_answer'] = NOTA;
          // NOTE: due to an extreme edge case it is vital for custom grid questions to have a default answers array due to API serializer validation.
          action['answers'] = question?.defaultBrandAnswers?.includes(NOTA)
            ? question.defaultBrandAnswers
            : [...(question?.defaultBrandAnswers as string[]), NOTA];
        }

        onSave(action);
      }}
      disabled={isReadOnly}
      styles={{
        control: (baseStyles: any) => ({
          ...baseStyles,
          backgroundColor: 'hsl(0, 0%, 100%)',
          color: 'hsl(0, 0%, 46%)'
        })
      }}
      components={{
        Control: (props: ControlProps<AnswerOption>) => (
          <Box
            lss={{
              backgroundColor: 'hsl(0, 0%, 100%)' as BackgroundColorProperty
            }}
          >
            <components.Control {...props} />
          </Box>
        ),
        Option: (props: OptionProps<AnswerOption>) => (
          <Select.Option {...props}>
            <Text>{props.children}</Text>
          </Select.Option>
        ),
        SingleValue: (props: OptionProps<AnswerOption>) => (
          <Text lss={{ color: isReadOnly ? 'gray700' : 'secondary500' }}>
            {props.children}
          </Text>
        )
      }}
    />
  );
};
