import React from 'react';

import { Flex, LssProp, Text } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

const textLss: LssProp = {
  height: 'fit-content',
  fontWeight: 'bold',
  pl: 'sm',
  mt: 'sm'
};

export const TemplateQuestionEmptyCard = () => {
  return (
    <Flex
      lss={{
        minHeight: '438px',
        flexBasis: '100%',
        border: 'regular'
      }}
    >
      <Text as="strong" lss={textLss}>
        {t('campaignManager.survey.standardQuestions.containerText')}
      </Text>
    </Flex>
  );
};
