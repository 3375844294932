import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { StepLayout } from '../../Steps/StepLayout';
import { BrandsForm } from './BrandsForm';

export const BrandsStep = () => {
  return (
    <StepLayout.Container>
      <StepLayout.Header
        title={t('campaignManager.survey.brands.header.title')}
        description={t('campaignManager.survey.brands.header.description')}
        lss={{ mb: 'xl' }}
      />
      <StepLayout.Content>
        <Flex lss={{ flexDirection: 'column', flexBasis: '100%' }}>
          <BrandsForm />
        </Flex>
      </StepLayout.Content>
    </StepLayout.Container>
  );
};
