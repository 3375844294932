import * as React from 'react';

import { NotificationTray, createGlobalStyle } from '@lucidhq/lucidium';

const AudienceGlobalStyle: any = createGlobalStyle`
  html, body {
    height: 100%;
  }

  #root {
    min-height: 100%;
    position: relative;
  }
`;

export const SharedElements = () => (
  <>
    <AudienceGlobalStyle />
    <NotificationTray
      lss={{
        bottom: '90px' as any
      }}
    />
  </>
);
