export enum SIZES {
  MACRO_ROW_LABEL_WIDTH = '12.5rem',
  MACRO_FIELD_WIDTH = 'calc(100% - 14.5rem)',
  MOBILE_MACRO_FIELD_WIDTH = 'calc(100% - 2rem)',
  BULK_MACRO_FIELD_WIDTH = 'calc(100% - 17.5rem)',
  MACRO_ROW_APPEND_WIDTH = '2rem',
  VALUES_COUNT_WIDTH = '6rem',
  CAMPAIGN_HEADER_HEIGHT = '13.4rem',
  MAPPING_TASK_HEADER_HEIGHT = '19rem'
}

export enum TRACKING {
  GDPR_PARAMS = 'gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_508}&gdpr_pd=${GDPR_PD}',
  MEDIA_TRACKING_LIST_FALLBACK = 'Unable to Load Media Tracking',
  CREATE_LABEL = 'Create',
  CREATE_PIXEL_LABEL = 'Create Pixels',
  MEDIA_TRACKING_LIST = 'Media Tracking List',
  DIGITAL_MEDIA_TRACKING = 'Digital Media Tracking',
  LINEAR_TV_TRACKING = 'Linear TV Tracking'
}

// Keep spacing as is or it may break the CSV
export enum PIXEL_DOWNLOAD {
  HEADER = `
LUCID TRACKING PIXELS
`,
  INSTRUCTIONS = `Universal Pixel - Implementation Instructions,Site-Served Pixel - Implementation Instructions
"1. Lucid tags are industry-standard 1x1 image impression pixels. Implement as a 1x1 image pixel / impression tracker.
2. If using a tag with macros, implement that ""universal"" tag across the media in the campaign.
DCM users: https://support.lucidhq.com/s/article/Lucid-Implementation-In-DCM","1. If running in mobile app, publisher must replace the ENTER_MOBILE_AD_ID_MACRO placeholder with a macro that dynamically
passes IDFA / AAID. If media doesn't run on mobile app, disregard and leave as-is.
2. Publisher must replace the [InsertCachebusterHere] placeholder with a random number generator (cache buster macro).
3. If only a URL is needed, use the URL portion of the tag: the area between "" and "".
4. Implement as a 1x1 image impression tracker.
5. If applicable, implement in addition to the corresponding agency 1x1 tracker."
 `
}

export enum WARNING_DIALOG {
  HEADING = 'You have unsaved changes!',
  SUBHEADING = 'You must click Save & Download Pixels and/or Save TV Mappings to save your changes.',
  BUTTON_DANGER = 'Leave Page',
  BUTTON_SECONDARY = 'Continue editing'
}
