import 'react-vis/dist/style.css';

import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';

import { Loading } from '@lucidhq/lucidium';
import { AuthProvider } from '@lucidhq/ui-auth';
import { UiAuthFeatureFlagProvider } from '@lucidhq/ui-feature-flags';

import { Analytics, SharedElements } from '~/impact-measurement/components';
import { SCOPES } from '~/utils/permissions';
import { queryClient } from '~/utils/rq';

import { Appcues } from './Appcues/Appcues';
import { PermissionsProvider } from './Permissions/PermissionsProvider';
import { Router } from './Router';
import { ThemeProvider } from './Themes/ThemeProvider';
import { ThemeSwitcher } from './Themes/ThemeSwitcher';

const App = () => (
  <BrowserRouter>
    <ThemeProvider>
      <AuthProvider scopes={Object.values(SCOPES)}>
        <QueryClientProvider client={queryClient}>
          <UiAuthFeatureFlagProvider>
            <PermissionsProvider>
              <SharedElements />
              <Analytics />
              <Appcues />
              <ThemeSwitcher />
              <React.Suspense fallback={<Loading spinnerSize="lg" />}>
                <Router />
              </React.Suspense>
            </PermissionsProvider>
          </UiAuthFeatureFlagProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  </BrowserRouter>
);

export default Sentry.withProfiler(App);
