import React from 'react';

import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';

import { Button, Tooltip } from '@lucidhq/lucidium';
import { DownloadIcon } from '@lucidhq/lucidium/icons';

import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

export const EXPORT_PREVIEW_ID = 'review-campaign';

export const ExportPreview = () => {
  const { managedCampaign } = useCampaignManager();

  const onClick = () => {
    const node = document.querySelector(`#${EXPORT_PREVIEW_ID}`);
    if (!node) return;

    domtoimage
      .toBlob(node)
      .then((blob: Blob) =>
        fileDownload(
          blob,
          `review-campaign-${managedCampaign?.details.campaign_id}.png`
        )
      );
  };

  return (
    <Tooltip title={t('campaignManager.review.header.export')}>
      <Button
        type="button"
        onClick={onClick}
        lss={{
          display: 'flex',
          alignItems: 'center',
          py: 'sm'
        }}
      >
        <DownloadIcon size={20} lss={{ mr: 'md' }} />{' '}
        {t('campaignManager.review.header.export')}
      </Button>
    </Tooltip>
  );
};
