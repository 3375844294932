import { EMPTY_ITEMS } from '~/impact-measurement/constants';

export const EMPTY_UNIVERSAL_TAG: UniversalPixel = {
  ad_server_id: '',
  macros: []
};

export const initialPixelList: MediaTrackingPixelListResponse = EMPTY_ITEMS;

export const initialLinearTvList: MediaTrackingLinearTvListResponse = EMPTY_ITEMS;

export const EMPTY_DATA_PIXELS_BULK: MappingBulkPixels = {
  titles: [],
  data: {}
};

export const initialBulkCreation: MappingBulkPixels = EMPTY_DATA_PIXELS_BULK;

export const BULK_MAID_DEFAULT = 'ENTER_MOBILE_AD_ID_MACRO';
export const BULK_CACHE_DEFAULT = '[InsertCachebusterHere]';

export const initialMediaTrackingToolsValues: MediaTrackingToolsData = {
  campaign_id: '',
  universal_tags: [EMPTY_UNIVERSAL_TAG],
  site_served_tags: [],
  bulk_tags: [],
  bulk_maid_default: BULK_MAID_DEFAULT,
  bulk_cache_default: BULK_CACHE_DEFAULT,
  linearTv: [],
  linearTvListData: []
};
