import React from 'react';
import { MdInfo } from 'react-icons/md';

import { Flex, Text, Tooltip, Icon } from '@lucidhq/lucidium';

type ColHeadProps = { label: string; tooltip: string };

export const IDsTableColHead: React.FC<ColHeadProps> = ({ label, tooltip }) => (
  <Flex lss={{ flexDirection: 'row', alignItems: 'flex-end', gap: '0.25rem' }}>
    <Text>{label}</Text>
    <Tooltip
      title={tooltip}
      placement="bottom-end"
      colorScheme="light"
      enforceRefWrapper
      lss={{ textScale: 'control', p: 'lg' }}
    >
      <Icon as={MdInfo} size={18} lss={{ color: 'gray800' }} />
    </Tooltip>
  </Flex>
);
