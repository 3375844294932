import { ADMIN_BASE_PATH } from '~/impact-measurement/constants';
import { URLS as SHARED_URLS } from '~/impact-measurement/pages/Admin/constants';

export const URLS = {
  basePixelUrl: (campaignId: CampaignId) =>
    `${SHARED_URLS.campaignAdmin(campaignId)}/pixel-base-url`,
  adServers: `${ADMIN_BASE_PATH}/ad-servers`,
  adServer: (id: string) =>
    `${ADMIN_BASE_PATH}/ad-servers/${id}?tag_type__in=standard,extra`,
  pixelGroups: (campaignId: CampaignId) =>
    `${SHARED_URLS.campaignAdmin(campaignId)}/pixel-groups`,
  pixelListItemMacros: (campaignId: CampaignId, id: string) =>
    `${URLS.pixelGroups(campaignId)}/${id}`,
  pixelQueryParams: `${ADMIN_BASE_PATH}/pixel-query-parameters`,
  linearTvList: (campaignId: CampaignId) =>
    `${SHARED_URLS.campaignAdmin(campaignId)}/s2s-mappings?channels=linear_tv`,
  linearTvPartners: `${ADMIN_BASE_PATH}/s2s-partners?channels=linear_tv`,
  linearTvMappingDelete: (campaignId: CampaignId, partnerCampaignId: string) =>
    `${SHARED_URLS.campaignAdmin(campaignId)}/s2s-mappings/${partnerCampaignId}`
};
