import React, { createContext, useMemo, useContext, useState } from 'react';

import { useAdminCampaignData } from '~/impact-measurement/pages/Admin/lib';

import {
  IPMatchingResponseType,
  useFetchIPMatching
} from '../Shared/hooks/useFetchIPMatching';

export type IPMatchedID = {
  ip_matching_type: string;
  ip_matching_id: string;
  enablement_status: string;
  is_enabled: boolean;
  added_at: string;
};

type IPMatching = {
  isLoadingIPMatching: boolean;
  campaign: AdminNavBarCampaign;
  ipMatching?: IPMatchingResponseType;
  rootLevelEnablement: boolean;
  matchedIds: IPMatchedID[];
  updatedIds?: IPMatchedID[];
  setUpdatedIds: React.Dispatch<React.SetStateAction<IPMatchedID[]>>;
};

export const IPMatchingContext = createContext<IPMatching>({} as IPMatching);

export const useIPMatchingContext = () =>
  useContext<IPMatching>(IPMatchingContext);

type IPMatchingProviderProps = {
  children: React.ReactNode;
};

export const IPMatchingProvider = ({ children }: IPMatchingProviderProps) => {
  const { campaign, isLoadingCampaign } = useAdminCampaignData();

  const { isLoading, data: ipMatching, error } = useFetchIPMatching({
    campaignId: campaign?.campaign_id
  });

  const rootLevelEnablement = useMemo(() => {
    return !!ipMatching?.is_enabled;
  }, [ipMatching?.is_enabled]);

  const matchedIds = useMemo(() => ipMatching?.items || [], [
    ipMatching?.items
  ]);
  const [updatedIds, setUpdatedIds] = useState<IPMatchedID[]>([]);

  const isLoadingIPMatching = isLoading || isLoadingCampaign;

  const value = useMemo(
    () => ({
      isLoadingIPMatching,
      ipMatching,
      error,
      rootLevelEnablement,
      campaign,
      matchedIds,
      updatedIds,
      setUpdatedIds
    }),
    [
      isLoadingIPMatching,
      ipMatching,
      error,
      rootLevelEnablement,
      campaign,
      matchedIds,
      updatedIds
    ]
  );

  return (
    <IPMatchingContext.Provider value={value}>
      {children}
    </IPMatchingContext.Provider>
  );
};
