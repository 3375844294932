import React, { useMemo } from 'react';

import { ClipboardButton, Flex, LssProp, Text } from '@lucidhq/lucidium';

import { useFetchCountryLanguages } from '~/components/Shared/hooks/useFetchCountryLanguages';
import { useFetchProjectManagers } from '~/components/Shared/hooks/useFetchProjectManagers';
import { useFetchSalesforce } from '~/components/Shared/hooks/useFetchSalesforce';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { QUOTA_TEMPLATES } from '../../ServiceCampaignDetailsStep/Form/QuotaTemplateField';
import { Colors, ReviewLayout } from '../Misc/ReviewLayout';

const getDate = (date: string) => date.substring(0, 10).replace(/-/g, '/');

const headingLss: LssProp = {
  fontSize: '1.1rem',
  fontWeight: 'bold',
  mb: 'sm'
};

const valueLss: LssProp = {
  fontSize: '1.1rem'
};

const formatImpressions = (n: number) => n && n.toLocaleString();

export const ServiceCampaignBlock = () => {
  const {
    managedCampaign,
    goToStep,
    steps,
    can,
    isService
  } = useCampaignManager();
  const { data: languages } = useFetchCountryLanguages();
  const { data: pms } = useFetchProjectManagers({ isService });
  const { data: salesforceOpps } = useFetchSalesforce({
    value: managedCampaign?.details.salesforce_id,
    isEnabled: true
  });

  const language = useMemo(() => {
    if (!languages) return null;
    const item = languages.items.find(
      lang => lang.id === managedCampaign?.details.country_language_id
    );

    return item;
  }, [languages, managedCampaign]);

  const pm = useMemo(() => {
    if (!pms) return null;
    const item = pms.items.find(
      manager =>
        manager.project_manager_user_id ===
        managedCampaign?.details.project_manager_user_id
    );

    return item;
  }, [managedCampaign, pms]);

  const salesforceName = useMemo(() => {
    if (!salesforceOpps) return null;
    const item = salesforceOpps?.items.find(
      opp => opp.salesforce_id === managedCampaign?.details.salesforce_id
    );

    return item?.salesforce_name;
  }, [salesforceOpps, managedCampaign]);

  const meta = managedCampaign?.details.meta;

  const quotaTemplate = managedCampaign?.details.draft_settings.quota_scheme;
  const quotaSize = managedCampaign?.details.draft_settings.quota_size;
  const controlSurvey =
    managedCampaign?.details.draft_settings.control_survey_enabled;
  const gemini = managedCampaign?.details.draft_settings.control_gemini_enabled;

  return (
    <ReviewLayout.Content color={Colors.BLUE}>
      {(can.edit || can.limitedEdit) && (
        <ReviewLayout.Edit onClick={() => goToStep(steps.DETAILS.id)} />
      )}
      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.campaignId')}
        </Text>

        <Flex lss={{ alignItems: 'center' }}>
          <Text lss={valueLss}>{managedCampaign?.details.campaign_id}</Text>
          <ClipboardButton
            buttonText={t('campaignManager.review.campaign.clipboard.label')}
            copyText={managedCampaign?.details.campaign_id}
            iconPosition="left"
            showIcon
            tooltipText={t('campaignManager.review.campaign.clipboard.tooltip')}
            emphasis="low"
            lss={{
              display: 'flex',
              padding: '.25rem',
              marginLeft: '0.5rem'
            }}
          />
        </Flex>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.project')}
        </Text>
        <Text lss={valueLss}>{managedCampaign?.details.campaign_name}</Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.dateRange')}
        </Text>
        <Text lss={valueLss}>
          {managedCampaign?.details &&
            `${getDate(managedCampaign?.details.start_date)} - ${getDate(
              managedCampaign?.details.end_date
            )}`}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.projectManager')}
        </Text>
        <Text lss={valueLss}>
          {pm ? pm.full_name : t('campaignManager.review.campaign.items.na')}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.salesforce')}
        </Text>
        <Text lss={valueLss}>
          {salesforceName ?? t('campaignManager.review.campaign.items.na')}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.surveyLanguage')}
        </Text>
        <Text lss={valueLss}>
          {language?.country_name} - {language?.language_name}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.quotaTemplate')}
        </Text>
        <Text lss={valueLss}>
          {quotaTemplate && QUOTA_TEMPLATES[quotaTemplate].label}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.trackedMedia')}
        </Text>
        <Text lss={valueLss}>
          {meta?.isDigital && t('forms.labels.digital')}
          {meta?.isDigital && meta?.isLinearTV && <br />}
          {meta?.isLinearTV && t('forms.labels.linear_tv')}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.completes')}
        </Text>
        <Text lss={valueLss}>{formatImpressions(quotaSize as number)}</Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.controlSurvey')}
        </Text>
        <Text
          lss={{
            ...valueLss,
            color: controlSurvey ? 'success400' : 'danger600'
          }}
        >
          {t(
            `campaignManager.review.campaign.items.${
              controlSurvey ? 'on' : 'off'
            }`
          )}
        </Text>
      </ReviewLayout.Item>

      <ReviewLayout.Item>
        <Text lss={headingLss}>
          {t('campaignManager.review.campaign.items.gemini')}
        </Text>
        <Text lss={{ ...valueLss, color: gemini ? 'success400' : 'danger600' }}>
          {t(`campaignManager.review.campaign.items.${gemini ? 'on' : 'off'}`)}
        </Text>
      </ReviewLayout.Item>
    </ReviewLayout.Content>
  );
};
