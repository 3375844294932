import React from 'react';

import { Flex, Label, Text } from '@lucidhq/lucidium';
import { useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import { useCampaignManager } from '~/components/CampaignManager/CampaignManagerProvider';
import { usePermissions } from '~/components/Permissions/PermissionsProvider';
import { CampaignFormValues } from '~/impact-measurement/pages/types';
import { t } from '~/utils/i18n';

import { DataPartnerField } from './DataPartnerField';
import { getTrackedMediaStates } from './helpers';
import { SocialPlatformsField } from './SocialPlatformsField';
import { TrackedMediaItem } from './TrackedMediaItem';

type TrackedMediaSectionProps = {
  showImpressionsFields?: boolean; // TODO: this can possibly be removed determined on how we use this in the future for custom campaigns
  limitedEdit?: boolean;
};

export const TrackedMediaSection: React.FC<TrackedMediaSectionProps> = ({
  showImpressionsFields = true,
  limitedEdit = false
}) => {
  const { setFieldValue, values, errors } = useFormikContext<
    CampaignFormValues
  >();
  const { isSoftware } = useCampaignManager();

  const { isAdmin } = usePermissions();

  const {
    channels,
    draft_settings: {
      expected_impressions_count: digitalImpressions,
      expected_impressions_count_linear_tv: tvImpressions,
      expected_impressions_count_social: socialImpressions,
      data_partners
    }
  } = values;

  const { isChecked } = getTrackedMediaStates(
    channels,
    digitalImpressions,
    tvImpressions,
    socialImpressions
  );

  return (
    <Flex lss={{ flexDirection: 'column' }}>
      <Text
        lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
        as="strong"
      >
        {t(`campaignManager.campaignDetails.questions.trackedMedia`)}
      </Text>
      <Label lss={{ mt: 'xl' }} required>
        {t(`forms.labels.trackedMedia`)}
      </Label>
      <Flex lss={{ flexDirection: 'column', gap: '2px' }}>
        <TrackedMediaItem
          channels={channels}
          type="digital"
          setFieldValue={setFieldValue}
          isChecked={isChecked.digital}
          impressions={digitalImpressions}
          showImpressionsField={showImpressionsFields && isChecked.digital}
          readOnly={limitedEdit}
        />
        <TrackedMediaItem
          channels={channels}
          type="linear_tv"
          setFieldValue={setFieldValue}
          isChecked={isChecked.linear_tv}
          impressions={tvImpressions}
          showImpressionsField={showImpressionsFields && isChecked.linear_tv}
          readOnly={limitedEdit}
        />
        <DataPartnerField
          name="draft_settings.data_partners"
          isLinearTvChecked={isChecked.linear_tv}
          dataPartners={data_partners}
          readOnly={limitedEdit}
        />
        {isAdmin && (
          <>
            <TrackedMediaItem
              channels={channels}
              type="social"
              setFieldValue={setFieldValue}
              isChecked={isChecked.social}
              impressions={socialImpressions}
              showImpressionsField={showImpressionsFields && isChecked.social}
              readOnly={limitedEdit}
            />
            {isSoftware && (
              <SocialPlatformsField
                name="draft_settings.social_platforms"
                isSocialChecked={isChecked.social}
                readOnly={limitedEdit}
              />
            )}
          </>
        )}
      </Flex>

      {!!errors.channels && (
        <Text lss={{ textScale: 'control', color: 'danger500', mb: 'md' }}>
          {errors.channels}
        </Text>
      )}
    </Flex>
  );
};
