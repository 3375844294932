import React from 'react';

import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';

import {
  Button,
  Emphasis,
  LssProp,
  PaletteVariants,
  PlacementType,
  Tooltip
} from '@lucidhq/lucidium';
import { DownloadIcon } from '@lucidhq/lucidium/icons';

type ExportButtonProps = {
  campaignId?: CampaignId;
  buttonLss?: LssProp;
  iconLss?: LssProp;
  emphasis?: Emphasis;
  palette?: PaletteVariants;
  showDownloadText?: boolean;
  tooltipPlacement?: PlacementType;
};

export const ExportButton: React.FunctionComponent<ExportButtonProps> = ({
  campaignId,
  emphasis = '',
  palette,
  buttonLss,
  iconLss,
  showDownloadText,
  tooltipPlacement,
  ...props
}) => {
  const onClick = () => {
    const node = document.querySelector('#question-review-body');
    if (!node) return;

    domtoimage
      .toBlob(node)
      .then((blob: Blob) => fileDownload(blob, `preview-${campaignId}.png`));
  };

  return (
    <Tooltip
      title="Export questions preview (.png)"
      placement={tooltipPlacement}
    >
      <Button
        id="export-preview"
        emphasis={emphasis as Emphasis}
        palette={palette as PaletteVariants}
        lss={{
          display: 'flex',
          alignItems: 'center',
          mr: 'xxs',
          px: 'md',
          py: 'sm',
          ...buttonLss
        }}
        type="button"
        onClick={onClick}
        {...props}
      >
        {!!showDownloadText && 'Download'}{' '}
        <DownloadIcon size={20} lss={iconLss} />
      </Button>
    </Tooltip>
  );
};
