import React from 'react';

import { TextInput } from '@lucidhq/lucidium';

type NumericInputProps = {
  value?: number | string;
  onChange: (value: [number]) => void;
};

export const NumericInput = ({ value, onChange }: NumericInputProps) => {
  return (
    <TextInput
      type="number"
      value={value}
      onChange={e => {
        const { value } = e.target;
        onChange([value]);
      }}
    />
  );
};
