import React from 'react';
import { MdSearch } from 'react-icons/md';

import { Spinner, Icon } from '@lucidhq/lucidium';

export const searchIcon = (isLoading: boolean) => ({
  DropdownIndicator: isLoading
    ? () => <Spinner size="sm" style={{ marginRight: '0.5rem' }} />
    : () => <Icon as={MdSearch} size={20} lss={{ mx: 'sm' }} />
});
