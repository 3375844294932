import React from 'react';
import {
  MdDelete,
  MdEdit,
  MdStar,
  MdStarBorder,
  MdFilterList
} from 'react-icons/md';

import { BusyButton, Button, Flex, Icon } from '@lucidhq/lucidium';

import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../CampaignManagerFooter';
import { useCampaignManager } from '../CampaignManagerProvider';
import { AddNewButton } from '../Steps/AddNewButton';
import { StepLayout } from '../Steps/StepLayout';
import { DeleteAudienceFilter } from './Actions/DeleteAudienceFilter';
import { ManageAudienceFilter } from './Actions/ManageAudienceFilter';
import { SetDefaultAudienceFilter } from './Actions/SetDefaultAudienceFilter';
import { SegmentItem } from './TargetedRecruitment/SegmentItem';
import { TargetedRecruitmentSection } from './TargetedRecruitment/TargetedRecruitmentSection';

export const TargetAudienceStep = () => {
  const {
    goToStep,
    steps,
    activeStep,
    managedCampaign,
    refetchCampaign,
    can
  } = useCampaignManager();
  const { isOn } = useSplit(IM_FLAGS.TARGETED_RECRUITMENT);

  const nextStepId = steps[activeStep].nextId;
  const prevStepId = steps[activeStep].prevId;

  const targets = managedCampaign?.details.draft_settings.target_audiences;

  const externalUserLimitations =
    !can.addCustomKPI && targets && targets.length >= 3;
  const internalUserLimitations = targets && targets.length >= 15;

  const isAddButtonDisabled =
    externalUserLimitations || internalUserLimitations;

  const hasTR =
    isOn && !!managedCampaign?.details?.meta?.hasTargetedRecruitment;

  return (
    <StepLayout.Container>
      <StepLayout.Header
        title={t('campaignManager.targetAudience.header.title')}
      />
      <StepLayout.Content>
        <Flex lss={{ flexDirection: 'column', ...(isOn && { gap: '0.5rem' }) }}>
          {isOn && can.edit && can.addCustomKPI && (
            <Flex lss={{ flexDirection: 'column', gap: '0.0625rem', mb: 'xl' }}>
              <TargetedRecruitmentSection />
              <SegmentItem hasActions />
            </Flex>
          )}

          <StepLayout.Card lss={{ pb: 'lg' }}>
            <StepLayout.CardHeader
              title={t('campaignManager.targetAudience.audienceFilters.title')}
              description={t(
                'campaignManager.targetAudience.audienceFilters.description'
              )}
              titleIcon={MdFilterList}
              descriptionLss={{ paddingLeft: '3.375rem' }}
            />

            <StepLayout.CardActions>
              <ManageAudienceFilter onSave={refetchCampaign}>
                {({ onToggle }) => (
                  <AddNewButton
                    id="add-filter"
                    onClick={onToggle}
                    disabled={!can.edit || isAddButtonDisabled}
                    lss={{ maxHeight: '2.5rem' }}
                  />
                )}
              </ManageAudienceFilter>
            </StepLayout.CardActions>
          </StepLayout.Card>

          {!!targets?.length && (
            <StepLayout.Card>
              <StepLayout.List>
                {targets.map((target, k) => (
                  <StepLayout.ListItem key={k}>
                    <StepLayout.ListTitle>
                      {target.target_name}
                    </StepLayout.ListTitle>
                    <StepLayout.ListActions>
                      <SetDefaultAudienceFilter id={k} onSave={refetchCampaign}>
                        {({ onSetAsDefault, isLoading }) => (
                          <BusyButton
                            isBusy={isLoading}
                            scale="sm"
                            emphasis="low"
                            onClick={onSetAsDefault}
                            disabled={!can.edit}
                          >
                            <Icon
                              size={24}
                              as={target.is_default ? MdStar : MdStarBorder}
                              lss={{ color: 'secondary500' }}
                            />
                          </BusyButton>
                        )}
                      </SetDefaultAudienceFilter>
                      <ManageAudienceFilter id={k} onSave={refetchCampaign}>
                        {({ onToggle }) => (
                          <Button
                            scale="sm"
                            emphasis="low"
                            onClick={onToggle}
                            disabled={!can.edit}
                          >
                            <Icon
                              size={24}
                              as={MdEdit}
                              lss={{ color: 'secondary500' }}
                            />
                          </Button>
                        )}
                      </ManageAudienceFilter>
                      <DeleteAudienceFilter id={k} onDelete={refetchCampaign}>
                        {({ onDelete, isLoading }) => (
                          <BusyButton
                            isBusy={isLoading}
                            scale="sm"
                            emphasis="low"
                            onClick={onDelete}
                            disabled={!can.edit}
                          >
                            <Icon
                              size={24}
                              as={MdDelete}
                              lss={{ color: 'secondary500' }}
                            />
                          </BusyButton>
                        )}
                      </DeleteAudienceFilter>
                    </StepLayout.ListActions>
                  </StepLayout.ListItem>
                ))}
              </StepLayout.List>
            </StepLayout.Card>
          )}

          <CampaignManagerFooter
            onBack={() => prevStepId && goToStep(prevStepId)}
            skipProps={
              !targets?.length && !hasTR
                ? {
                    onClick: () => nextStepId && goToStep(nextStepId)
                  }
                : undefined
            }
            nextProps={
              targets?.length || hasTR
                ? {
                    onClick: () => nextStepId && goToStep(nextStepId)
                  }
                : undefined
            }
          />
        </Flex>
      </StepLayout.Content>
    </StepLayout.Container>
  );
};
