import { useAuth } from '@lucidhq/ui-auth';

type UseProfileType = () => {
  [key: string]: any;
  id: string;
  name: string;
  username: string;
  email: string;
};

export const useProfile: UseProfileType = () => {
  const { user } = useAuth();
  const profile = user || {};

  const username =
    profile && (profile.nickname || profile.name || profile.email);
  const id = profile && (profile.sub || profile.email);
  const name = profile && (profile.name || profile.nickname);

  return {
    ...profile,
    id,
    username,
    name
  };
};
