import * as React from 'react';

const noop = () => {}; // eslint-disable-line

export type SelectedTagType = {
  index: number;
  tagType: TagKeys;
};

export type MediaTrackingToolsContextType = {
  campaignId: CampaignId;
  toolView: ToolView;
  setToolView: (toolView: ToolView) => void;
  selectedTag: SelectedTagType | null;
  setSelectedTag: (selectedTag: SelectedTagType | null) => void;
  fileName: string;
  setFilename: (name: string) => void;
  warningTracker: WarningTracker;
  dispatchWarningTracker: any; //type for dispatch
  savedMappings: LinearTvSaveItem[];
  setSavedMappings: (mappings: LinearTvSaveItem[]) => void;
};

export const MediaTrackingToolsContext = React.createContext<
  MediaTrackingToolsContextType
>({
  campaignId: '1',
  toolView: 'universal',
  setToolView: noop,
  selectedTag: null,
  setSelectedTag: noop,
  fileName: '',
  setFilename: noop,
  warningTracker: {
    universal_tags: {},
    site_served_tags: {},
    bulk_tags: {}
  },
  dispatchWarningTracker: noop,
  savedMappings: [],
  setSavedMappings: noop
});
