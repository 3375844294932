import React from 'react';

import QuestionMarkImg from '../../../assets/question-mark.png';

export const QuestionMarkIcon = ({ light = false }: { light?: boolean }) => (
  <img
    src={QuestionMarkImg}
    alt="Question Mark"
    {...(light && { style: { filter: 'invert(100%)' } })}
  />
);
