import React from 'react';

import { Flex, Loading, LssProp, Text } from '@lucidhq/lucidium';

import { ROUTES } from '~/constants';
import { BasePage } from '~/impact-measurement/components';
import { AdminNavBar } from '~/impact-measurement/pages/Admin';
import { t } from '~/utils/i18n';

import { IDsTable } from './IDsTable/IDsTable';
import { IPManagementSection } from './IPManagementSection';
import { useIPMatchingContext } from './IPMatchingProvider';
import { MatchedIDsHeader } from './MatchedIDsHeader';

const containerLss: LssProp = {
  flexDirection: 'column',
  mx: 'xxxl',
  minHeight: '25rem',
  pb: 'xxl',
  mb: 'xxl',
  gap: '1rem'
};

export const IPMatchingBasePage = () => {
  const { isLoadingIPMatching, campaign, matchedIds } = useIPMatchingContext();

  if (isLoadingIPMatching) {
    return (
      <Flex lss={{ m: 'auto', width: '100%', height: 'calc(100vh - 80px)' }}>
        <Loading message={'Loading IP Matching...'} />
      </Flex>
    );
  }

  return (
    <BasePage isFluid hasFooter={false}>
      {/* NOTE: need to add client-name to campaign in order to display in-target brand correctly */}
      <AdminNavBar
        showBackButton
        campaign={campaign}
        isReview={false}
        backToPath={ROUTES.adminCampaigns}
        navBarLss={{
          top: '0',
          position: 'unset',
          tablet: { position: 'unset', paddingLeft: 0 }
        }}
      />
      <Flex lss={containerLss}>
        <Text
          as="h3"
          lss={{ fontWeight: 'bold', lineHeight: '40px', fontSize: '2rem' }}
        >
          {t('ipMatching.pageHeader')}
        </Text>
        <IPManagementSection />
        <MatchedIDsHeader
          title={t('ipMatching.matchedIdsHeader.title')}
          description={t('ipMatching.matchedIdsHeader.description')}
          matchedIds={matchedIds}
        />
        <IDsTable />
      </Flex>
    </BasePage>
  );
};
