import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { initialAdminCampaign } from '~/impact-measurement/pages/Admin/constants';
import { useApiFetchEffect } from '~/utils/fetch';

import { URLS } from '../constants';

type BaseAdminPageParams = {
  campaignId: CampaignId;
};

type UseCampaignData = () => {
  isLoadingCampaign: boolean;
  campaign: AdminNavBarCampaign;
  campaignId: CampaignId;
  campaignError: ErrorResponse;
};

export const useAdminCampaignData: UseCampaignData = () => {
  const { campaignId } = useParams<BaseAdminPageParams>();

  const { state } = useLocation<{ campaign: AdminNavBarCampaign }>();

  const stateCampaign = state && state.campaign;

  const [campaign, setCampaign] = useState<AdminCampaignResponse>({
    ...initialAdminCampaign,
    ...stateCampaign,
    campaign_id: campaignId
  });

  const skipFetch = !!campaign.status;

  const [
    isLoadingCampaign,
    campaignResponse = initialAdminCampaign,
    campaignError
  ] = useApiFetchEffect<AdminCampaignResponse, ErrorResponse>(
    URLS.campaignAdmin(campaignId),
    {},
    skipFetch
  );

  useEffect(() => {
    if (campaignError || skipFetch) return;
    setCampaign(campaignResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignError, campaignResponse]);

  return {
    isLoadingCampaign,
    campaign: { ...campaign, client_name: stateCampaign?.client_name || '' },
    campaignId,
    campaignError
  };
};
