import React from 'react';

import {
  Flex,
  Text,
  LssProp,
  H2,
  H3,
  Icon,
  IconProps
} from '@lucidhq/lucidium';

type ContainerProps = {
  children: React.ReactNode;
  isFluid?: boolean;
};

const defaultContainerLss: LssProp = {
  width: '100%',
  py: 'lg',
  px: 'xl',
  flexDirection: 'column',
  desktop: { mx: 'auto', width: `62rem` }
};

const fluidContainerLss: LssProp = {
  width: '100%',
  flexDirection: 'column',
  py: 'lg',
  px: 'xl'
};

const Container = ({ children, isFluid = false }: ContainerProps) => (
  <Flex lss={isFluid ? fluidContainerLss : defaultContainerLss}>
    {children}
  </Flex>
);

type ContentProps = { children: React.ReactNode; lss?: LssProp };

const Content = ({ children, lss }: ContentProps) => (
  <Flex lss={lss}>{children}</Flex>
);

type HeaderProps = {
  title: string;
  description?: string;
  lss?: LssProp;
  children?: React.ReactNode;
};

const Header = ({ description, title, lss, children }: HeaderProps) => (
  <Flex lss={{ mb: 'xxxl', mt: 'xxl', flexDirection: 'column', ...lss }}>
    <H2>{title}</H2>
    {description && (
      <Text lss={{ mt: 'xl', whiteSpace: 'pre-line' }}>{description}</Text>
    )}
    {!!children && children}
  </Flex>
);

/**
 * Card Components
 */
const cardLss: LssProp = {
  borderBottom: 'regular',
  borderColor: 'gray300',
  pb: 'xxxl',
  mb: 'xxxl'
};

type CardProps = {
  children: React.ReactNode;
  separator?: boolean;
  lss?: LssProp;
};

export const Card = ({ children, separator = false, lss }: CardProps) => (
  <Flex
    lss={{
      pb: 'xl',
      ...(separator && cardLss),
      ...lss
    }}
  >
    {children}
  </Flex>
);

type CardHeaderProps = {
  title: string;
  description: string | React.ReactElement;
  titleIcon?: React.ComponentType<any>;
  iconProps?: Omit<IconProps, 'as'>;
  cardActions?: React.ReactElement;
  descriptionLss?: LssProp;
};

export const CardHeader = ({
  title,
  description,
  titleIcon,
  iconProps,
  cardActions,
  descriptionLss
}: CardHeaderProps) => (
  <Flex lss={{ flexDirection: 'column', flex: '1' }}>
    <Flex lss={{ flexDirection: 'row', justifyContent: 'space-between' }}>
      <Flex lss={{ flexDirection: 'row', gap: '1.25rem' }}>
        {!!titleIcon && <Icon as={titleIcon} size={32} {...iconProps} />}
        <H3>{title}</H3>
      </Flex>
      {cardActions}
    </Flex>
    <Text lss={{ mt: 'md', ...descriptionLss }}>{description}</Text>
  </Flex>
);

type CardActionsProps = {
  children: React.ReactNode;
  lss?: LssProp;
};

export const CardActions = ({ children, lss }: CardActionsProps) => (
  <Flex
    lss={{
      width: '200px',
      flexShrink: 0,
      justifyContent: 'flex-end',
      ...lss
    }}
  >
    {children}
  </Flex>
);

/**
 * List Components
 */
type ListProps = {
  children: React.ReactNode;
  lss?: LssProp;
};

const List = ({ children, lss }: ListProps) => (
  <Flex lss={{ flexDirection: 'column', flex: '1', ...lss }}>{children}</Flex>
);

type ListItemProps = { children: React.ReactNode; lss?: LssProp };

const listItemLss: LssProp = {
  mb: 'md',
  p: 'md',
  border: 'regular',
  borderColor: 'gray300',
  alignItems: 'center',
  borderRadius: 'md'
};

const ListItem = ({ children, lss }: ListItemProps) => (
  <Flex lss={{ ...listItemLss, ...lss }}>{children}</Flex>
);

type ListTitleProps = { children: React.ReactNode };

const ListTitle = ({ children }: ListTitleProps) => (
  <Flex lss={{ flex: '1' }}>
    <Text lss={{ fontWeight: 'bold' }}>{children}</Text>
  </Flex>
);

type ListCountProps = { children: React.ReactNode };

const ListCount = ({ children }: ListCountProps) => (
  <Flex lss={{ flex: '1' }}>
    <Text>{children}</Text>
  </Flex>
);

type ListActionsProps = { children: React.ReactNode };

const ListActions = ({ children }: ListActionsProps) => (
  <Flex lss={{ flex: '0' }}>{children}</Flex>
);

export const StepLayout: {
  Container: React.FC<ContainerProps>;
  Header: React.FC<HeaderProps>;
  Content: React.FC<ContentProps>;
  Card: React.FC<CardProps>;
  CardHeader: React.FC<CardHeaderProps>;
  CardActions: React.FC<CardActionsProps>;
  List: React.FC<ListProps>;
  ListItem: React.FC<ListItemProps>;
  ListTitle: React.FC<ListTitleProps>;
  ListActions: React.FC<ListActionsProps>;
  ListCount: React.FC<ListCountProps>;
} = {
  Container,
  Header,
  Content,
  Card,
  CardHeader,
  CardActions,
  List,
  ListItem,
  ListTitle,
  ListActions,
  ListCount
};
