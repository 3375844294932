import React from 'react';
import { MdSave } from 'react-icons/md';

import { Icon, useBreakpoint, BusyButton } from '@lucidhq/lucidium';
import { useFeatureFlag } from '@lucidhq/ui-feature-flags';

import { IM_FLAGS } from '~/impact-measurement/constants';

import { useSaveLinearTv, hasPartnerCampaignIds } from '../lib';
import { getButtonLss } from '../styles';

enum BREAKPOINTS {
  tablet = 'tablet',
  desktop = 'desktop'
}

type MediaTrackingSaveButton = {
  data: LinearTvSaveItem[];
  linearTv: LinearTv[];
  isTvTabSelected: boolean;
  isValid: boolean;
  pixelCount: number;
  isSubmitting: boolean;
};

export const MediaTrackingSaveButton: React.FC<MediaTrackingSaveButton> = ({
  data = [],
  linearTv = [],
  isTvTabSelected,
  isValid,
  pixelCount,
  isSubmitting
}) => {
  const { treatment } = useFeatureFlag(IM_FLAGS.TV_CAMPAIGN_MAPPING);
  const isTablet = useBreakpoint(BREAKPOINTS.tablet);
  const { saveLinearTv, isLoading } = useSaveLinearTv();

  const buttonPixelCount =
    pixelCount && !isTvTabSelected ? `(${pixelCount})` : '';
  const buttonLabel = isTvTabSelected
    ? 'Save TV Mappings'
    : `Save & Download Pixels ${buttonPixelCount}`;
  const hasNewCampaignIds = hasPartnerCampaignIds(linearTv);

  const isDisabled =
    (!hasNewCampaignIds && isTvTabSelected) || (!isValid && !isTvTabSelected);

  return (
    <BusyButton
      lss={getButtonLss(treatment, isTablet)}
      scale="sm"
      disabled={isDisabled}
      isBusy={isSubmitting || isLoading}
      type={isTvTabSelected ? 'button' : 'submit'}
      onClick={() => isTvTabSelected && saveLinearTv(data)}
      aria-label={buttonLabel}
    >
      <Icon as={MdSave} size={25} lss={{ mr: isTablet ? 'sm' : 'none' }} />
      {isTablet && buttonLabel}
    </BusyButton>
  );
};
