import * as React from 'react';

import { ButtonLink, Text, Link, Message } from '@lucidhq/lucidium';

import { ROUTES } from '~/constants';
import { Page } from '~/impact-measurement/components';

export const UnauthorizedPage = () => {
  return (
    <Page hideProductName>
      <Message
        message="Unauthorized"
        description="You are not authorized to view this page."
        helper={
          <Text lss={{ textScale: 'caption' }}>
            If you need access, please{' '}
            <Link to="https://support.lucidhq.com/s/" target="_blank">
              contact
            </Link>{' '}
            our support team.
          </Text>
        }
        actions={[
          <ButtonLink to="/" palette="primary" key="back-to-home">
            Back to Home
          </ButtonLink>,
          <ButtonLink
            to={ROUTES.logout}
            palette="primary"
            emphasis="medium"
            key="logout"
          >
            Logout
          </ButtonLink>
        ]}
      />
    </Page>
  );
};
