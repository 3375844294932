export default {
  translation: {
    header: {
      title: {
        im: 'Impact Measurement',
        studiesList: 'Studies'
      }
    },
    home: {
      welcome: 'Welcome, {{name}}',
      cards: {
        im: {
          name: 'Impact Measurement',
          description: 'View the results of your Impact Measurement campaigns'
        },
        admin: {
          name: 'Admin',
          description:
            'Create Impact Measurement and Custom Campaigns, complete tasks.',
          externalUserDescription: 'Create and Manage Studies.'
        }
      }
    },
    dataGrid: {
      columns: {
        manage: 'Manage'
      },
      actions: {
        editStudy: 'Edit Study',
        editStudyV1: 'Edit Study in V1',
        editStudyV2: 'Edit Study in V2',
        delete: 'Delete',
        revert: 'Revert to Draft',
        ipMatching: 'IP Matching'
      },
      deleteCampaign: {
        confirm: 'Are you sure you want to delete this campaign?'
      },
      revertCampaign: {
        confirm: 'Are you sure you want to revert this campaign to draft?',
        success: 'The campaign was successfully reverted to draft.',
        failure: 'The campaign could not be reverted to draft.'
      }
    },
    campaignFilters: {
      tags: {
        prefixes: {
          targetAudience: 'Target Audience',
          startDate: 'Start Date',
          endDate: 'End Date',
          age: 'Age',
          education: 'Education',
          employment: 'Employment',
          ethnicity: 'Ethnicity',
          gender: 'Gender',
          hispanic: 'Hispanic',
          'household income': 'Household Income',
          'household income (international)': 'Household Income',
          industry: 'Industry',
          region: 'Region',
          state: 'State',
          device_types: 'Device',
          device_type_groups: 'Device',
          frequency: 'Frequency',
          frequency_range: 'Frequency Range',
          frequency_gte: 'Frequency',
          publishers: 'Site',
          publisher_groups: 'Site Group',
          placements: 'Placement',
          placement_groups: 'Placement Group',
          creatives: 'Creative',
          creative_groups: 'Creative Group',
          networks: 'Network',
          ad_durations: 'Ad Length',
          dayparts: 'Daypart',
          media_channels: 'Media Channel',
          channel__in: 'INCLUDE Study Channel',
          exclude_channels: 'EXCLUDE Study Channel'
        }
      },
      dialog: {
        header: 'You have unapplied filters, do you still want to exit?'
      },
      dropdown: {
        buttonTitle: 'Filter',
        title: 'Filters',
        actions: {
          clear: 'Clear all',
          apply: 'Apply filters',
          exit: 'Exit'
        },
        states: {
          error: 'Failed to load current filters. Please try again later.',
          noData: 'No options available for selected filter'
        }
      },
      demographics: {
        title: 'Demographics',
        ariaLabel: 'Select Demographics Filters'
      },
      targets: {
        title: 'Target Audience',
        ariaLabel: 'Select Target Filters',
        qualifications: {
          question: 'Qualification:',
          answer: 'Target value(s):'
        },
        toggler: {
          less: 'See less',
          more: 'See more'
        },
        tooltip:
          'Target audience(s) are defined during set-up, using specific demographics or behavioral characteristics. Clicking on a Target Audience will filter the campaign lift results by the respondents who match the target audience criteria, in the UI.'
      },
      studyChannels: {
        title: 'Study Channels',
        ariaLabel: 'Select Study Channels'
      },

      mediaFilters: {
        title: 'Media',
        ariaLabel: 'Select Media Filters',
        sections: {
          device_types: 'Device',
          device_type_groups: 'Device',
          frequency: 'Frequency',
          publishers: 'Site',
          publisher_groups: 'Site Groups',
          placements: 'Placement',
          placement_groups: 'Placement Groups',
          creatives: 'Creative',
          creative_groups: 'Creative Groups',
          networks: 'Network',
          ad_durations: 'Ad Length',
          dayparts: 'Daypart',
          media_channels: 'Media Channels',
          exclude_channels: 'Exclude selected channels'
        }
      },
      dateRange: {
        title: 'Date Range',
        ariaLabel: 'Select Date Range Filters',
        tooltip:
          'The date range will filter the campaign lift results by the completed responses that occurred within that date range.'
      },
      search: {
        placeholder: 'Search Filters'
      }
    },
    form: {
      labels: {
        startDate: 'Start Date',
        endDate: 'End Date',
        minAge: 'Minimum Age',
        maxAge: 'Maximum Age',
        minFrequency: 'Minimum Frequency',
        maxFrequency: 'Maximum Frequency'
      }
    },
    insights: {
      values: {
        phone: 'Mobile'
      }
    },
    reports: {
      charts: {
        kpi: {
          name: 'KPI',
          title: 'Lift Performance by KPI',
          tooltip: {
            title: 'Lift Performance by KPI',
            subtitle:
              'Overall brand lift results including statistical significance, margin of error, and exposed/control counts for each campaign KPI.',
            description:
              'Which media platforms are included will depend on what the study is measuring.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ]
          }
        },
        age: {
          name: 'Age',
          title: 'Lift Performance by Age',
          tooltip: {
            title: 'Lift Performance by Age',
            subtitle:
              'Overall brand lift results for each campaign KPI broken out by standard age buckets.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ]
          }
        },
        gender: {
          name: 'Gender',
          title: 'Lift Performance by Gender',
          tooltip: {
            title: 'Lift Performance by Gender',
            subtitle:
              'Overall brand lift results for each campaign KPI broken out by standard gender buckets.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ]
          }
        },
        frequency: {
          name: 'Frequency',
          title: 'Lift Performance by Frequency',
          tooltip: {
            title: 'Lift Performance by Frequency',
            subtitle:
              'Overall brand lift results for each campaign KPI broken out by standard frequency buckets.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ]
          }
        },
        crossPlatform: {
          name: 'Cross-Platform',
          title: 'Lift Performance by Cross-Platform',
          tooltip: {
            title: 'Cross-Platform Lift Performance',
            subtitle:
              'Overall brand lift results for each campaign KPI broken out by unique exposed groups.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ]
          },
          description:
            'Not all cross-platform buckets have enough sample to calculate significance and will not populate in the chart.'
        },
        device: {
          name: 'Device',
          title: 'Lift Performance by Device',
          tooltip: {
            title: 'Lift Performance by Device',
            subtitle:
              'Brand lift results for all respondents whose last exposure was on linear TV. The control group includes all respondents who took a survey and were not exposed to an ad.',
            infoSections: [
              {
                category: 'Desktop',
                definition: 'Ads served on desktop devices'
              },
              {
                category: 'Mobile/Phone',
                definition:
                  'Ads served on mobile devices including web and in-app'
              },
              {
                category: 'Tablet',
                definition: 'Ads served on a tablet'
              },
              {
                category: 'TV',
                definition:
                  'Ads served in a CTV or Linear TV environment, based on IP-address '
              },
              {
                category: 'Included in results',
                definition: 'Digital and/or Linear TV'
              }
            ]
          }
        },
        mediaChannel: {
          overview: {
            title: 'Lift Performance by Media Channel'
          },
          name: 'Media Channel',
          title: 'Lift Performance by Media Channel',
          tooltip: {
            title: 'Lift Performance by Media Channel',
            subtitle:
              'Overall brand lift results for each campaign KPI broken out by media channel. Media channel is defined by how and where an ad was served.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital, Linear TV, and/or Social'
              }
            ],
            description: `Note: A 'highly likely' exposed to social respondent may have also been exposed to a trackable digital or linear tv ad. If so, that respondent would also be counted toward brand lift for that media channel, depending on which was the last exposure.`
          }
        },
        site: {
          name: 'Site',
          tooltip: {
            title: 'Site Definitions',
            subtitle:
              'The digital properties where the digital media campaign will be running.',
            infoSections: [
              {
                category: 'Lift % Ranking',
                definition:
                  'shows the ranking by lift percentage. Lift % is the difference in percentages between the exposed group and the control group.'
              },
              {
                category: 'Exposed % Ranking',
                definition:
                  'shows the ranking by percentage of exposed respondents that selected the in-target answer. Exposed % is inclusive of respondent weighting and propensity.'
              },
              {
                category: 'Impression Share',
                definition:
                  'visualizes share of impressions and lift %. Size indicates the share (%) of impressions and color indicates lift performance. Click a box to see more details.'
              }
            ]
          }
        },
        placement: {
          name: 'Placement',
          tooltip: {
            title: 'Placement Definitions',
            subtitle:
              'Otherwise referred as an "ad placement" is the place where a display ad will be located on a website. A placement can be on a website or a specific page on a site, a mobile app, video content or even an individual ad unit.',
            infoSections: [
              {
                category: 'Lift % Ranking',
                definition:
                  'shows the ranking by lift percentage. Lift % is the difference in percentages between the exposed group and the control group.'
              },
              {
                category: 'Exposed % Ranking',
                definition:
                  'shows the ranking by percentage of exposed respondents that selected the in-target answer. Exposed % is inclusive of respondent weighting and propensity.'
              },
              {
                category: 'Impression Share',
                definition:
                  'visualizes share of impressions and lift %. Size indicates the share (%) of impressions and color indicates lift performance. Click a box to see more details.'
              }
            ]
          }
        },
        creative: {
          name: 'Creative',
          tooltip: {
            title: 'Creative Definitions',
            subtitle:
              'The ad served to users on a webpage, app, tv or other environment. Creatives can be images, videos, audio, and other formats that get delivered to users.', // TODO: need product definition
            infoSections: [
              {
                category: 'Lift % Ranking',
                definition:
                  'shows the ranking by lift percentage. Lift % is the difference in percentages between the exposed group and the control group.'
              },
              {
                category: 'Exposed % Ranking',
                definition:
                  'shows the ranking by percentage of exposed respondents that selected the in-target answer. Exposed % is inclusive of respondent weighting and propensity.'
              },
              {
                category: 'Impression Share',
                definition:
                  'visualizes share of impressions and lift %. Size indicates the share (%) of impressions and color indicates lift performance. Click a box to see more details.'
              }
            ]
          }
        },
        //NOTE: KPI Tab content tooltips below
        crossPlatformTabContent: {
          name: 'Digital and Linear TV Lift Performance',
          tooltip: {
            title: 'Digital and Linear TV Lift Performance by KPI',
            subtitle:
              'Brand lift results for respondents exposed to ad(s) on digital and/or linear TV. The control group includes all respondents who took a survey and were not exposed to an ad.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital and Linear TV'
              }
            ]
          }
        },
        digitalTabContent: {
          name: 'Digital Lift Performance',
          tooltip: {
            title: 'Digital Lift Performance by KPI',
            subtitle:
              'Brand lift results for all respondents whose last exposure was on a digital channel. The control group includes all respondents who took a survey and were not exposed to an ad.  ',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Digital'
              }
            ]
          }
        },
        linearTvTabContent: {
          name: 'Linear TV Lift Performance',
          tooltip: {
            title: 'Linear TV Lift Performance by KPI',
            subtitle:
              'Brand lift results for all respondents whose last exposure was on linear TV. The control group includes all respondents who took a survey and were not exposed to an ad.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Linear TV'
              }
            ]
          }
        },
        socialTabContent: {
          name: 'Social Lift Performance',
          tooltip: {
            title: 'Social Lift Performance by KPI',
            subtitle:
              'Brand lift results for all respondents whose last exposure was on social. The control group includes all respondents who took a survey and were not exposed to an ad.',
            infoSections: [
              {
                category: 'Included in results',
                definition: 'Social'
              }
            ]
          }
        },
        // Misc. Chart Tooltips
        tooltips: {
          buckets: [
            {
              chartType: 'crossPlatform',
              label: 'Digital Only',
              description: 'Respondents who were only exposed to digital ad(s).'
            },
            {
              chartType: 'crossPlatform',
              label: 'Linear TV Only',
              description:
                'Respondents who were only exposed to linear tv ad(s).'
            },
            {
              chartType: 'crossPlatform',
              label: 'Social Only',
              description: `Respondents who were scored as ‘highly likely’ exposed to a social ad for the campaign. `
            },
            {
              chartType: 'crossPlatform',
              label: 'Digital & Linear TV',
              description:
                'Respondents who were exposed to both a digital ad(s) and a linear tv ad(s).'
            },
            {
              chartType: 'crossPlatform',
              label: 'Digital & Social',
              description:
                'Respondents who were exposed to a digital ad(s) and are scored as ‘highly likely’ exposed to a social ad for the campaign.'
            },
            {
              chartType: 'crossPlatform',
              label: 'Linear TV & Social',
              description:
                'Respondents who were exposed to a linear tv ad(s) and are scored as ‘highly likely’ exposed to a social ad for the campaign.'
            },
            {
              chartType: 'crossPlatform',
              label: 'Digital, Social, & Linear TV',
              description: `Respondents who were exposed to a digital ad(s), a linear tv ad(s) and are scored as ‘highly likely’ exposed to a social ad for the campaign.`
            },
            // Start of media channel buckets
            {
              chartType: 'mediaChannel',
              label: 'Desktop Web',
              description:
                'Where the operating system information indicates “desktop”.'
            },
            {
              chartType: 'mediaChannel',
              label: 'Mobile App',
              description:
                'Where the device classification is mobile, tablet, phone or watch and is identified as non-browser app.'
            },
            {
              chartType: 'mediaChannel',
              label: 'Mobile Web',
              description:
                'Where the device classification is mobile, tablet, phone or watch and the layout classification is mobile web.'
            },
            {
              chartType: 'mediaChannel',
              label: 'Connected TV',
              description:
                'Where the device classification is set-top-box or tv and the data source is not linear tv.'
            },
            {
              chartType: 'mediaChannel',
              label: 'Linear TV',
              description:
                'Where the device classification is set-top-box or tv and the data source is linear tv.'
            },
            {
              chartType: 'mediaChannel',
              label: 'Social',
              description: `Where Impact Measurement social methodology has identified that a respondent is 'highly likely' to have been exposed to a campaign ad on a social channel.`
            }
          ]
        }
      },
      tooltips: {
        targetedRecruitment:
          'Participant recruitment is limited to <bold>{{name}}<bold>'
      }
    },
    campaignManagerLanding: {
      title: 'What kind of campaign do you want to create?',
      softwarev2: {
        title: 'V.2 Software Campaign',
        description: 'Create a software campaign'
      },
      servicev2: {
        title: 'V.2 Custom / Services',
        description: 'Create a campaign with custom quotas and target audience'
      },
      softwarev1: {
        title: 'V.1 Software Campaign',
        description: 'Create a software campaign'
      },
      servicev1: {
        title: 'V.1 Custom / Services',
        description: 'Create a custom campaign '
      }
    },
    campaignManager: {
      steps: {
        DETAILS: 'Details',
        MEDIA_TRACKING: 'Tracking',
        SURVEY: 'Survey',
        AUDIENCE: 'Audience',
        REVIEW: 'Review'
      },
      buttonTexts: {
        back: 'Back',
        next: 'Next',
        skip: 'Skip',
        saveClose: 'Save and Close',
        saveContinue: 'Save and Continue',
        preview: 'Preview',
        edit: 'Edit',
        generateVariations: 'Generate variations'
      },
      campaignDetails: {
        header: {
          title: 'Details',
          description:
            'Enter the details for your campaign and associated brand lift study.'
        },
        questions: {
          dates: 'Survey flight dates',
          industry: 'What industry does your brand fall in?',
          countryLanguage: 'What language do you want to run your survey in?',
          trackedMedia: 'What media would you like to track?'
        },
        tooltips: {
          startDate:
            'The start date cannot be changed once you launch your campaign.',
          endDate:
            'The end date can be extended after launching your study, but you will need to provide the updated number of campaign impressions to align with your extension. You cannot shorten your date range.',
          industry:
            'The selected industry adds your campaign to a category to provide you with performance benchmarks viewable in your results.',
          impressions: 'Minimum impression requirements may vary by region.'
        },
        placeholders: {
          digital: 'Enter 1,000,000 Impressions or more',
          linear_tv: 'Enter 50,000,000 Impressions or more',
          social: 'Enter 300,000 Impressions or more'
        }
      },
      targetAudience: {
        header: {
          title: 'Target Audience Filter (optional)'
        },
        targetedRecruitment: {
          title: 'Targeted Recruitment',
          description:
            'Target your recruitment and get the exact participants you need. Choose from Age, Gender, Household Income, and Region to create a precise scope of who can take your survey. Your selections will <bold>limit recruitment</bold>, allowing you to define more specifically who will be eligible to take your survey.',
          modal: {
            alert:
              'At least one demographic attribute must be selected in order to create Targeted Recruitment.',
            header: 'Create Targeted Recruitment',
            labels: {
              name: 'Targeted Recruitment Group Name',
              age: 'Age',
              gender: 'Gender',
              hhi: 'Income',
              location: 'Geographic Location',
              region: 'Region',
              DMA: 'DMA',
              state: 'State',
              'household income': 'Income'
            }
          },
          delete: 'Are you sure you want to remove targeted recruitment?'
        },
        audienceFilters: {
          title: 'Audience Filters',
          description:
            'Create up to 3 custom filters to be able to quickly view certain data cuts of your campaign in the reporting dashboard. \nPlease note: This does NOT limit recruitment but may add additional questions onto the survey to ensure the respondents align with your Target Audience.',
          form: {
            create: 'Create New Filter'
          }
        },
        tooltips: {
          attributes:
            'There are a wide range of attributes available to you. If you have something very specific that is not available in our library, our team may have to set this up for you.'
        }
      },
      mediaTracking: {
        header: {
          title: 'Media Tracking',
          description:
            'Please choose how you will be tracking media for your campaign.'
        },
        universalTag: {
          title: 'Universal Tag',
          description:
            'If your media is being served through an ad server (such as GAM, DCM, TTD) use this to generate an event tag that can be appended at the campaign level.',
          form: {
            description:
              'Choose your ad server, then add or edit your macros or values in the ID fields.'
          }
        },
        siteServed: {
          title: 'Site-Served',
          description:
            'If your media is being served directly by a publisher and the tags need to be placed individually at the site, placement, or creative level.',
          label: 'Site-Served Pixel {{id}}',
          form: {
            description: 'Create site-served pixels one at a time.'
          },
          tooltips: {
            sid:
              'This name will appear in your results dashboard when viewing your Lift Performance by Site.',
            pid:
              'This name will appear in your results dashboard when viewing your Lift Performance by Placement.',
            crid:
              'This will appear in your results dashboard when viewing your Lift Performance by Creative.',
            cachebuster:
              'A dynamically populating parameter in the Lucid Pixel. The ad servers macro should be entered at the time of implementation. This parameter ensures that a user’s browser sees a pixel as unique and serves the pixel up during each new ad call.',
            device_id:
              'A unique pseudo-anonymous identifier tied to a mobile phone. Also known as the Advertising ID, this unique ID number is how mobile advertisers can track users and engagement on mobile apps.'
          }
        },
        linearTv: {
          title: 'Linear TV',
          description:
            'If you have an integrated partnership with us, initiate by appending Samba or iSpot Creative IDs here.',
          form: {
            description:
              'Add {{partner}} Creative IDs one at a time. Or, add multiple IDs separated by a comma.',
            creativeIds: 'Associated {{partner}} Creative IDs: ',
            emptyMessage:
              'You currently do not have any associated Linear TV IDs. Please add them above.'
          },
          mediaCount: 'Associated Creative IDs: {{count}}'
        },
        download: {
          description:
            'You can copy the URL and HTML values and apply them to your media. Or, you can download all URL and HTML strings in the xcel document on the Media Tracking page.'
        },
        delete: {
          description: 'Are you sure you want to delete this?'
        }
      },
      survey: {
        brands: {
          header: {
            title: 'Brands',
            description:
              'Enter your brand name and up to 10 competitive brands for comparison. These brand names will be randomized and shown as multiple choice options in the survey, with a “None of the Above” option included by default.'
          },
          brandInformation: {
            title: 'Brand Information',
            fields: {
              productCategory: {
                label: 'Product Category (automotive, beverages)',
                tooltip:
                  'This can be used in several KPI questions. Ex:“The next time you are in-market for [Product Category], which of the following brands are you most likely to consider? Select one.”'
              },
              purchaseWindow: {
                label: 'Purchase Window (7 days, 3 months)',
                tooltip:
                  'This will be used in the Purchase Intent KPI question. “Which of the following brands are you intending to purchase in the next [Purchase Window]? Select all that apply.” (Purchase window could be X days, X weeks or X months.)'
              },
              adMessage: {
                label: 'Ad message',
                tooltip: `This can be used in the Message Association or Message Awareness KPI question. Ex: “Which of the following brands of [Product Category] do you most associate with '[Message]'? Select one.`
              }
            }
          },
          brandAttributes: {
            title: 'Brand Attributes',
            description:
              'Will you be asking a Brand Attributes question?  “How strongly do you agree or disagree with the following statements about Your Brand?”'
          },
          questions: {
            brand: 'What is your brand name? (In Target brand)',
            competitors: 'And what are your competitive brands?',
            brandInfo:
              'This information will be used to populate certain KPIs if selected.',
            brandAttributes:
              'Will you be asking a Brand Attributes question?  “How strongly do you agree or disagree with the following statements about Your Brand?”'
          },
          ariaLabels: {
            deleteField: 'Delete {{field}} {{index}}'
          }
        },
        standardQuestions: {
          header: {
            title: 'Survey Questions'
          },
          tooltips: {
            primaryKPI: {
              selected: 'Primary KPI',
              notSelected: 'Set as Primary KPI'
            }
          },
          questionsListDescription: `Please choose up to 5 KPI's for your survey. The following are most used
          in Lucid brand lift studies.`,
          containerText:
            'To get started, add a KPI from the options on the left',
          questionCard: {
            inTargetAnswers:
              'Please click on the star(s) to select one or multiple for your in-target answer.',
            gridInTargetAnswers:
              'Note: Please click on the star icon to select answer(s) for your in-target answers.',
            answer: 'Answer',
            questionLogic: {
              title: 'Question Logic',
              tooltip:
                'Each KPI has been programmed with settings that align with best practices. These settings are shown for transparency but cannot be changed.'
            }
          }
        },
        behavioralQuestions: {
          header: {
            title: 'Behavorial/Attitudinal',
            description: `These questions are automatically included in all Impact Measurement studies as part of Lucid’s deconfounding methodology.  These ensure that respondents in the exposed and control group are similar both in demographics and psychographic/behavioral traits. These can be edited, but NOT removed from your survey. The response data will be made available in the raw data file.

            Your chosen industry falls outside of a traditional brand lift study. In order to ensure only industry appropriate questions are added to your survey, please choose at least one of the questions from the left-hand column. A campaign requires at least 4 behavioral/attitudinal questions for our methodology.  You can edit all blue text.`
          },
          sidebar: {
            description:
              'Add from this list to ensure you have at least FOUR Behavioral/Attitudinal Questions.'
          }
        },
        socialQuestions: {
          header: {
            title: 'Social',
            description: `Because you selected Social OTS as part of your Tracked Media, this question will be included as part of your survey.`
          }
        },
        customKPI: 'Custom KPI',
        customKPIDescription: 'Your question here',
        customQuestion: 'Custom Question',
        kpiName: 'KPI Name'
      },
      review: {
        header: {
          title: 'Review',
          revert: 'Revert to Draft',
          export: 'Export Survey Review',
          softwareDescription:
            'Clicking “Launch” will set your brand lift survey live. Response collection will begin using the information in the workflow (i.e. start date) when your ad campaign is also live with the appropriate impression tracking enabled.',
          serviceDescription:
            'Clicking “Launch” at the bottom of this page will prompt a control and exposed survey to be created in Marketplace. Please do all programming and editing of survey questions within Marketplace.'
        },
        campaign: {
          title: 'Campaign Details',
          clipboard: {
            label: 'Copy',
            tooltip: 'Copied!'
          },
          items: {
            account: 'Account',
            campaignName: 'Campaign Name',
            campaignId: 'Campaign Id',
            completes: 'Number of Completes',
            project: 'Project Name',
            projectManager: 'Project Manager',
            salesforce: 'Salesforce Opportunity',
            controlSurvey: 'Create a control survey',
            gemini: 'Turn on Gemini',
            quotaTemplate: 'Quota Template',
            dateRange: 'Date Range',
            surveyLanguage: 'Survey Language',
            brandIndustry: 'Brand Industry',
            trackedMedia: 'Tracked Media',
            mediaImpressions: 'Media Impressions',
            mediaPartner: 'Media Partner',
            socialPlatforms: 'Social Platforms',
            na: 'N/A',
            on: 'ON',
            off: 'OFF'
          }
        },
        mediaTracking: {
          title: 'Media Tracking',
          warning: {
            title: 'Warning',
            description:
              'You have not set up any Media Tracking for your campaign. Although not required, will result in lack of control and exposed weighting in your Campaign. ',
            decline: 'I do not need Media Tracking',
            confirm: 'Add Media Tracking',
            learnMore: 'Learn More'
          },
          notRequired: {
            title: 'No Media Tracking Required',
            description:
              'You have acknowledged that Media Tracking is not required for your campaign. Is this incorrect?',
            confirm: 'Add Media Tracking'
          }
        },
        survey: {
          title: 'Survey Questions',
          standard: 'Standard Questions',
          behavioral: 'Behavioral/Attitudinal Questions',
          social: 'Social Questions',
          warning: {
            title: 'Warning',
            description:
              'You have not entered any Standard Questions for your campaign. Please enter at least one Standard Question in order to launch your campaign.',
            confirm: 'Add Standard Questions'
          }
        },
        audience: {
          title: 'Audience',
          targetAudience: 'Target Audience',
          targetedRecruitment: 'Targeted Recruitment',
          warning: {
            title: 'Warning',
            description:
              'You have not set up any Target Audience Filters. Although not required, these help expedite filtering to specific data cuts you may want to monitor throughout the campaign. ',
            decline: 'I do not need Audience Filters',
            confirm: 'Add Audience Filters',
            learnMore: 'Learn More'
          },
          notRequired: {
            title: 'No Target Audience Filters Required',
            description:
              'You have acknowledged that you do not need any Target Audience filters for viewing your campaign results. Is this incorrect?',
            confirm: 'Add Audience Filters'
          }
        },
        launch: {
          dialog: {
            header: 'Launch Study',
            description: 'Ready to launch your Study?',
            warning:
              'Please review your study before launching. Once you’ve launched your study, you won’t be unable to edit key details such as KPI questions, In-Target Brand and Competitive Set, Media Channels, Industry, Country - Language, and Start Date.',
            requirementsDescription: 'All required information is complete.',
            requirementsOptional:
              'You are choosing not to include optional fields:',
            successMessage: 'Your study has been launched!',
            validation: {
              warning:
                'You cannot launch your campaign without completing the following information:',
              brands:
                'You must complete the required information on the brand page',
              kpiQuestions:
                'Add at least one Standard Question to your campaign',
              baselineQuestions: 'Add at least four Behavioral Questions',
              optional: 'Optional information:'
            }
          }
        }
      },
      actions: {
        addNew: 'Add New',
        downloadPixels: 'Download Pixels',
        saveAndClose: 'Save and Close',
        add: 'Add',
        close: 'Close',
        cancel: 'Cancel',
        addCustom: 'Add Custom',
        launch: 'Launch',
        ok: 'Ok',
        create: 'Create'
      }
    },
    ipMatching: {
      pageHeader: 'IP Management',
      title: 'IP Matching',
      description:
        'Enable if your study is running CTV, OTT, or Podcast media. It is automatically enabled any time <CustomLink> the business rules</CustomLink> are met for IP-based studies. ',
      matchedIdsHeader: {
        title: 'Manage IP Matched IDs',
        description:
          'This table shows placement IDs that have been automatically or manually IP matched for this study. You can turn on/off current ID matching or add new placement IDs.'
      },
      noMatchedIDsTitle: 'No matched IDs',
      noMatchedIDsDisabled:
        'IP Matching is turned off. To match placement IDs, first toggle on IP Matching at the top of the page.',
      noMatchedIDsEnabled:
        'To match placement ID’s upload them using the “+ Add IDs” button',
      confirmDialog: {
        title:
          'Are you sure you would like to <underlined>{{enablement}}</underlined> IP match for this study?',
        notificationTitle:
          'This study will not be matching respondents based off IP address',
        notificationMsg:
          'You can enable IP match at any point to continue automated and manual IP matching for this study.'
      },
      duplicateIDsDialog: {
        title: 'One or more IDs have previously been IP matched.',
        notificationTitle: 'The following IDs have already been IP Matched',
        notificationMsg: '{{duplicateIds}} will not be added to the list again.'
      },
      actions: {
        addNewIDs: 'Add New IDs',
        cancel: 'Cancel',
        upload: 'Upload IDs',
        placement:
          'Add the Placement IDs that you would like to be IP Matched.',
        site: 'Add the Site IDs that you would like to be IP Matched.',
        placeholder: 'Place each ID on a new line',
        confirmIPMatch: 'Yes, turn {{enablement}} IP Match',
        close: 'Close'
      },
      labels: {
        status: 'Status',
        dateAdded: 'Date Added',
        ipMatch: 'IP Match',
        idType: 'ID Type',
        ipID: 'ID',
        placementIDs: 'Placement IDs',
        siteIDs: 'Site IDs',
        enablement: '{{status}} enabled',
        placement: 'Placement',
        site: 'Site'
      },
      tooltips: {
        dateAdded:
          'This is the date that this placement ID was added to the list of IP matched ID’s.',
        ipMatch:
          '"Automated” indicates that IP match has been automatically enabled for this placement ID based off of the business rules. “Manual” indicates that IP match for this placement has been manually enabled by the user. Any time a user disables/enables an ID this will change to “Manual” status as they have taken a manual action with the ID.',
        status:
          'By default all IDs uploaded manually or by automation will be turned on for IP Matching. If you no longer want an ID to be matched, toggle the status off. If IP match is disabled at the top of this page, these placement toggles will not be activated.'
      }
    },
    forms: {
      labels: {
        account: 'Account',
        adServer: 'Select your ad server',
        urlPreview: 'URL Preview',
        htmlPreview: 'HTML Preview',
        campaignName: 'Campaign Name',
        startDate: 'Start Date',
        endDate: 'End Date',
        countryLanguage: 'Country-Language',
        industry: 'Industry',
        trackedMedia: 'Tracked Media',
        digital: 'Digital',
        linear_tv: 'Linear TV',
        dataPartner: 'Data Partner',
        socialPlatforms:
          'Which social media platforms are included on the media plan?',
        samba: 'Samba',
        ispot: 'iSpot.tv',
        social: 'Social',
        salesforce: 'Salesforce Opportunity',
        project: 'Project',
        completes: 'Number of Completes',
        controlSurvey: 'Create a control survey',
        gemini: 'Turn on Gemini',
        quotaTemplate: 'Quota Template',
        projectManager: 'Project Manager',
        sid: 'Site ID',
        pid: 'Placement ID',
        crid: 'Creative ID',
        cachebuster: 'Cachebuster',
        device_id: 'Mobile Advertising ID',
        c1: 'Custom Parameter 1',
        c2: 'Custom Parameter 2',
        c3: 'Custom Parameter 3',
        c4: 'Custom Parameter 4',
        c5: 'Custom Parameter 5',
        brand: 'My Brand',
        targetName: 'Target Audience Name',
        targetDefault:
          'Apply this filter by default to my study results (You can have one default filter per study)',
        targetDefaultOverride:
          'Apply this filter by default to my study results (It will override any existing filter set as default)',
        attributeX: 'Attribute {{index}}',
        competitorX: 'Competitor {{index}}',
        customAnswerX: 'Answer {{index}}',
        rows: 'Rows',
        columns: 'Columns',
        randomize: 'Randomize',
        none_of_the_above_answer: 'Add none of the above',
        required: 'Required',
        impressions: {
          digital: 'Number of Impressions (Digital)',
          linear_tv: 'Number of Impressions (Linear TV)',
          social: 'Number of Impressions (Social)'
        },
        baselineQuestionsOverride:
          'Disable behavioral question validation for this campaign.',
        is_custom_question_text: 'Custom Question'
      },
      placeholders: {
        loadingAccounts: 'Loading Accounts...',
        account: 'Select Account',
        searchingSalesforce: 'Searching Opportunities...',
        reqsSalesforce: 'Please type three or more characters',
        noopSalesforce: 'No Opportunities',
        loadingCountryLanguage: 'Loading Country-Languages...',
        countryLanguage: 'Select Country-Language',
        quotaTemplate: 'Select Quota Template',
        loadingIndustry: 'Loading Industries...',
        loadingProjectManager: 'Loading Project Managers...',
        projectManager: 'Select Project Manager',
        industry: 'Select Industry',
        search: 'Search...',
        options: 'Select Options...',
        controlSurvey:
          'Test your campaign with respondents from a control group',
        socialPlatforms: 'Search and select all desired options',
        textAnswerVariations:
          'Type variation(s), separated by a comma and press enter'
      },
      descriptions: {
        targetName:
          'This is the name you will see as an in the filter panel on your results dashboard.',
        qualifications:
          'Start typing the attribute you’d like to filter for, such as gender, politics, or zip code, etc.'
      },
      errors: {
        requiredField: 'This field is required',
        countryLanguage:
          'Must be “United States - English”, or "United Kingdom - English" if Linear TV is included',
        countryLanguageSocial:
          'Must be "Australia - English", "Canada - English", "United Kingdom - English", or "United States - English" if Social is included',
        channels: 'You must select at least one Tracked Media channel',
        digitalImpressions: 'Must be at least 1,000,000 to launch campaign',
        tvImpressions: 'Must be at least 50,000,000 to launch campaign',
        socialImpressions: 'Must be at least 300,000 to launch campaign',
        socialWithoutOtherMedia:
          'You must add digital Tracked Media in order to include social measurement',
        minSocialPlatforms: 'At least 1 social platform is required',
        maxSocialPlatforms:
          'To ensure the best survey experience, no more than 5 social platforms including “other” can be selected',
        smallerThanInitial: 'Must be at least {{min}} impressions',
        startDate: 'Start date must be before end date',
        endDateLive: 'New end date must be after existing end date',
        endDate: 'End date must be after start date',
        brandName: 'Brand name is required',
        competitorX: 'Competitor Brand {{index}} is required',
        answerX: 'Answer {{index}} is required',
        endDatePast: 'End Date cannot be in the past',
        quotaSize: 'Quota size must be greater than 0',
        customQuestionText: 'Custom Question is required',
        customKPIName: 'KPI Name is required'
      }
    },
    labels: {
      and: 'and',
      digital: 'Digital',
      linearTV: 'Linear TV',
      social: 'Social',
      targetedRecruitment: 'Targeted Segment',
      crossPlatform: 'Cross-Platform',
      overview: 'Overview',
      on: 'ON',
      off: 'OFF'
    },
    links: {
      backToCampaigns: 'Back to Campaigns',
      backToStudies: 'Back to Studies',
      noSocialData: 'Data does not include social.',
      learnMore: 'Learn more'
    },
    buttons: {
      ok: 'Ok',
      addCompetitor: 'Add Competitor',
      addAttributes: 'Add Attributes',
      addAttribute: 'Add Attribute',
      addAnswer: 'Add Answer',
      addGridAnswer: 'Add {{type}} Answer',
      createCampaign: 'Create Campaign',
      createStudy: 'Create Study'
    },
    actions: {
      cancel: 'Cancel',
      remove: 'Remove',
      delete: 'Delete'
    },
    errors: {
      campaignDraft:
        'We ran into an issue while processing your campaign. Please try again later.',
      brand:
        'We were unable to process your brand related changes. Please try again later.',
      generalApiError:
        'We were unable to process your request. Please try again later.'
    },
    imAdmin: {
      users: {
        roles: {
          admin: 'Admin',
          external: 'External',
          externalBasicPlus: 'External Basic Plus'
        }
      }
    }
  }
};
