import React, { memo } from 'react';

import { Flex, Text, Link } from '@lucidhq/lucidium';

import { Trans } from '~/utils/i18n';

export const IPMatchingSectionDescription = memo(() => (
  <Flex lss={{ flexDirection: 'row', marginTop: '1.5rem', wordWrap: 'normal' }}>
    <Text>
      <Trans
        i18nKey="ipMatching.description"
        components={{
          CustomLink: (
            <Link
              to="https://support.lucidhq.com/s/article/Matching-on-IP-Address"
              target="_blank"
              lss={{
                textDecoration: 'underline',
                color: 'primary',
                mx: 'xxs'
              }}
            />
          )
        }}
      />
    </Text>
  </Flex>
));
