import { TemplateQuestions } from './components/Shared/hooks/useFetchTemplateQuestions';
import { TargetedRecruitment } from './impact-measurement/pages/types';

export enum Channels {
  DIGITAL = 'digital',
  LINEAR_TV = 'linear_tv',
  SOCIAL = 'social'
}

export enum CampaignStatus {
  DRAFT = 'draft',
  LIVE = 'live',
  COMPLETE = 'complete',
  PENDING = 'pending',
  ERROR = 'error',
  PROOF_SERVICES = 'proof/services',
  SERVICES_DRAFT = 'services/draft'
}

export enum CampaignTypes {
  SERVICE = 'services',
  SOFTWARE = 'software'
}

export type DraftTargetQualification = {
  qual_name: string;
  marketplace_question_id: number;
  answer_type: 'contains' | 'range';
  precodes: string[];
};

export type DraftTargetAudience = {
  target_name: string;
  qualifications: DraftTargetQualification[];
  is_default?: boolean;
};

export type DraftTargetedRecruitment = {
  name: string;
  qualifications: any[];
};

export type FieldValue = {
  label: string;
  name: string;
  value: string;
};

export type Campaign = {
  account_id?: string;
  campaign_data_updated_at: string | null;
  campaign_id: string;
  campaign_name: string;
  client_name: string;
  control_survey_id?: number;
  country_language_id: number;
  created_at: string;
  default_question_id?: number;
  default_question_name: string;
  draft_settings: {
    [key: string]: any;
    target_audiences?: DraftTargetAudience[];
    targeted_recruitment?: DraftTargetedRecruitment;
    questions: TemplateQuestions;
    baseline_questions: TemplateQuestions;
    ui?: {
      mediaTrackingAck?: boolean;
      targetAudAck?: boolean;
      // Override flag for baseline questions validation
      isBaselineValidationDisabled?: boolean;
    };
    quota_scheme?: string;
    quota_size?: number;
    control_gemini_enabled?: boolean;
    control_survey_enabled?: boolean;
    campaign_version?: number;
  };
  end_date: string;
  errors: any;
  exposed_survey_id?: number;
  industry_id: number;
  salesforce_id?: string | null;
  permission_id: number;
  start_date: string;
  status: CampaignStatus;
  updated_at: string;
  channels: Channels[];
  region?: string;
  targeted_recruitment?: TargetedRecruitment | null;

  // Custom Campaign
  project_manager_user_id?: number;
  product_type?: CampaignTypes;
};

export enum DEMOGRAPHICS {
  AGE = 'age',
  GENDER = 'gender',
  EDUCATION = 'education'
}

export type SocialPlatform = {
  id: number;
  social_platform_name: string;
};
