import React from 'react';

import { useToggle } from '@lucidhq/lucidium';

import { BusyDeleteDialog } from '~/components/Shared/BusyDeleteDialog';
import { useUpsertDraftCampaign } from '~/components/Shared/hooks/useMutateCampaignDetails';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';

type DeleteTargetedRecruitmentProps = {
  children: ({
    onDelete,
    isLoading
  }: {
    onDelete: () => void;
    isLoading: boolean;
  }) => React.ReactNode;
  onDelete: () => void;
};

export const DeleteTargetedRecruitment = ({
  children,
  onDelete
}: DeleteTargetedRecruitmentProps) => {
  const [isOpen, handleToggle] = useToggle(false);
  const { managedCampaign, onMutateError } = useCampaignManager();
  const { mutate, isLoading } = useUpsertDraftCampaign(
    managedCampaign?.details?.campaign_id
  );

  const handleDelete = () => {
    if (!managedCampaign) return;

    const copyDraftSettings = { ...managedCampaign?.details?.draft_settings };

    if (copyDraftSettings?.hasOwnProperty('targeted_recruitment')) {
      delete copyDraftSettings?.targeted_recruitment;
    }

    mutate(
      {
        ...managedCampaign?.details,
        draft_settings: {
          ...copyDraftSettings
        }
      },
      {
        onSuccess: () => {
          onDelete();
          handleToggle();
        },
        ...onMutateError
      }
    );
  };

  return (
    <>
      {children({ onDelete: handleToggle, isLoading })}
      <BusyDeleteDialog
        isOpen={isOpen}
        isLoading={isLoading}
        onDelete={handleDelete}
        onClose={handleToggle}
        deleteBtnText={t('actions.remove')}
      >
        {t('campaignManager.targetAudience.targetedRecruitment.delete')}
      </BusyDeleteDialog>
    </>
  );
};
