import React, { useContext } from 'react';

import { Flex, Tabs, LssProp } from '@lucidhq/lucidium';
import { useFeatureFlag } from '@lucidhq/ui-feature-flags';

import { IM_FLAGS } from '~/impact-measurement/constants';
import { useTabsWithCounts } from '~/impact-measurement/hooks';
import {
  MediaTrackingToolsContext,
  MediaTrackingToolsFormContext,
  SelectedTagType
} from '~/impact-measurement/pages/Admin/Contexts';
import { AudienceTheme } from '~/utils/styles';

import { TAB_IDS } from '../constants';
import { getTabsAndCounts, getMergedTvDataForSave } from '../lib';
import { TAG_KEYS } from '../Tags/TagsGeneration/constants';
import { useAssociatedMappings } from '../TvMapping/lib';
import { MediaTrackingSaveButton } from './MediaTrackingSaveButton';

const containerFullScreenLss: LssProp = {
  justifyContent: 'center',
  border: 'regular',
  px: 'xl',
  pt: 'xl',
  bg: 'gray100'
};

const wrapperLss: LssProp = {
  width: '100%',
  justifyContent: 'space-between',
  maxWidth: `calc(${AudienceTheme.breakpoints.desktop} - 2*${AudienceTheme.space.xl})`,
  position: 'relative'
};

export const MediaTrackingToolsHeader = () => {
  const { treatment } = useFeatureFlag(IM_FLAGS.TV_CAMPAIGN_MAPPING);

  const { setToolView, setSelectedTag, toolView } = useContext(
    MediaTrackingToolsContext
  );

  const {
    mediaToolData: { bulk_tags, site_served_tags, universal_tags, linearTv },
    isValid,
    isSubmitting
  } = useContext(MediaTrackingToolsFormContext);

  const { associatedMappings } = useAssociatedMappings();

  const { mediaTrackingTabs, pixelCount, bulkTagsLength } = getTabsAndCounts(
    bulk_tags,
    site_served_tags,
    universal_tags,
    linearTv,
    treatment
  );

  const { tabs, selectedTabId, setSelectedTabId } = useTabsWithCounts(
    mediaTrackingTabs,
    toolView
  );

  const handleSelect = (id: string | number) => {
    const siteTagType = bulkTagsLength ? 'bulk' : 'site';
    const tagId = id === 'site' ? siteTagType : id;

    const tag: SelectedTagType = { index: 0, tagType: TAG_KEYS[tagId] };
    setSelectedTabId(id as ToolView);
    setToolView(id as ToolView);
    id !== 'tv' && setSelectedTag(tag);
  };

  const isTvTabSelected = selectedTabId === TAB_IDS.tv;
  const saveButtonData = isTvTabSelected
    ? getMergedTvDataForSave(linearTv, associatedMappings)
    : [];

  return (
    <Flex lss={containerFullScreenLss}>
      <Flex lss={wrapperLss}>
        <Tabs
          id="tag-builder"
          tabs={tabs}
          selectedId={selectedTabId}
          onSelect={handleSelect}
          lss={{
            border: 'none',
            lineHeight: 1
          }}
        />
        <MediaTrackingSaveButton
          data={saveButtonData}
          linearTv={linearTv}
          isTvTabSelected={isTvTabSelected}
          isValid={isValid && !!pixelCount}
          pixelCount={pixelCount}
          isSubmitting={isSubmitting}
        />
      </Flex>
    </Flex>
  );
};
