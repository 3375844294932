import React, { useState } from 'react';
import { MdDelete } from 'react-icons/md';

import {
  BusyButton,
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  Icon,
  Text,
  TextArea,
  useToggle
} from '@lucidhq/lucidium';

import { S2SPartner } from '~/components/Shared/hooks/useFetchS2SPartners';
import { useManageS2SMappings } from '~/components/Shared/hooks/useMutateS2SMappings';
import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { DeleteS2SMapping } from './DeleteS2sMapping';

type ManageS2SMappingsProps = {
  campaignId: string;
  partner?: S2SPartner;
  creativeIds?: string[];
  onSave: () => void;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const ManageS2SMappings = ({
  children,
  campaignId,
  partner,
  onSave,
  creativeIds
}: ManageS2SMappingsProps) => {
  const [newIds, setNewIds] = useState<string>();
  const [isOpen, handleToggle] = useToggle(false);
  const { mutate, isLoading } = useManageS2SMappings(campaignId);
  const { onMutateError } = useCampaignManager();

  const handleSubmit = () => {
    if (!newIds?.length) return;
    const parsedIds = newIds.split(',').map(id => id.trim());

    if (!parsedIds.length) return;

    mutate(
      [
        {
          partner_id: partner?.id as number,
          partner_campaigns_ids: parsedIds
        }
      ],
      {
        onSuccess: () => {
          setNewIds('');
          onSave();
        },
        ...onMutateError
      }
    );
  };

  const handleClose = () => {
    handleToggle();
    setNewIds('');
  };

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog isOpen={isOpen} onClose={handleClose} lss={{ width: '43rem' }}>
        <DialogHeader palette="disabled" onClose={handleClose}>
          {t('campaignManager.mediaTracking.linearTv.title')}
        </DialogHeader>
        <DialogContent>
          <Text lss={{ mb: 'md' }}>
            {t('campaignManager.mediaTracking.linearTv.form.description', {
              partner: partner?.name
            })}
          </Text>
          <TextArea
            data-testid="creative-ids"
            onChange={e => setNewIds(e.currentTarget.value)}
            value={newIds}
            rows={4}
          />
          <Flex
            lss={{
              justifyContent: 'center',
              mt: 'xxl',
              mb: 'xxl'
            }}
          >
            <BusyButton
              isBusy={isLoading}
              disabled={!newIds?.length}
              onClick={handleSubmit}
            >
              {t('campaignManager.actions.add')}
            </BusyButton>
          </Flex>
          <Text lss={{ mb: 'md' }}>
            {t('campaignManager.mediaTracking.linearTv.form.creativeIds', {
              partner: partner?.name
            })}
            {!creativeIds?.length && (
              <Text as="span">
                {t('campaignManager.mediaTracking.linearTv.form.emptyMessage')}
              </Text>
            )}
          </Text>
          <Flex lss={{ flexDirection: 'column' }}>
            {partner &&
              creativeIds?.map((id, k) => (
                <Flex
                  key={`${id}-${k}`}
                  lss={{
                    borderTop: 'regular',
                    borderTopColor: 'gray300',
                    alignItems: 'center'
                  }}
                >
                  <Text lss={{ flex: '1', pl: 'lg' }}>{id}</Text>
                  <DeleteS2SMapping
                    campaignId={campaignId}
                    partnerId={partner.id}
                    creativeId={id}
                    onDelete={onSave}
                  >
                    {({ onDelete, isLoading }) => (
                      <BusyButton
                        isBusy={isLoading}
                        scale="sm"
                        emphasis="low"
                        onClick={onDelete}
                      >
                        <Icon
                          size={20}
                          as={MdDelete}
                          lss={{ color: 'secondary500' }}
                        />
                      </BusyButton>
                    )}
                  </DeleteS2SMapping>
                </Flex>
              ))}
          </Flex>

          <Flex
            lss={{
              justifyContent: 'center',
              mt: 'xxl',
              mb: 'xxl'
            }}
          >
            <Button type="button" onClick={handleToggle}>
              {t('campaignManager.actions.close')}
            </Button>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
