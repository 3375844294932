import React from 'react';
import { GoAlert } from 'react-icons/go';

import {
  Dialog,
  DialogContent,
  Button,
  LssProp,
  Icon,
  Message
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type ErrorDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  errorText: string;
  lss?: LssProp;
};

const dialogContentLss: LssProp = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const buttonLss: LssProp = {
  minWidth: 0,
  width: '3.5rem',
  mx: 'sm',
  mt: 'xl',
  mb: 'sm'
};

export const ErrorDialog = ({
  isOpen = false,
  errorText,
  onClose,
  lss
}: ErrorDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      closeOnEscPress
      lss={{
        minWidth: 'xs',
        ...lss
      }}
    >
      <DialogContent lss={dialogContentLss}>
        <Message
          description={errorText}
          icon={() => (
            <Icon as={GoAlert} size={42} lss={{ color: 'danger500' }} />
          )}
        />

        <Button scale="sm" onClick={onClose} lss={buttonLss}>
          {t('buttons.ok')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
