import React from 'react';

import {
  Box,
  Button,
  Flex,
  InlineNotification,
  Link,
  Text
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

type MediaTrackingWarningProps = {
  showCTAs: boolean;
  onConfirm: () => void;
  onDecline: () => void;
};

export const MediaTrackingWarning = ({
  showCTAs,
  onConfirm,
  onDecline
}: MediaTrackingWarningProps) => {
  return (
    <Flex lss={{ flexDirection: 'column' }}>
      <InlineNotification
        lss={
          showCTAs
            ? {
                mb: 'xxl'
              }
            : {}
        }
        palette="warning"
        title={t('campaignManager.review.mediaTracking.warning.title')}
      >
        <Box>
          <Text lss={{ mb: 'lg', display: 'block' }}>
            {t('campaignManager.review.mediaTracking.warning.description')}

            <Link
              target={'_blank'}
              to="https://support.lucidhq.com/s/article/Lucid-Pixels-101"
            >
              {t('campaignManager.review.mediaTracking.warning.learnMore')}.
            </Link>
          </Text>
        </Box>
      </InlineNotification>
      {showCTAs && (
        <Flex lss={{ justifyContent: 'center', gap: '1rem' }}>
          <Button emphasis="medium" onClick={onDecline}>
            {t('campaignManager.review.mediaTracking.warning.decline')}
          </Button>
          <Button onClick={onConfirm}>
            {t('campaignManager.review.mediaTracking.warning.confirm')}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
