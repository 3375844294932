import React from 'react';

import { BusyButton, Button, Flex } from '@lucidhq/lucidium';
import { useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { TargetedRecruitmentForm } from './ManageTargetedRecruitment';

type TRFormActionsProps = {
  handleToggle: () => void;
  isLoading: boolean;
};

const findSelectedValues = (values: any) => {
  if (!values?.name?.length || !values?.name?.trim()) return false;

  for (const key in values) {
    if (key !== 'name' && values[key].size > 0) {
      return true;
    }
  }
  return false;
};

export const FormActions = ({
  handleToggle,
  isLoading
}: TRFormActionsProps) => {
  const { managedCampaign } = useCampaignManager();
  const { resetForm, values } = useFormikContext<TargetedRecruitmentForm>();

  const handleCancel = () => {
    //NOTE: Reset the form on create
    if (!managedCampaign?.details?.meta?.hasTargetedRecruitment) {
      resetForm();
    }
    handleToggle();
  };

  const hasSelectedValues = findSelectedValues(values);

  return (
    <Flex
      lss={{
        borderTop: 'regular',
        color: 'white',
        flexDirection: 'row',
        py: 'md',
        px: 'xl',
        gap: '0.75rem',
        justifyContent: 'flex-end',
        flexGrow: 0,
        alignSelf: 'flex-end',
        width: '100%'
      }}
    >
      <Button
        type="button"
        scale="sm"
        palette="primary"
        emphasis="medium"
        lss={{ borderRadius: 'xs' }}
        onClick={handleCancel}
      >
        {t('campaignManager.actions.cancel')}
      </Button>
      <BusyButton
        scale="sm"
        isBusy={isLoading}
        type="submit"
        lss={{
          borderRadius: 'xs'
        }}
        disabled={!hasSelectedValues}
      >
        {t('campaignManager.actions.create')}
      </BusyButton>
    </Flex>
  );
};
