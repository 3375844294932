import * as React from 'react';

import { Flex, FlexProps } from '@lucidhq/lucidium';

export type ContainerSizeKeys = 'sm' | 'md' | 'lg';
export type ContainerSizeT = Record<ContainerSizeKeys, number>;
export const ContainerSizes: ContainerSizeT = {
  sm: 960,
  md: 1280,
  lg: 1920
};

export type ContainerProps = FlexProps & {
  children: React.ReactNode;
  offsetHeight?: number;
  minHeight?: string;
  size?: ContainerSizeKeys;
};

export const Container = ({
  children,
  minHeight,
  offsetHeight,
  ...props
}: ContainerProps) => (
  <Flex
    as="main"
    {...props}
    lss={{
      flex: 1,
      flexDirection: 'column',
      my: 0,
      mx: 'auto',
      minHeight: `calc(${minHeight} - ${offsetHeight}px)`,
      ...props.lss
    }}
  >
    {children}
  </Flex>
);

Container.defaultProps = {
  size: 'md',
  offsetHeight: 0,
  minHeight: '100vh'
};

export const ContentContainer = ({
  children,
  size,
  ...styles
}: ContainerProps) => (
  <Flex
    {...styles}
    lss={{
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
      maxWidth: ContainerSizes[size || Container.defaultProps.size],
      mt: 0,
      mx: 'auto',
      mb: 'xxxl',
      pb: 'xxl',
      zIndex: '2' as any,

      mobile: {
        px: 'xl'
      },

      tablet: {
        px: 'xxl'
      },
      ...styles.lss
    }}
  >
    {children}
  </Flex>
);
