import React from 'react';

import { Flex, Label } from '@lucidhq/lucidium';

import { DemographicOptions } from './DemographicOptions';

type DemoSectionProps = {
  label: string;
  name: string;
  options: any[];
};

export const DemographicSection = ({
  label,
  name,
  options
}: DemoSectionProps) => {
  return (
    <Flex lss={{ flexDirection: 'column', px: 'xl' }}>
      <Label htmlFor={name} lss={{ fontWeight: 'bold' }}>
        {label}
      </Label>
      <DemographicOptions options={options} name={name} />
    </Flex>
  );
};
