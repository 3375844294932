import React from 'react';
import ReactDOM from 'react-dom';

import './utils/i18n';
import App from './components/App';
import { API_MOCK_ENABLED } from './constants';

if (API_MOCK_ENABLED) {
  (async () => {
    const { worker } = await import('./__msw__/browser');
    worker.start();
  })();
}

ReactDOM.render(<App />, document.getElementById('root'));
