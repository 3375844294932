import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  Flex,
  Text,
  TextArea,
  useToggle
} from '@lucidhq/lucidium';
import { Field, Form } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

type DownloadTagProps = {
  url: string;
  title: string;
  children: ({ onToggle }: { onToggle: () => void }) => React.ReactNode;
};

export const DownloadTag = ({ children, url, title }: DownloadTagProps) => {
  const [isOpen, handleToggle] = useToggle(false);

  return (
    <>
      {children({ onToggle: handleToggle })}
      <Dialog
        isOpen={isOpen}
        onClose={handleToggle}
        lss={{ maxWidth: '43rem' }}
      >
        <DialogHeader palette="disabled" onClose={handleToggle}>
          {title}
        </DialogHeader>
        <DialogContent>
          <Text lss={{ mb: 'xl' }}>
            {t('campaignManager.mediaTracking.download.description')}
          </Text>
          <Form
            initialValues={{
              htmlPreview: `<img src="${url}" />`,
              urlPreview: url
            }}
            enableReinitialize
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
          >
            <Field
              label={t('forms.labels.urlPreview')}
              name="urlPreview"
              render={fieldProps => (
                <TextArea
                  {...fieldProps}
                  readOnly
                  rows={5}
                  onFocus={event => event.target.select()}
                />
              )}
            />
            <Field
              label={t('forms.labels.htmlPreview')}
              name="htmlPreview"
              render={fieldProps => (
                <TextArea
                  {...fieldProps}
                  readOnly
                  rows={5}
                  onFocus={event => event.target.select()}
                />
              )}
            />
          </Form>
          <Flex
            lss={{
              justifyContent: 'center',
              mt: 'xxl',
              mb: 'xxl'
            }}
          >
            <Button type="button" onClick={handleToggle}>
              {t('campaignManager.actions.close')}
            </Button>
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
};
