import { Campaign, CampaignTypes } from '~/types';
import { useQuery } from '~/utils/rq';

export type CampaignResponse = Campaign;

export const useFetchCampaign = (type: CampaignTypes, campaignId?: string) => {
  const BASE_URL =
    type === CampaignTypes.SERVICE
      ? `/internal/admin/services/campaigns`
      : '/campaigns';

  const qs =
    type === CampaignTypes.SERVICE ? '' : '?include_baseline_questions=true';

  const URL = `${BASE_URL}/${campaignId}${qs}`;

  return useQuery<CampaignResponse>(
    {
      queryKey: [URL],
      enabled: !!campaignId
    },
    {
      method: 'get',
      url: URL
    }
  );
};
