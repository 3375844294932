import { useMutation } from '~/utils/rq';

export type PixelQueryParams = {
  cachebuster: string;
  crid: string;
  device_id: string;
  pid: string;
  sid: string;
  c1?: string;
  c2?: string;
  c3?: string;
  c4?: string;
  c5?: string;
};

export enum PixelTypes {
  SITE_SERVED = 'Site-Served',
  UNIVERSAL = 'Universal'
}

export type CampaignPixel = {
  pixel_id: string;
  ad_server_id?: string;
  ad_server_name?: string;
  pixel_type: PixelTypes;
  pixel_url: string;
  query_params: PixelQueryParams;
};

type CampaignPixels = CampaignPixel[];

type CampaignPixelGroupResponse = {
  campaign_pixels: CampaignPixels;
  id: number;
};

export type CampaignPixelVariables = {
  ad_server_id?: string;
  id?: string;
  query_params: PixelQueryParams;
};

type CampaignPixelGroupRequest = {
  campaign_pixels: CampaignPixelVariables[];
};

export const useAddCampaignPixelGroup = (campaignId?: string) => {
  const URL = `/admin/campaigns/${campaignId}/pixel-groups`;

  return useMutation<CampaignPixelGroupRequest, CampaignPixelGroupResponse>(
    {},
    {
      method: 'post',
      url: URL
    }
  );
};

export const useEditCampaignPixelGroup = (campaignId: string, id: string) => {
  const URL = `/admin/campaigns/${campaignId}/pixel-groups/${id}`;

  return useMutation<CampaignPixelGroupRequest, CampaignPixelGroupResponse>(
    {},
    {
      method: 'put',
      url: URL
    }
  );
};

export const useDeleteCampaignPixelGroup = (campaignId: string, id: string) => {
  const URL = `/admin/campaigns/${campaignId}/pixel-groups/${id}`;

  return useMutation(
    {},
    {
      method: 'delete',
      url: URL
    }
  );
};
