import React, { useContext } from 'react';

import { EMPTY_ITEMS } from '~/impact-measurement/constants';

import { useCache } from './useCache';

const initialCache: CacheContextType<{ items: never[] }> = {
  useFetchWithCache: () => ({
    data: EMPTY_ITEMS,
    isLoading: false,
    error: null
  }),
  cache: {},
  setCache: () => null
};

const CacheContext = React.createContext<CacheContextType<any>>(initialCache);

export const CacheProvider: React.FunctionComponent = ({ children }) => {
  const { useFetchWithCache, cache, setCache } = useCache();

  return (
    <CacheContext.Provider value={{ useFetchWithCache, cache, setCache }}>
      {children}
    </CacheContext.Provider>
  );
};

export function useCacheContext<TResponse>() {
  return useContext<CacheContextType<TResponse>>(CacheContext);
}
