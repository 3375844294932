import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, H3, LssProp } from '@lucidhq/lucidium';
import {
  CustomServicesIcon,
  IMCampaignSetupIcon
} from '@lucidhq/lucidium/icons';

import { ROUTES } from '~/constants';
import { LandingCardBtn } from '~/impact-measurement/components';
import { BasePage, PageContentWrapper } from '~/impact-measurement/components';
import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { CampaignTypes } from '~/types';
import { getCampaignManagerRoute } from '~/utils/campaignManager';
import { t } from '~/utils/i18n';

import { BackToLink } from '../Shared/BackToLink';

const cardsContainerLss: LssProp = {
  height: 'auto',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  tablet: {
    flexDirection: 'row',
    alignItems: 'stretch'
  }
};

export const CampaignManagerLanding = () => {
  const { isOn: isCCv2On } = useSplit(IM_FLAGS.CCv2);
  const history = useHistory();

  return (
    <BasePage isFluid hasFooter={false}>
      <Flex lss={{ flexDirection: 'row', m: 'sm' }}>
        <BackToLink
          to={ROUTES.adminCampaigns}
          label={t('links.backToStudies')}
        />
      </Flex>
      <PageContentWrapper>
        <Flex lss={{ justifyContent: 'center', mb: 'xxl' }}>
          <H3>{t('campaignManagerLanding.title')}</H3>
        </Flex>
        <Flex lss={cardsContainerLss}>
          <LandingCardBtn
            name={t('campaignManagerLanding.softwarev2.title')}
            icon={IMCampaignSetupIcon}
            description={t('campaignManagerLanding.softwarev2.description')}
            handleClick={() =>
              history.push(getCampaignManagerRoute(CampaignTypes.SOFTWARE))
            }
            cardContentLss={{ py: 'xxxl' }}
            disabled={!isCCv2On}
          />
          <LandingCardBtn
            name={t('campaignManagerLanding.servicev2.title')}
            icon={CustomServicesIcon}
            description={t('campaignManagerLanding.servicev2.description')}
            handleClick={() =>
              history.push(getCampaignManagerRoute(CampaignTypes.SERVICE))
            }
            cardContentLss={{ py: 'xxxl' }}
            disabled={!isCCv2On}
          />
        </Flex>

        <Flex lss={cardsContainerLss}>
          <LandingCardBtn
            name={t('campaignManagerLanding.softwarev1.title')}
            description={t('campaignManagerLanding.softwarev1.description')}
            handleClick={() => history.push('/admin/campaign/new?type=im')}
            cardContentLss={{ py: 'xxl' }}
          />
          <LandingCardBtn
            name={t('campaignManagerLanding.servicev1.title')}
            description={t('campaignManagerLanding.servicev1.description')}
            handleClick={() => history.push('/admin/campaign/new?type=custom')}
            cardContentLss={{ py: 'xxl' }}
          />
        </Flex>
      </PageContentWrapper>
    </BasePage>
  );
};
