import * as React from 'react';

import { initialCampaignStats } from '~/impact-measurement/constants/initialValues';

type StatsContextType = {
  stats: CampaignStats;
  isLoading: boolean;
};

export const StatsContext = React.createContext<StatsContextType>({
  stats: initialCampaignStats,
  isLoading: false
});
