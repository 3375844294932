import merge from 'lodash/merge';

import { BaseTheme, LssProp } from '@lucidhq/lucidium';

export const getRem = (
  pixels: number,
  base?: number
): { string: string; rem: number } => {
  const string = `${pixels / (base || 16)}rem`;
  const rem = pixels / (base || 16);

  return { string, rem };
};

export const AudienceTheme = merge({}, BaseTheme, {
  typography: {
    heading1: {
      fontWeight: BaseTheme.fontWeights.regular,
      fontSize: BaseTheme.typography.heading3.fontSize,
      marginTop: 0,
      marginBottom: 0
    },
    heading2: {
      fontWeight: BaseTheme.fontWeights.regular,
      fontSize: BaseTheme.typography.heading4.fontSize,
      marginTop: 0,
      marginBottom: 0
    },
    heading3: {
      fontWeight: BaseTheme.fontWeights.regular,
      fontSize: BaseTheme.typography.heading5.fontSize,
      marginTop: 0,
      marginBottom: 0
    },
    heading4: {
      fontWeight: BaseTheme.fontWeights.regular,
      fontSize: BaseTheme.typography.heading6.fontSize,
      marginTop: 0,
      marginBottom: 0
    }
  },
  breakpoints: {
    mobile: '32em',
    tablet: '60em',
    desktop: '90em'
  }
});

export const DisneyTheme = merge({}, AudienceTheme, {
  colors: {
    secondary: '#1554C3',
    secondary100: '#F4F8FD',
    secondary200: '#90CFFF',
    secondary400: '#0091FF',
    secondary500: '#1554C3',
    secondary600: '#09337D',
    secondary700: '#21263A',
    primary: '#21263A',
    primary500: '#21263A'
  }
});

export const fullWidthPageContentLss: LssProp = {
  px: 0,
  mt: 0,
  pt: 0,
  maxWidth: 'none',
  tablet: { px: 0, mx: 0 },
  desktop: { px: 0 }
};

export const zeroPaddingLss: LssProp = {
  p: 0,
  tablet: {
    p: 0
  },
  desktop: {
    p: 0
  }
};
