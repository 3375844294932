import { getRem } from '~/utils/styles';

export const NAV_HEIGHT = { string: '5rem', rem: 5 };
export const ADMIN_SUBNAV_HEIGHT = { string: '2.375rem', rem: 2.375 };
export const ADMIN_HEADER_HEIGHT = { string: '4.5625rem', rem: 4.5625 };
export const ADMIN_CAMPAIGN_HEADER_HEIGHT = {
  string: '4.5625rem',
  rem: 4.5625
};
export const REPORT_SUBNAV_HEIGHT = {
  string: getRem(48).string,
  rem: getRem(48).rem
};

export const REPORT_SUBNAV_HEIGHT_MOBILE = {
  string: getRem(40).string,
  rem: getRem(40).rem
};

export const FOOTER_HEIGHT = { string: '3.125rem', rem: 3.125 };
