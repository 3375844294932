import React, { PropsWithChildren, useCallback, useMemo } from 'react';

import { LucidiumProvider } from '@lucidhq/lucidium';

import { DisneyTheme, AudienceTheme } from '~/utils/styles';

type Theme = {
  name: string;
  theme: any;
};

const THEMES = {
  default: {
    name: 'default',
    theme: AudienceTheme
  },
  disney: {
    name: 'disney',
    theme: DisneyTheme
  }
} as { [key: string]: Theme };

type ThemeProvider = {
  setDefaultTheme: () => void;
  setDisneyTheme: () => void;
  isDefault: boolean;
  isDisney: boolean;
};

const PermissionsProviderContext = React.createContext<ThemeProvider>(
  {} as ThemeProvider
);

export const useTheme = () =>
  React.useContext<ThemeProvider>(PermissionsProviderContext);

export const ThemeProvider = ({ children }: PropsWithChildren<any>) => {
  const [currentTheme, setTheme] = React.useState<Theme>(THEMES.default);

  const setDefaultTheme = useCallback(() => {
    setTheme(THEMES.default);
  }, [setTheme]);

  const setDisneyTheme = useCallback(() => {
    setTheme(THEMES.disney);
  }, [setTheme]);

  const value = useMemo(
    () => ({
      setDefaultTheme,
      setDisneyTheme,
      isDefault: currentTheme.name === 'default',
      isDisney: currentTheme.name === 'disney'
    }),
    [setDefaultTheme, setDisneyTheme, currentTheme]
  );

  return (
    <PermissionsProviderContext.Provider value={value}>
      <LucidiumProvider theme={currentTheme.theme}>{children}</LucidiumProvider>
    </PermissionsProviderContext.Provider>
  );
};
