export const EMPTY_DATA_PIXELS_BULK_ITEM: DataPixelObject = {
  errors: {
    invalid: 0,
    missing: 0
  },
  values: [],
  label: '',
  totalItemsInRow: 0
};

export const REQUIRED_BULK_COLUMNS = [
  { label: 'Site ID', colKey: 'site_id' },
  { label: 'Placement ID', colKey: 'placement_id' },
  { label: 'Creative ID', colKey: 'creative_id' }
];

// Used to calculate the appropriate label, param for a custom Macro; i.e Custom 3 (c3)
export const CUSTOM_MACRO_ADJUSTMENT = REQUIRED_BULK_COLUMNS.length - 1;

export const MAPPING_SELECT_WIDTH = 'calc(100% - 182px)';

export const REGEX_FILE_SPLIT = /,|;/;
