export const handleDeleteMapping = (
  associatedMappings: LinearTv[],
  partnerId: number,
  creativeId: string,
  setSavedMappings: (mappings: LinearTvSaveItem[]) => void
) => {
  const existingMappings = associatedMappings
    .filter(m => m.partner_id !== partnerId)
    .map(({ partner_id, partner_campaigns_ids }) => ({
      partner_id,
      partner_campaigns_ids
    }));

  const newMappingIds =
    associatedMappings
      .find(m => m.partner_id === partnerId)
      ?.partner_campaigns_ids.filter(id => creativeId !== id) || [];

  setSavedMappings([
    ...existingMappings,
    {
      partner_id: partnerId,
      partner_campaigns_ids: newMappingIds
    }
  ]);
};
