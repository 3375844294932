import { LssProp } from '@lucidhq/lucidium';

export const exportDialogEmailIconLss: LssProp = {
  my: 0,
  mx: 'auto',
  bg: 'gray200',
  width: '4rem',
  height: '4rem',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 'rounded'
};

export const exportDialogLoadingAndErrorIconLss: LssProp = {
  justifyContent: 'center',
  mt: 'lg'
};

export const exportDialogTextLss: LssProp = {
  display: 'flex',
  textAlign: 'center',
  mx: 'md'
};

export const exportDialogLoadingAndErrorDescriptionLss: LssProp = {
  ...exportDialogTextLss,
  justifyContent: 'center',
  my: 'xl'
};

export const exportDialogConfirmBtnLss: LssProp = {
  display: 'flex',
  minWidth: '3rem',
  alignSelf: 'center',
  justifyContent: 'center',
  px: 'xl'
};

export const exportDialogContentLss: LssProp = {
  maxWidth: '28rem',
  minHeight: '17rem',
  minWidth: '26rem',
  justifyContent: 'space-evenly'
};
