import React from 'react';

import { Box } from '@lucidhq/lucidium';

export const BOX_SHADOW_BOTTOM = '0px 4px 4px -4px rgba(0,0,0,0.2)' as any;
export const BOX_SHADOW_TOP = '0px -4px 4px -4px rgba(0,0,0,0.2)' as any;

const isAdminPage = window.location.pathname.includes('/admin');

export const ShadowBox = () => (
  <Box
    lss={{
      display: 'none',
      tablet: {
        display: 'block',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: isAdminPage ? 191 : 153,
        zIndex: 'notification',
        boxShadow: BOX_SHADOW_BOTTOM
      }
    }}
  />
);
