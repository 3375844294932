import React from 'react';
import { useHistory } from 'react-router-dom';

import { Flex } from '@lucidhq/lucidium';

import { ROUTES } from '~/constants';
import { BasePage } from '~/impact-measurement/components';
import { t } from '~/utils/i18n';

import { usePermissions } from '../Permissions/PermissionsProvider';
import { BackToLink } from '../Shared/BackToLink';
import { CampaignDetailsStep } from './CampaignDetailsStep/CampaignDetailsStep';
import { useCampaignManager } from './CampaignManagerProvider';
import { CampaignManagerStepper } from './CampaignManagerStepper';
import { CampaignReviewStep } from './CampaignReviewStep/CampaignReviewStep';
import { MediaTrackingStep } from './MediaTrackingStep/MediaTrackingStep';
import { ServiceCampaignDetailsStep } from './ServiceCampaignDetailsStep/ServiceCampaignDetailsStep';
import { SurveyStep } from './SurveyStep/SurveyStep';
import { TargetAudienceStep } from './TargetAudienceStep/TargetAudienceStep';

export const CampaignManagerContainer = () => {
  const {
    steps: { DETAILS, MEDIA_TRACKING, REVIEW, AUDIENCE, SURVEY },
    activeStep,
    isService,
    isSoftware
  } = useCampaignManager();
  const { isAdmin } = usePermissions();
  const history = useHistory();

  if (isService && !isAdmin) {
    history.push(ROUTES.adminCampaigns);
  }

  return (
    <BasePage isFluid hasFooter={false}>
      <Flex lss={{ flexDirection: 'row', m: 'sm' }}>
        <BackToLink
          to={ROUTES.adminCampaigns}
          label={t('links.backToStudies')}
        />
      </Flex>
      <CampaignManagerStepper />
      {isSoftware && DETAILS.id === activeStep && <CampaignDetailsStep />}
      {isService && DETAILS.id === activeStep && <ServiceCampaignDetailsStep />}
      {MEDIA_TRACKING.id === activeStep && <MediaTrackingStep />}
      {isSoftware && SURVEY.id === activeStep && <SurveyStep />}
      {isSoftware && AUDIENCE.id === activeStep && <TargetAudienceStep />}
      {REVIEW.id === activeStep && <CampaignReviewStep />}
    </BasePage>
  );
};
