import React, { useMemo } from 'react';

import { Flex, Label, Select } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

export type DataPartnerOption = {
  label: string;
  value: string[];
};

export const dataPartnerOptions: DataPartnerOption[] = [
  {
    label: 'Samba TV',
    value: ['s2s_samba']
  },
  {
    label: 'iSpot.tv',
    value: ['s2s_ispot']
  }
];

type DataPartnerFieldProps = {
  isLinearTvChecked: boolean;
  dataPartners?: string[];
  name: string;
  readOnly: boolean;
};

export const findSelectedDataPartner = (
  dataPartners?: string[]
): DataPartnerOption | undefined => {
  if (!dataPartners || dataPartners?.length < 1) return;

  const filteredOption = dataPartnerOptions.filter(
    option => option.value[0] === dataPartners[0]
  );

  return filteredOption[0];
};

export const DataPartnerField: React.FC<DataPartnerFieldProps> = ({
  isLinearTvChecked,
  dataPartners = [],
  name,
  readOnly
}) => {
  const [field, meta, helpers] = useField(name);
  const selectedPartner = useMemo(() => findSelectedDataPartner(dataPartners), [
    dataPartners
  ]);

  if (!isLinearTvChecked) return null;

  return (
    <Flex
      id="data-partner-field"
      lss={{
        flexDirection: 'column',
        mb: isLinearTvChecked ? 'md' : 'none'
      }}
    >
      <Label lss={{ my: 'sm' }} required>
        {t(`forms.labels.dataPartner`)}
      </Label>
      <Select
        id="data-partners"
        name={field.name}
        value={selectedPartner?.value || field.value}
        error={meta.error}
        disabled={readOnly}
        placeholder="Select Data Partner"
        labelKey="label"
        valueKey="value"
        options={dataPartnerOptions}
        onChange={(option: any) => helpers.setValue(option)}
        lss={{ width: '100%' }}
      />
    </Flex>
  );
};
