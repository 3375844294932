import React from 'react';

import { Box, Flex, Text } from '@lucidhq/lucidium';

import { InfoTooltip } from '~/impact-measurement/components';

import { dialogTooltipLss } from '../styles';

export const ColumnMappingSectionIntro: React.FC = () => (
  <Box lss={{ mb: 'sm' }}>
    <Text as="h2" lss={{ fontWeight: 'bold', my: 'xs' }}>
      Edit CSV Mapping
    </Text>
    <Flex lss={{ alignItems: 'center' }}>
      <Text as="p" lss={{ my: 'xs', mr: 'xs' }}>
        Select the CSV column mapping to Site-Served Macros.
      </Text>
      <InfoTooltip
        title={
          <Text lss={dialogTooltipLss}>
            Unassigned or blank macros will not break your pixels.
          </Text>
        }
      />
    </Flex>
  </Box>
);
