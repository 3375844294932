import React from 'react';
import { OptionProps } from 'react-select';

import { Flex, Label, LssProp, Select, Text } from '@lucidhq/lucidium';
import { Field, useFormikContext } from '@lucidhq/lucidium/components/FormV2';

import { TargetAudienceQuestion } from '~/components/Shared/hooks/useFetchTargetAudienceQuestions';
import { InfoTooltip } from '~/impact-measurement/components';
import { searchIcon } from '~/impact-measurement/pages/Admin/lib';
import { DraftTargetQualification } from '~/types';
import { t } from '~/utils/i18n';

type CustomQuestionOption = OptionProps<TargetAudienceQuestion>;

const groupLss: LssProp = {
  width: '100%',
  mr: 'lg',
  pb: 'sm'
};

const components = {
  ...searchIcon(false),
  Option: (props: CustomQuestionOption) => {
    const { question_type, name, question_text } = props.data;
    return (
      <Select.Option {...props}>
        <Flex lss={{ flexDirection: 'column' }}>
          <Text lss={{ textScale: 'caption', color: 'gray500' }}>
            {name} - {question_type}
          </Text>
          <Text lss={{ textScale: 'control' }}>{question_text}</Text>
        </Flex>
      </Select.Option>
    );
  }
};

type QualificationQuestionProps = {
  index: number;
  questions?: TargetAudienceQuestion[];
  showLabel: boolean;
};

export const QualificationQuestion = ({
  index,
  questions,
  showLabel
}: QualificationQuestionProps) => {
  const { setFieldValue } = useFormikContext<{
    qualifications: DraftTargetQualification[];
  }>();

  return (
    <>
      {showLabel && (
        <Flex
          lss={{
            flexDirection: 'row',
            gap: '2px',
            alignItems: 'center'
          }}
        >
          <Label
            lss={{ my: 'sm' }}
            required
            htmlFor={`qualifications.${index}.qual_name`}
          >
            {t('forms.labels.attributeX', { index: index + 1 })}
          </Label>
          <InfoTooltip
            title={t(`campaignManager.targetAudience.tooltips.attributes`)}
            placement="top-start"
            iconLss={{ height: '1.125rem', width: '1.125rem' }}
          />
        </Flex>
      )}
      <Field
        key={index}
        required
        name={`qualifications.${index}.qual_name`}
        render={fieldProps => (
          <Select
            {...fieldProps}
            placeholder={t('forms.placeholders.search')}
            labelKey="question_text"
            valueKey="name"
            isFormik={false}
            options={questions}
            components={components}
            onChange={(
              value: string | null,
              option: TargetAudienceQuestion | null
            ) => {
              if (!value || !option) return;
              setFieldValue(`qualifications.${index}`, {
                precodes: [],
                qual_name: option.name,
                marketplace_question_id: option.question_id,
                answer_type: option.name === 'AGE' ? 'range' : 'contains'
              });
            }}
          />
        )}
        groupLss={groupLss}
      />
    </>
  );
};
