import React from 'react';

import { ImpactMeasurementIcon, IMAdminIcon } from '@lucidhq/lucidium/icons';

import { ROUTES } from '~/constants';
import { LandingCardBtn } from '~/impact-measurement/components';
import { changePath } from '~/impact-measurement/components/lib';
import { t } from '~/utils/i18n';

import { usePermissions } from '../Permissions/PermissionsProvider';

export const HomeCards = () => {
  const { permissions, isImt, isBasicPlusUser } = usePermissions();

  return (
    <>
      {permissions.admin && (
        <LandingCardBtn
          icon={IMAdminIcon}
          name={t('home.cards.admin.name')}
          description={
            isBasicPlusUser
              ? t('home.cards.admin.externalUserDescription')
              : t('home.cards.admin.description')
          }
          handleClick={() => changePath(ROUTES.admin)}
        />
      )}
      <LandingCardBtn
        icon={ImpactMeasurementIcon}
        name={t('home.cards.im.name')}
        description={t('home.cards.im.description')}
        disabled={!isImt}
        handleClick={() => changePath(ROUTES.im)}
      />
    </>
  );
};
