import { useRoles } from '@lucidhq/ui-auth';

type UseAppAccess = () => {
  hasImAuth: boolean;
};

export const useAppAccess: UseAppAccess = () => {
  const { roles } = useRoles();
  const hasImAuth = roles.some(r => r.substr(0, 3) === 'imt');
  return { hasImAuth };
};
