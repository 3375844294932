import React from 'react';

import { FileUploadButton, Flex, LssProp, Text } from '@lucidhq/lucidium';
import { CsvBasicIcon } from '@lucidhq/lucidium/icons';

const fileUploadBarLss: LssProp = {
  alignItems: 'center',
  justifyContent: 'space-between',
  bg: 'gray100',
  borderRadius: 'md',
  borderWidth: 1,
  borderStyle: 'dashed',
  borderColor: 'secondary500',
  fontSize: 17,
  py: 'xs',
  px: 'lg'
};

type FileNameBarProps = {
  fileName: string;
  handleUpload: (file: File) => void;
  isUploading?: boolean;
  lss?: LssProp;
};

export const FileNameBar: React.FC<FileNameBarProps> = ({
  fileName,
  handleUpload,
  isUploading = false,
  lss = {}
}) => (
  <Flex
    lss={{
      ...fileUploadBarLss,
      ...lss
    }}
  >
    <Flex lss={{ alignItems: 'center' }}>
      <CsvBasicIcon size={16} lss={{ color: 'secondary500' }} />
      <Text lss={{ color: 'secondary500', ml: 'sm', wordBreak: 'break-all' }}>
        {fileName}
      </Text>
    </Flex>
    <FileUploadButton
      id="reupload-pixel-csv"
      emphasis="low"
      scale="sm"
      onFileSelection={handleUpload}
      isUploading={isUploading}
      lss={{ width: 'auto' }}
      selectLabel="Reupload"
      acceptedFileTypes=".csv"
    />
  </Flex>
);
