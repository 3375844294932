import React from 'react';

import { RadioGroup, Flex } from '@lucidhq/lucidium';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { EnhancedTemplateQuestion, NOTA } from '~/utils/templateQuestions';

const options = [
  { label: 'Default Brand Answers', value: true },
  { label: 'Custom', value: false }
];

type AnswersRadioGroupProps = {
  value?: boolean;
  onSave: (data: Partial<TemplateQuestion>) => void;
  question: EnhancedTemplateQuestion;
};

// NOTE: both of the following functions resemble each other, but are not the same as the functions in QuestionLogicItem.tsx
// In order to avoid (more) confusion these methods have been separated out by file.
export const getUpdatedAnswers = (
  question: EnhancedTemplateQuestion,
  answers: string[]
) => {
  if (!!question.none_of_the_above_answer && !answers.includes(NOTA)) {
    return [...answers, NOTA];
  }

  if (question.none_of_the_above_answer === null && answers.includes(NOTA)) {
    return answers.filter(answer => answer !== NOTA);
  }

  return answers;
};

const getAnswersRecord = (val: boolean, question: EnhancedTemplateQuestion) => {
  // edge case for empty answers array API error
  if (question.question_type.includes('table')) return {};

  if (val) {
    const updatedBrandAnswers = getUpdatedAnswers(
      question,
      question.defaultBrandAnswers as string[]
    );

    return {
      answers: updatedBrandAnswers,
      defaultBrandAnswers: updatedBrandAnswers
    };
  }

  const updatedCustomAnswers = getUpdatedAnswers(
    question,
    question?.customAnswers as string[]
  );

  return {
    answers: updatedCustomAnswers,
    customAnswers: updatedCustomAnswers
  };
};

export const AnswersRadioGroup = ({
  value,
  onSave,
  question
}: AnswersRadioGroupProps) => {
  return (
    <Flex lss={{ my: 'sm' }}>
      <RadioGroup
        name="use_answer_meta"
        isInline
        options={options}
        value={value}
        onChange={val => {
          const isCustomGridKPI = !!question.question_type.includes('table');
          const answerRecord = getAnswersRecord(val, question);

          onSave({
            use_answer_meta: val,
            ...answerRecord,
            ...(isCustomGridKPI && {
              rows: !!val ? question?.defaultBrandRows : question?.customRows
            })
          });
        }}
      />
    </Flex>
  );
};
