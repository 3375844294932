import React, { useState } from 'react';

import {
  Button,
  BusyButton,
  Dialog,
  DialogContent,
  TextArea,
  useToggle,
  Text,
  Flex,
  Label,
  LssProp
} from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { AddIDsDialogHeader } from './AddIDsDialogHeader';
import { DuplicateIDsDialogContent } from './DuplicateIDsDialogContent';
import { IPMatchedID, useIPMatchingContext } from './IPMatchingProvider';

type AddIDsDialogProps = {
  isOpen: boolean;
  handleToggle: () => void;
};

const SITE = 'site';
const PLACEMENT = 'placement';
const HIGH = 'high';
const MEDIUM = 'medium';

const findDuplicateIds = (
  incomingIDsMap: Record<string, any>,
  matchedIDs: IPMatchedID[]
) => {
  const copy = [...matchedIDs];
  const duplicateIds: any[] = [];

  copy.forEach(item => {
    // NOTE: all values below will need to be updated once we have API data
    const key = item.ip_matching_id as string;
    const mappedItem = incomingIDsMap[key];
    if (
      !!mappedItem &&
      mappedItem?.ip_matching_type === item?.ip_matching_type
    ) {
      duplicateIds.push(item);
    }
  });

  return duplicateIds;
};

// NOTE: this method will potentially be removed dependent on API response from POST endpoint.
const removeDuplicateIds = (
  duplicateIds: IPMatchedID[],
  incomingIds: IPMatchedID[]
) => {
  const copy: any[] = [...incomingIds];

  const duplicateIdsMap = duplicateIds.reduce(
    (acc: Record<string, any>, cur: Record<string, any>) => {
      const key = cur.ip_matching_id;
      acc[key] = cur;
      return acc;
    },
    {}
  );

  copy.forEach((item, i) => {
    const key = item.ip_matching_id;
    const mappedItem = duplicateIdsMap[key];
    if (
      !!mappedItem &&
      mappedItem?.ip_matching_type === item?.ip_matching_type
    ) {
      delete copy[i];
    }
  });

  return copy.filter(item => item !== undefined || item !== null);
};

export const AddIDsDialog = ({ isOpen, handleToggle }: AddIDsDialogProps) => {
  const { matchedIds, setUpdatedIds } = useIPMatchingContext();
  const [isSite, handleIDType] = useToggle(false);
  const [newIds, setNewIds] = useState<string>('');
  const [hasDuplicates, setHasDuplicates] = useState<boolean>(false);
  const [duplicateIds, setDuplicateIds] = useState<any[]>([]);

  const handleTabClick = (isSiteTab: boolean) => {
    if (isSite !== isSiteTab) {
      handleIDType();
    }
  };

  const handleSubmit = () => {
    if (!newIds?.length) return;

    // Split the IDs by new lines and trim each ID
    const parsedIds = newIds.split('\n').map(id => id.trim());
    if (!parsedIds.length) return;

    //filter out empty lines
    const filteredIds = parsedIds.filter(id => id !== '');

    // Get the current date, probably will be removed
    const currentDate = new Date().toISOString().split('T')[0];

    const uniqueIds = [...new Set(filteredIds)];

    // Create an array of objects with the filtered IDs
    const newMatchedIds: IPMatchedID[] = uniqueIds.map(id => ({
      ip_matching_type: `${isSite ? SITE : PLACEMENT}_id`,
      ip_matching_id: id,
      // The following fields might/will be unnecessary depending on api call
      enablement_status: 'Manual',
      is_enabled: true,
      added_at: currentDate
    }));

    const prevMatchedIDsMap = newMatchedIds.reduce((acc, cur) => {
      const key = cur.ip_matching_id;
      acc[key] = cur;
      return acc;
    }, {});

    const duplicateIds = findDuplicateIds(prevMatchedIDsMap, matchedIds);

    const uniqueIncomingIds = removeDuplicateIds(duplicateIds, newMatchedIds);
    //TODO: POST new ids to endpoint once POST is complete.

    setUpdatedIds(prevIds => {
      if (!!prevIds?.length) return [...prevIds, ...uniqueIncomingIds];

      return [...matchedIds, ...uniqueIncomingIds];
    });
    setNewIds('');

    if (!!duplicateIds.length) {
      setDuplicateIds(duplicateIds);
      setHasDuplicates(true);
    }
  };

  const handleClose = () => {
    handleToggle();
    setNewIds('');
    setHasDuplicates(false);
    setDuplicateIds([]);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleToggle}
      lss={
        {
          width: '35rem',
          height: '45rem',
          ...(hasDuplicates && {
            width: '27.75rem',
            height: 'fit-content',
            p: 'lg'
          })
        } as LssProp
      }
    >
      {hasDuplicates ? (
        <DuplicateIDsDialogContent
          handleToggle={handleClose}
          duplicateIds={duplicateIds}
        />
      ) : (
        <>
          <AddIDsDialogHeader onClose={handleToggle}>
            {t('ipMatching.actions.addNewIDs')}
          </AddIDsDialogHeader>
          <Flex lss={{ p: 'xl' }}>
            <Button
              type="button"
              isBlock={true}
              scale="lg"
              palette="secondary"
              lss={{
                ...(!isSite && { opacity: 1 })
              }}
              emphasis={!isSite ? HIGH : MEDIUM}
              onClick={() => handleTabClick(false)}
              disabled={!isSite}
            >
              {t('ipMatching.labels.placementIDs')}
            </Button>
            <Button
              type="button"
              isBlock={true}
              scale="lg"
              palette="secondary"
              lss={{
                ...(isSite && { opacity: 1 })
              }}
              emphasis={isSite ? HIGH : MEDIUM}
              onClick={() => handleTabClick(true)}
              disabled={isSite}
            >
              {t('ipMatching.labels.siteIDs')}
            </Button>
          </Flex>
          <Flex lss={{ flexDirection: 'column' }}>
            <DialogContent lss={{ py: 'xxs' }}>
              <Text as="p" lss={{ mt: 'none' }}>
                {t(`ipMatching.actions.${isSite ? SITE : PLACEMENT}`)}
              </Text>
              <Label htmlFor="ip-ids" lss={{ fontWeight: 'bold', mt: 'xxs' }}>
                {isSite
                  ? t('ipMatching.labels.siteIDs')
                  : t('ipMatching.labels.placementIDs')}
              </Label>

              <TextArea
                name="ip-ids"
                placeholder={t('ipMatching.actions.placeholder')}
                onChange={e => setNewIds(e.currentTarget.value)}
                value={newIds}
                rows={25}
              />

              <Flex
                lss={{
                  width: '100%',
                  justifyContent: 'center',
                  mt: 'xxl'
                }}
              >
                <BusyButton
                  type="submit"
                  disabled={!newIds?.length}
                  scale="md"
                  lss={{ mr: 'md' }}
                  palette={!newIds?.length ? 'disabled' : 'secondary'}
                  onClick={handleSubmit}
                >
                  {t('ipMatching.actions.upload')}
                </BusyButton>
                <Button
                  type="button"
                  scale="md"
                  palette="primary"
                  emphasis="medium"
                  onClick={handleClose}
                >
                  {t('ipMatching.actions.cancel')}
                </Button>
              </Flex>
            </DialogContent>
          </Flex>
        </>
      )}
    </Dialog>
  );
};
