import React, { useEffect } from 'react';

import { useAuth } from '@lucidhq/ui-auth';

import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';

import { useTheme } from './ThemeProvider';

export const ThemeSwitcher = () => {
  const { isOn } = useSplit(IM_FLAGS.DISNEY_THEME);
  const { setDefaultTheme, setDisneyTheme } = useTheme();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.email?.includes('@disney.com') || isOn) setDisneyTheme();
    else setDefaultTheme();
  }, [user, setDisneyTheme, setDefaultTheme, isOn]);

  return <></>;
};
