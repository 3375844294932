import { useQuery } from '~/utils/rq';

const URL = '/admin/permission-groups/?group_type=account&status=active';

type Account = {
  permission_id: number;
  permission_group: string;
  group_name: string;
  group_type: string;
  status: string;
  metadata: Record<string, any> | null;
};

export type AccountsResponse = {
  items: Account[];
};

type useFetchAccountsProps = {
  isEnabled: boolean;
};

export const useFetchAccounts = ({ isEnabled }: useFetchAccountsProps) => {
  return useQuery<AccountsResponse>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60,
      enabled: isEnabled
    },
    {
      method: 'get',
      url: URL
    }
  );
};
