import React from 'react';
import { MdClose } from 'react-icons/md';

import { Button, Flex, H6, Icon, LssProp } from '@lucidhq/lucidium';

const containerLss: LssProp = {
  justifyContent: 'space-between',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: 'md',
  flexShrink: 0,
  bg: 'white',
  borderBottom: 'regular'
};

const closeButtonLss: LssProp = {
  bg: 'inherit',
  color: 'primary500',
  border: 'none',
  borderRadius: 'none',
  borderTopRightRadius: 'md',
  ':hover': {
    bg: 'gray600',
    color: 'primary500'
  }
};

type AddIDsDialogHeaderProps = {
  children: React.ReactNode;
  onClose?: () => void;
};

export const AddIDsDialogHeader = ({
  children,
  onClose
}: AddIDsDialogHeaderProps) => {
  return (
    <Flex lss={containerLss}>
      <H6
        as="h1"
        lss={{
          px: 'md',
          py: 'lg',
          color: 'primary500'
        }}
      >
        {children}
      </H6>

      {onClose && (
        <Button
          type="button"
          onClick={onClose}
          emphasis="medium"
          lss={closeButtonLss}
        >
          <Icon as={MdClose} size={18} lss={{ color: 'primary500' }} />
        </Button>
      )}
    </Flex>
  );
};
