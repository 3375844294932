import { useEffect } from 'react';

function confirmExit() {
  return 'You have attempted to leave this page with unsaved changes. Are you sure?';
}

type UseConfirmExit = (condition: boolean) => void;

export const useConfirmExit: UseConfirmExit = condition => {
  useEffect(() => {
    window.onbeforeunload = condition ? confirmExit : null;
  }, [condition]);
};
