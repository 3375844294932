import React, { useContext } from 'react';

import { Flex } from '@lucidhq/lucidium';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';

import {
  handleAddCustomMacro,
  handleRemoveCustomMacro,
  getFieldIsModified
} from '../../lib';
import { getValuesCount } from '../../SiteServedSetup/components/BulkCreate/CsvUploadDialog/lib';
import { addCustomButtonLss } from '../../styles';
import { SingleUrlMacroField, AddCustomMacroButton } from './';

type SingleUrlSetupProps = {
  macros: MacroType[];
  tagKey: TagKeys;
  tagIndex: number;
  hasMacros: boolean;
  adServer?: AdServerType | null;
  hasModifiedMacros?: boolean[];
  setHasModifiedMacros?: ([]: boolean[]) => void;
  isBulk?: boolean;
};

export const SingleUrlSetup: React.FC<SingleUrlSetupProps> = ({
  macros,
  adServer,
  hasMacros,
  tagIndex,
  tagKey,
  hasModifiedMacros,
  setHasModifiedMacros,
  isBulk = false
}) => {
  const { setFieldValue } = useContext(MediaTrackingToolsFormContext);
  const showButton = hasMacros && !isBulk;

  return (
    <Flex lss={{ flexDirection: 'column', width: '100%' }}>
      {macros.map((macro: MacroType, index: number) => (
        <SingleUrlMacroField
          tagKey={tagKey}
          key={macro.label}
          label={macro.label}
          index={index}
          isModified={
            adServer ? getFieldIsModified(adServer, macro, index) : undefined
          }
          onRemove={() =>
            handleRemoveCustomMacro({
              tagKey,
              macros,
              index,
              tagIndex,
              setFieldValue
            })
          }
          tagIndex={tagIndex}
          hasModifiedMacros={hasModifiedMacros}
          setHasModifiedMacros={setHasModifiedMacros}
          isBulk={isBulk}
          macroSize={macro.values.length}
          valuesCount={getValuesCount(macro.values)}
        />
      ))}
      {showButton && (
        <AddCustomMacroButton
          lss={addCustomButtonLss}
          onClick={() =>
            handleAddCustomMacro({
              tagKey,
              macros,
              tagIndex,
              setFieldValue
            })
          }
        />
      )}
    </Flex>
  );
};
