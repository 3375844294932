import React, { useContext } from 'react';

import { Flex } from '@lucidhq/lucidium';

import { AddItemButton } from '~/impact-measurement/components';
import { PageIntro, PageIntroProps } from '~/impact-measurement/components';
import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';

import { TAG_KEYS } from '../constants';
import { handleAddTagCard } from '../lib';

type SingleTagSetupSection = {
  tagKey: TagKeys;
  sectionIntro: PageIntroProps;
  siteServedPixel?: SiteServedPixel;
};
export const SingleTagSetupSection: React.FC<SingleTagSetupSection> = ({
  tagKey,
  sectionIntro,
  children,
  siteServedPixel
}) => {
  const { mediaToolData, setFieldValue, isValid, errors } = useContext(
    MediaTrackingToolsFormContext
  );
  const { selectedTag, setSelectedTag } = useContext(MediaTrackingToolsContext);
  const tags = mediaToolData[tagKey];
  const hasErrorMessage = !!Object.keys(errors).length;

  const handleAdd = () =>
    handleAddTagCard({
      tagKey,
      tags,
      setFieldValue,
      selectedTag,
      setSelectedTag,
      siteServedPixel
    });

  return (
    <Flex
      lss={{
        mt: 'xl',
        flexDirection: 'column'
      }}
    >
      <PageIntro {...sectionIntro} />
      {children}
      <AddItemButton isValid={isValid || !hasErrorMessage} onClick={handleAdd}>
        Add a {tagKey === TAG_KEYS.site ? 'site' : 'universal'} tag
      </AddItemButton>
    </Flex>
  );
};
