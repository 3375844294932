import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { addNotification, Notification } from '@lucidhq/lucidium';

import { EMPTY_ITEMS } from '~/impact-measurement/constants';
import {
  MediaTrackingToolsFormContext,
  MediaTrackingToolsContext
} from '~/impact-measurement/pages/Admin/Contexts';
import { useApiFetch } from '~/utils/fetch';

import { URLS } from '../../constants';

type UseSaveLinearTv = () => {
  isLoading: boolean;
  saveLinearTv: (tvData: LinearTvSaveItem[]) => void;
  linearTvItems: LinearTv[];
  savedMappings: LinearTvSaveItem[];
};

export const useSaveLinearTv: UseSaveLinearTv = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const apiFetch = useApiFetch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { savedMappings, setSavedMappings } = useContext(
    MediaTrackingToolsContext
  );
  const {
    setFieldValue,
    setFieldTouched,
    mediaToolData: { linearTv }
  } = useContext(MediaTrackingToolsFormContext);

  const saveLinearTv = async (tvData: LinearTvSaveItem[]) => {
    setIsLoading(true);

    const { response = EMPTY_ITEMS, error } = await apiFetch<
      LinearTvSaveRequestAndResponse
    >({
      url: URLS.linearTvList(campaignId),
      method: 'POST',
      json: tvData
    });

    if (error) {
      addNotification(
        <Notification palette="danger">Error! {error.message}</Notification>
      );
    } else if (response?.items) {
      addNotification(
        <Notification palette="success">IDs Saved!</Notification>
      );
      setSavedMappings(response.items);
      setFieldValue(
        'linearTv',
        linearTv.map(item => ({ ...item, partner_campaigns_ids: [''] }))
      );
      setFieldTouched('linearTv', false);
    }

    setIsLoading(false);
  };

  return {
    isLoading,
    saveLinearTv,
    linearTvItems: linearTv,
    savedMappings
  };
};
