import React from 'react';

import { DEPLOYMENT_ENV } from '~/constants';
import { useProfile } from '~/impact-measurement/utils/auth';

const isDev =
  !!window['LUCID_SPA_CONFIG'] &&
  (DEPLOYMENT_ENV?.includes('dev') || DEPLOYMENT_ENV?.includes('local'));

export const Appcues = () => {
  const profile = useProfile();

  React.useEffect(() => {
    if (isDev) {
      return;
    }
    //@ts-ignore
    if (!!window?.Appcues && !!profile?.id && !!profile?.email) {
      //@ts-ignore
      window.Appcues.identify(profile.id, { email: profile.email });
    }
  }, [profile]);

  return null;
};
