import React from 'react';

import { Field } from '@lucidhq/lucidium/components/FormV2';

import { t } from '~/utils/i18n';

import { FIELD_NAMES } from './CampaignDetailsForm';

export const CampaignNameField = ({ readOnly }: { readOnly: boolean }) => {
  return (
    <Field
      id="campaign-name"
      required
      name={FIELD_NAMES.CAMPAIGN_NAME}
      label={t(`forms.labels.campaignName`)}
      disabled={readOnly}
    />
  );
};
