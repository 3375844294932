import React, { SyntheticEvent } from 'react';
import { MdOutlineChevronLeft } from 'react-icons/md';

import { Flex, Icon, Text, LssProp, useToggle } from '@lucidhq/lucidium';
import { OutlineColorProperty } from '@lucidhq/lucidium/lss/csstypes';

import { TemplateQuestion } from '~/components/Shared/hooks/useFetchTemplateQuestions';
import { InfoTooltip } from '~/impact-measurement/components';
import { t } from '~/utils/i18n';
import { EnhancedTemplateQuestion } from '~/utils/templateQuestions';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { QuestionLogicItem } from './QuestionLogicItem';
import { QuestionTypeSelect } from './QuestionTypeSelect';

const containerLss: LssProp = {
  flexDirection: 'column',
  minHeight: '280px',
  maxWidth: '580px',
  minWidth: '260px',
  gap: '0.75rem',
  p: 'md',
  pt: 'xxs'
};

const expandButtonLss: LssProp = {
  alignItems: 'center',
  justifyContent: 'flex-start',
  textDecoration: 'none',
  color: 'gray900',
  minHeight: '1.5rem',
  minWidth: '2rem',
  ':focus': {
    outlineColor: 'hsla(214, 76%, 49%, 0.5)' as OutlineColorProperty
  },
  marginLeft: '-12px'
};

const expansionSectionWrapper: LssProp = {
  height: '80%',
  borderLeft: 'regular',
  justifyContent: 'flex-end'
};

export enum QUESTION_LOGIC_ITEMS {
  RANDOMIZE = 'randomize',
  NOTA = 'none_of_the_above_answer',
  CUSTOM_QUESTION_TEXT = 'is_custom_question_text'
}

type QuestionLogicDrawerProps = {
  question: EnhancedTemplateQuestion;
  onSave: (data: Partial<TemplateQuestion>) => void;
};

const TEXT = 'text';

export const QuestionLogicDrawer = ({
  question,
  onSave
}: QuestionLogicDrawerProps) => {
  const [isOpen, handleToggle] = useToggle(true);
  const { can } = useCampaignManager();
  const isReadOnly =
    !can.editKPI || question.meta.isBehavioral || question.meta.isSocial;
  const isTextQuestionType = question.question_type.includes(TEXT);
  const isDefaultChecked = !can.editKPI || question.meta.isSocial;

  return (
    <Flex
      lss={{
        flexDirection: 'column',
        minWidth: '277px',
        minHeight: '320px',
        p: 'sm'
      }}
    >
      <Flex
        as="a"
        href="#"
        role="button"
        lss={{
          ...expandButtonLss,
          justifyContent: !isOpen ? 'flex-end' : 'flex-start',
          marginRight: !isOpen ? '-10px' : '0'
        }}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          handleToggle();
        }}
      >
        <Icon
          as={MdOutlineChevronLeft}
          lss={{
            transform: isOpen ? 'rotate(180deg)' : 'none',
            transition: 'fast'
          }}
          size={24}
        />
      </Flex>
      <Flex
        lss={{
          ...expansionSectionWrapper,
          justifyContent: !isOpen ? 'flex-end' : 'flex-start',
          borderLeft: !isOpen ? 'none' : 'regular'
        }}
      >
        {isOpen ? (
          <Flex lss={containerLss}>
            <Flex lss={{ gap: '0.5rem', alignItems: 'center' }}>
              <Text lss={{ fontWeight: 'bold' }}>
                {t(
                  'campaignManager.survey.standardQuestions.questionCard.questionLogic.title'
                )}
              </Text>
              <InfoTooltip
                enforceRefWrapper
                title={t(
                  'campaignManager.survey.standardQuestions.questionCard.questionLogic.tooltip'
                )}
                lss={{ ml: 'md' }}
              />
            </Flex>

            <QuestionTypeSelect
              isReadOnly={isReadOnly}
              question={question}
              onSave={onSave}
            />

            <QuestionLogicItem
              itemName={QUESTION_LOGIC_ITEMS.RANDOMIZE}
              itemValue={question.randomize}
              isItemChecked={question.randomize || isDefaultChecked}
              isReadOnly={
                isReadOnly ||
                !question.meta.isAllowedToRandomize ||
                isTextQuestionType
              }
              onSave={onSave}
              //NOTE: this key prop is to force a re-render when Question Type select value changes
              {...((question.meta.isBehavioral ||
                question.meta.isCustomKPI) && {
                key: `${QUESTION_LOGIC_ITEMS.RANDOMIZE} ${question.question_name} ${question.randomize}`
              })}
            />

            <QuestionLogicItem
              question={question}
              itemName={QUESTION_LOGIC_ITEMS.NOTA}
              itemValue={question.none_of_the_above_answer}
              isItemChecked={
                !!question.none_of_the_above_answer ||
                (!question.meta.isAllowedToAddNOTA &&
                  !question.question_type.includes('text-interpreted')) ||
                isReadOnly ||
                question.question_type.includes('table')
              }
              isReadOnly={
                isReadOnly ||
                !question.meta.isAllowedToAddNOTA ||
                question.question_type.includes('table') ||
                isTextQuestionType
              }
              onSave={onSave}
              wrapperLss={{ borderTop: 'none' }}
              //NOTE: this key prop is to force a re-render when Question Type select value changes
              {...(question.meta.isCustomKPI && {
                key: `${QUESTION_LOGIC_ITEMS.NOTA} ${question.question_name} ${question.none_of_the_above_answer}`
              })}
            />

            {!question.meta.isCustomKPI &&
              can.edit &&
              can.addCustomKPI &&
              !question.meta.isSocial && (
                <QuestionLogicItem
                  question={question}
                  itemName={QUESTION_LOGIC_ITEMS.CUSTOM_QUESTION_TEXT}
                  isItemChecked={!!question?.is_custom_question_text}
                  isReadOnly={!can.edit}
                  onSave={onSave}
                  itemValue={question?.is_custom_question_text}
                  wrapperLss={{ borderTop: 'none' }}
                />
              )}
          </Flex>
        ) : (
          <Flex
            lss={{
              flexDirection: 'column',
              width: '1px',
              height: '100%',
              borderLeft: 'regular'
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
