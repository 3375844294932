import React from 'react';

import { Flex } from '@lucidhq/lucidium';

type CenterBoxProps = {
  children: React.ReactNode;
  isFullscreen?: boolean;
};

export const CenterBox: React.FunctionComponent<CenterBoxProps> = ({
  children,
  isFullscreen
}) => (
  <Flex
    lss={{
      m: 'auto',
      height: isFullscreen ? '50vh' : '',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {children}
  </Flex>
);
