import React from 'react';

import { Checkbox, Flex, LssProp } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

type DemographicOptionsProps = {
  options: any[];
  name: string;
};

export const DemographicOptions = ({
  options,
  name
}: DemographicOptionsProps) => {
  const [field, , helpers] = useField(name);

  const handleChange = (value: string) => {
    const fieldValues = !!field?.value ? [...field?.value] : [];
    const setCopy = new Set(fieldValues);

    // toggle for set value and checkbox UI
    if (setCopy.has(value)) {
      setCopy.delete(value);
    } else {
      setCopy.add(value);
    }

    helpers.setValue(setCopy, false);
  };

  return (
    <Flex lss={{ flexDirection: 'row', gap: '1.5rem' }}>
      {options?.map(option => {
        const isChecked = !!(option?.value && field.value?.has(option?.value));

        return (
          <Checkbox
            key={`${option?.type}_${option?.value}`}
            lss={{ mt: 'xxs', mb: 'md', 'text-wrap': 'nowrap' } as LssProp}
            name={`${option?.type}_checkbox`}
            label={option?.label}
            checked={isChecked || false}
            onChange={() => {
              handleChange(option?.value);
            }}
          />
        );
      })}
    </Flex>
  );
};
