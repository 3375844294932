import React, { useContext } from 'react';

import { Box, Message } from '@lucidhq/lucidium';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';

import { getMediaTrackingLeftColLss } from '../../styles';
import { ERROR_LOADING_MAPPING } from '../constants';
import { MappingSetup } from './components';

type MappingGenerationProps = { isCampaignTask: boolean };

export const MappingGeneration: React.FC<MappingGenerationProps> = ({
  isCampaignTask
}) => {
  const {
    mediaToolData: { linearTv },
    setFieldValue
  } = useContext(MediaTrackingToolsFormContext);

  const hasError = !linearTv.length;

  const sorted = linearTv.sort((a, b) => a.partner_id - b.partner_id);

  return (
    <Box
      lss={{
        ...getMediaTrackingLeftColLss(!isCampaignTask),
        mt: hasError ? ('35vh' as any) : 0
      }}
    >
      {hasError ? (
        <Message description={ERROR_LOADING_MAPPING} />
      ) : (
        sorted.map((linear, index) => (
          <MappingSetup
            key={linear.partner_id}
            linearTv={linear}
            sectionIntro={{ title: `${linear.partner_name} Creative IDs` }}
            index={index}
            setFieldValue={setFieldValue}
          />
        ))
      )}
    </Box>
  );
};
