import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';

import { Button, Flex, Icon, Label } from '@lucidhq/lucidium';
import { Field } from '@lucidhq/lucidium/components/FormV2';

import { InfoTooltip } from '~/impact-measurement/components';
import { t } from '~/utils/i18n';

type QueryParam = {
  name: string;
  label: string;
  initialValue: string;
};

type PixelQueryParamFormProps = {
  params: QueryParam[];
};

const ALLOWED_CUSTOM_PARAMS = ['c1', 'c2', 'c3', 'c4', 'c5'];

export const PixelQueryParamForm = ({ params }: PixelQueryParamFormProps) => {
  const [counter, setCounter] = useState(0);

  const fields = params.map(param => {
    const isCustom = ALLOWED_CUSTOM_PARAMS.includes(param.name);
    // If custom attribute, it should be visible only if a value is present
    // Or the allowed number of fields is >= than the param index
    const isConditionallyVisible =
      param.initialValue || +param.name[1] <= counter;

    return {
      ...param,
      isVisible: !isCustom || isConditionallyVisible,
      isCustom
    };
  });

  // Set the initial allowed custom fields visible
  useEffect(() => {
    // Filter custom fields only
    const visibleCustomFields = fields.filter(
      field => field.isCustom && field.isVisible
    );

    if (!visibleCustomFields.length) return;

    const lastCustomField = visibleCustomFields.pop();
    if (lastCustomField) setCounter(+lastCustomField.name[1]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex lss={{ flexDirection: 'column' }}>
      {fields.map(
        param =>
          param.isVisible && (
            <Flex key={param.name} lss={{ alignItems: 'center', mb: 'xl' }}>
              <Flex
                lss={{
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                  minWidth: '30%'
                }}
              >
                <Label lss={{ margin: '0' }}>{param.label}</Label>
                <InfoTooltip
                  title={t(
                    `campaignManager.mediaTracking.siteServed.tooltips.${param.name}`
                  )}
                  placement="top-start"
                  iconLss={{ height: '1.125rem', width: '1.125rem' }}
                />
              </Flex>
              <Flex lss={{ flex: '1' }}>
                <Field
                  name={param.name}
                  data-testid={param.name}
                  type="text"
                  groupLss={{ p: 0, width: '100%' }}
                />
              </Flex>
            </Flex>
          )
      )}

      {ALLOWED_CUSTOM_PARAMS.length > counter && (
        <Flex>
          <Flex lss={{ width: '30%' }}></Flex>
          <Flex lss={{ flex: '1' }}>
            <Button
              type="button"
              emphasis="medium"
              scale="sm"
              onClick={() => setCounter(counter + 1)}
              lss={{
                borderRadius: 'md',
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Icon as={MdAdd} lss={{ marginRight: '0.5rem' }} />{' '}
              {t('campaignManager.actions.addCustom')}
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};
