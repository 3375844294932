import * as React from 'react';

import { initialQuestionsLibraryQuestions } from '~/impact-measurement/constants';

type QuestionsLibraryContextType = {
  questions: QuestionsLibraryQuestion[];
  isLoadingQuestions: boolean;
  questionsError: any;
};

export const QuestionsLibraryContext = React.createContext<
  QuestionsLibraryContextType
>({
  questions: initialQuestionsLibraryQuestions,
  isLoadingQuestions: false,
  questionsError: null
});
