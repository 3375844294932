import React, { ReactNode } from 'react';

import { Form } from '@lucidhq/lucidium/components/FormV2';

import {
  EnhancedTemplateQuestion,
  DEFAULT_GRID_VALUES,
  DEFAULT_CUSTOM_ANSWERS
} from '~/utils/templateQuestions';

export type CustomKPIData = {
  customAnswers: string[];
  customRows: string[];
  columns: string[];
};

const getInitialValues = (question: EnhancedTemplateQuestion) => {
  return {
    customAnswers: !!question?.customAnswers
      ? question?.customAnswers
      : DEFAULT_CUSTOM_ANSWERS,
    customRows:
      !!question?.customRows && !!question.customRows?.length
        ? question.customRows
        : DEFAULT_GRID_VALUES,
    columns:
      !!question?.columns && !!question?.columns.length
        ? question.columns
        : DEFAULT_GRID_VALUES
  };
};

type CustomAnswersContainerProps = {
  question: EnhancedTemplateQuestion;
  children: ReactNode;
};

export const CustomAnswersContainer = ({
  question,
  children
}: CustomAnswersContainerProps) => {
  return (
    <Form
      initialValues={getInitialValues(question)}
      // @ts-ignore
      onSubmit={() => null}
      wrapperLss={{ display: 'flex', width: '100%' }}
    >
      {children}
    </Form>
  );
};
