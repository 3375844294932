import { Campaign } from '~/types';
import { useQuery } from '~/utils/rq';

const getURL = (id?: Campaign['campaign_id']) =>
  `campaigns/${id}/target-recruitment-options/`;

type useFetchTargetedRecruitmentProps = {
  campaignId?: string;
};

export const useFetchTargetedRecruitment = ({
  campaignId
}: useFetchTargetedRecruitmentProps) => {
  const URL = getURL(campaignId);

  return useQuery<any>(
    {
      queryKey: [URL],
      staleTime: 10 * 60 * 60
    },
    {
      method: 'get',
      url: URL
    }
  );
};
