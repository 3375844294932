import React, { useMemo } from 'react';

import { Select, Flex, Radio } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

type GeoLocationOptionProps = {
  name: string;
  label: string;
  isChecked: boolean;
  options?: any[];
  onChange: () => void;
};

export const GeoLocationOption = ({
  name,
  label,
  isChecked,
  options,
  onChange
}: GeoLocationOptionProps) => {
  const [field, , helpers] = useField(name);

  const fieldValue = useMemo(() => (!!field?.value ? [...field?.value] : []), [
    field.value
  ]);

  return (
    <Flex lss={{ flexDirection: 'column', gap: '0.5rem', py: 'xs' }}>
      <Radio
        name={name}
        label={label}
        checked={isChecked}
        onChange={onChange}
      />
      {isChecked && (
        <Select
          isMulti
          labelKey="label"
          valueKey="value"
          value={fieldValue}
          onChange={(val: string[] | null) => {
            const fieldValue = new Set(val ?? []);

            helpers.setValue(fieldValue, false);
          }}
          styles={{
            container: (baseStyles: any, state: any) => ({
              ...baseStyles,
              paddingLeft: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              ...(state?.isFocused && { paddingBottom: '6rem' })
            }),
            menu: (baseStyles: any) => ({
              ...baseStyles,
              overflow: 'auto',
              maxHeight: '128px',
              display: 'flex',
              width: 'fill-available'
            }),
            menuList: (baseStyles: any) => ({
              ...baseStyles,
              width: 'fill-available'
            }),
            option: (baseStyles: any) => ({
              ...baseStyles,
              display: 'flex',
              width: 'fit-content'
            })
          }}
          options={options || []}
        />
      )}
    </Flex>
  );
};
