type EncodeQueryData = (data: { [key: string]: any }) => string;

export const encodeQueryData: EncodeQueryData = (data = {}) => {
  const ret = [];
  for (const d in data) {
    const key = encodeURIComponent(d);
    const rawValue = data[d];

    if (!rawValue) continue;
    const value = encodeURIComponent(data[d]);
    ret.push(key + '=' + value);
  }

  return ret.join('&');
};

export const getURLParam = (name = '') => {
  const params = new URLSearchParams(document.location.search.substring(1));
  return params.get(name);
};
