import { ManagedCampaign } from './campaignManager';

const HOUSEHOLD = 'household';
const HHI = 'hhi';

export const decorateTROptions = (items?: any[]) => {
  if (!items?.length) return null;

  const map = items?.reduce((acc: Record<string, any>, cur) => {
    const qual = cur?.qualification;
    const key = qual.includes(HOUSEHOLD) ? HHI : qual;

    acc[key] = cur;

    return acc;
  }, {});

  for (const key in map) {
    const options = map?.[key]?.['options'];
    map[key]['decoratedOptions'] = options?.map((option: string) => ({
      label: key === HHI ? option.toUpperCase() : option,
      value: option,
      type: key
    }));
  }

  return map;
};

export const mapValuesToSegment = (formValues: any, data: any) => {
  const qualifications: any[] = [];

  for (const key in data) {
    if (key !== 'name' && !!formValues[key]?.size) {
      const qual = {
        id: data[key]?.id,
        qualification: data[key]?.qualification,
        options: [...formValues[key]]?.sort((a, b) => a?.localeCompare(b))
      };
      qualifications.push(qual);
    }
  }

  return {
    name: formValues.name,
    qualifications
  };
};

export const initialValues = {
  name: '',
  age: new Set(),
  gender: new Set(),
  hhi: new Set(),
  region: new Set(),
  state: new Set(),
  DMA: new Set()
};

export const getInitialValues = (managedCampaign: ManagedCampaign) => {
  if (!managedCampaign?.details?.meta?.hasTargetedRecruitment) {
    return initialValues;
  }
  const tr = managedCampaign?.details?.draft_settings?.targeted_recruitment;

  const initValuesCopy = {
    ...initialValues,
    name: !!tr?.name ? tr?.name : ''
  };

  const quals = tr?.qualifications.reduce((acc, cur) => {
    const key = cur?.qualification?.includes(HOUSEHOLD)
      ? HHI
      : cur.qualification;

    acc[key] = new Set(cur.options);

    return acc;
  }, initValuesCopy);

  return quals;
};
