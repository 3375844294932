import React, { useContext } from 'react';

import { Message } from '@lucidhq/lucidium';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';

import {
  SingleTagSetupSection,
  SingleUrlSetup,
  TagCard
} from '../../../components';
import { TAG_KEYS } from '../../../constants';
import { SINGLE_TAG_INTRO, SITE_SERVED_FAIL_MESSAGE } from '../../constants';

type SiteServedSingleSectionProps = {
  siteServedPixel: SiteServedPixel;
  pixelParamsErrors: ErrorResponse;
};

export const SiteServedSingleSection: React.FC<SiteServedSingleSectionProps> = ({
  siteServedPixel,
  pixelParamsErrors
}) => {
  const {
    mediaToolData: { site_served_tags }
  } = useContext(MediaTrackingToolsFormContext);

  if (pixelParamsErrors)
    return <Message description={SITE_SERVED_FAIL_MESSAGE} />;

  return (
    <SingleTagSetupSection
      tagKey={TAG_KEYS.site}
      sectionIntro={SINGLE_TAG_INTRO}
      siteServedPixel={siteServedPixel}
    >
      {site_served_tags.map((tag, index) => (
        <TagCard
          key={`${TAG_KEYS.site}-${index}`}
          index={index}
          tagKey={TAG_KEYS.site}
          title={`Site-Served Pixel ${index + 1}`}
        >
          <SingleUrlSetup
            macros={tag.macros}
            tagKey={TAG_KEYS.site}
            hasMacros={!!tag.macros.length}
            tagIndex={index}
          />
        </TagCard>
      ))}
    </SingleTagSetupSection>
  );
};
