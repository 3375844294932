import React from 'react';

import { Flex, LssProp } from '@lucidhq/lucidium';

import { Z_INDICES } from '~/impact-measurement/constants';

type NavBarProps = {
  children: React.ReactNode;
  lss?: LssProp;
};

export const NavBar: React.FunctionComponent<NavBarProps> = ({
  children,
  lss = {}
}) => {
  return (
    <>
      <Flex
        lss={{
          position: 'relative',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-between',
          top: 0,
          left: 0,
          width: '100%',
          height: 'auto',
          px: 0,
          py: 0,
          bg: 'white',
          borderBottom: 'none',
          zIndex: Z_INDICES.navBar as any,
          ...lss,
          tablet: {
            flexDirection: 'row',
            position: 'fixed',
            top: 80 as any,
            px: 'xl',
            py: 'lg',
            ...lss.tablet
          }
        }}
      >
        {children}
      </Flex>
    </>
  );
};
