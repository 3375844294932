import React from 'react';
import { MdStar, MdStarBorder } from 'react-icons/md';

import {
  Flex,
  TransparentButton,
  Tooltip,
  Text,
  LssProp
} from '@lucidhq/lucidium';
import { OutlineColorProperty } from '@lucidhq/lucidium/lss/csstypes';

import { t } from '~/utils/i18n';

const buildWrapperLss = (isSelected: boolean): LssProp => ({
  alignItems: 'center',
  justifyContent: 'center',
  height: '40px',
  width: '40px',
  bg: isSelected ? 'secondary500' : 'white',
  color: isSelected ? 'white' : 'gray500',
  border: 'regular',
  cursor: isSelected ? 'default' : 'pointer',
  borderRadius: 'sm'
});

type PrimaryKpiButtonProps = {
  isSelected: boolean;
  onClick: () => void;
  readOnly?: boolean;
};

export const PrimaryKpiButton = ({
  isSelected,
  onClick,
  readOnly = false
}: PrimaryKpiButtonProps) => {
  const Icon = isSelected ? MdStar : MdStarBorder;
  const tooltipkey = isSelected ? 'selected' : 'notSelected';

  return (
    <TransparentButton
      type="button"
      onClick={() => !isSelected && onClick()}
      disabled={readOnly}
      lss={{
        outline: 'none',
        p: 'none',
        ':focus': {
          outlineColor: 'hsla(214, 76%, 49%, 0.5)' as OutlineColorProperty
        }
      }}
    >
      {!readOnly ? (
        <Tooltip
          placement="top"
          enforceRefWrapper
          title={
            <Text lss={{ textScale: 'control' }}>
              {t(
                `campaignManager.survey.standardQuestions.tooltips.primaryKPI.${tooltipkey}`
              )}
            </Text>
          }
        >
          <Flex lss={buildWrapperLss(isSelected)}>
            <Icon size={20} />
          </Flex>
        </Tooltip>
      ) : (
        <Flex lss={buildWrapperLss(isSelected)}>
          <Icon size={20} />
        </Flex>
      )}
    </TransparentButton>
  );
};
