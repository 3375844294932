import React, { useContext } from 'react';

import { MediaTrackingToolsFormContext } from '~/impact-measurement/pages/Admin/Contexts';
import {
  BULK_CACHE_DEFAULT,
  BULK_MAID_DEFAULT
} from '~/impact-measurement/pages/Admin/pages/MediaTracking/constants';

import { SingleUrlSetup, TagCard } from '../../../components';
import { TAG_KEYS } from '../../../constants';
import { handleFileUpload } from '../../lib';
import { FileNameBar } from './FileNameBar';

type SiteServedBulkSetupProps = {
  fileName: string;
  handleToggle: () => void;
  setFilename: (name: string) => void;
  setBulkPixels: React.Dispatch<React.SetStateAction<MappingBulkPixels>>;
};

export const SiteServedBulkSetup: React.FC<SiteServedBulkSetupProps> = ({
  fileName,
  handleToggle,
  setFilename,
  setBulkPixels
}) => {
  const {
    setFieldValue,
    mediaToolData: { bulk_tags }
  } = useContext(MediaTrackingToolsFormContext);

  const hasBulk = !!bulk_tags.length;

  return (
    <>
      {bulk_tags.map((tag, index) => (
        <TagCard
          key={`${TAG_KEYS.bulk}-${index}`}
          index={index}
          tagKey={TAG_KEYS.bulk}
          title="Bulk Site-Served Pixels"
        >
          <FileNameBar
            fileName={fileName}
            handleUpload={file => {
              handleFileUpload(file, setBulkPixels, setFilename);
              setFieldValue('bulk_tags', []);
              setFieldValue('bulk_cache_default', BULK_CACHE_DEFAULT);
              setFieldValue('bulk_maid_default', BULK_MAID_DEFAULT);
              !!file && handleToggle();
            }}
            lss={{ mb: 'xxl' }}
          />
          <SingleUrlSetup
            isBulk={hasBulk}
            macros={tag.macros}
            tagKey={TAG_KEYS.bulk}
            hasMacros={!!tag.macros.length}
            tagIndex={index}
          />
        </TagCard>
      ))}
    </>
  );
};
