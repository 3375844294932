import React from 'react';
import { Switch, BrowserRouter, Route, useLocation } from 'react-router-dom';

import { ROUTES } from '~/constants';
import {
  UserConfirmation,
  ScrollToTop
} from '~/impact-measurement/components/';
import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { Summary, ReportDashboard } from '~/impact-measurement/pages';
import * as AdminPages from '~/impact-measurement/pages/Admin/pages';

import { AppcueRedirectPage } from './Pages/AppcueRedirectPage';
import { CampaignManagerLandingPage } from './Pages/CampaignManagerLandingPage';
import { CampaignManagerPage } from './Pages/CampaignManagerPage';
import { HomePage } from './Pages/HomePage';
import { IPMatchingPage } from './Pages/IPMatchingPage';
import { LogoutPage } from './Pages/LogoutPage';
import { CacheProvider } from './Shared/Cache/CacheProvider';
import { AuthorizedRoute } from './Shared/Routes/AuthorizedRoute';

export const Router: React.FC = () => {
  // SEE https://github.com/lucidhq/audience-ui/pull/777 for info on why we are using location here
  const location = useLocation();
  const { isOn: isCustomCampaignsOn } = useSplit(IM_FLAGS.CUSTOM_CAMPAIGNS);

  return (
    <>
      <Route exact path={ROUTES.logout} component={LogoutPage} />
      <AuthorizedRoute exact path={ROUTES.home} component={HomePage} />
      <Route exact path={ROUTES.appcues} component={AppcueRedirectPage} />

      {/* Impact Measurement Routes */}
      <BrowserRouter
        basename="/impact-measurement"
        getUserConfirmation={(message, callback) =>
          UserConfirmation(message, callback)
        }
      >
        <ScrollToTop />
        <Switch>
          <AuthorizedRoute
            path="/"
            exact
            component={() => <Summary key={location.key} />}
          />
          <CacheProvider>
            <AuthorizedRoute
              path="/report/:campaignId"
              exact
              component={ReportDashboard}
            />

            {/* IM Admin Routes */}
            <Switch>
              <AuthorizedRoute
                exact
                path="/admin"
                component={AdminPages.AdminHome}
                isAdminOnly
              />
              <AuthorizedRoute
                exact
                path={`${ROUTES.campaignManagerLanding}`}
                component={CampaignManagerLandingPage}
                isAdminOnly
              />
              <AuthorizedRoute
                exact
                path={`${ROUTES.campaignManager}:typeId/:campaignId?/:stepId?`}
                component={CampaignManagerPage}
                isAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaigns"
                component={AdminPages.AdminCampaigns}
                isAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/new"
                component={
                  isCustomCampaignsOn
                    ? AdminPages.CampaignNew
                    : AdminPages.ProjectSetup
                }
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/draft"
                component={
                  isCustomCampaignsOn
                    ? AdminPages.CampaignDraft
                    : AdminPages.ProjectSetup
                }
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId"
                component={AdminPages.CampaignTasks}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/mapping"
                component={AdminPages.Mapping}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/discrepancy-check"
                component={AdminPages.DiscrepancyCheck}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/discrepancy-check/:discrepancyCheckId"
                component={AdminPages.DiscrepancyCheckReport}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/accounts"
                component={AdminPages.AccountsList}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/account/new"
                component={AdminPages.AccountForm}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/account/:accountId"
                component={AdminPages.AccountDetails}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/account/:accountId/edit"
                component={AdminPages.AccountForm}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/users"
                component={AdminPages.UsersList}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/user/new"
                component={AdminPages.UserForm}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/user/:userId"
                component={AdminPages.UserDetails}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/user/:userId/edit"
                component={AdminPages.UserForm}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/frequency"
                component={AdminPages.FrequencyReporting}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/tracking"
                component={AdminPages.MediaTracking}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path={`${ROUTES.adminCampaign}/:campaignId/ip-matching`}
                component={IPMatchingPage}
                isSuperAdminOnly
              />

              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/details"
                component={AdminPages.CampaignDetails}
                isSuperAdminOnly
              />
              <AuthorizedRoute
                exact
                path="/admin/campaign/:campaignId/review"
                component={AdminPages.SurveyReview}
                isSuperAdminOnly
              />
            </Switch>
          </CacheProvider>

          <Route exact path={ROUTES.logout} component={LogoutPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
};
