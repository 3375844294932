import * as React from 'react';

export const DEFAULT_TOTAL_EXPECTED_FILES = 11;
export const PENDING_EXPORT_STATUS = 'pending_file_export';
export const DEFAULT_FILE_NAME = 'report';

const noop = () => {}; // eslint-disable-line

export type DownloadContextType = {
  files: Record<string, TFile>;
  isExporting: boolean;
  setIsExporting: (isExporting: boolean) => void;
  setFiles: (files: DownloadContextType['files']) => void;
  resetFiles: () => void;
  totalExpectedFiles: number;
  fileName: string;
};

export const DownloadContext = React.createContext<DownloadContextType>({
  files: {},
  isExporting: false,
  setIsExporting: noop,
  setFiles: noop,
  resetFiles: noop,
  totalExpectedFiles: DEFAULT_TOTAL_EXPECTED_FILES,
  fileName: DEFAULT_FILE_NAME
});
