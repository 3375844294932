import React from 'react';

import { Box, LssProp } from '@lucidhq/lucidium';

export const IndicatorDot = ({ lss }: { lss: LssProp }) => (
  <Box
    lss={{
      width: '10px',
      height: '10px',
      bg: 'secondary500',
      borderRadius: 'rounded',
      mx: 'sm',
      mt: '1px' as any,
      ...lss
    }}
  />
);
