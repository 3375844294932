import React from 'react';

import { Box, Flex } from '@lucidhq/lucidium';

import { usePermissions } from '~/components/Permissions/PermissionsProvider';
import { IM_FLAGS } from '~/impact-measurement/constants';
import { useSplit } from '~/impact-measurement/hooks';
import { t } from '~/utils/i18n';

import { CampaignManagerFooter } from '../../CampaignManagerFooter';
import { useCampaignManager } from '../../CampaignManagerProvider';
import { StepLayout } from '../../Steps/StepLayout';
import { SurveyStepIds } from '../../useCampaignManagerSteps';
import { SurveyStepCarousel } from '../SurveyStepCarousel';
import { TemplateQuestionCard } from '../TemplateQuestions/TemplateQuestionCard';
import { useBehavioralQuestions } from './BehavioralQuestionsProvider';
import { DisableBaselineValidationCheckbox } from './DisableBaselineValidationCheckbox';

export const BehavioralQuestions = ({ hasColumn }: { hasColumn: boolean }) => {
  const {
    baselineQuestions,
    socialQuestions,
    saveQuestion,
    submitQuestions,
    deleteQuestion,
    customKPIErrors,
    hasCustomKPIErrors,
    kpiErrors,
    hasKPIErrors
  } = useBehavioralQuestions();
  const {
    goToStep,
    steps,
    activeStep,
    managedCampaign,
    can
  } = useCampaignManager();
  const { isAdmin } = usePermissions();
  const { isOn } = useSplit(IM_FLAGS.DISABLE_USAGE);

  const isSocialCampaign = !!managedCampaign?.details.meta.isSocial;
  const isSocialEnabled = isAdmin;

  const handleBack = () => {
    goToStep(steps.SURVEY.id, {
      activeChildStep: SurveyStepIds.STANDARD_QUESTIONS
    });
  };

  const content = (
    <>
      <StepLayout.Header
        title={t('campaignManager.survey.behavioralQuestions.header.title')}
        description={t(
          'campaignManager.survey.behavioralQuestions.header.description'
        )}
      />
      <Box>
        <Flex lss={{ flexDirection: 'column', flexBasis: '100%', gap: '2rem' }}>
          <Flex
            lss={{ flexDirection: 'column', flexBasis: '100%', gap: '1.5rem' }}
          >
            {!!baselineQuestions.length &&
              baselineQuestions.map((q, index) => (
                <TemplateQuestionCard
                  readOnly={!can.edit}
                  index={index}
                  question={q}
                  key={index}
                  onSave={data => saveQuestion(index, data)}
                  isRequired={!can.editKPI && q.default} // @TODO Update to q.required when the attrs gets added by the API
                  customKPIErrors={customKPIErrors}
                  hasCustomKPIErrors={hasCustomKPIErrors}
                  kpiErrors={kpiErrors}
                  hasKPIErrors={hasKPIErrors}
                  //NOTE: only internal users can remove all baseline questions and swap them with customKPIs
                  {...(can.addCustomKPI && {
                    onDelete: () => deleteQuestion(index)
                  })}
                />
              ))}
          </Flex>
          {isSocialEnabled && isSocialCampaign && !!socialQuestions?.length && (
            <Flex lss={{ flexDirection: 'column' }}>
              <StepLayout.Header
                title={t('campaignManager.survey.socialQuestions.header.title')}
                description={t(
                  'campaignManager.survey.socialQuestions.header.description'
                )}
              />
              {socialQuestions?.map((q, index) => (
                <TemplateQuestionCard
                  readOnly={!can.edit}
                  index={index}
                  question={q}
                  key={index}
                  onSave={data => saveQuestion(index, data)}
                  isRequired
                />
              ))}
            </Flex>
          )}
          {isOn && can.editKPI && (
            <DisableBaselineValidationCheckbox
              value={
                !!managedCampaign?.details?.draft_settings?.ui
                  ?.isBaselineValidationDisabled
              }
            />
          )}
          <Flex lss={{ flexDirection: 'column' }}>
            <SurveyStepCarousel />
            <CampaignManagerFooter
              onBack={handleBack}
              nextProps={{
                isDisabled:
                  hasCustomKPIErrors ||
                  !baselineQuestions?.length ||
                  hasKPIErrors,
                onClick: () => {
                  submitQuestions({
                    onSuccess: () => {
                      const nextStepId = can.limitedEdit
                        ? steps.REVIEW.id
                        : steps[activeStep].nextId;
                      if (nextStepId) goToStep(nextStepId);
                    }
                  });
                }
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );

  return (
    <>
      {!hasColumn && <StepLayout.Container>{content}</StepLayout.Container>}
      {hasColumn && (
        <Box
          lss={{
            width: 'fit-content',
            justifySelf: 'start',
            maxWidth: '995px'
          }}
        >
          {content}
        </Box>
      )}
    </>
  );
};
