import React from 'react';

import { Flex, Label } from '@lucidhq/lucidium';
import { Field } from '@lucidhq/lucidium/components/FormV2';

import { InfoTooltip } from '~/impact-measurement/components';
import { Channel } from '~/impact-measurement/pages/types';
import { t } from '~/utils/i18n';

export enum IMPRESSIONS_FIELD_NAME {
  'digital' = 'draft_settings.expected_impressions_count',
  'linear_tv' = 'draft_settings.expected_impressions_count_linear_tv',
  'social' = 'draft_settings.expected_impressions_count_social'
}

export enum IMPRESSIONS_FIELD_LABEL {
  'digital' = 'Number of Impressions (Digital)',
  'linear_tv' = 'Number of Impressions (Linear TV)',
  'social' = 'Number of Impressions (Social)'
}

const fieldSteps: Record<Channel, number> = {
  digital: 500_000,
  linear_tv: 10_000_000,
  social: 500_000
};

type ImpressionsFieldProps = {
  isChecked: boolean;
  type: Channel;
  impressionsValue?: number;
  isDisabled?: boolean;
};

export const ImpressionsField: React.FC<ImpressionsFieldProps> = ({
  isChecked,
  type,
  impressionsValue,
  isDisabled
}) => (
  <>
    <Flex lss={{ flexDirection: 'row', gap: '2px', alignItems: 'center' }}>
      <Label
        lss={{ my: 'sm' }}
        required={isChecked}
        htmlFor={IMPRESSIONS_FIELD_NAME[type]}
      >
        {t(`forms.labels.impressions.${type}`)}
      </Label>
      <InfoTooltip
        title={t('campaignManager.campaignDetails.tooltips.impressions')}
        placement="top-start"
        iconLss={{ height: '1.125rem', width: '1.125rem' }}
      />
    </Flex>
    <Field
      name={IMPRESSIONS_FIELD_NAME[type]}
      type="number"
      step={fieldSteps[type]}
      disabled={!isChecked || isDisabled}
      placeholder={t(`campaignManager.campaignDetails.placeholders.${type}`)}
      value={impressionsValue || ''}
      groupLss={{ pb: 'none', width: '100%' }}
    />
  </>
);
