import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';

import { BusyButton, Button, Flex, Icon, LssProp } from '@lucidhq/lucidium';

import { t } from '~/utils/i18n';

import { useCampaignManager } from '../../CampaignManagerProvider';
import { StepLayout } from '../../Steps/StepLayout';
import { DeleteTargetedRecruitment } from './DeleteTargetedRecruitment';
import { ManageTargetedRecruitment } from './ManageTargetedRecruitment';
import { SelectedQualifications } from './SelectedQualifications';

const createReadOnlyValue = (options: string[], qual: string): string => {
  if (!options?.length || !qual?.length) return '';

  return options
    .map(option => (qual.includes('household') ? option.toUpperCase() : option))
    .join(', ');
};

const decorateQuals = (quals?: any[]) => {
  if (!quals?.length) return [];

  return quals?.map(q => {
    const key = q?.qualification?.includes('household')
      ? 'hhi'
      : q?.qualification;
    return {
      ...q,
      label: t(
        `campaignManager.targetAudience.targetedRecruitment.modal.labels.${key}`
      ),
      readOnlyValue: createReadOnlyValue(q?.options, q?.qualification)
    };
  });
};

type SegmentItemProps = {
  hasActions: boolean;
  containerLss?: LssProp;
};

export const SegmentItem = ({
  hasActions = true,
  containerLss
}: SegmentItemProps) => {
  const { can, managedCampaign, refetchCampaign } = useCampaignManager();
  const hasTR = !!managedCampaign?.details?.meta?.hasTargetedRecruitment;

  if (!hasTR) return null;

  const decoratedQualifications = decorateQuals(
    managedCampaign?.details?.draft_settings?.targeted_recruitment
      ?.qualifications
  );

  return (
    <StepLayout.List lss={{ paddingLeft: '3.25rem', ...containerLss }}>
      <StepLayout.ListItem
        lss={{ justifyContent: 'space-between', gap: '0.5rem' }}
      >
        <Flex lss={{ flexDirection: 'column', gap: '1rem' }}>
          <StepLayout.ListTitle>
            {
              managedCampaign?.details?.draft_settings?.targeted_recruitment
                ?.name
            }
          </StepLayout.ListTitle>
          <SelectedQualifications qualifications={decoratedQualifications} />
        </Flex>
        {hasActions && (
          <StepLayout.ListActions>
            <ManageTargetedRecruitment>
              {({ onToggle }) => (
                <Button
                  scale="sm"
                  emphasis="low"
                  type="button"
                  onClick={onToggle}
                  disabled={!can.edit}
                >
                  <Icon
                    size={24}
                    as={MdEdit}
                    lss={{ color: 'secondary500', px: 'xxs' }}
                  />
                </Button>
              )}
            </ManageTargetedRecruitment>
            <DeleteTargetedRecruitment onDelete={refetchCampaign}>
              {({ onDelete }) => (
                <BusyButton
                  isBusy={false}
                  scale="sm"
                  emphasis="low"
                  type="button"
                  onClick={onDelete}
                  disabled={!can.edit}
                >
                  <Icon
                    size={24}
                    as={MdDelete}
                    lss={{ color: 'secondary500', px: 'xxs' }}
                  />
                </BusyButton>
              )}
            </DeleteTargetedRecruitment>
          </StepLayout.ListActions>
        )}
      </StepLayout.ListItem>
    </StepLayout.List>
  );
};
