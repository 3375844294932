import React from 'react';

import { Flex } from '@lucidhq/lucidium';

import { SiteServedBulkSection, SiteServedSingleSection } from './components';

type SiteServedSetupProps = {
  siteServedPixel: SiteServedPixel;
  pixelParamsErrors: ErrorResponse;
};

export const SiteServedSetup: React.FC<SiteServedSetupProps> = ({
  siteServedPixel,
  pixelParamsErrors
}) => (
  <Flex lss={{ flexDirection: 'column' }}>
    <SiteServedBulkSection />
    <SiteServedSingleSection
      pixelParamsErrors={pixelParamsErrors}
      siteServedPixel={siteServedPixel}
    />
  </Flex>
);
