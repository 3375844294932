import { useQuery } from '~/utils/rq';

const INDUSTRY_URL = `admin/industries`;

type Industry = {
  id: number;
  industry_name: string;
};

type IndustryResponseType = {
  items: Industry[];
};

export const useFetchIndustries = () => {
  return useQuery<IndustryResponseType>(
    {
      queryKey: [INDUSTRY_URL],
      staleTime: 10 * 60 * 60
    },
    {
      method: 'get',
      url: INDUSTRY_URL
    }
  );
};
