import * as React from 'react';

import { FormikContext, FormikErrors } from 'formik-legacy';

const noop = () => {}; // eslint-disable-line

import { initialMediaTrackingToolsValues } from '../../pages/MediaTracking/constants';

export type MediaTrackingToolsFormContextType = {
  setFieldValue: FormikContext<MediaTrackingToolsData>['setFieldValue'];
  setFieldTouched: FormikContext<MediaTrackingToolsData>['setFieldTouched'];
  mediaToolData: MediaTrackingToolsData;
  isValid: boolean;
  isSubmitting: boolean;
  errors: FormikErrors<MediaTrackingToolsData>;
};

export const MediaTrackingToolsFormContext = React.createContext<
  MediaTrackingToolsFormContextType
>({
  setFieldValue: noop,
  setFieldTouched: noop,
  mediaToolData: initialMediaTrackingToolsValues,
  isValid: false,
  isSubmitting: false,
  errors: {}
});
