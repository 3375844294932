import React from 'react';

import { Box, LssProp } from '@lucidhq/lucidium';

import { QuestionCardContainer } from './QuestionCardContainer';
import { StandardQuestionsList } from './StandardQuestionsList';
import { StandardQuestionsProvider } from './StandardQuestionsProvider';

const stepContainerLss: LssProp = {
  display: 'grid',
  gridTemplateColumns: '2fr 6fr',
  gap: '3rem',
  width: '100%',
  p: 'xl'
};

export const StandardQuestionsStep = () => {
  return (
    <StandardQuestionsProvider>
      <Box lss={stepContainerLss}>
        <StandardQuestionsList />
        <QuestionCardContainer />
      </Box>
    </StandardQuestionsProvider>
  );
};
