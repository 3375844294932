import {
  API_BASE_PATH,
  INTERNAL_BASE_PATH
} from '~/impact-measurement/constants';
import { encodeQueryData } from '~/impact-measurement/utils';

export const URLS = {
  campaign: (campaignId: CampaignId) =>
    `${API_BASE_PATH}/campaigns/${campaignId}`,
  campaignExtend: (campaignId: CampaignId) =>
    `${URLS.campaign(campaignId)}/extend`,
  campaigns: (params: CampaignsOptionsParams = {}) =>
    `${API_BASE_PATH}/campaigns?${encodeQueryData(params)}`,
  campaignStats: (campaignId: CampaignId) =>
    `${URLS.campaign(campaignId)}/stats`,
  serviceCampaign: (campaignId: CampaignId) =>
    `${INTERNAL_BASE_PATH}/services/campaigns/${campaignId}`,
  serviceCampaigns: (params: CampaignsOptionsParams = {}) =>
    `${INTERNAL_BASE_PATH}/services/campaigns?${encodeQueryData(params)}`,
  launchServiceCampaign: (campaignId: CampaignId) =>
    `${INTERNAL_BASE_PATH}/services/campaigns/${campaignId}/launch`
};
