import React from 'react';

import { IPMatchingBasePage } from './IPMatchingBasePage';
import { IPMatchingProvider } from './IPMatchingProvider';

export type MatchedIDs = {
  idType?: string;
  ipID?: string;
  ipMatch?: string;
  status?: boolean;
  dateAdded?: string;
};

export const IPMatching = () => {
  return (
    <IPMatchingProvider>
      <IPMatchingBasePage />
    </IPMatchingProvider>
  );
};
