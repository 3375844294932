import React from 'react';

import { Box, LucidiumThemedComponentProps } from '@lucidhq/lucidium';

import { AudienceTheme } from '~/utils/styles';

type PageContentWrapperProps = LucidiumThemedComponentProps & {};

export const PageContentWrapper: React.FC<PageContentWrapperProps> = ({
  children,
  lss = {}
}) => (
  <Box
    lss={{
      width: '100%',
      maxWidth: AudienceTheme.breakpoints.desktop,
      mb: 'xxxl',
      p: 'xl',
      ...lss,
      tablet: {
        mt: 74 as any,
        mx: 'auto',
        px: 'xl',
        ...lss.tablet
      },
      desktop: {
        px: 'xl',
        ...lss.desktop
      }
    }}
  >
    {children}
  </Box>
);
