import React from 'react';

import { Flex, Select, Label, Text } from '@lucidhq/lucidium';
import { useField } from '@lucidhq/lucidium/components/FormV2';

import { useFetchCountryLanguages } from '~/components/Shared/hooks/useFetchCountryLanguages';
import { EMPTY_ITEMS } from '~/impact-measurement/constants';
import { t } from '~/utils/i18n';

type CountryLanguageFieldProps = {
  name: string;
  readOnly?: boolean;
};

const getCountryLanguageOptions = (
  countryLanguages: CountryLanguage[]
): StandardOption[] =>
  countryLanguages?.map(countryLanguage => ({
    value: (countryLanguage.id as unknown) as string,
    label: `${countryLanguage.country_name} - ${countryLanguage.language_name}`
  }));

export const CountryLanguageField = ({
  name,
  readOnly = false
}: CountryLanguageFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { isLoading, data = EMPTY_ITEMS, error } = useFetchCountryLanguages();
  const countryLanguageOptions = getCountryLanguageOptions(data.items);

  return (
    <Flex
      id="country-language-field"
      lss={{ flexDirection: 'column', mb: 'md' }}
    >
      <Text
        lss={{ my: 'md', fontWeight: 'bold', fontSize: '1rem' }}
        as="strong"
      >
        {t(`campaignManager.campaignDetails.questions.countryLanguage`)}
      </Text>
      <Label lss={{ my: 'md' }} htmlFor={field.name} required>
        {t(`forms.labels.countryLanguage`)}
      </Label>

      <Select
        required
        name={field.name}
        value={field.value}
        error={meta.error}
        disabled={!!error || readOnly}
        placeholder={
          isLoading
            ? t(`forms.placeholders.loadingCountryLanguage`)
            : t(`forms.placeholders.countryLanguage`)
        }
        labelKey="label"
        valueKey="value"
        options={countryLanguageOptions}
        onChange={(option: any) => {
          helpers.setValue(option, false);
        }}
      />
    </Flex>
  );
};
