import React from 'react';
import { MdClose } from 'react-icons/md';

import { Button, Flex, H6, Icon } from '@lucidhq/lucidium';

type TargetedRecruitmentDialogHeaderProps = {
  onClose?: () => void;
  children: React.ReactNode;
};

export const TargetedRecruitmentDialogHeader = ({
  children,
  onClose
}: TargetedRecruitmentDialogHeaderProps) => {
  return (
    <Flex
      lss={{
        justifyContent: 'space-between',
        borderTopLeftRadius: 'md',
        borderTopRightRadius: 'md',
        flexShrink: 0,
        bg: 'gray100',
        borderBottom: 'regular'
      }}
    >
      <H6
        as="h1"
        lss={{
          px: 'md',
          py: 'lg',
          color: 'primary500'
        }}
      >
        {children}
      </H6>

      {onClose && (
        <Button
          type="button"
          onClick={onClose}
          emphasis="medium"
          lss={{
            bg: 'inherit',
            color: 'primary500',
            border: 'none',
            borderRadius: 'none',
            borderTopRightRadius: 'md',
            ':hover': {
              bg: 'gray600',
              color: 'primary500'
            }
          }}
        >
          <Icon as={MdClose} size={18} lss={{ color: 'primary500' }} />
        </Button>
      )}
    </Flex>
  );
};
